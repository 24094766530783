import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE } from 'components/constants';
import Cta from 'components/Cta';
import { Button } from 'components/v2/Button';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';
import hideable from 'utils/hideable';

interface IOffset {
  offset: number;
}

const BannerTopDesktopContainer = styled.div<
  IColor &
    IOffset & { isBackgroundDark: boolean } & { isBackgroundGradient: boolean }
>(({ isBackgroundDark, isBackgroundGradient, background, offset }) => ({
  background,
  display: 'flex',
  width: '100%',
  height: 'auto',
  position: 'fixed',
  top: 0,
  zIndex: 2,
  transformstyle: 'preserve-3d',
  transform: `translateY(${offset}px)`,
  transition: 'transform 1s linear',
  padding: '8px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px hsla(0,0%,100%,.2)',
  boxShadow: '1px 0 24px 0 rgb(0 0 0 / 12%)',
  backgroundImage: isBackgroundGradient
    ? `linear-gradient(to right, #01F0D3, #00D1FF, #9A4BFF)`
    : background,
  p: {
    color: isBackgroundDark
      ? themev2.colors.neutral.N00
      : themev2.colors.black.S900,
  },
  svg: {
    path: {
      fill: isBackgroundDark
        ? themev2.colors.neutral.N00
        : themev2.colors.black.S900,
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    display: 'none',
  },
}));

const BannerTopMobileContainer = styled.div<
  IColor &
    IOffset & { isBackgroundDark: boolean } & { isBackgroundGradient: boolean }
>(({ isBackgroundDark, isBackgroundGradient, background, offset }) => ({
  display: 'none',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    background,
    display: 'flex',
    width: '100%',
    height: 'auto',
    position: 'fixed',
    top: 0,
    zIndex: 2,
    transformstyle: 'preserve-3d',
    transform: `translateY(${offset}px)`,
    transition: 'transform 1s linear',
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px hsla(0,0%,100%,.2)',
    boxShadow: '1px 0 24px 0 rgb(0 0 0 / 12%)',
    backgroundImage: isBackgroundGradient
      ? `linear-gradient(to right, #01F0D3, #00D1FF, #9A4BFF)`
      : background,
    p: {
      color: isBackgroundDark
        ? themev2.colors.neutral.N00
        : themev2.colors.black.S900,
    },
    svg: {
      path: {
        fill: isBackgroundDark
          ? themev2.colors.neutral.N00
          : themev2.colors.black.S900,
      },
    },
  },
}));

export const ContentContainer = styled.div(() => ({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  height: 'auto',
  maxWidth: '1024px',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  alignSelf: CSS_ALIGN.CENTER,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
}));

export const TitleAndIconContainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  div: {
    maxWidth: '21px',
    height: '21px',
    marginRight: getThemeGutter(props),
    div: {
      width: '100%',
    },
  },
  p: {
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: themev2.fonts.lineHeight.p_medium,
    fontWeight: 400,
    fontFamily: themev2.fonts.family.additional,
    marginBottom: 0,
    marginRight: themev2.spacings.S,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: '6px',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    div: {
      width: '424px',
      height: '32px',
      marginBottom: '10px',
      marginRight: 0,
    },
    p: {
      width: '100%',
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '100%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    div: {
      minWidth: '21px',
    },
  },
}));

export const InfoContainer = styled.div((props) => ({
  marginRight: getThemeGutter(props),
  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    marginBottom: 0,
    fontWeight: 500,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: getThemeGutter(props),
    p: {
      width: '100%',
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const ButtonsContainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  svg: {
    width: '12px',
    height: '12px',
    marginLeft: '32px',
    cursor: 'pointer',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '100%',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    svg: {
      display: CSS_DISPLAY.NONE,
    },
    a: {
      minWidth: '200px',
      marginRight: getThemeGutter(props),
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      minWidth: '200px',
      margin: 0,
      marginRight: 0,
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
    a: {
      width: '100%',
    },
  },
}));

export const CtaBlock = styled(Cta)((props) => ({
  width: 'auto',
  height: '32px',
  minWidth: '120px',
  whiteSpace: 'nowrap',
  padding: `3px ${themev2.spacings.XS}`,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  borderRadius: '4px',
  background: themev2.colors.black.S900,
  fontWeight: 500,
  margin: 0,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  ':hover': {
    padding: `3px ${themev2.spacings.XS}`,
    background: themev2.colors.neutral.N90,
  },
}));

export const CloseIcon = styled(Button)({
  position: 'absolute',
  height: '12px',
  width: '12px',
  padding: '0px',
  background: 'transparent',
  cursor: 'pointer',
  margin: `${themev2.spacings.S} 8px`,
  right: 0,
  top: 0,
  svg: {
    width: '12px',
    height: '12px',
  },
});

export const HideableDesktopContainer = hideable(BannerTopDesktopContainer);
export const HideableMobileContainer = hideable(BannerTopMobileContainer);

import { SECTION_NAMES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import { generateSectionsId } from 'features/utils';
import * as React from 'react';
import {
  FeaturesContainer,
  FeaturesTop,
  StyledHeroFeaturesContainer,
  StyledHeroFeaturesWrapper,
} from './styledComponents';
import { IFeaturesUSP } from './types';
import { RichText } from 'components/v2/RichText';

const HeroFeaturesUSP: React.FC<IFeaturesUSP> = ({
  primary: { is_background_dark: isBackgroundDark },
  items,
  pageContext,
}) => {
  const { tags, type } = pageContext || { tags: [] };
  return (
    <StyledHeroFeaturesWrapper
      isBackgroundDark={isBackgroundDark}
      data-section-id={generateSectionsId(
        SECTION_NAMES.HERO_FEATURES,
        tags,
        type
      )}
    >
      <StyledHeroFeaturesContainer>
        {items.map(
          ({
            feature_logo: logo,
            feature_title: title,
            feature_description: description,
          }) => (
            <FeaturesContainer key={title.text}>
              <FeaturesTop>
                <GatsbyImg image={logo} />
                <RichText field={title} />
              </FeaturesTop>
              <RichText field={description} />
            </FeaturesContainer>
          )
        )}
      </StyledHeroFeaturesContainer>
    </StyledHeroFeaturesWrapper>
  );
};

export default HeroFeaturesUSP;

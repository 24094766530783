import { IOption } from 'components/SearchBar/types';
import React from 'react';
import { PRISMIC_VALUES } from 'constants/common';
import {
  BlogHero,
  BlogHeroBackgroundWrapper,
  BlogHeroSubtitle,
  BlogHeroText,
  BlogHeroTitle,
  BlogHeroImage,
  HideableSearchBar,
} from './styledComponents';
import { IHero } from './types';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const Hero: React.FC<IHero> = ({
  primary: { background, accent_color: accentColor, title, subtitle, image },
  articles,
}) => {
  const options: IOption[] = articles.map((article) => ({
    label: article.data.title.text,
    value: `/${article.type}/${article.tags.join('/')}`,
  }));

  return (
    <BlogHeroBackgroundWrapper background={background}>
      <BlogHero>
        <BlogHeroText>
          <BlogHeroTitle accentColor={accentColor}>
            <RichText field={title} />
          </BlogHeroTitle>
          <BlogHeroSubtitle accentColor={accentColor}>
            <RichText field={subtitle} />
          </BlogHeroSubtitle>
        </BlogHeroText>
        <BlogHeroImage>
          <GatsbyImg image={image} loading="eager" />
        </BlogHeroImage>
      </BlogHero>
      <HideableSearchBar
        options={options}
        placeholder={PRISMIC_VALUES.SEARCH_PLACEHOLDER}
      />
    </BlogHeroBackgroundWrapper>
  );
};

export default Hero;

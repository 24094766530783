import { StyledHeroCtaWrapper } from './styledComponents';
import { LinkButton } from 'components/v2/LinkButton';

interface HeroCtasProps {
  order: number;
  primaryCtaLink?: string;
  primaryCtaLabel?: string;
  primaryCtaTrackingSelector: string;
  secondaryCtaLink?: string;
  secondaryCtaLabel?: string;
  secondaryCtaTrackingSelector: string;
}

export const HeroCtas = ({
  order,
  primaryCtaLabel,
  primaryCtaLink,
  primaryCtaTrackingSelector,
  secondaryCtaLabel,
  secondaryCtaLink,
  secondaryCtaTrackingSelector,
}: HeroCtasProps) => {
  return (
    <StyledHeroCtaWrapper order={order}>
      {!!primaryCtaLink && (
        <LinkButton
          data-tracker={primaryCtaTrackingSelector}
          to={primaryCtaLink}
          variant="filled"
          color="blue"
          size="lg"
          fullWidth
        >
          {primaryCtaLabel}
        </LinkButton>
      )}
      {!!secondaryCtaLink && (
        <LinkButton
          data-tracker={secondaryCtaTrackingSelector}
          to={secondaryCtaLink}
          variant="outlined"
          color="white"
          size="lg"
          fullWidth
        >
          {secondaryCtaLabel}
        </LinkButton>
      )}
    </StyledHeroCtaWrapper>
  );
};

import {
  WithPrismicPreviewProps,
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews';
import { DefaultSlice } from 'types';
import { graphql, PageProps } from 'gatsby';
import { PageTemplateQuery } from 'types.generated';
import { getTemplates } from 'helpers/sliceHelper';
import {
  NotFoundPageSliceMap,
  NOT_FOUND_PAGE_SLICES,
} from 'pageUtils/404/constants';
import { Wrapper } from 'commonStyles';

interface IWithPrismic404 {
  prismic404: {
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismic404;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const NotFoundPage = ({ data }: Props & PageTemplateProps) => {
  const { prismic404 } = data;
  const templates = getTemplates<NOT_FOUND_PAGE_SLICES, DefaultSlice>(
    prismic404.data.body,
    NotFoundPageSliceMap
  );
  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} />
      ))}
    </Wrapper>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery {
    prismic404 {
      _previewable
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on Prismic404DataBody404Hero {
            id
            primary {
              title {
                richText
                text
              }
              cta_text {
                richText
                text
              }
              cta_link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

import { PRISMIC_VALUES } from 'constants/common';
import React, { useEffect, useRef } from 'react';
import { PrismicTextField } from 'types';
import { setGeneratedId } from './utils';

export interface IGeneratedIdWrapper {
  text: PrismicTextField;
}

export const GeneratedIdWrapper: React.FC<IGeneratedIdWrapper> = ({
  children,
  text,
  ...props
}) => {
  const idRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    idRef.current && setGeneratedId(text, PRISMIC_VALUES.HEADING_2, idRef);
  }, []);

  return (
    <div {...props} ref={idRef}>
      {children}
    </div>
  );
};

import { TitleWrapper } from './styledComponents';
import React from 'react';
import { IQuestionTitle } from './types';
import { RichText } from 'components/v2/RichText';

const QuestionTitle: React.FC<IQuestionTitle> = ({
  title,
  primary: { background, is_centered: isCentered },
}) => (
  <TitleWrapper background={background} isCentered={isCentered}>
    <RichText field={title} />
  </TitleWrapper>
);

export default QuestionTitle;

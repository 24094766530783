import { useState } from 'react';

export enum HTTP_METHOD {
  POST = 'POST',
}

interface IRequestStatus {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
}

const initialRequestState: IRequestStatus = {
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const setSuccess = (prevState: IRequestStatus) => ({
  ...prevState,
  isSuccess: true,
});

const setError = (prevState: IRequestStatus) => ({
  ...prevState,
  isError: true,
});

const setLoading = (prevState: IRequestStatus) => ({
  ...prevState,
  isLoading: true,
});

const setFinally = (prevState: IRequestStatus) => ({
  ...prevState,
  isLoading: false,
});

export const useApi = (method: HTTP_METHOD, url: string) => {
  const [requestStatus, setRequestStatus] = useState(initialRequestState);
  const reset = () => setRequestStatus(initialRequestState);

  const request = async <T>(body: T, onFinally: () => void) => {
    const httpOptions: RequestInit = {
      body: JSON.stringify(body),
      method,
    };

    try {
      setRequestStatus(setLoading);
      await fetch(url, httpOptions).then((res) => {
        if (res.ok) {
          setRequestStatus(setSuccess);
        } else {
          setRequestStatus(setError);
        }
      });
    } catch (error) {
      setRequestStatus(setError);
    } finally {
      setRequestStatus(setFinally);
      onFinally();
    }
  };

  return { ...requestStatus, reset, request };
};

import Hero from 'features/Hero';
import { Spacing } from 'features/Spacing/Spacing';
import TextColumns from 'features/TextColumns';
import { SliceMap } from 'types';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { PrismicImageTabs, TextBlock } from '@smartproxy-web/features';

export enum LEGAL_SLICES {
  Hero = 'hero_block',
  TextBlock = 'text_block',
  Spacing = 'spacing',
  TextColumn = 'text_column',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  ImageTabs = 'image_tabs',
}

export const legalSliceMap: SliceMap<LEGAL_SLICES> = {
  [LEGAL_SLICES.Hero]: Hero,
  [LEGAL_SLICES.TextBlock]: TextBlock,
  [LEGAL_SLICES.Spacing]: Spacing,
  [LEGAL_SLICES.TextColumn]: TextColumns,
  [LEGAL_SLICES.FooterBanner]: FooterBanner,
  [LEGAL_SLICES.Herov2]: Herov2,
  [LEGAL_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [LEGAL_SLICES.HeroLogosv2]: HeroLogosv2,
  [LEGAL_SLICES.ImageTabs]: PrismicImageTabs,
};

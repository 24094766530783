import styled from '@emotion/styled';
import GatsbyImg from 'components/GatsbyImg';
import { IconCloseWhite } from 'images';
import React from 'react';
import Modal from './Modal';
import { TGatsbyImage } from 'types';
import { convertEmbedDimensionsToFill } from '../Hero/helpers';
import { Lightbox } from '@smartproxy-web/ui';

const ImageWrapper = styled.div({
  maxWidth: '740px',
});

const IconWrapper = styled.div({
  marginTop: '20px',
  position: 'absolute',
  top: 0,
  right: 0,
  width: '4em',
  opacity: 0.8,
  cursor: 'pointer',
  ':hover': {
    opacity: 1,
  },
});

interface Props {
  isVisible: boolean;
  onClose: (arg: boolean) => void;
  image?: TGatsbyImage;
  video?: string;
  closeBtnTrackingId?: string;
}

const ImageModal: React.FC<Props> = ({
  image,
  onClose,
  isVisible,
  video,
  closeBtnTrackingId,
}) => (
  <Modal isVisible={isVisible} onClick={onClose}>
    {video ? (
      <Lightbox
        closeBtnTrackingId={closeBtnTrackingId || ''}
        isOpen={isVisible}
        handleClose={() => onClose(false)}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: convertEmbedDimensionsToFill(video),
          }}
        />
      </Lightbox>
    ) : (
      image && (
        <ImageWrapper>
          <GatsbyImg image={image} />
        </ImageWrapper>
      )
    )}
    <IconWrapper>
      <IconCloseWhite />
    </IconWrapper>
  </Modal>
);

export default ImageModal;

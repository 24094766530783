import styled from '@emotion/styled';
import { ComponentPropsWithoutRef } from 'react';
import { Box } from '../Box';

type ListAs = 'ul' | 'ol';

const StyledList = styled(Box)({
  padding: 0,
  margin: 0,
});

type ListProps = ComponentPropsWithoutRef<'ul'> & {
  as?: ListAs;
};

export const List = ({ as, ...rest }: ListProps) => {
  return <StyledList as={as} {...rest} />;
};

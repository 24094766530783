import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import { IconArrowLeft, IconArrowRight } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import { buildTrackingSelector } from 'utils/page';
import {
  Description,
  ImageContainer,
  LeftArrow,
  Name,
  RightArrow,
  Role,
  TestimonialWrapper,
} from './styledComponents';
import { IItem } from './types';

const Testimonial: React.FC<IItem> = ({
  name,
  isTextLight = false,
  description,
  role,
  image,
  onRightArrowClick,
  onLeftArrowClick,
}) => {
  const { tags, type } = usePageContext();
  return (
    <TestimonialWrapper>
      <div>
        <Description isTextLight={isTextLight}>
          <RichText field={description} />
        </Description>
        <Name>
          <RichText field={name} />
        </Name>
        <Role>
          <RichText field={role} />
        </Role>
      </div>
      <ImageContainer>
        <GatsbyImg image={image} />
      </ImageContainer>
      <LeftArrow
        onClick={onLeftArrowClick}
        className={buildTrackingSelector({
          page: tags[0] || type,
          section: 'testimonials',
          context: 'arrow-left',
        })}
      >
        <IconArrowLeft />
      </LeftArrow>
      <RightArrow
        onClick={onRightArrowClick}
        className={buildTrackingSelector({
          page: tags[0] || type,
          section: 'testimonials',
          context: 'arrow-right',
        })}
      >
        <IconArrowRight />
      </RightArrow>
    </TestimonialWrapper>
  );
};

export default hideable(Testimonial);

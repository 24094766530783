import { apiEndpoints } from 'apiEndpoints';
import { InquiryPage } from 'features/InquiryForm';
import { INQUIRY_FORM_SLICES } from 'features/InquiryForm/constants';
import { inquiryFormQuery } from 'features/InquiryForm/query';
import {
  IFormState,
  IInquiryFormConfirmation,
  TForkmikHelpers,
} from 'features/InquiryForm/types';
import { getSlice, mapFormDataToPostData } from 'features/InquiryForm/utils';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import Form from './Form';
import FormConfirmation from './FormConfirmation';
import { HideableFormContent } from './styledComponents';
import { IFormContent } from './types';

const FormContent: React.FC<IFormContent> = ({ buttonColor }) => {
  const { ErrorModal, SuccessModal } = INQUIRY_FORM_SLICES;
  const [showing, setShowing] = useState<InquiryPage>(InquiryPage.FORM);
  const { isLoading, isError, isSuccess, request } = useApi(
    HTTP_METHOD.POST,
    apiEndpoints.inquiryFormSubmit
  );
  const handleTryAgain = () => setShowing(InquiryPage.FORM);

  const handleClose = () => setShowing(InquiryPage.FORM);

  useEffect(() => {
    if (isError) setShowing(InquiryPage.ERROR);
    if (isSuccess) setShowing(InquiryPage.SUCCESS);
  }, [isError, isSuccess]);

  const handleSubmit = (values: IFormState, actions: TForkmikHelpers) =>
    request(mapFormDataToPostData(values), () => actions.resetForm);

  const { body, ...rest } = inquiryFormQuery().prismicInquiryForm.data;
  const successSlice = getSlice<IInquiryFormConfirmation>(body, SuccessModal);
  const errorSlice = getSlice<IInquiryFormConfirmation>(body, ErrorModal);
  return (
    <>
      <HideableFormContent isVisible={showing === InquiryPage.FORM}>
        <Form
          onSubmit={handleSubmit}
          isLoading={isLoading}
          buttonColor={buttonColor}
          {...rest}
        />
      </HideableFormContent>
      <HideableFormContent isVisible={showing === InquiryPage.ERROR}>
        <FormConfirmation {...errorSlice} onClick={handleTryAgain} />
      </HideableFormContent>
      <HideableFormContent isVisible={showing === InquiryPage.SUCCESS}>
        <FormConfirmation
          {...successSlice}
          primary={{
            ...successSlice.primary,
            cta_text:
              showing === InquiryPage.SUCCESS
                ? 'Book a demo call'
                : successSlice.primary.cta_text,
          }}
          onClick={handleClose}
        />
      </HideableFormContent>
    </>
  );
};

export default FormContent;

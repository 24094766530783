import styled from '@emotion/styled';
import { ColContainer, RowContainer } from 'commonStyles';
import { responsiveInputStyle } from 'components/commonStyles';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { getGutter, getInputMaxWidth } from 'components/utils';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_FLEX_WRAP,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeErrorColor,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeInputBorderShape,
  getThemeInputHeightShape,
  getThemeInputWidthShape,
  getThemeNeutralColor,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';
import { IStyledInput } from './types';

export const getInputBorderColor = (theme: ITheme, hasError?: boolean) =>
  !!hasError
    ? getThemeErrorColor(theme, COLOR_SHADE.MAIN)
    : getThemeNeutralColor(theme, COLOR_SHADE.LIGHT);

export const InputWrapper = styled.div<IStyledInput & ITheme>(
  ({ inputWidth, ...props }) => ({
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    maxWidth: getInputMaxWidth(props, inputWidth),
    position: CSS_POSITION.RELATIVE,
    ...responsiveInputStyle,
  })
);

export const StyledInput = styled.input<IStyledInput & ITheme>(
  ({ hasError, inputWidth, inputHeight, ...props }) => ({
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    border: `1px solid ${getInputBorderColor(props, hasError)}`,
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    height: getThemeInputHeightShape(props, inputHeight),
    maxWidth: getInputMaxWidth(props, inputWidth),
    padding: '8px 12px',
    ...responsiveInputStyle,
  })
);

export const InputContainer = styled(ColContainer)<IStyledInput & ITheme>(
  (props) => ({
    flexWrap: CSS_FLEX_WRAP.WRAP,
    alignItems: CSS_ALIGN.START,
    margin: getGutter(props),
    width: '100%',
  })
);

export const Label = styled.span<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  whiteSpace: 'nowrap',
  paddingRight: '10px',
  marginBottom: '6px',
  lineHeight: '20px',
  maxWidth: '210px',
}));

export const InputWithContent = styled(RowContainer)<IStyledInput & ITheme>(
  (props) => ({
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    alignItems: CSS_ALIGN.CENTER,
  })
);

const IconContainer = styled.div({
  position: CSS_POSITION.ABSOLUTE,
  top: '9px',
  right: '12px',
});

export const HideableLabelContainer = hideable(ColContainer);
export const HidableIconContainer = hideable(IconContainer);

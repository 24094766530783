import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  getThemeBrandedColors,
  getThemeButtonConversionColor,
  getThemeButtonFollowingColor,
  getThemeButtonImportantColor,
  getThemeButtonInactiveColor,
  getThemeButtonNotImportantColor,
  getThemeButtonOperationalColor,
  getThemeInputBorderShape,
  getThemeInputFontSizeShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  IClickable,
  IColorWithHover,
  getThemeInputPaddingShape,
  ITheme,
  MAIN_COLOR,
  getThemeButtonDefaultColor,
} from 'theme';
import { responsiveInputStyle } from './commonStyles';
import { TEXT_TYPE, TSHIRT_SIZE } from './constants';
import { getGutter } from './utils';

export enum BUTTON_THEMES {
  DEFAULT = 'default',
  BRANDED = 'branded',
  FUNCTIONAL = 'functional',
  NEUTRAL = 'neutral',
  CONVERSION = 'conversion',
  OPERATIONAL = 'operational',
  UNIMPORTANT = 'unImportant',
  IMPORTANT = 'important',
  FOLLOWING = 'following',
  INACTIVE = 'inactive',
}

export enum BUTTON_TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ADDITIONAL = 'additional',
}

export interface IStyledButton {
  themeName: BUTTON_THEMES;
  fontSize?: TSHIRT_SIZE;
  height?: TSHIRT_SIZE;
  textType?: TEXT_TYPE;
  themeType?: BUTTON_TYPES;
  gutterBot?: TSHIRT_SIZE;
  gutterTop?: TSHIRT_SIZE;
  sidePadding?: string;
  width?: TSHIRT_SIZE;
  testId?: string;
  gutterLeft?: TSHIRT_SIZE;
  gutterRight?: TSHIRT_SIZE;
  type?: 'button' | 'submit' | 'reset';
}

export interface IButton {
  onClick?: (e: React.SyntheticEvent) => void;
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  isDone?: boolean;
  testId?: string;
}

export type TButton = IStyledButton & IButton & ITheme;

type TButtonColorsMap = (props: IStyledButton & ITheme) => {
  [key in BUTTON_THEMES]: {
    [COLOR_SHADE.PRIMARY]?: string;
    [COLOR_SHADE.SECONDARY]?: string;
    text?: { main: string; hover?: string };
    border?: string;
    background?: IColorWithHover;
    clickable?: IClickable;
    cursor?: string;
  };
};

const mapButtonColors: TButtonColorsMap = ({ themeType, ...props }) => ({
  [BUTTON_THEMES.DEFAULT]: {
    ...getThemeButtonDefaultColor(props, themeType),
  },
  [BUTTON_THEMES.BRANDED]: {
    ...getThemeBrandedColors(props),
    text: { main: getThemeMainColor(props) },
  },
  [BUTTON_THEMES.FUNCTIONAL]: { ...getThemeBrandedColors(props) },
  [BUTTON_THEMES.NEUTRAL]: { ...getThemeBrandedColors(props) },
  [BUTTON_THEMES.CONVERSION]: getThemeButtonConversionColor(props, themeType),
  [BUTTON_THEMES.OPERATIONAL]: getThemeButtonOperationalColor(props, themeType),
  [BUTTON_THEMES.UNIMPORTANT]: getThemeButtonNotImportantColor(
    props,
    themeType
  ),
  [BUTTON_THEMES.IMPORTANT]: getThemeButtonImportantColor(props, themeType),
  [BUTTON_THEMES.FOLLOWING]: getThemeButtonFollowingColor(props, themeType),
  [BUTTON_THEMES.INACTIVE]: getThemeButtonInactiveColor(props, themeType),
});

const StyledButton = styled.button<IStyledButton & ITheme>(
  ({ themeName, width, disabled, fontSize, ...props }) => {
    const colors = mapButtonColors({ themeName, ...props });
    return {
      padding:
        props.themeType !== BUTTON_TYPES.SECONDARY
          ? getThemeInputPaddingShape(props, TSHIRT_SIZE.L)
          : getThemeInputPaddingShape(props, TSHIRT_SIZE.M),
      margin: getGutter(props),
      boxSizing: 'border-box',
      color: colors[themeName]?.text?.main,
      backgroundColor: colors[themeName]?.background?.main,
      opacity: disabled ? '0.4' : 'unset',
      border: colors[themeName]?.border || '1px solid transparent',
      fontSize: getThemeInputFontSizeShape(props, fontSize),
      fontWeight: 500,
      borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
      cursor: disabled ? 'not-allowed' : colors[themeName]?.cursor || 'pointer',
      ':focus': {
        outline: 'none',
      },
      width: width ? getThemeInputWidthShape(props, width) : 'initial',
      ':hover': {
        color: colors[themeName]?.text?.hover,
        borderWidth: '2px',
        borderColor:
          colors[themeName]?.border ||
          getThemeMainColor(props, MAIN_COLOR.BLACK),
        backgroundColor: disabled
          ? colors[themeName]?.background?.main
          : colors[themeName]?.background?.hover,
        padding:
          props.themeType !== BUTTON_TYPES.SECONDARY
            ? getThemeInputPaddingShape(props, TSHIRT_SIZE.L)
            : getThemeInputPaddingShape(props, TSHIRT_SIZE.S),
      },
      ...responsiveInputStyle,
    };
  }
);

const Wrapper = styled.span<{ sidePadding?: string }>(
  ({ sidePadding = '0' }) => ({
    padding: `0 ${sidePadding}`,
  })
);

const Button = ({
  onClick,
  themeName,
  fontSize,
  themeType,
  height,
  children,
  isDisabled,
  isLoading,
  className,
  textType,
  gutterTop,
  gutterBot,
  width,
  type,
  sidePadding,
  testId,
  gutterLeft,
  gutterRight,
  ...rest
}: TButton) => {
  const clickHandler = (e: React.SyntheticEvent) => {
    if (type === 'submit') {
      e.preventDefault();
    }
    onClick && onClick(e);
  };

  return (
    <StyledButton
      onClick={clickHandler}
      disabled={isDisabled || isLoading || themeName === BUTTON_THEMES.INACTIVE}
      themeName={themeName}
      fontSize={fontSize}
      className={className}
      themeType={themeType}
      height={height}
      textType={textType}
      gutterBot={gutterBot}
      gutterTop={gutterTop}
      gutterLeft={gutterLeft}
      gutterRight={gutterRight}
      width={width}
      type={type}
      data-testid={testId}
      sidePadding={sidePadding}
      {...rest}
    >
      <Wrapper sidePadding={sidePadding}>{children}</Wrapper>
    </StyledButton>
  );
};

export default withTheme(Button);

import { forwardRef } from 'react';
import { StyledBadge } from './styledComponents';
import { BadgeProps } from './types';

export const Badge = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BadgeProps>
>((props, ref) => {
  const {
    children,
    className,
    color = 'red',
    variant = 'filled',
    fullWidth = false,
    as,
    ...rest
  } = props;

  return (
    <StyledBadge
      {...rest}
      ref={ref}
      as={as}
      fullWidth={fullWidth}
      className={className}
      variant={variant}
      color={color}
    >
      {children}
    </StyledBadge>
  );
});

import { SECTION_NAMES } from 'constants/common';
import ImageVideoPlayer from 'features/Hero/ImageVideoPlayer';
import { generateSectionsId, slugify } from 'features/utils';
import React from 'react';
import Blocks from './Blocks';
import {
  StyledContainer,
  StyledTextContainer,
  StyledWrapper,
} from './styledComponents';
import { IHeroAbout } from './types';
import { updateColorInfo } from 'components/utils';
import { usePageContext } from 'contexts/PageContext';
import { RichText } from 'components/v2/RichText';
import { HeroBadges } from '@smartproxy-web/features';
import { initBuildTrackingSelector } from 'utils/page';

const HeroAboutUs: React.FC<IHeroAbout> = ({
  primary: {
    accent_color: accentColor,
    block_one_text: blockOneText,
    block_one_title: blockOneTitle,
    block_two_text: blockTwoText,
    block_two_title: blockTwoTitle,
    description,
    hero_image: image,
    title,
    video_embed: video,
  },
}) => {
  const { tags, type } = usePageContext();
  const buildClickId = (btnType: string) => {
    return `hero-${slugify(tags[0] || type)}-${btnType}-cta`;
  };
  const createBadgeTrackingID = initBuildTrackingSelector({
    section: 'hero-badge',
    type: 'link',
  });
  const updatedAccentColor = updateColorInfo(accentColor.toLowerCase());

  return (
    <StyledWrapper
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledContainer>
        <StyledTextContainer color={updatedAccentColor.color}>
          <HeroBadges
            trackingSelectors={{
              g2: createBadgeTrackingID({ context: 'g2' }),
              proxyway: createBadgeTrackingID({ context: 'proxyway' }),
              trustpilot: createBadgeTrackingID({ context: 'trustpilot' }),
            }}
          />
          <RichText field={title} />
          <RichText field={description} />
          <Blocks
            blockOneText={blockOneText}
            blockOneTitle={blockOneTitle}
            blockTwoText={blockTwoText}
            blockTwoTitle={blockTwoTitle}
            updatedAccentColor={updatedAccentColor.color}
          />
        </StyledTextContainer>
        <ImageVideoPlayer
          image={image}
          video={video}
          buildClickId={buildClickId}
        />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default HeroAboutUs;

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { flexColContainerStyles, HideOnMobile } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE } from 'components/constants';

import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';

const FadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const ToTop = keyframes({
  from: {
    transform: 'translateY(150px)',
  },

  to: {
    transform: 'translateY(0px)',
  },
});
export const ContactsBarContainer = styled.div<
  ITheme & { itemsNumb: number; scrolled: boolean; opacity: number }
>(({ itemsNumb, scrolled, opacity, ...props }) => ({
  position: CSS_POSITION.FIXED,
  display: CSS_DISPLAY.GRID,
  gridTemplateRows: `repeat(${itemsNumb}, 60px)`,
  columnGap: '16px',
  rowGap: '15px',
  right: '40px',
  top: '190px',
  padding: getThemeInputPaddingShape(props),
  borderRadius: '12px',
  background: getThemeMainColor(props),
  height: 'auto',
  boxShadow: '0 0 16px 0 rgba(0,0,0, .12)',
  opacity: scrolled ? opacity : 0,
  transition: 'opacity 0.1s ease-in-out',
  transform: 'translateY(30px)',
  animation: `${ToTop} .2s forwards`,
  zIndex: 2,
  ...HideOnMobile,
}));

export const HideableContactsBarContainer = hideable(ContactsBarContainer);

export const IconContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  width: '60px',
  height: '60px',
  img: {
    margin: '0 auto',
    maxWidth: '38px',
    maxHeight: '38px',
  },
  ':hover': {
    cursor: 'pointer',
    borderRadius: '50%',
    border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,
  },
}));

export const AdditionalTab = styled.div<
  ITheme & { topPosition: number; isOpen: boolean }
>(({ topPosition, isOpen, ...props }) => ({
  ...flexColContainerStyles,
  position: CSS_POSITION.ABSOLUTE,
  right: '100px',
  top: `${topPosition}%`,
  display: isOpen ? CSS_DISPLAY.FLEX : CSS_DISPLAY.NONE,
  width: '240px',
  padding: '20px',
  background: getThemeMainColor(props),
  boxShadow: '0 0 16px 0 rgba(0,0,0, .12)',
  borderRadius: '12px',
  svg: {
    position: CSS_POSITION.ABSOLUTE,
    transform: 'rotate(90deg)',
    height: '22px',
    top: '25px',
    right: '-25px',
  },
}));

export const HideableAdditionalTab = hideable(AdditionalTab);

export const Tabs = styled.div({
  display: CSS_DISPLAY.GRID,
  zIndex: 10,
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '10px',
  marginBottom: '20px',
});

export const Tab = styled.div<ITheme & { selected: boolean }>(
  ({ selected, ...props }) => ({
    p: {
      textAlign: TEXT_ALIGN.CENTER,
      height: '30px',
      padding: '3px 10px 0',
      border: `1px solid ${getThemeMainColor(props, MAIN_COLOR.BLACK)}`,
      borderRadius: '6px',
      background: selected
        ? getThemeMainColor(props, MAIN_COLOR.BLACK)
        : getThemeMainColor(props, MAIN_COLOR.WHITE),
      color: selected
        ? getThemeMainColor(props, MAIN_COLOR.WHITE)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
      cursor: 'pointer',
    },
  })
);

export const HideableTabs = hideable(Tabs);
export const HideableTab = hideable(Tab);

export const BottomTextContainer = styled.div({
  marginTop: '10px',
});

export const HideableBottomTextContainer = hideable(BottomTextContainer);

export const SmallText = styled.div<ITheme>((props) => ({
  'p, strong, em': {
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
}));

export const BottomText = styled.div<ITheme>((props) => ({
  'p, strong, em': {
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
  },
}));

export const BarcodeContainer = styled.div({
  animation: `${FadeIn} .3s forwards`,
  img: {
    width: '100%',
  },
});

export const HideableBarcodeContainer = hideable(BarcodeContainer);

export const BarcodeInfoContainer = styled.div<{ isVisible: boolean }>(
  ({ isVisible }) => ({
    div: {
      display: isVisible ? CSS_DISPLAY.BLOCK : CSS_DISPLAY.NONE,
    },
  })
);

import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import ProductCard from './ProductCard';
import {
  HideableTitles,
  ProductCardsWrapper,
  Wrapper,
} from './styledComponents';
import { IProductCards } from './types';

const ProductCards: React.FC<IProductCards> = ({
  primary: { background, accent_color: accentColor, title, subtitle },
  items,
}) => {
  const { tags, type } = usePageContext();
  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'products-cards',
  });

  return (
    <Wrapper background={background} data-tracker={buildTrackingSelector()}>
      <HideableTitles isVisible={!!title || !!subtitle}>
        <RichText field={title} />
        <RichText field={subtitle} />
      </HideableTitles>
      <ProductCardsWrapper>
        {items.map((item) => (
          <ProductCard
            key={item.product_title.text}
            {...item}
            accentColor={accentColor}
            trackerCta={buildTrackingSelector({
              context: item.product_title.text,
              type: 'cta',
            })}
            trackerPopup={buildTrackingSelector({
              context: item.product_title.text,
              type: 'button',
            })}
          />
        ))}
      </ProductCardsWrapper>
    </Wrapper>
  );
};

export default ProductCards;

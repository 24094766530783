import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  FaqBlock,
  PrismicBlogImage,
  PrismicImageTabs,
  TextImageDynamic,
} from '@smartproxy-web/features';
import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import SideImageTextBlock from 'features/Blog/BlogPost/SideImageTextBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import ColumnLinks from 'features/ColumnsLinks';
import CtaBlock from 'features/CtaBlock';
import ExpandablePricingCards from 'features/ExpandablePricingCards';
import { FeatureComparisonTable } from 'features/FeatureComparisonTable';
import Hero from 'features/Hero';
import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import IconListBlock from 'features/IconListBlock';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import PaymentMethod from 'features/PaymentMethod';
import ProxyListCountries from 'features/ProxyListCountries';
import RelatedArticles from 'features/RelatedArticles';
import { Spacing } from 'features/Spacing/Spacing';
import TableBlock from 'features/TableBlock';
import TableOfContent from 'features/TableOfContent';
import Title from 'features/Title';
import TrustpilotReviews from 'features/TruspilotReviews';
import UseCasesBlock from 'features/UseCasesBlock';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import ProductCards from 'features/ProductCards';
import { SliceMap } from 'types';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import TargetsList from 'features/TargetsList';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { PrismicTextGrid, TextBlock } from '@smartproxy-web/features';

export enum TARGETS_SLICES {
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  Spacing = 'spacing',
  Title = 'title',
  ExpandableProductsPricingOverview = 'expandable_pricing_overview',
  BadgesBlock = 'badges_block',
  BiggerPlansBanner = 'bigger_plans',
  FaqBlock = 'faq_block',
  ImageTextBlock = 'image_text_block',
  ImageTextColumn = 'image_text_column',
  LocationFlags = 'location_flags',
  PaymentMethod = 'payment_method',
  TextGrid = 'text_grid',
  TrustpilotReviews = 'trustpilot_reviews',
  TextBlock = 'text_block',
  TableBlock = 'proxy_table',
  CtaBlock = 'cta_block',
  ColumnsTextBlock = 'column_text',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  ColumnLinks = 'column_links',
  BlogImage = 'blog_image',
  SideImageTextBlock = 'side_image_text_block',
  IconListBlock = 'icon_list_block',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  ProxyListCountries = 'proxy_list_countries',
  TextImageDynamic = 'dynamic_text_and_image',
  FeatureComparisonTable = 'feature_comparison_table',
  UseCasesBlock = 'use_case_block',
  TableOfContent = 'table_of_content',
  RelatedArticles = 'related_articles',
  CodeBlock = 'code_block',
  ImageTabs = 'image_tabs',
  ProductCard = 'product_card',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  TargetsList = 'targets_list',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  AutomatedPricingCards = 'automated_pricing_cards',
}

export const targetsSliceMap: SliceMap<TARGETS_SLICES> = {
  [TARGETS_SLICES.Breadcrumbs]: Breadcrumbs,
  [TARGETS_SLICES.Hero]: Hero,
  [TARGETS_SLICES.Spacing]: Spacing,
  [TARGETS_SLICES.Title]: Title,
  [TARGETS_SLICES.ExpandableProductsPricingOverview]: ExpandablePricingCards,
  [TARGETS_SLICES.BadgesBlock]: BadgesBlock,
  [TARGETS_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [TARGETS_SLICES.FaqBlock]: FaqBlock,
  [TARGETS_SLICES.ImageTextBlock]: ImageTextBlock,
  [TARGETS_SLICES.ImageTextColumn]: ImageTextColumn,
  [TARGETS_SLICES.LocationFlags]: LocationFlags,
  [TARGETS_SLICES.PaymentMethod]: PaymentMethod,
  [TARGETS_SLICES.TextGrid]: PrismicTextGrid,
  [TARGETS_SLICES.TrustpilotReviews]: TrustpilotReviews,
  [TARGETS_SLICES.TextBlock]: TextBlock,
  [TARGETS_SLICES.TableBlock]: TableBlock,
  [TARGETS_SLICES.CtaBlock]: CtaBlock,
  [TARGETS_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [TARGETS_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [TARGETS_SLICES.VideoBlock]: VideoBlock,
  [TARGETS_SLICES.ColumnLinks]: ColumnLinks,
  [TARGETS_SLICES.BlogImage]: PrismicBlogImage,
  [TARGETS_SLICES.SideImageTextBlock]: SideImageTextBlock,
  [TARGETS_SLICES.IconListBlock]: IconListBlock,
  [TARGETS_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
  [TARGETS_SLICES.ProxyListCountries]: ProxyListCountries,
  [TARGETS_SLICES.TextImageDynamic]: TextImageDynamic,
  [TARGETS_SLICES.FeatureComparisonTable]: FeatureComparisonTable,
  [TARGETS_SLICES.UseCasesBlock]: UseCasesBlock,
  [TARGETS_SLICES.TableOfContent]: TableOfContent,
  [TARGETS_SLICES.RelatedArticles]: RelatedArticles,
  [TARGETS_SLICES.CodeBlock]: CodeBlock,
  [TARGETS_SLICES.ImageTabs]: PrismicImageTabs,
  [TARGETS_SLICES.ProductCard]: ProductCards,
  [TARGETS_SLICES.SocialProofBlock]: SocialProofBlock,
  [TARGETS_SLICES.FeaturedIn]: FeaturedIn,
  [TARGETS_SLICES.TargetsList]: TargetsList,
  [TARGETS_SLICES.FooterBanner]: FooterBanner,
  [TARGETS_SLICES.Herov2]: Herov2,
  [TARGETS_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [TARGETS_SLICES.HeroLogosv2]: HeroLogosv2,
  [TARGETS_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
};

import styled from '@emotion/styled';
import { BackgroundWrapper } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeFontSize,
  getThemeGutter,
} from 'theme';

export const BackgroundContainer = styled(BackgroundWrapper)<{
  isSubtitleCentered: boolean;
  isSubtitleAdded: boolean;
}>(({ background }) => ({
  background,
  maxWidth: 'unset',
  margin: 0,
  padding: '0 20px 80px',
}));

export const ContentContainer = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '100px 1fr',
  gap: '40px',
  maxWidth: '1024px',
  width: '100%',
  'p, em, strong': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 500,
  },
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const IconsContainer = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(5, auto )',
  columnGap: '40px',
  alignItems: CSS_ALIGN.CENTER,
  gap: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.FLEX,
    flexWrap: 'wrap',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

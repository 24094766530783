import styled from '@emotion/styled';
import useId from 'hooks/useId';
import { theme } from 'theme-v2/theme';

type SwitchProps = {
  onToggle: () => void;
  isOn: boolean;
  label?: string;
  labelPosition?: 'left' | 'right';
};

export default function Switch({
  onToggle,
  isOn,
  label,
  labelPosition = 'left',
}: SwitchProps) {
  const randomId = useId();

  function handleToggle() {
    onToggle();
  }

  return (
    <StyledWrapper>
      {labelPosition === 'left' && label}
      <StyledInput
        checked={isOn}
        onChange={handleToggle}
        type="checkbox"
        id={randomId}
      />
      <StyledInputLabel isOn={isOn} htmlFor={randomId}>
        <StyledInputLabelButton />
      </StyledInputLabel>
      {labelPosition === 'right' && label}
    </StyledWrapper>
  );
}

const StyledInputLabelButton = styled.span({
  content: '""',
  position: 'absolute',
  top: '0.2rem',
  left: '0.2rem',
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '100%',
  transition: '0.2s',
  background: '#fff',
  boxShadow: '0 0 2px 0 rgba(10, 10, 10, 0.29)',
});

const StyledInputLabel = styled.label<{ isOn }>(({ isOn }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  width: '3.6rem',
  height: '1.875rem',
  marginLeft: '0.4rem',
  borderRadius: '100px',
  position: 'relative',
  backgroundColor: isOn ? theme.colors.primary.P99 : theme.colors.gray.S100,
  transition: 'background-color .2s',
  userSelect: 'none',

  ':active': {
    [`${StyledInputLabelButton}`]: {
      width: '2rem',
    },
  },
}));

const StyledWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledInput = styled.input({
  height: 0,
  width: 0,
  display: 'none',

  [`:checked +  ${StyledInputLabel} ${StyledInputLabelButton}`]: {
    left: 'calc(100% - 2px)',
    transform: 'translateX(-100%)',
  },
});

import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  FaqBlock,
  PrismicBlogImage,
  PrismicImageTabs,
} from '@smartproxy-web/features';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import TableBlock from 'features/TableBlock';
import TrustpilotReviews from 'features/TruspilotReviews';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import { SliceMap } from 'types';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { PrismicTextGrid, TextBlock } from '@smartproxy-web/features';

export enum INTEGRATIONS_SLICES {
  BadgesBlock = 'badges_block',
  BiggerPlansBanner = 'bigger_plans',
  Breadcrumbs = 'breadcrumbs',
  FaqBlock = 'faq_block',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  ImageTextColumn = 'image_text_column',
  LocationFlags = 'location_flags',
  PaymentMethod = 'payment_method',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  TrustpilotReviews = 'trustpilot_reviews',
  TextBlock = 'text_block',
  TableBlock = 'proxy_table',
  CtaBlock = 'cta_block',
  ColumnsTextBlock = 'column_text',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  BlogImage = 'blog_image',
  CodeBlock = 'code_block',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  AutomatedPricingCards = 'automated_pricing_cards',
  ImageTabs = 'image_tabs',
}

export const integrationsSliceMap: SliceMap<INTEGRATIONS_SLICES> = {
  [INTEGRATIONS_SLICES.BadgesBlock]: BadgesBlock,
  [INTEGRATIONS_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [INTEGRATIONS_SLICES.Breadcrumbs]: Breadcrumbs,
  [INTEGRATIONS_SLICES.FaqBlock]: FaqBlock,
  [INTEGRATIONS_SLICES.Hero]: Hero,
  [INTEGRATIONS_SLICES.ImageTextBlock]: ImageTextBlock,
  [INTEGRATIONS_SLICES.ImageTextColumn]: ImageTextColumn,
  [INTEGRATIONS_SLICES.LocationFlags]: LocationFlags,
  [INTEGRATIONS_SLICES.PaymentMethod]: PaymentMethod,
  [INTEGRATIONS_SLICES.Spacing]: Spacing,
  [INTEGRATIONS_SLICES.TextGrid]: PrismicTextGrid,
  [INTEGRATIONS_SLICES.TrustpilotReviews]: TrustpilotReviews,
  [INTEGRATIONS_SLICES.TextBlock]: TextBlock,
  [INTEGRATIONS_SLICES.TableBlock]: TableBlock,
  [INTEGRATIONS_SLICES.CtaBlock]: CtaBlock,
  [INTEGRATIONS_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [INTEGRATIONS_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [INTEGRATIONS_SLICES.VideoBlock]: VideoBlock,
  [INTEGRATIONS_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
  [INTEGRATIONS_SLICES.BlogImage]: PrismicBlogImage,
  [INTEGRATIONS_SLICES.CodeBlock]: CodeBlock,
  [INTEGRATIONS_SLICES.SocialProofBlock]: SocialProofBlock,
  [INTEGRATIONS_SLICES.FeaturedIn]: FeaturedIn,
  [INTEGRATIONS_SLICES.FooterBanner]: FooterBanner,
  [INTEGRATIONS_SLICES.Herov2]: Herov2,
  [INTEGRATIONS_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [INTEGRATIONS_SLICES.HeroLogosv2]: HeroLogosv2,
  [INTEGRATIONS_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [INTEGRATIONS_SLICES.ImageTabs]: PrismicImageTabs,
};

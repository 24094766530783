import { RichText } from 'components/v2/RichText';
import { USE_CASES_BLOCK } from 'constants/common';
import React from 'react';
import ColumnItem from './ColumnItem';
import {
  ListItem,
  MainTitle,
  UseCasesContainer,
  Wrapper,
} from './styledComponents';
import { IUseCasesBlock } from './types';

const UseCasesBlock: React.FC<IUseCasesBlock> = ({
  primary: {
    background_color: background,
    main_title: mainTitle,
    column_title_1: column1,
    column_title_2: column2,
    column_title_3: column3,
    number_of_columns: numbOfColumns,
  },
  items,
}) => (
  <Wrapper background={background}>
    <MainTitle>
      <RichText field={mainTitle} />
    </MainTitle>
    <UseCasesContainer numbOfColumns={numbOfColumns}>
      {[
        USE_CASES_BLOCK.MARKET,
        USE_CASES_BLOCK.MEDIA,
        USE_CASES_BLOCK.RETAIL,
      ].map((useCase, index) => (
        <ListItem key={useCase}>
          <RichText field={[column1, column2, column3][index]} />
          {items.map(({ group_name: groupName, ...item }) =>
            groupName === useCase ? (
              <ColumnItem key={item.subtitle?.text} {...item} />
            ) : null
          )}
        </ListItem>
      ))}
    </UseCasesContainer>
  </Wrapper>
);

export default UseCasesBlock;

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Spinner from 'components/Spinner';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  BottomTextWrapper,
  CtaText,
  FormControl,
  StyledButton,
} from '../styledComponents';
import { IInquiryFormControl } from '../types';

const InquiryFormControl: React.FC<IInquiryFormControl> = ({
  bottomText,
  ctaText,
  isLoading,
  isDisabled,
  ...rest
}) => (
  <FormControl>
    <BottomTextWrapper>
      <RichText field={bottomText} />
    </BottomTextWrapper>
    <StyledButton
      fontSize={TSHIRT_SIZE.L}
      gutterTop={TSHIRT_SIZE.XXS}
      gutterBot={TSHIRT_SIZE.XXS}
      themeName={BUTTON_THEMES.FOLLOWING}
      themeType={BUTTON_TYPES.PRIMARY}
      isDisabled={isDisabled}
      sidePadding="12px"
      {...rest}
    >
      {!isLoading ? <CtaText>{ctaText}</CtaText> : <Spinner />}
    </StyledButton>
  </FormControl>
);

export default InquiryFormControl;

import styled from '@emotion/styled';
import { HideOnMobile } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledHomePageHeroWrapper = styled.div<ITheme>((props) => ({
  background: themev2.colors.black.S900,
  overflow: 'hidden',
  padding: '62px 20px 60px',
  height: 'auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.TABLET]: {
    padding: `20px 20px ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
  },
}));

export const StyledHomePageHeroContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
}));

export const HeroHomePage = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.XL),
  rowGap: getThemeGutter(props, TSHIRT_SIZE.XL),
  gridTemplateColumns: '1fr 500px',
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_ALIGN.START,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    gridTemplateColumns: '1fr',
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageImageBlock = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  maxWidth: '500px',
  maxHeight: 'none',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  div: {
    width: '100%',
    height: '100%',
  },
  ...HideOnMobile,
}));

export const HeroHomePageTextBlock = styled.div<ITheme>((props) => ({
  gridColumn: '1 / 2',
  gridRow: '1 / 2',
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.FLEX_START,
  p: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    color: getThemeMainColor(props),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    alignItems: CSS_ALIGN.CENTER,
    order: 1,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    },
  },
}));

export const HeroTitleWrapper = styled.div<ITheme & { color: string }>(
  ({ color, ...props }) => ({
    h1: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      marginTop: '20px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.H1),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H1),
      textAlign: TEXT_ALIGN.LEFT,
      color: color,
      [MEDIA_QUERIES.TABLET]: {
        textAlign: CSS_ALIGN.CENTER,
      },
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        fontSize: getThemeFontSize(props, TEXT_TYPE.H1),
        lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H1),
      },
    },
  })
);

export const HeroFeature = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  color: getThemeMainColor(props),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
  fontFamily: getThemeFontFamily(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
  div: {
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
    width: '20px',
    height: '20px',
  },
}));

export const TrustpilotContainer = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  display: CSS_DISPLAY.INLINE_BLOCK,
  div: {
    width: '123px',
    height: '28px',
  },
  img: {
    width: '123px',
    height: '28px',
  },
  span: {
    color: getThemeMainColor(props),
    marginLeft: getThemeGutter(props, TSHIRT_SIZE.XS),
    marginBottom: 0,
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    fontWeight: 500,
  },
  [MEDIA_QUERIES.TABLET]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const CtaHeroUSP = styled(Cta)<ITheme>((props) => ({
  width: '200px',
  height: '50px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  margin: 0,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    width: '220px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '220px',
    maxWidth: '220px',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '100%',
  },
}));

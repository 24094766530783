import React, { ChangeEvent, useCallback } from 'react';
import InputError from '../InputError';
import {
  HidableIconContainer,
  HideableLabelContainer,
  InputContainer,
  InputWithContent,
  InputWrapper,
  Label,
  StyledInput,
} from './styledComponents';
import { TInput } from './types';

const Input: React.FC<TInput> = ({
  error,
  gutterBot,
  gutterLeft,
  gutterRight,
  gutterTop,
  icon,
  inputHeight,
  inputWidth,
  label,
  onChange,
  ...rest
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value);
  }, []);

  return (
    <InputContainer
      gutterBot={gutterBot}
      gutterTop={gutterTop}
      gutterLeft={gutterLeft}
      gutterRight={gutterRight}
    >
      <HideableLabelContainer isVisible={!!label}>
        <Label>{label}</Label>
      </HideableLabelContainer>
      <InputWrapper inputWidth={inputWidth}>
        <InputWithContent>
          <StyledInput
            onChange={handleChange}
            inputHeight={inputHeight}
            hasError={!!error}
            inputWidth={inputWidth}
            {...rest}
          />
          <HidableIconContainer isVisible={!!icon}>{icon}</HidableIconContainer>
        </InputWithContent>
        <InputError error={error} isVisible={error} />
      </InputWrapper>
    </InputContainer>
  );
};

export default Input;

import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import {
  COMPACT_HEADER_MEDIA_QUERY,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'features/v2/Header/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeGutter,
  ITheme,
} from 'theme';
import { IColor } from 'types';

export const flexRowContainerStyles = {
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.ROW,
};
export const flexColContainerStyles = {
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
};

export const flexShrinkBasisStyles = {
  flexShrink: 0,
  flexBasis: 'auto',
};

export const ColContainer = styled.div({
  ...flexColContainerStyles,
});
export const RowContainer = styled.div({
  ...flexRowContainerStyles,
});

export const FullSizeFlex = styled.div({
  flex: 1,
});

export const BackgroundWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    maxWidth: '1024px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
    width: '100%',
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
  })
);

export const Wrapper = styled.div<ITheme & { sidePadding?: string }>(
  ({ sidePadding = '0' }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    padding: `${HEADER_HEIGHT_DESKTOP}px ${sidePadding} 0`,
    width: '100%',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      padding: `${HEADER_HEIGHT_MOBILE}px ${sidePadding} 0`,
    },
    '[data-section-name=hero]': {
      marginTop: -HEADER_HEIGHT_DESKTOP,
      [COMPACT_HEADER_MEDIA_QUERY]: {
        marginTop: -HEADER_HEIGHT_MOBILE,
      },
    },
  })
);

export const HideOnMobile = {
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.NONE,
  },
};

export const SeoLinks = [
  {
    rel: 'dns-prefetch',
    href: 'https://www.googletagmanager.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.google-analytics.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://youtube.com',
  },
];

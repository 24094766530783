import { Box } from 'components/v2/Box';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { Ctas } from './components/Ctas';
import { Features } from './components/Features';
import { Reviews } from './components/Reviews';
import {
  StyledDescription,
  StyledHeroWrapper,
  StyledHeroWrapperInner,
  StyledImageWrapper,
  StyledTitle,
} from './styledComponents';
import { HeroPPCProps } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

export const HeroLpPPC: React.FC<HeroPPCProps> = ({
  primary: {
    award_badge: awardBadge,
    description,
    features,
    highlighted_text: highlightedText,
    primary_cta_label: primaryCtaLabel,
    primary_cta_link: primaryCtaLink,
    secondary_cta_label: secondaryCtaLabel,
    secondary_cta_link: secondaryCtaLink,
    show_money_back_guarantee,
    title,
    reviews_title: reviewsTitle,
  },
  items,
}) => {
  const { tags, type } = usePageContext();

  const buildCtaTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero-lp-ppc',
    type: 'cta',
  });

  const highlightedTextDiv = !!highlightedText
    ? title.text.replace(
        new RegExp(highlightedText || '', 'g'),
        `<span>${highlightedText}</span>`
      )
    : title.text;

  return (
    <StyledHeroWrapper>
      <StyledHeroWrapperInner>
        {!!awardBadge && (
          <StyledImageWrapper>
            <GatsbyImg image={awardBadge} loading="eager" />
          </StyledImageWrapper>
        )}
        <Box
          sx={{
            paddingTop: { base: '20px', md: '24px' },
            paddingBottom: { base: '32px', md: '36px' },
            margin: '0 auto',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <StyledTitle>
            <h1 dangerouslySetInnerHTML={{ __html: highlightedTextDiv }} />
          </StyledTitle>
          <StyledDescription>
            <RichText field={description} />
          </StyledDescription>
        </Box>
        <Ctas
          buildCtaTrackingSelector={buildCtaTrackingSelector}
          primaryCtaLink={primaryCtaLink}
          primaryCtaLabel={primaryCtaLabel}
          secondaryCtaLink={secondaryCtaLink}
          secondaryCtaLabel={secondaryCtaLabel}
        />
        <MoneyBackTag isVisible={show_money_back_guarantee} />
        {!!features?.richText.length && !!features?.richText[0]?.text && (
          <Features features={features} />
        )}
        {!!reviewsTitle && !!items.length && (
          <Reviews reviewsTitle={reviewsTitle} items={items} />
        )}
      </StyledHeroWrapperInner>
    </StyledHeroWrapper>
  );
};

import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import InquiryForm from 'features/InquiryForm';
import Dialog from 'features/Dialog';
import { changeDomain } from 'helpers/updateLink';
import StickyContactsBar from 'features/StickyContactsBar';
import { isBrowser } from 'utils/build';
import BannerTop from 'features/BannerTop';
import Header from 'features/v2/Header/Header';
import { Footer } from 'features/v2/Footer';
import { getTypeFromPath } from 'components/utils';
import { DEALS, LP } from 'constants/common';
import LeadsPopup from 'features/LeadsPopup';
import { HideableHeaderLpDeals } from 'features/v2/HeaderLpDeals/Header';

export const PageWrapper: React.FC<PageProps> = ({ children, location }) => {
  useEffect(() => {
    changeDomain();
  }, []);
  const currentType = getTypeFromPath(location.pathname);
  const path = isBrowser ? location.pathname : '';
  const isDealsOrLpPage = currentType === DEALS || currentType === LP;

  return (
    <>
      <Header location={location} isVisible={!isDealsOrLpPage} />
      <HideableHeaderLpDeals
        isVisible={isDealsOrLpPage}
        primary={{
          logo_alignment: false,
          logo_clickable: true,
        }}
      />
      {children}
      <Footer isDealsOrLpPage={isDealsOrLpPage} />
      <Dialog pathname={path} />
      <InquiryForm />
      <StickyContactsBar />
      <BannerTop pathname={path} />
      <LeadsPopup />
    </>
  );
};

export default PageWrapper;

import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const BadgesBlockContainer = styled.div<
  ITheme & { isImageOnLeft: boolean }
>(({ isImageOnLeft, ...props }) => ({
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 0`,
  display: CSS_DISPLAY.FLEX,
  flexDirection: isImageOnLeft
    ? CSS_FLEX_DIRECTION.ROW
    : CSS_FLEX_DIRECTION.ROW_REVERSE,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  maxWidth: '1024px',
  [MEDIA_QUERIES.TABLET]: {
    flexWrap: CSS_FLEX_WRAP.WRAP,
  },
}));

export const TextContainer = styled.div<
  ITheme & { isCentered: boolean; isTextWhite: boolean }
>(({ isCentered, isTextWhite, ...props }) => ({
  width: '45%',
  maxWidth: '1024px',
  textAlign: TEXT_ALIGN.LEFT,
  'h1, h2, h3': {
    textAlign: TEXT_ALIGN.LEFT,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H2),
    fontWeight: 600,
    marginBottom: getThemeGutter(props),
    color: isTextWhite
      ? getThemeMainColor(props, MAIN_COLOR.WHITE)
      : getThemeNeutralColor(props, COLOR_SHADE.DEEP),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
  p: {
    margin: 0,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    color: isTextWhite
      ? getThemeMainColor(props, MAIN_COLOR.WHITE)
      : getThemeNeutralColor(props, COLOR_SHADE.SECONDARY),
  },
  [MEDIA_QUERIES.TABLET]: {
    textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    width: '100%',
  },
}));

export const TitleContainer = styled.div<{ color: string }>(({ color }) => ({
  'h1, h2, h3': {
    color,
    [MEDIA_QUERIES.TABLET]: {
      fontWeight: 500,
    },
  },
}));

export const BadgesList = styled.ul<ITheme & { items: number }>(
  ({ items, ...props }) => ({
    listStyle: 'none',
    padding: 0,
    width: '50%',
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: 'repeat(3, 150px)',
    gridTemplateRows: `repeat(${Math.ceil(items / 3)}, 150px)`,
    columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
    [MEDIA_QUERIES.TABLET]: {
      width: '100%',
      columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
      justifyContent: CSS_JUSTIFY_CONTENT.SPACE_AROUND,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: 'repeat(3, 134px)',
      gridTemplateRows: `repeat(${Math.ceil(items / 3)}, 134px)`,
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: '1fr',
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.M),
    },
  })
);

export const BadgeItem = styled.li({
  padding: 0,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '134px',
  },
});

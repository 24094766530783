import { PRISMIC_VALUES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import Link from 'components/GatsbyLink';
import {
  ArticleImageWrapper,
  ArticleTitleShorter,
} from 'features/ArticleThumbnails/styledComponents';
import {
  getComma,
  getTopicLabel,
  getTopicUrl,
} from 'features/ContentTags/utils';
import { StyledTopicsLink } from 'features/SocialButtonsBar/styledComponents';
import { shortenText } from 'features/utils';
import { IconClock } from 'images';
import { IBody } from 'pageUtils/blog/types';
import React from 'react';
import { BLOG_SLICES } from 'templates/Blog/constants';
import { PrismicTextField, TGatsbyImage } from 'types';
import { ArticleAuthorItem } from './ArticleAuthorItem';
import {
  ArticleContainer,
  ArticleDescription,
  ArticleImage,
  ArticleInfoContainer,
  ArticleTitle,
  HideableArticleDetailsContainer,
  HideableReadmore,
  HideableTimeContainer,
  HideableTopics,
  HideableTopicsTitle,
} from './styledComponents';

interface IAllArticlesItem {
  descriptionLength: number;
  isHomepage: boolean;
  time_spent_reading?: number;
  title: PrismicTextField;
  description: PrismicTextField;
  article_image: TGatsbyImage;
  release_date: string;
  body: IBody[];
  url: string;
}
export const AllArticlesItem: React.FC<IAllArticlesItem> = ({
  article_image: articleImage,
  title,
  description,
  release_date: releaseDate,
  time_spent_reading: readingTime,
  body,
  url,
  descriptionLength,
  isHomepage,
}) => {
  const topics = body
    .filter(
      (bodyItem) =>
        bodyItem.slice_type === BLOG_SLICES.SocialButtonsBar &&
        bodyItem.items.some((x) => !!x.topic?.document)
    )
    .map(({ items }) => items)
    .flat();

  return (
    <ArticleContainer isHomepage={isHomepage}>
      {!isHomepage ? (
        <ArticleImage>
          <Link to={url} isForcedInternalLink>
            <GatsbyImg image={articleImage} />
          </Link>
        </ArticleImage>
      ) : (
        <ArticleImageWrapper>
          <Link to={url} isForcedInternalLink>
            <GatsbyImg image={articleImage} />
          </Link>
        </ArticleImageWrapper>
      )}
      <ArticleInfoContainer isHomepage={isHomepage}>
        <Link to={url} isForcedInternalLink>
          {!isHomepage ? (
            <ArticleTitle>{title.text}</ArticleTitle>
          ) : (
            <ArticleTitleShorter>{title.text}</ArticleTitleShorter>
          )}
        </Link>
        <Link to={url} isForcedInternalLink>
          <ArticleDescription isHomepage={isHomepage}>
            {shortenText(description.text, descriptionLength)}
          </ArticleDescription>
        </Link>
        <HideableTopics isVisible={!!topics.length} isHomepage={isHomepage}>
          <HideableTopicsTitle isVisible={!isHomepage}>
            {PRISMIC_VALUES.TOPICS}
          </HideableTopicsTitle>
          {topics.map(({ topic }, key) => (
            <Link
              isForcedInternalLink
              key={topic?.document?.id}
              to={getTopicUrl(topic?.document?.tags)}
            >
              <StyledTopicsLink isHomepage={isHomepage}>
                {getTopicLabel(topic?.document)}
              </StyledTopicsLink>
              {getComma(key, topics?.length)}
            </Link>
          ))}
        </HideableTopics>
        <HideableArticleDetailsContainer isVisible={!isHomepage}>
          <HideableReadmore isVisible={!isHomepage}>
            <Link to={url} isForcedInternalLink>
              {PRISMIC_VALUES.READ_MORE}
            </Link>
          </HideableReadmore>
          <HideableTimeContainer isVisible={readingTime}>
            <IconClock />
            {readingTime} {PRISMIC_VALUES.TIME_READ_ARTICLES}
          </HideableTimeContainer>
          {body.map(
            (item) =>
              item.slice_type === BLOG_SLICES.BlogAuthor && (
                <ArticleAuthorItem
                  releaseDate={releaseDate}
                  key={item.primary.blog_author.document.id}
                  authorName={item.primary.blog_author.document.data.full_name}
                  authorImage={
                    item.primary.blog_author.document.data.authors_image
                  }
                />
              )
          )}
        </HideableArticleDetailsContainer>
      </ArticleInfoContainer>
    </ArticleContainer>
  );
};

import { normalizeString } from '@smartproxy-web/shared/utils';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import {
  TABS,
  CONTACT_SALES_URL_HASH,
  NAMES,
  PRISMIC_VALUES,
  SECTION_NAMES,
} from 'constants/common';
import { Product, products } from 'constants/products';
import { clearPlanCookies, setPlanCookies } from 'helpers/cookiesHelper';
import { RefObject } from 'react';
import { colors } from 'theme-v2/colors';
import { PrismicTextField } from 'types';
import { isBrowser } from 'utils/build';

type TGenerateTitleId = (title: string) => string;

export interface IBackground {
  isBackgroundDark: boolean;
}
export type TSetGeneratedId = (
  text: PrismicTextField,
  titleType: string,
  ref: RefObject<HTMLDivElement>
) => void;

export const generateTitleId: TGenerateTitleId = (title) => {
  const initialNumberingRegex = /^[0-9.\s?]+/g;
  const spacesRegex = /\s+/g;
  const punctuationRegex = /[^A-Z\u4e00-\u9fa5-]/gi;

  return title
    .toLowerCase()
    .trim()
    .replaceAll(initialNumberingRegex, '')
    .replaceAll(spacesRegex, '-')
    .replaceAll('&', '和')
    .replaceAll(punctuationRegex, '');
};

export const slugify: TGenerateTitleId = (title) => {
  if (!title) return '';

  const initialNumberingRegex = /^([0-9]\.\s?)+/g;
  const spacesRegex = /\/|\s+/g;
  const punctuationRegex = /[^A-Za-z0-9\u4e00-\u9fa5-]/gi;

  return title
    .toLowerCase()
    .trim()
    .replaceAll(initialNumberingRegex, '')
    .replaceAll(spacesRegex, '-')
    .replaceAll('&', '和')
    .replaceAll(punctuationRegex, '');
};

export const generateSectionsId = (
  section: SECTION_NAMES | string,
  tags: string[],
  type: string
) =>
  tags.length > 1
    ? `${section}-${slugify(tags.join('-'))}`
    : `${section}-${slugify(tags[0] || type)}`;

export const isValidForTrackingSelector = (str: string) =>
  str.replace(/[^a-zA-Z0-9\s-]+/, '').toLowerCase() === str.toLowerCase();

export const shortenText = (text: string, number: number) => {
  const formatedText = text.split('').slice(0, number).join('');
  if (text.length > number + 1) {
    return formatedText + PRISMIC_VALUES.DOTS;
  }
  return formatedText;
};

export const scrollSmoothUnset = () => {
  document.querySelector('html')!.style.scrollBehavior = 'unset';
};

export const scrollSmooth = () => {
  document.querySelector('html')!.style.scrollBehavior = 'smooth';
};

export const defaultAnimationOptions404 = {
  loop: true,
  autoplay: true,
};

export const contactSalesPath =
  isBrowser && window.location.href + CONTACT_SALES_URL_HASH;

export const cookieHandler = ({
  name,
  scrapingApiPlan,
  DDCNonSeq,
  product,
  e,
}: {
  name: string;
  scrapingApiPlan?: string;
  DDCNonSeq?: boolean;
  product?: Product;
  e?: React.SyntheticEvent;
}) => {
  e?.preventDefault();
  name === NAMES.PAYG_NAME
    ? clearPlanCookies()
    : setPlanCookies({ planName: name, scrapingApiPlan, DDCNonSeq, product });
};

export const ctaLinkHandler = (ctaLink: string) => {
  return ctaLink == `https://${CONTACT_SALES_URL_HASH}`
    ? contactSalesPath
    : ctaLink;
};

export const options = (labelValueOne: string, labelValueTwo: string) => {
  return [
    {
      label: labelValueOne,
      value: TABS.LEFT,
    },
    { label: labelValueTwo, value: TABS.RIGHT },
  ];
};

export const setGeneratedId: TSetGeneratedId = (text, titleType, ref) => {
  if (!text) return;
  text.richText?.find((item) =>
    item?.type === titleType
      ? ref.current?.setAttribute('id', slugify(item?.text || ''))
      : null
  );
};

export const getCheckmarkColor = (theme: string) => {
  if (theme === 'Green') return '#3CC700';
  if (theme === 'Red') return '#F44862';
  return '#2C96F7';
};

export interface IBackground {
  isBackgroundDark: boolean;
}

export const assertProduct = (
  possibleProduct: string
): possibleProduct is Product => {
  return products.includes(normalizeString(possibleProduct) as Product);
};

export const updateColorInfo = (color: string) => {
  const colorMap = {
    blue: {
      themeName: BUTTON_THEMES.IMPORTANT,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#2C96F7',
    },
    red: {
      themeName: BUTTON_THEMES.CONVERSION,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#F44862',
    },
    green: {
      themeName: BUTTON_THEMES.FOLLOWING,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#00BE7A',
    },
    yellow: {
      themeName: BUTTON_THEMES.UNIMPORTANT,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#F8A300',
    },
    transparent: {
      themeName: BUTTON_THEMES.CONVERSION,
      themeType: BUTTON_TYPES.SECONDARY,
      color: '#FFFF',
    },
    blue_border: {
      themeName: BUTTON_THEMES.DEFAULT,
      themeType: BUTTON_TYPES.SECONDARY,
      color: colors.blue.default,
    },
  };

  return colorMap[color];
};

export const getUpdatedAccentColor = (accentColor: string) => {
  const updatedColor =
    accentColor !== 'None' &&
    updateColorInfo(accentColor?.toLowerCase().split(' ').join('_'));

  return updatedColor;
};

export const scrollWithMouse = (element) => {
  if (element !== null)
    element!.addEventListener('wheel', (e) => {
      e.preventDefault();
      element!.scrollLeft += e.deltaY;
    });
};

export const copyToClipboard = async (text: string) =>
  await navigator.clipboard.writeText(text);

export const appendPlanIdToURL = (url: string, planId: string) =>
  url && url.includes('?')
    ? `${url}&planId=${planId}`
    : `${url}?planId=${planId}`;

import React from 'react';
import { IReviews } from './types';
import { StyledImageContainer, StyledReviews } from './styledComponents';
import hideable from 'utils/hideable';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const Reviews: React.FC<IReviews> = ({
  review_stars: reviewStars,
  review_trustpilot: reviewTrustpilot,
  review_text: reviewText,
  isStarsAdded,
}) => (
  <StyledReviews>
    <StyledImageContainer isStarsAdded={isStarsAdded}>
      <GatsbyImg image={reviewTrustpilot} loading="eager" />
    </StyledImageContainer>
    <StyledImageContainer>
      <GatsbyImg image={reviewStars} loading="eager" />
    </StyledImageContainer>
    <RichText field={reviewText} />
  </StyledReviews>
);

export default hideable(Reviews);

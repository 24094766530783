import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import { useScroll } from 'hooks/useScrollBlock';
import { IconClose } from 'images';
import React, { useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  DEFAULT_DESKTOP_OFFSET,
  DESKTOP_OFFSET_START,
  DESKTOP_SCROLL_POSITION,
} from './constants';
import {
  ButtonsContainer,
  ContentContainer,
  CtaBlock,
  HideableDesktopContainer,
  InfoContainer,
  TitleAndIconContainer,
} from './styledComponents';
import { TBannerContainer } from './types';

const BannerDesktopContainer: React.FC<TBannerContainer> = ({
  cta_link: ctaLink,
  cta_label: ctaLabel,
  info,
  icon,
  title,
  background,
  is_background_gradient: isBackgroundGradient,
  is_background_dark: isBackgroundDark,
  isVisible,
  onClose,
}) => {
  const { tags, type } = usePageContext();
  const [offset, setOffset] = useState(DEFAULT_DESKTOP_OFFSET);

  const onScroll = () => {
    if (window.pageYOffset < DESKTOP_SCROLL_POSITION) {
      setOffset(DEFAULT_DESKTOP_OFFSET);
    } else {
      setOffset(DESKTOP_OFFSET_START);
    }
  };

  useScroll(isVisible, onScroll);

  return (
    <HideableDesktopContainer
      isVisible={isVisible}
      offset={Math.round(offset)}
      background={background}
      isBackgroundGradient={isBackgroundGradient}
      isBackgroundDark={isBackgroundDark}
    >
      <ContentContainer>
        <TitleAndIconContainer>
          <GatsbyImg image={icon} />
          <RichText field={title} />
        </TitleAndIconContainer>
        <InfoContainer>
          <RichText field={info} />
        </InfoContainer>
        <ButtonsContainer>
          <CtaBlock
            isVisible={ctaLabel.text && ctaLink.url}
            ctaText={ctaLabel.text}
            ctaLink={ctaLink.url}
            data-tracker={buildTrackingSelector({
              page: tags[0] || type,
              section: 'top-banner',
              type: 'cta',
            })}
          />
          <IconClose
            onClick={onClose}
            data-tracker={buildTrackingSelector({
              page: tags[0] || type,
              section: 'top-banner',
              context: 'close',
              type: 'button',
            })}
          />
        </ButtonsContainer>
      </ContentContainer>
    </HideableDesktopContainer>
  );
};

export default BannerDesktopContainer;

import { RichText } from 'components/v2/RichText';
import React from 'react';
import Item from './ColumnsItem';
import { Columns, ImageTextColumnContainer, Wrapper } from './styledComponents';
import { IImageTextColumnsCta } from './types';

const ImageTextColumnsCta: React.FC<IImageTextColumnsCta> = ({
  primary: {
    background_color: background,
    title_field: title,
    subtitle_and_text_alignment: textAlign,
  },
  items,
}) => {
  const columnCount = items.length === 4;

  return (
    <Wrapper background={background}>
      <ImageTextColumnContainer>
        <RichText field={title} />
        <Columns
          colNumber={items.length}
          columnCount={columnCount}
          textAlign={textAlign}
        >
          {items.map((props, idx) => (
            <Item key={idx} {...props} />
          ))}
        </Columns>
      </ImageTextColumnContainer>
    </Wrapper>
  );
};

export default ImageTextColumnsCta;

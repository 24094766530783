import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { Link } from 'prismic-reactjs';
import React from 'react';
import { PrismicTextField, TGatsbyImage } from 'types';
import {
  Container,
  ContentWrapper,
  DescriptionWrapper,
  HideableStyledCtaContainer,
  StyledContainer,
  StyledCta,
  StyledFeaturesWrapper,
  TitleWrapper,
} from './styledComponents';

interface IItem {
  image: TGatsbyImage;
  description: PrismicTextField;
}

interface IImageTextColumn {
  primary: {
    title: PrismicTextField;
    background: string;
    numbers_of_columns?: number;
    cta_url?: Link;
    cta_label?: PrismicTextField;
  };
  items: IItem[];
}

const ImageTextColumn: React.FC<IImageTextColumn> = ({
  primary: {
    background,
    title,
    numbers_of_columns: numberOfColumns,
    cta_url: ctaUrl,
    cta_label: ctaLabel,
  },
  items,
}) => (
  <Container background={background}>
    <ContentWrapper>
      <TitleWrapper>
        <RichText field={title} />
      </TitleWrapper>
      <StyledContainer numberOfColumns={numberOfColumns || 5}>
        {items.map(({ image, description }, index) => (
          <StyledFeaturesWrapper key={index}>
            <GatsbyImg image={image} />
            <DescriptionWrapper>
              <RichText field={description} />
            </DescriptionWrapper>
          </StyledFeaturesWrapper>
        ))}
      </StyledContainer>
      <HideableStyledCtaContainer isVisible={ctaLabel?.text && ctaUrl?.url}>
        <StyledCta
          ctaText={ctaLabel?.text}
          ctaLink={ctaUrl?.url}
          isVisible={ctaLabel?.text && ctaUrl?.url}
        />
      </HideableStyledCtaContainer>
    </ContentWrapper>
  </Container>
);

export default ImageTextColumn;

import { ISetCookies } from 'types';

export const cookieNames = {
  activeCampaign: 'SP_ACTIVE_CAMPAIGN',
};

export const getCookies = () => {
  const pairs = document.cookie.split(';');
  const cookies: Partial<Record<string, string>> = {};
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    cookies[(pair[0] + '').trim()] = decodeURI(pair[1]);
  }
  return cookies;
};

export const setCookie = ({
  cname,
  cvalue,
  exdays = 1,
  domain,
}: ISetCookies) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';domain=' + domain + ';path=/';
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname};path=/`;
};

export const getRootDomain = (hostname: string) => {
  const hostnameParts = hostname.split('.');
  if (hostnameParts.length > 1) {
    return hostnameParts.reverse().splice(0, 2).reverse().join('.');
  }
  return hostname;
};

const parseQueryParamsFromURL = (paramNames: any) => {
  const allQueryParams = new URLSearchParams(window.location.search);
  const parsedQueryParams = {};
  paramNames.forEach((paramName) => {
    parsedQueryParams[paramName] = allQueryParams.get(paramName);
  });
  return parsedQueryParams;
};

const setPaddleCookies = () => {
  const cookies = getCookies();
  const domain = getRootDomain(window.location.hostname);
  Object.keys(cookies).forEach((cookieName) => {
    if (cookieName.startsWith('paddlejs_')) {
      deleteCookie(cookieName);
      setCookie({
        cname: cookieName,
        cvalue: cookies[cookieName],
        exdays: 30,
        domain,
      });
    }
  });
};

const setCookiesFromQueryParams = () => {
  const queryParams = parseQueryParamsFromURL(['coupon', 'affiliate']);
  const domain = getRootDomain(window.location.hostname);
  Object.keys(queryParams).forEach((paramName) => {
    const paramVal = queryParams[paramName];
    if (paramVal !== null) {
      setCookie({
        cname: 'smartproxy_' + paramName,
        cvalue: paramVal,
        exdays: 30,
        domain,
      });
    }
  });
};

const setPartnerizeCookies = () => {
  if (window.location.href.indexOf('x-clickref') > -1) {
    const domainName = '.smartproxy.com';
    const daysToRemember = 60;
    const cookieName = 'cb_prf_sp';
    const urlString = window.location.href;
    const url = new URL(urlString);
    const clickref = url.searchParams.get('x-clickref');
    const d = new Date();
    d.setTime(d.getTime() + daysToRemember * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    const nameValue = cookieName + '=' + clickref;
    const domain = 'domain=' + domainName;
    document.cookie = nameValue + ';' + domain + ';' + expires + ';path=/;';
  }
};

const setGoogleClickIdCookie = () => {
  if (window.location.href.indexOf('gclid') > -1) {
    const hostname = window.location.hostname;
    const domain = getRootDomain(hostname);
    const daysToRemember = 90;
    const cookieName = 'SP-GCLID';
    const urlString = window.location.href;
    const url = new URL(urlString);
    const value = url.searchParams.get('gclid');
    setCookie({
      cname: cookieName,
      cvalue: value,
      exdays: daysToRemember,
      domain,
    });
  }
};

export const setActiveCampaignCookie = ({ value }) => {
  const domain = getRootDomain(window.location.hostname);
  setCookie({
    cname: cookieNames.activeCampaign,
    exdays: 30,
    cvalue: value,
    domain,
  });
};

export const isActiveCampaignCookieSet = () => {
  const cookies = getCookies();
  const activeCampaign = Object.keys(cookies).includes(
    cookieNames.activeCampaign
  );
  if (!activeCampaign) return;
  return !!activeCampaign;
};

export const initCookiesHandling = () => {
  setPaddleCookies();
  setCookiesFromQueryParams();
  setPartnerizeCookies();
  setGoogleClickIdCookie();
};

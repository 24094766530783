import {
  CN_PAGE_DOMAIN,
  DASHBOARD_SUBDOMAIN,
  PAGE_DOMAIN,
  STAGING_SUBDOMAIN,
} from 'constants/common';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { TARGET } from './constants';

export type TLink = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> & {
  isForcedInternalLink?: boolean;
};

const Link: React.FC<TLink> = ({
  children,
  to,
  isForcedInternalLink,
  ...props
}) => {
  // You can test out this regex here: https://regexr.com/79cmu
  const relativePathRegex = new RegExp(`^(\/|#).*`, 'g');
  const absolutePathRegex = new RegExp(
    `^(https?://)?(www.)?(${STAGING_SUBDOMAIN}.)?(${PAGE_DOMAIN}|${CN_PAGE_DOMAIN})`,
    'gim'
  );

  const isInternalLink =
    relativePathRegex.test(to) || absolutePathRegex.test(to);

  if (!isInternalLink) {
    const dashboardPathRegex = new RegExp(
      `^(https?://)?(www.)?${DASHBOARD_SUBDOMAIN}.(${PAGE_DOMAIN}|${CN_PAGE_DOMAIN})`,
      'gim'
    );
    const isDashboardLink = dashboardPathRegex.test(to);

    return (
      <a
        {...props}
        href={to}
        target={
          isDashboardLink || isForcedInternalLink ? TARGET.SELF : TARGET.BLANK
        }
      >
        {children}
      </a>
    );
  }

  if (process.env.GATSBY_NODE_ENV !== 'production') {
    return (
      <a
        {...props}
        href={to.replace(absolutePathRegex, '').padStart(1, '/')}
        target={TARGET.SELF}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      {...props}
      to={to.replace(absolutePathRegex, '').padStart(1, '/')}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;

import React from 'react';
import { IPrismicFooterBanner } from './types';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { initBuildTrackingSelector } from 'utils/page';
import {
  Container,
  CtaBlock,
  CtasContainer,
  FooterApiWrapper,
} from './styledComponents';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';

const FooterBanner: React.FC<IPrismicFooterBanner> = ({
  primary: {
    title,
    subtitle,
    cta_one_text: ctaOneText,
    cta_one_link: ctaOneLink,
    cta_two_text: ctaTwoText,
    cta_two_link: ctaTwoLink,
    is_money_back_visible: isMoneyBackVisible,
  },
}) => {
  const buildTrackingSelector = initBuildTrackingSelector({
    section: 'footer-api',
    type: 'cta',
  });
  const isMoneyBackSelected =
    isMoneyBackVisible !== null ? isMoneyBackVisible : true;

  return (
    <FooterApiWrapper>
      <Container>
        <RichText field={title} />
        <RichText field={subtitle} />
        <div>
          <CtasContainer>
            <CtaBlock
              isVisible={ctaOneText.text && ctaOneLink.url}
              ctaText={ctaOneText.text}
              ctaLink={ctaOneLink.url}
              themeName={BUTTON_THEMES.IMPORTANT}
              themeType={BUTTON_TYPES.PRIMARY}
              data-tracker={buildTrackingSelector({
                context: 'primary',
              })}
            />
            <CtaBlock
              isVisible={ctaTwoText.text && ctaTwoLink.url}
              ctaText={ctaTwoText.text}
              ctaLink={ctaTwoLink.url}
              themeName={BUTTON_THEMES.CONVERSION}
              themeType={BUTTON_TYPES.SECONDARY}
              data-tracker={buildTrackingSelector({
                context: 'secondary',
              })}
            />
          </CtasContainer>
          <MoneyBackTag isVisible={isMoneyBackSelected && !!ctaOneLink.url} />
        </div>
      </Container>
    </FooterApiWrapper>
  );
};

export default FooterBanner;

import { PRISMIC_VALUES } from 'constants/common';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Link from 'components/GatsbyLink';
import { Paths } from 'paths';
import React from 'react';
import {
  BlockContainer,
  ContentContainer,
  HideableButtonWrapper,
  StyledAllButton,
  StyledButton,
} from './styledComponents';
import { IContentTags } from './types';
import { getTopicLabel, getTopicUrl } from './utils';
import { Box } from 'components/v2/Box';
import { themev2 } from 'theme-v2';
import { RichText } from 'components/v2/RichText';

const ContentTags: React.FC<IContentTags> = ({ primary: { title }, items }) => (
  <BlockContainer>
    <ContentContainer>
      {!!title.text && (
        <Box sx={{ textAlign: 'center', marginBottom: themev2.spacings.L }}>
          <RichText field={title} />
        </Box>
      )}
      <HideableButtonWrapper isVisible={!!items.length}>
        <Link to={Paths.blog_page}>
          <StyledAllButton
            themeName={BUTTON_THEMES.IMPORTANT}
            themeType={BUTTON_TYPES.ADDITIONAL}
            fontSize={TSHIRT_SIZE.M}
            gutterBot={TSHIRT_SIZE.XXS}
            gutterRight={TSHIRT_SIZE.XS}
            gutterTop={TSHIRT_SIZE.XXS}
          >
            {PRISMIC_VALUES.ALL}
          </StyledAllButton>
        </Link>
        {items.map(({ topic: { tags, document } }, key) => (
          <Link key={key} to={getTopicUrl(tags)}>
            <StyledButton
              themeName={BUTTON_THEMES.IMPORTANT}
              themeType={BUTTON_TYPES.SECONDARY}
              fontSize={TSHIRT_SIZE.M}
              gutterBot={TSHIRT_SIZE.XXS}
              gutterRight={TSHIRT_SIZE.XS}
              gutterTop={TSHIRT_SIZE.XXS}
            >
              {getTopicLabel(document)}
            </StyledButton>
          </Link>
        ))}
      </HideableButtonWrapper>
    </ContentContainer>
  </BlockContainer>
);

export default ContentTags;

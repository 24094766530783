import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  ImgTextBlock,
  ImgTextBlockContainer,
  ImgTextBlockTitleWrapper,
  SideImageContainer,
  SideImageText,
} from './styledComponents';
import { ISideImageTextBlock } from './types';

const SideImageTextBlock: React.FC<ISideImageTextBlock> = ({
  primary: {
    side_image: sideImage,
    text_field: textField,
    title_field: titleField,
    is_image_right: isImageRight,
  },
}) => (
  <ImgTextBlockContainer text={titleField.text ? titleField : textField}>
    <ImgTextBlockTitleWrapper>
      <RichText field={titleField} />
    </ImgTextBlockTitleWrapper>
    <ImgTextBlock isOnTheRight={isImageRight}>
      <SideImageText>
        <RichText field={textField} />
      </SideImageText>
      <SideImageContainer isOnTheRight={isImageRight}>
        <GatsbyImg image={sideImage} />
      </SideImageContainer>
    </ImgTextBlock>
  </ImgTextBlockContainer>
);

export default SideImageTextBlock;

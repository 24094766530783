import { SIGNUP_URL } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  Wrapper,
  IconList,
  IconListBlockContainer,
  IconListItem,
  TextFieldContainer,
  StyledLink,
  IconContainer,
} from './styledComponents';
import { IIconListBlock } from './types';
import { RichText } from 'components/v2/RichText';

const IconListBlock: React.FC<IIconListBlock> = ({
  primary: {
    is_text_centered: isTextCentered,
    list_title: listTitle,
    subtitle,
    column_number: columnNumber,
  },
  items,
}) => (
  <Wrapper text={listTitle}>
    <IconListBlockContainer>
      <TextFieldContainer isCentered={isTextCentered}>
        <RichText field={listTitle} />
        <RichText field={subtitle} />
      </TextFieldContainer>
      <IconList columnNumber={columnNumber || 6}>
        {items.map(({ icon, link_to: linkTo, text_field: textField }, idx) => (
          <IconListItem key={idx}>
            <StyledLink to={linkTo.url || SIGNUP_URL} target={linkTo.target}>
              <IconContainer>
                <GatsbyImg image={icon} />
              </IconContainer>
              <RichText field={textField} />
            </StyledLink>
          </IconListItem>
        ))}
      </IconList>
    </IconListBlockContainer>
  </Wrapper>
);

export default IconListBlock;

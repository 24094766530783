import { css } from '@emotion/react';
import { theme } from 'theme-v2/theme';

export enum MEDIA_QUERIES {
  DESKTOP = '@media (max-width: 1500px)',
  TABLET_PORTRAIT = '@media (max-width: 1060px)',
  TABLET = '@media (max-width: 991px)',
  PHONE_LANDSCAPE = '@media (max-width: 767px)',
  PHONE_PORTRAIT_MAX = '@media (max-width: 577px)',
  PHONE_PORTRAIT = '@media (max-width: 479px)',
  SMALL_MOBILE = '@media (max-width: 239px)',
}

export const globalStyle = css({
  html: {
    width: '100%',
    height: '100%',
    scrollPaddingTop: '120px',
  },
  body: {
    width: '100%',
    height: '100%',
    margin: 0,
    fontSize: '16px',
    lineHeight: '1.5',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins, sans-serif',
    color: '#071018',
  },
  '*': {
    fontFamily: 'Poppins,sans-serif',
    boxSizing: 'border-box',
    margin: 0,
    '&::before, &::after': {
      boxSizing: 'border-box',
    },
  },
  '.block-img': {
    margin: 0,
  },
  '.grecaptcha-badge': {
    display: 'none',
  },
  '.anticon': {
    verticalAlign: 0,
  },
  '#root': {
    height: '100%',
    display: 'flex',
  },
  'button, input, optgroup, select, textarea': {
    margin: 0,
    fontFamily: 'inherit',
    fontSize: '16px',
    lineHeight: 'inherit',
    outline: 'none',
  },
  'svg, image': {
    verticalAlign: 'middle',
  },
  '.drift-widget-welcome-online, .drift-widget-welcome-expanded-online': {
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      bottom: '7px!important',
      right: '-5px!important',
    },
  },
  pre: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  a: {
    color: '#2997FF',
    wordBreak: 'break-word',
    textDecoration: 'none',
    fontFamily: theme.fonts.family.additional,
  },
  h1: {
    fontSize: '36px',
    lineHeight: '54px',
    fontWeight: 500,
  },
  h2: {
    fontSize: '30px',
    lineHeight: '45px',
    fontWeight: 500,
  },
  h3: {
    fontSize: '22px',
    lineHeight: '33px',
    fontWeight: 500,
  },
  h4: {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 600,
  },
  h5: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 600,
  },
  h6: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 600,
  },
  ul: {
    paddingLeft: '30px',
    li: {
      paddingBottom: '20px',
    },
  },
  p: {
    wordBreak: 'break-word',
  },
  '.sr-only': {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
});

import {
  BlogParent,
  FaqBlock,
  GettingStarted,
  KHArticles,
  KnowledgeHubHero,
  PrismicCarouselBlock,
  PrismicImageTabs,
} from '@smartproxy-web/features';
import Author from '@smartproxy-web/features/src/components/Blog/Author';
import { Divider } from '@smartproxy-web/ui';
import BlogAuthor from 'features/Blog/BlogPost/BlogAuthor';
import FooterBanner from 'features/FooterBanner';
import { SocialButtonsBar } from 'features/SocialButtonsBar';
import { SliceMap } from 'types';

export enum DEVS_SLICES {
  FaqBlock = 'faq_block',
  BlogParent = 'blog_parent_block',
  FooterBanner = 'footer_banner_slice',
  Divider = 'divider',
  GettingStarted = 'getting_started',
  PrismicCarouselBlock = 'carousel_block',
  KHArticles = 'kh_articles',
  KnowledgeHubHero = 'knowledge_hub_hero',
  Author = 'author_v2',
  BlogAuthor = 'blog_author',
  SocialButtonsBar = 'social_buttons_bar',
  ImageTabs = 'image_tabs',
}

export const devsSliceMap: SliceMap<DEVS_SLICES> = {
  [DEVS_SLICES.FaqBlock]: FaqBlock,
  [DEVS_SLICES.BlogParent]: BlogParent,
  [DEVS_SLICES.FooterBanner]: FooterBanner,
  [DEVS_SLICES.Divider]: Divider,
  [DEVS_SLICES.GettingStarted]: GettingStarted,
  [DEVS_SLICES.PrismicCarouselBlock]: PrismicCarouselBlock,
  [DEVS_SLICES.KHArticles]: KHArticles,
  [DEVS_SLICES.KnowledgeHubHero]: KnowledgeHubHero,
  [DEVS_SLICES.Author]: Author,
  [DEVS_SLICES.BlogAuthor]: BlogAuthor,
  [DEVS_SLICES.SocialButtonsBar]: SocialButtonsBar,
  [DEVS_SLICES.ImageTabs]: PrismicImageTabs,
};

import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import * as React from 'react';
import {
  FeaturesBottom,
  StyledHeroFeature,
  FeaturesTop,
  StyledHeroFeaturesContainer as StyledHeroFeaturesWrapperInner,
  StyledHeroFeaturesWrapper,
} from './styledComponents';
import { HeroFeaturesProps } from './types';
import { usePageContext } from 'contexts/PageContext';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

export const HeroFeatures: React.FC<HeroFeaturesProps> = ({
  primary: { is_dark_theme: isDarkTheme },
  items,
}) => {
  const { tags, type } = usePageContext();

  return (
    <StyledHeroFeaturesWrapper
      isDarkTheme={isDarkTheme}
      data-section-id={generateSectionsId(
        SECTION_NAMES.HERO_FEATURES,
        tags,
        type
      )}
    >
      <StyledHeroFeaturesWrapperInner>
        {items.map(
          ({
            icon,
            features_title: title,
            features_description: description,
          }) => (
            <StyledHeroFeature key={title.text}>
              <FeaturesTop>
                <GatsbyImg image={icon} loading="eager" />
                <RichText field={title} />
              </FeaturesTop>
              <FeaturesBottom>
                <RichText field={description} />
              </FeaturesBottom>
            </StyledHeroFeature>
          )
        )}
      </StyledHeroFeaturesWrapperInner>
    </StyledHeroFeaturesWrapper>
  );
};

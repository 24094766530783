import { LinkButton } from 'components/v2/LinkButton';
import React from 'react';
import { RightNavMenuListItemProps } from './types';

export const RightNavMenuListItem: React.FC<RightNavMenuListItemProps> = ({
  index,
  link,
  linksCount,
  trackingId,
}) => {
  const color = index + 1 === linksCount ? 'white' : 'gray';
  const variant = index + 1 === linksCount ? 'outlined' : 'text';
  const isCompact = index + 1 === linksCount ? false : true;

  return (
    <LinkButton
      to={link.url?.endsWith('#contact-sales') ? '#contact-sales' : link.url}
      data-tracker={trackingId}
      size="sm"
      color={color}
      variant={variant}
      compact={isCompact}
    >
      {link.label}
    </LinkButton>
  );
};

import React from 'react';
import { IFeatures } from './types';
import {
  CheckmarkContainer,
  ListItem,
  StyledFeaturesContainer,
  StyledList,
} from './styledComponents';
import hideable from 'utils/hideable';
import { GatsbyImg } from '@smartproxy-web/ui';

const Features: React.FC<IFeatures> = ({ features }) => (
  <StyledFeaturesContainer>
    <StyledList>
      {features.map(({ feature, checkmark }, index) => (
        <ListItem key={index}>
          <CheckmarkContainer>
            <GatsbyImg image={checkmark} />
          </CheckmarkContainer>
          {feature.text}
        </ListItem>
      ))}
    </StyledList>
  </StyledFeaturesContainer>
);

export default hideable(Features);

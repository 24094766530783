import { usePageContext } from 'contexts/PageContext';
import { IconPlayOutlinedv2, IconPlayv2 } from 'images';
import { useState } from 'react';
import { TGatsbyImage } from 'types';
import { initBuildTrackingSelector } from 'utils/page';
import { convertEmbedDimensionsToFill } from './helpers';
import {
  StyledHeroImageWrapper,
  StyledHeroLightboxTrigger,
} from './styledComponents';
import { GatsbyImg, Lightbox } from '@smartproxy-web/ui';

interface HeroImageProps {
  image: TGatsbyImage;
  embedCode?: string;
}

export const HeroImage = ({ image, embedCode }: HeroImageProps) => {
  const { tags, type } = usePageContext();

  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  const toggleLightbox = (isOpen?: boolean) => {
    setIsLightboxVisible((prevState) => isOpen ?? !prevState);
  };

  const buildEmbedTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero-embed',
  });

  return (
    <StyledHeroImageWrapper>
      <GatsbyImg image={image} loading="eager" fetchPriority="high" />
      {embedCode && (
        <StyledHeroLightboxTrigger
          onClick={() => {
            toggleLightbox(true);
          }}
          color="gray"
          variant="filled"
          size="lg"
          data-tracker={buildEmbedTrackingSelector({
            context: 'open',
            type: 'button',
          })}
        >
          <IconPlayv2 data-layer-top />
          <IconPlayOutlinedv2 />
        </StyledHeroLightboxTrigger>
      )}
      {embedCode && (
        <Lightbox
          closeBtnTrackingId={buildEmbedTrackingSelector({
            context: 'close',
            type: 'button',
          })}
          isOpen={isLightboxVisible}
          handleClose={() => toggleLightbox(false)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: convertEmbedDimensionsToFill(embedCode),
            }}
          />
        </Lightbox>
      )}
    </StyledHeroImageWrapper>
  );
};

import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { IBackground } from 'features/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputWidthShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledHeroFeaturesWrapper = styled.div<ITheme & IBackground>(
  ({ isBackgroundDark, ...props }) => ({
    background: isBackgroundDark
      ? themev2.colors.black.S900
      : getThemeMainColor(props),
    overflow: 'hidden',
    padding: '0px 20px',
    height: 'auto',
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    [String(FeaturesContainer)]: {
      'p, ol, li': {
        color: isBackgroundDark
          ? getThemeNeutralColor(props, COLOR_SHADE.LIGHT)
          : getThemeMainColor(props, MAIN_COLOR.BLACK),
        'strong, em': {
          color: isBackgroundDark
            ? getThemeNeutralColor(props, COLOR_SHADE.LIGHT)
            : getThemeMainColor(props, MAIN_COLOR.BLACK),
        },
      },
    },
    [String(FeaturesTop)]: {
      p: {
        color: isBackgroundDark
          ? getThemeMainColor(props)
          : getThemeMainColor(props, MAIN_COLOR.BLACK),
        'strong, em': {
          color: isBackgroundDark
            ? getThemeMainColor(props)
            : getThemeMainColor(props, MAIN_COLOR.BLACK),
        },
      },
    },
  })
);

export const StyledHeroFeaturesContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  display: CSS_DISPLAY.GRID,
  gridAutoColumns: '1fr',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
  rowGap: getThemeGutter(props, TSHIRT_SIZE.XS),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    rowGap: '38px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  },
}));

export const FeaturesContainer = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  'p, ol, ul': {
    margin: 0,
    marginBottom: '10px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    li: {
      margin: 0,
      marginBottom: '10px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
      [MEDIA_QUERIES.TABLET]: {
        justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
        textAlign: TEXT_ALIGN.CENTER,
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
      textAlign: TEXT_ALIGN.CENTER,
    },
    'strong, a, em': {
      margin: 0,
      marginBottom: '10px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
      [MEDIA_QUERIES.TABLET]: {
        justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
        textAlign: TEXT_ALIGN.CENTER,
      },
    },
  },
}));

export const FeaturesTop = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  display: CSS_DISPLAY.FLEX,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  div: {
    width: '36px',
    height: '36px',
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  p: {
    width: 'auto',
    margin: 0,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
    fontFamily: getThemeFontFamily(props),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
    fontWeight: 500,
    'strong, a, em': {
      width: 'auto',
      margin: 0,
      fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
      fontFamily: getThemeFontFamily(props),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
      fontWeight: 500,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    alignItems: CSS_ALIGN.CENTER,
  },
}));

export enum Paths {
  blog_page = '/blog',
  home_page = '/',
  case_studies = '/case-studies',
  about = '/about',
  smartdaili_quick_start_guide = '/smartdaili-quick-start-guide',
  contact_us = '/contact-us',
  why_smartproxy = '/why-smartproxy',
  how_it_works = '/how-it-works',
  proxy_affiliate_program = '/proxy-affiliate-program',
}

export enum TemplatePaths {
  proxies = '/proxies',
  blog = '/blog',
  topic = '/',
  apps = '/apps',
  case_post = '/case-studies',
  legal = '/legal',
  questions = '/questions',
  faq = '/faq',
  deals = '/deals',
  integrations = '/integrations',
  lp = '/lp',
  scraping = '/scraping',
  targets = '/targets',
  partners_guide = '/partners-guide',
  use_cases = '/use-cases',
  knowledge_hub_landing = '/devs',
  smartdaili_quick_start_guide_pages = '/smartdaili-quick-start-guide',
}

import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import {
  CtaBlockWrapper,
  StyledCtaBlock,
} from 'features/CtaBlock/styledComponents';
import { IBackground } from 'features/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';
import { IText } from './types';

export const TextCtaWrapper = styled.div<IColor & IBackground>(
  ({ background, isBackgroundDark, ...props }) => ({
    background,
    width: '100%',
    maxWidth: '1024px',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    'h1, h2, h3, h4, h5, h6, p, ol, ul': {
      color: isBackgroundDark
        ? getThemeMainColor(props)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
      'strong, em, li,': {
        color: isBackgroundDark
          ? getThemeMainColor(props)
          : getThemeMainColor(props, MAIN_COLOR.BLACK),
      },
    },
    [String(CtaBlockWrapper)]: {
      padding: 0,
      [MEDIA_QUERIES.TABLET]: {
        margin: '0 auto',
      },
      [String(StyledCtaBlock)]: {
        marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
        fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
        padding: '15px',
        marginBottom: 0,
      },
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: `60px 20px`,
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const TextWrapper = styled.div<IText>(({ isCentered, ...props }) => ({
  width: '100%',
  textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
  ul: {
    listStylePosition: 'inside',
  },
  'p:last-child, ol:last-child, ul:last-child': {
    marginBottom: 0,
  },
  'h1, h2, h3, h4, h5, h6': {
    width: '100%',
    margin: `0 auto ${getThemeGutter(props)}`,
    textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
  },
  'p, strong, em, a': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  },
}));

const TitlesContainer = styled.div<IText>(({ accentColor, ...props }) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  'h1, h2, h3, h4, h5, h6': {
    color: accentColor,
  },
  'h1, h2, h3:first-of-type': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
}));

export const SubtitleContainer = styled.div<IText>(({ isCentered }) => ({
  width: '100%',
  maxWidth: '1024px',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  'h2, h3, h4, h5, h6': {
    textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    'h2, h3, h4, h5, h6': {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const HideableTitlesContainer = hideable(TitlesContainer);

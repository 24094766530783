import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { IGatsbyImage } from 'types';

const GatsbyImg: React.FC<IGatsbyImage> = ({ image }) => {
  const img = getImage(image);
  return (
    <GatsbyImage
      image={img!}
      alt={image.alt || 'smartproxy'}
      objectFit={'contain'}
    />
  );
};

export default GatsbyImg;

import { TabProps } from 'components/v2/Toggle/Toggle';
import { ISP_PROXIES_PLANS } from 'constants/plans';

// 1. ISP GB Self service

const ispGbStandard1 = {
  title: '2 GB',
  discount: null,
  price: '$14',
  priceOld: null,
  pricePer: 'GB',
  priceTotal: '$28',
  priceTotalOld: null,
  planId: ISP_PROXIES_PLANS.nano,
};

const ispGbStandard2 = {
  title: '7 GB',
  discount: '7%',
  price: '$13',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$90',
  priceTotalOld: '$98',
  planId: ISP_PROXIES_PLANS.micro,
  isPopular: true,
};

const ispGbStandard3 = {
  title: '25 GB',
  discount: '14%',
  price: '$12',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$300',
  priceTotalOld: '$350',
  planId: ISP_PROXIES_PLANS.starter,
};

const ispGbStandard4 = {
  title: '50 GB',
  discount: '21%',
  price: '$11',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$550',
  priceTotalOld: '$700',
  planId: ISP_PROXIES_PLANS.regular,
};

// 1. ISP GB enterprise

const ispGbEnterprise1 = {
  title: '100 GB',
  discount: '32%',
  price: '$9.5',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$950',
  priceTotalOld: '$1400',
  planId: ISP_PROXIES_PLANS.advanced,
};

const ispGbEnterprise2 = {
  title: '250 GB',
  discount: '39%',
  price: '$8.5',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$2,150',
  priceTotalOld: '$3500',
  planId: ISP_PROXIES_PLANS.premium,
};

const ispGbEnterprise3 = {
  title: '500 GB',
  discount: '46%',
  price: '$7.5',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$3,750',
  priceTotalOld: '$7000',
  planId: ISP_PROXIES_PLANS.professional,
};

const ispGbEnterprise4 = {
  title: '1000 GB',
  discount: '54%',
  price: '$6.5',
  priceOld: '$14',
  pricePer: 'GB',
  priceTotal: '$6,500',
  priceTotalOld: '$14000',
  planId: ISP_PROXIES_PLANS.enterprise,
};

const ispGbEnterprise5 = {
  contactSales: true,
};

// 2. ISP Dedicated standard

const ispIpStandard1 = {
  title: '10 IPs',
  discount: null,
  price: '$3.5',
  priceOld: null,
  pricePer: 'IP',
  priceTotal: '$35',
  priceTotalOld: null,
};

const ispIpStandard2 = {
  title: '25 IPs',
  discount: '14%',
  price: '$3.0',
  priceOld: '$3.5',
  pricePer: 'IP',
  priceTotal: '$75',
  priceTotalOld: '$88',
};

const ispIpStandard3 = {
  title: '50 IPs',
  discount: '21%',
  price: '$2.75',
  priceOld: '$3.5',
  pricePer: 'IP',
  priceTotal: '$138',
  priceTotalOld: '$175',
  isPopular: true,
};

const ispIpStandard4 = {
  title: '100 IPs',
  discount: '26%',
  price: '$2.6',
  priceOld: '$3.5',
  pricePer: 'IP',
  priceTotal: '$260',
  priceTotalOld: '$350',
};

const ispIpStandard5 = {
  title: '200 IPs',
  discount: '29%',
  price: '$2.5',
  priceOld: '$3.5',
  pricePer: 'IP',
  priceTotal: '$500',
  priceTotalOld: '$700',
};

const ispIpEnterprise6 = {
  contactSales: true,
};

// 3. ISP per IP standard

const ispPerIpStandard1 = {
  title: '25 IPs',
  discount: null,
  price: '$0.5',
  priceOld: null,
  pricePer: 'IP',
  priceTotal: '$12.5',
  priceTotalOld: null,
};

const ispPerIpStandard2 = {
  title: '50 IPs',
  discount: '4%',
  price: '$0.48',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$24',
  priceTotalOld: '$25',
};

const ispPerIpStandard3 = {
  title: '100 IPs',
  discount: '8%',
  price: '$0.46',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$46',
  priceTotalOld: '$50',
  isPopular: true,
};

const ispPerIpStandard4 = {
  title: '200 IPs',
  discount: '12%',
  price: '$0.44',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$88',
  priceTotalOld: '$100',
};

const ispPerIpStandard5 = {
  title: '500 IPs',
  discount: '16%',
  price: '$0.42',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$210',
  priceTotalOld: '$250',
};

// 2. ISP per IP enterprise

const ispPerIpEnterprise1 = {
  title: '1000 IPs',
  discount: '20%',
  price: '$0.4',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$400',
  priceTotalOld: '$500',
};

const ispPerIpEnterprise2 = {
  title: '2000 IPs',
  discount: '24%',
  price: '$0.38',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$760',
  priceTotalOld: '$1000',
};

const ispPerIpEnterprise3 = {
  title: '3000 IPs',
  discount: '28%',
  price: '$0.36',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$1,080',
  priceTotalOld: '$1500',
};

const ispPerIpEnterprise4 = {
  title: '5000 IPs',
  discount: '30%',
  price: '$0.35',
  priceOld: '$0.5',
  pricePer: 'IP',
  priceTotal: '$1,750',
  priceTotalOld: '$2500',
};

const ispPerIpEnterprise5 = {
  contactSales: true,
};

export const ispPricingsGlobal = {
  staticProxies: {
    standard: [ispGbStandard1, ispGbStandard2, ispGbStandard3, ispGbStandard4],
    enterprise: [
      ispGbEnterprise1,
      ispGbEnterprise2,
      ispGbEnterprise3,
      ispGbEnterprise4,
      ispGbEnterprise5,
    ],
  },
  dedicatedProxies: {
    standard: [
      ispIpStandard1,
      ispIpStandard2,
      ispIpStandard3,
      ispIpStandard4,
      ispIpStandard5,
      ispIpEnterprise6,
    ],
  },
  payPerIp: {
    standard: [
      ispPerIpStandard1,
      ispPerIpStandard2,
      ispPerIpStandard3,
      ispPerIpStandard4,
      ispPerIpStandard5,
    ],
    enterprise: [
      ispPerIpEnterprise1,
      ispPerIpEnterprise2,
      ispPerIpEnterprise3,
      ispPerIpEnterprise4,
      ispPerIpEnterprise5,
    ],
  },
} as const;

export const ispPricingsChina = {
  ...ispPricingsGlobal,
} as const;

export const tabsIspGlobal: TabProps[] = [
  { key: 'dedicatedProxies', label: 'Dedicated' },
  { key: 'staticProxies', label: 'Pay / GB' },
  { key: 'payPerIp', label: 'Pay / IP' },
];

export const tabsIspChina: TabProps[] = [
  { key: 'dedicatedProxies', label: '独享 IP' },
  { key: 'staticProxies', label: '共享流量' },
  { key: 'payPerIp', label: '共享 IP' },
];

const featuresIspPayGbGlobal = [
  { feature: 'Rotating on-demand IPs' },
  { feature: '99.99% uptime' },
  { feature: 'Unlimited connections and threads' },
  { feature: 'Country-level targeting' },
  { feature: 'Unlimited-duration sessions' },
  { feature: 'Available for US, France, Hong Kong & Canada' },
];

const featuresIspDedicatedGlobal = [
  { feature: 'Unlimited traffic' },
  { feature: 'Purely dedicated IPs' },
  { feature: 'Country-level targeting' },
  { feature: '99.99% uptime' },
  { feature: 'Unlimited bandwidth (IP-based pricing)' },
  { feature: 'Available for US & UK' },
  { feature: 'Premium ISP providers' },
];

export const featuresIspGlobal = {
  staticProxies: featuresIspPayGbGlobal,
  dedicatedProxies: featuresIspDedicatedGlobal,
  payPerIp: featuresIspDedicatedGlobal,
};

const featuresIspPayGbChina = [
  { feature: '按需轮换 IP' },
  { feature: '99.99% 正常运行时间' },
  { feature: '无限连接和线程' },
  { feature: '国家级定位' },
  { feature: '无限期会话' },
  { feature: '适用于美国、法国、香港和加拿大' },
];

const featuresIspDedicatedChina = [
  { feature: '无限流量' },
  { feature: '纯专用IP' },
  { feature: '国家级定位' },
  { feature: '99.99% 正常运行时间' },
  { feature: '无限带宽（基于 IP 定价）' },
  { feature: '适用于美国和英国' },
  { feature: '优质 ISP 提供商' },
];

const featuresIspPayIPChina = [
  { feature: '纯专用IP' },
  { feature: '国家级定位' },
  { feature: '99.99% 正常运行时间' },
  { feature: '无限带宽（基于 IP 定价）' },
  { feature: '适用于美国和英国' },
  { feature: '优质 ISP 提供商' },
];

export const featuresIspChina = {
  staticProxies: featuresIspPayGbChina,
  dedicatedProxies: featuresIspDedicatedChina,
  payPerIp: featuresIspPayIPChina,
};

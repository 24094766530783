import styled from '@emotion/styled';
import React from 'react';
import {
  COLOR_SHADE,
  CSS_DISPLAY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeTextColor,
  IThemeProps,
} from 'theme';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from './constants';
import { getGutter } from './utils';

export enum TEXT_WEIGHT {
  BOLD = 'bold',
  NORMAL = 'normal',
  NONE = '100',
  HEADER = '500',
}

export enum TEXT_WRAP {
  PRE_WRAP = 'pre-wrap',
  NO_WRAP = 'nowrap',
}

export interface ITextProps {
  color?: COLOR_SHADE;
  type?: TEXT_TYPE;
  weight?: TEXT_WEIGHT;
  align?: TEXT_ALIGN;
  gutterTop?: TSHIRT_SIZE;
  gutterBot?: TSHIRT_SIZE;
  gutterLeft?: TSHIRT_SIZE;
  gutterRight?: TSHIRT_SIZE;
  theme?: IThemeProps;
  display?: CSS_DISPLAY;
  textWrap?: TEXT_WRAP;
  lineHeight?: number;
  itemProp?: string;
}

const StyledText = styled.div<ITextProps>((props: any) => ({
  margin: getGutter(props),
  color: getThemeTextColor(props, props.color),
  fontSize: getThemeFontSize(props, props.type),
  textAlign: props.align,
  fontWeight: props.weight || TEXT_WEIGHT.NONE,
  fontFamily: getThemeFontFamily(props),
  lineHeight: props.lineHeight || getThemeFontLineHeight(props, props.type),
  display: props.display,
  position: 'relative',
  whiteSpace: 'nowrap',
  p: {
    display: CSS_DISPLAY.INLINE_BLOCK,
  },
}));

const TextContainer: React.FC<ITextProps> = ({
  children,
  type,
  align,
  color,
  weight,
  gutterTop,
  gutterBot,
  gutterLeft,
  gutterRight,
  textWrap,
  lineHeight,
  ...restProps
}) => (
  <StyledText
    color={color}
    align={align}
    weight={weight}
    lineHeight={lineHeight}
    {...restProps}
    type={type}
    gutterTop={gutterTop}
    gutterBot={gutterBot}
    gutterLeft={gutterLeft}
    gutterRight={gutterRight}
    textWrap={textWrap}
  >
    {children}
  </StyledText>
);

export default TextContainer;

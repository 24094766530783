import { IconMoneyBackCheckmarkv2 } from 'images';
import { StyledMoneyBackContainer } from './styledComponents';
import hideable from 'utils/hideable';
import { MONEY_BACK_TEXT } from 'constants/common';

const MoneyBackTag = ({ isOnHomepage = false }) => (
  <StyledMoneyBackContainer isOnHomepage={isOnHomepage}>
    <IconMoneyBackCheckmarkv2 />
    <span>{MONEY_BACK_TEXT}</span>
  </StyledMoneyBackContainer>
);

export default hideable(MoneyBackTag);

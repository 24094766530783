import { forwardRef } from 'react';
import { ButtonProps } from './types';
import { StyledButton, StyledButtonInner } from './styledComponents';

export const Button = forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>((props, ref) => {
  const {
    children,
    type = 'button',
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    color = 'red',
    disabled = false,
    fullWidth = false,
    fullWidthOnMobile = false,
    variant = 'filled',
    size = 'md',
    compact = false,
    onClick,
    as,
    minWidth,
    ...rest
  } = props;

  return (
    <StyledButton
      as={as}
      ref={ref}
      size={size}
      variant={variant}
      color={color}
      type={!as ? type : undefined}
      compact={compact}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      minWidth={minWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      {...rest}
    >
      <StyledButtonInner>
        {LeftIcon}
        <span>{children}</span>
        {RightIcon}
      </StyledButtonInner>
    </StyledButton>
  );
});

import { usePageContext } from 'contexts/PageContext';
import { useScrollBlock } from 'hooks/useScrollBlock';
import { IconVideoModal } from 'images';
import React, { useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import { ImageContainer, StyledPlayIconWrapper } from './styledComponents';
import { IImageVideoPlayer } from './types';
import VideoModal from './VideoModal';
import { GatsbyImg } from '@smartproxy-web/ui';

const ImageVideoPlayer: React.FC<IImageVideoPlayer> = ({
  image,
  video,
  buildClickId,
}) => {
  const { tags, type } = usePageContext();

  const [isModalVisible, setModalVisibility] = useState(false);
  const isScrollable = useScrollBlock(isModalVisible);
  const showModal = () => {
    setModalVisibility((isModalVisible) => !isModalVisible);
    isScrollable;
  };

  if (!video) {
    return (
      <ImageContainer>
        <GatsbyImg image={image} />
      </ImageContainer>
    );
  }

  return (
    <>
      <ImageContainer
        isPointerVisible={!!video}
        onClick={showModal}
        id={buildClickId('video')}
        className={buildTrackingSelector({
          page: tags[0] || type,
          section: 'video-hero',
        })}
      >
        <StyledPlayIconWrapper>
          <IconVideoModal />
        </StyledPlayIconWrapper>
        <GatsbyImg image={image} />
      </ImageContainer>
      <VideoModal
        video={video}
        isVisible={isModalVisible}
        onClose={showModal}
      />
    </>
  );
};

export default ImageVideoPlayer;

import { RichText } from 'components/v2/RichText';
import {
  StyledToc,
  StyledTocUl,
  TocListItem,
} from 'features/TableOfContent/styledComponents';
import { getTemplates } from 'helpers/sliceHelper';
import { useHash } from 'hooks/useHash';
import { IconBlueDot } from 'images';
import React from 'react';
import {
  questionsSliceMap,
  QUESTIONS_SLICES,
} from 'templates/Questions/constants';
import { DefaultSlice } from 'types';
import { QuestionsContainer, StyledFakeLink } from './styledComponents';
import { IFaqQuestion } from './types';

const FaqQuestions: React.FC<IFaqQuestion> = ({
  primary: { title },
  items,
}) => {
  const { hash } = useHash();

  const questions = items.map(({ question_document: questionDocument }) => ({
    hash: `#${questionDocument.tags}`,
    data: questionDocument.document.data,
  }));

  const questionIndex = questions.findIndex(
    (question) => question.hash === hash
  );

  const prismicQuestions =
    questionIndex !== -1 ? questions[questionIndex] : questions[0];

  const templates = getTemplates<QUESTIONS_SLICES, DefaultSlice>(
    prismicQuestions?.data?.body || [],
    questionsSliceMap
  );
  const filteredTemplates = templates.filter(
    ({ slice }) => slice.slice_type !== QUESTIONS_SLICES.Breadcrumbs
  );

  const getQuestionHash = (questionHash: string) => {
    location.hash = questionHash;

    window.scroll({
      top: 900,
      behavior: 'smooth',
    });
  };

  return (
    <QuestionsContainer>
      <StyledToc>
        <RichText field={title} />
        <StyledTocUl rows={items.length}>
          {questions.map(({ data: { question_title: title }, hash }, idx) => (
            <TocListItem key={idx} onClick={() => getQuestionHash(hash)}>
              <IconBlueDot />
              <StyledFakeLink isActive={prismicQuestions.hash === hash}>
                {title.text}
              </StyledFakeLink>
            </TocListItem>
          ))}
        </StyledTocUl>
      </StyledToc>
      {filteredTemplates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          {...slice}
          title={prismicQuestions.data.question_title}
        />
      ))}
    </QuestionsContainer>
  );
};
export default FaqQuestions;

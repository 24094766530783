import { RichText } from 'components/v2/RichText';
import React from 'react';
import hideable from 'utils/hideable';
import {
  Content,
  ListItem,
  SmallTitle,
  StyledListContainer,
  TextColumnsContainer,
  TextColumnWrapper,
  Title,
} from './styledComponent';
import { ITextColumns } from './types';

const HideableSmallTitle = hideable(SmallTitle);

const TextColumns: React.FC<ITextColumns> = ({ primary: { title }, items }) => (
  <TextColumnsContainer>
    <TextColumnWrapper>
      <Title>
        <RichText field={title} />
      </Title>
      <StyledListContainer>
        {items.map(({ small_title: smallTitle, content }, index) => (
          <ListItem key={index}>
            <HideableSmallTitle isVisible={smallTitle.text}>
              <RichText field={smallTitle} />
            </HideableSmallTitle>
            <Content>
              <RichText field={content} />
            </Content>
          </ListItem>
        ))}
      </StyledListContainer>
    </TextColumnWrapper>
  </TextColumnsContainer>
);

export default TextColumns;

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import Cta from 'components/Cta';
import React from 'react';
import FormContent from './FormContent';
import {
  HeroHomePage,
  HeroHomePageTextBlock,
  StyledHomePageHeroContainer,
  StyledHomePageHeroWrapper,
  DescriptionWrapper,
} from './styledComponents';
import { IHeroInquiryForm } from './types';
import Reviews from 'features/Hero/Reviews';
import Features from './Features';
import { RichText } from 'components/v2/RichText';

const HeroInquiryForm: React.FC<IHeroInquiryForm> = ({
  primary: {
    title,
    description,
    button_text: buttonText,
    button_link: buttonLink,
    main_color: mainColor,
    button_color: buttonColor,
    trustpilot_logo: trustpilotLogo,
    trustpilot_stars: trustpilotStars,
    review_text: reviewText,
  },
  items,
}) => {
  const isReviewOn = Boolean(
    trustpilotStars.url || trustpilotLogo.url || reviewText.text
  );
  return (
    <StyledHomePageHeroWrapper>
      <StyledHomePageHeroContainer>
        <HeroHomePage>
          <HeroHomePageTextBlock
            mainColor={mainColor}
            isReviewOn={!!isReviewOn}
          >
            <Reviews
              isVisible={!!isReviewOn}
              review_stars={trustpilotStars}
              review_trustpilot={trustpilotLogo}
              review_text={reviewText}
              isStarsAdded={!!trustpilotStars.url}
            />
            <RichText field={title} />
            <DescriptionWrapper>
              <RichText field={description} />
            </DescriptionWrapper>
            <Cta
              isVisible={buttonText && buttonLink.url}
              ctaText={buttonText}
              ctaLink={buttonLink.url}
              themeName={BUTTON_THEMES[buttonColor]}
              themeType={BUTTON_TYPES.PRIMARY}
            />
            <Features
              features={items}
              isVisible={!!items.length}
              isCtaAdded={!!buttonLink.url}
            />
          </HeroHomePageTextBlock>
          <FormContent buttonColor={buttonColor} />
        </HeroHomePage>
      </StyledHomePageHeroContainer>
    </StyledHomePageHeroWrapper>
  );
};

export default HeroInquiryForm;

import BlogHero from 'features/Blog/BlogPost/Hero';
import TableOfContent from 'features/TableOfContent';
import { SliceMap } from 'types';
import BlogAuthor from 'features/Blog/BlogPost/BlogAuthor';
import { SocialButtonsBar } from 'features/SocialButtonsBar';
import RelatedArticles from 'features/RelatedArticles';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import TableBlock from 'features/TableBlock';
import SideImageTextBlock from 'features/Blog/BlogPost/SideImageTextBlock';
import { Spacing } from 'features/Spacing/Spacing';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import IconListBlock from 'features/IconListBlock';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import FooterBanner from 'features/FooterBanner';
import {
  BiggerPlansBanner,
  FaqBlock,
  QuoteComponent,
  SideBannerComponent,
  MiniTabbedCodeBlock,
  PrismicBlogImage,
  PrismicImageTabs,
  TextBlock,
} from '@smartproxy-web/features';
import Author from '@smartproxy-web/features/src/components/Blog/Author';

export enum BLOG_SLICES {
  Hero = 'hero_block',
  BlogImage = 'blog_image',
  TextBlock = 'text_block',
  BlogAuthor = 'blog_author',
  SocialButtonsBar = 'social_buttons_bar',
  TableOfContent = 'table_of_content',
  RelatedArticles = 'related_articles',
  Breadcrumbs = 'breadcrumbs',
  CtaBlock = 'cta_block',
  FaqBlock = 'faq_block',
  CodeBlock = 'code_block',
  CodeBlockv2 = 'code_block_v2',
  TableBlock = 'proxy_table',
  SideImageTextBlock = 'side_image_text_block',
  Spacing = 'spacing',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  IconListBlock = 'icon_list_block',
  ColumnsTextBlock = 'column_text',
  FooterBanner = 'footer_banner_slice',
  BiggerPlansBanner = 'bigger_plans',
  Authorv2 = 'author_v2',
  QuoteComponent = 'quote_component',
  SideBannerComponent = 'side_banner_component',
  ImageTabs = 'image_tabs',
}

export const blogSliceMap: SliceMap<BLOG_SLICES> = {
  [BLOG_SLICES.Hero]: BlogHero,
  [BLOG_SLICES.BlogImage]: PrismicBlogImage,
  [BLOG_SLICES.TextBlock]: TextBlock,
  [BLOG_SLICES.BlogAuthor]: BlogAuthor,
  [BLOG_SLICES.SocialButtonsBar]: SocialButtonsBar,
  [BLOG_SLICES.TableOfContent]: TableOfContent,
  [BLOG_SLICES.RelatedArticles]: RelatedArticles,
  [BLOG_SLICES.Breadcrumbs]: Breadcrumbs,
  [BLOG_SLICES.CtaBlock]: CtaBlock,
  [BLOG_SLICES.FaqBlock]: FaqBlock,
  [BLOG_SLICES.CodeBlock]: CodeBlock,
  [BLOG_SLICES.TableBlock]: TableBlock,
  [BLOG_SLICES.SideImageTextBlock]: SideImageTextBlock,
  [BLOG_SLICES.Spacing]: Spacing,
  [BLOG_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [BLOG_SLICES.VideoBlock]: VideoBlock,
  [BLOG_SLICES.IconListBlock]: IconListBlock,
  [BLOG_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [BLOG_SLICES.FooterBanner]: FooterBanner,
  [BLOG_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [BLOG_SLICES.Authorv2]: Author,
  [BLOG_SLICES.CodeBlockv2]: MiniTabbedCodeBlock,
  [BLOG_SLICES.QuoteComponent]: QuoteComponent,
  [BLOG_SLICES.SideBannerComponent]: SideBannerComponent,
  [BLOG_SLICES.ImageTabs]: PrismicImageTabs,
};

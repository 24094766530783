import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { RichText } from 'components/v2/RichText';
import { IconCheckmarkGreen } from 'images';
import React, { useState, useRef } from 'react';
import hideable from 'utils/hideable';
import {
  CopyButtonContainer,
  CopyMessage,
  CopyMessageContainer,
  StyledCodeBlock,
  StyledCodeSection,
  StyledCopyButton,
} from './styledComponents';
import { ICodeBlock } from './types';

const HideableCopyButton = hideable(StyledCopyButton);
const HideableMessage = hideable(CopyMessageContainer);

const CodeBlock: React.FC<ICodeBlock> = ({
  primary: { code_field: codeField, copy_option: copyOption },
}) => {
  const [messageVisibility, setMessageVisibility] = useState(false);
  const codeBlockRef = useRef<HTMLDivElement>(null);

  const copyCode = () => {
    setMessageVisibility(true);

    const code = codeBlockRef.current?.innerText;
    navigator.clipboard.writeText(code!);

    setTimeout(() => {
      setMessageVisibility(false);
    }, 2000);
  };
  return (
    <StyledCodeSection>
      <StyledCodeBlock ref={codeBlockRef}>
        <RichText field={codeField} />
      </StyledCodeBlock>
      <CopyButtonContainer>
        <HideableCopyButton
          isVisible={copyOption}
          themeName={BUTTON_THEMES.IMPORTANT}
          themeType={BUTTON_TYPES.SECONDARY}
          fontSize={TSHIRT_SIZE.M}
          onClick={copyCode}
        >
          Copy code
        </HideableCopyButton>
        <HideableMessage isVisible={messageVisibility}>
          <IconCheckmarkGreen />
          <CopyMessage>Code copied to clipboard</CopyMessage>
        </HideableMessage>
      </CopyButtonContainer>
    </StyledCodeSection>
  );
};

export default CodeBlock;

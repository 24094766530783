import { PRISMIC_VALUES } from 'constants/common';
import {
  StyledActionColumns,
  StyledActionsWrapper,
  StyledActionColumn,
} from '../styledComponents';
import ActionItem from './ActionItem';
import FooterLogo from './FooterLogo';
import {
  footerConnectLinks,
  footerPartnerLinks,
  footerSocialLinks,
} from '../constants';
import { QRCodeMap, enrichFooterLinksWithQRCode } from '../helpers';

const Actions = ({
  qrCodes,
  isDealsOrLpPage,
}: {
  qrCodes: QRCodeMap;
  isDealsOrLpPage: boolean;
}) => (
  <StyledActionsWrapper>
    <FooterLogo />
    <StyledActionColumns>
      <StyledActionColumn>
        <ActionItem
          isDealsOrLpPage={isDealsOrLpPage}
          direction="row"
          sectionName="connect"
          title={PRISMIC_VALUES.GET_IN_TOUCH}
          links={enrichFooterLinksWithQRCode(footerConnectLinks, qrCodes)}
        />
        <ActionItem
          sectionName="follow"
          title={PRISMIC_VALUES.FOLLOW}
          links={footerSocialLinks}
        />
      </StyledActionColumn>
      {!isDealsOrLpPage && (
        <StyledActionColumn>
          <ActionItem
            sectionName="links"
            title={PRISMIC_VALUES.LINKS}
            links={footerPartnerLinks}
          />
        </StyledActionColumn>
      )}
    </StyledActionColumns>
  </StyledActionsWrapper>
);

export default Actions;

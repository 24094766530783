import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  Block,
  BlocksContainer,
  BlockText,
  BlockTitle,
} from './styledComponents';
import { IBlock } from './types';

const Blocks: React.FC<IBlock> = ({
  blockOneText,
  blockOneTitle,
  blockTwoText,
  blockTwoTitle,
  updatedAccentColor,
}) => (
  <BlocksContainer color={updatedAccentColor}>
    <Block>
      <BlockTitle>
        <RichText field={blockOneTitle} />
      </BlockTitle>
      <BlockText>
        <RichText field={blockOneText} />
      </BlockText>
    </Block>
    <Block>
      <BlockTitle>
        <RichText field={blockTwoTitle} />
      </BlockTitle>
      <BlockText>
        <RichText field={blockTwoText} />
      </BlockText>
    </Block>
  </BlocksContainer>
);

export default Blocks;

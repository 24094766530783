import { slugify } from 'features/utils';

export interface ClickTrackingSelectorGeneratorProps {
  prefix?: string;
  page?: string;
  section?: string;
  context?: string;
  type?: 'button' | 'cta' | 'field' | 'link' | 'trigger' | 'video' | 'image';
}
/**
 * Returns a generated classname in the form: `x-page-section-context-type`
 * @param props
 * @returns
 */
export const buildTrackingSelector = (
  props: ClickTrackingSelectorGeneratorProps
) => {
  const {
    prefix = 'x',
    page = '',
    section = '',
    context = '',
    type = '',
  } = props;

  return [prefix, page, section, context, type]
    .filter(Boolean)
    .map(slugify)
    .join('-');
};

/**
 * Useful when you have multiple elements in the same section
 * and the only thing that needs to change is the context
 * @param defaultProps
 * @returns
 */
export const initBuildTrackingSelector = (
  defaultProps: ClickTrackingSelectorGeneratorProps
) => {
  return (props?: ClickTrackingSelectorGeneratorProps) =>
    buildTrackingSelector({
      ...defaultProps,
      ...props,
    });
};

export const getPageContextFromPathname = (pathname: string) => {
  if (pathname.startsWith('/')) {
    pathname = pathname.slice(1);
  }
  const splitPathname = pathname.split('/');
  const [type, ...tags] = splitPathname;
  return { type: type || 'home_page', tags: tags || [] };
};

import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  PrismicCarouselBlock,
  GettingStarted,
  FaqBlock,
  PrismicImageTabs,
  PromoBanner,
} from '@smartproxy-web/features';
import { BlogParent } from '@smartproxy-web/features';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import TableBlock from 'features/TableBlock';
import TrustpilotReviews from 'features/TruspilotReviews';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import { SliceMap } from 'types';
import HomepagePricingCards from 'features/HomePage/PricingCards';
import ProductCardBlock from 'features/HomePage/ProductCard/ProductCardBlock';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { HeroLpPPC } from 'features/v2/HeroLpPPC';
import { HeaderLpDeals } from 'features/v2/HeaderLpDeals';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { ProductCardsComponent } from 'features/v2/ProductCards';
import { ComparisonTable, PrismicTextGrid } from '@smartproxy-web/features';
import { QuoteComponent, TextBlock } from '@smartproxy-web/features';

export enum DEALS_SLICES {
  BadgesBlock = 'badges_block',
  BiggerPlansBanner = 'bigger_plans',
  Breadcrumbs = 'breadcrumbs',
  FaqBlock = 'faq_block',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  ImageTextColumn = 'image_text_column',
  LocationFlags = 'location_flags',
  PaymentMethod = 'payment_method',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  TrustpilotReviews = 'trustpilot_reviews',
  TextBlock = 'text_block',
  TableBlock = 'proxy_table',
  CtaBlock = 'cta_block',
  ColumnsTextBlock = 'column_text',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  HomepagePricingCards = 'homepage_pricing_cards',
  ProductCardsBlock = 'product_cards_block',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  HeroFeaturesv2 = 'hero_v2_features',
  Herov2 = 'hero_v2',
  HeroLogosv2 = 'hero_v2_logos',
  HeroLpPPC = 'ppc_lp_hero',
  HeaderLpDeals = 'lp_deals_header',
  AutomatedPricingCards = 'automated_pricing_cards',
  ProductCardsComponent = 'new_product_cards',
  ComparisonTable = 'comparison_table',
  PrismicCarouselBlock = 'carousel_block',
  GettingStarted = 'getting_started',
  BlogParent = 'blog_parent_block',
  QuoteComponent = 'quote_component',
  ImageTabs = 'image_tabs',
  PromoBanner = 'promo_banner',
}

export const dealsSliceMap: SliceMap<DEALS_SLICES> = {
  [DEALS_SLICES.BadgesBlock]: BadgesBlock,
  [DEALS_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [DEALS_SLICES.Breadcrumbs]: Breadcrumbs,
  [DEALS_SLICES.FaqBlock]: FaqBlock,
  [DEALS_SLICES.Hero]: Hero,
  [DEALS_SLICES.ImageTextBlock]: ImageTextBlock,
  [DEALS_SLICES.ImageTextColumn]: ImageTextColumn,
  [DEALS_SLICES.LocationFlags]: LocationFlags,
  [DEALS_SLICES.PaymentMethod]: PaymentMethod,
  [DEALS_SLICES.Spacing]: Spacing,
  [DEALS_SLICES.TextGrid]: PrismicTextGrid,
  [DEALS_SLICES.TrustpilotReviews]: TrustpilotReviews,
  [DEALS_SLICES.TextBlock]: TextBlock,
  [DEALS_SLICES.TableBlock]: TableBlock,
  [DEALS_SLICES.CtaBlock]: CtaBlock,
  [DEALS_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [DEALS_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [DEALS_SLICES.VideoBlock]: VideoBlock,
  [DEALS_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
  [DEALS_SLICES.HomepagePricingCards]: HomepagePricingCards,
  [DEALS_SLICES.ProductCardsBlock]: ProductCardBlock,
  [DEALS_SLICES.SocialProofBlock]: SocialProofBlock,
  [DEALS_SLICES.FeaturedIn]: FeaturedIn,
  [DEALS_SLICES.FooterBanner]: FooterBanner,
  [DEALS_SLICES.Herov2]: Herov2,
  [DEALS_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [DEALS_SLICES.HeroLogosv2]: HeroLogosv2,
  [DEALS_SLICES.HeroLpPPC]: HeroLpPPC,
  [DEALS_SLICES.HeaderLpDeals]: HeaderLpDeals,
  [DEALS_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [DEALS_SLICES.ProductCardsComponent]: ProductCardsComponent,
  [DEALS_SLICES.ComparisonTable]: ComparisonTable,
  [DEALS_SLICES.PrismicCarouselBlock]: PrismicCarouselBlock,
  [DEALS_SLICES.GettingStarted]: GettingStarted,
  [DEALS_SLICES.BlogParent]: BlogParent,
  [DEALS_SLICES.QuoteComponent]: QuoteComponent,
  [DEALS_SLICES.ImageTabs]: PrismicImageTabs,
  [DEALS_SLICES.PromoBanner]: PromoBanner,
};

import styled from '@emotion/styled';
import { StyledBadge } from 'components/v2/Badge/styledComponents';
import { StyledButton } from 'components/v2/Button/styledComponents';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
const CARD_WIDTH = 262;
const CARD_HEADER_HEIGHT = 160;

export const StyledCardContainer = styled.div({
  boxShadow: '0px 4px 16px rgba(29, 29, 31, 0.08)',
  backgroundColor: themev2.colors.neutral.N00,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  width: CARD_WIDTH,
  transition: '0.3s',
  alignSelf: 'stretch',
  'em, strong': {
    color: themev2.colors.neutral.N99,
    fontFamily: themev2.fonts.family.additional,
  },
  a: {
    fontFamily: themev2.fonts.family.additional,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
});

export const StyledCardHeader = styled.div({
  background: themev2.colors.black.S900,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  padding: themev2.spacings.S,
  position: 'relative',
  minHeight: CARD_HEADER_HEIGHT,
  height: 'auto',
  p: {
    color: themev2.colors.white.default,
    fontWeight: 700,
    fontSize: themev2.fonts.size.h4,
  },
  [String(StyledBadge)]: {
    fontSize: '10px',
    height: '20px',
    lineHeight: '12px',
    position: 'absolute',
    top: 12,
    right: 12,
    color: themev2.colors.white.default,
    padding: '4px 8px',
    textAlign: 'center',
    fontWeight: 500,
  },
});

export const StyledCtaWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  [String(StyledButton)]: {
    padding: 0,
  },
});

export const StyledCtaContainer = styled.div({
  ':hover': {
    svg: {
      transition: 'all 0.1s ease-in-out 0s',
      marginLeft: '2px',
    },
  },
});

export const StyledCtaDownloadContainer = styled.div({
  background: themev2.colors.neutral.N10,
  borderRadius: '50%',
  svg: {
    path: {
      fill: themev2.colors.neutral.N80,
    },
  },
  ':hover': {
    transition: 'all 0.1s ease-in-out 0s',
    background: themev2.colors.gray.S100,
  },
});

import React from 'react';
import { CtaBlockContainer, CtaBlockWrapper } from './styledComponents';
import { ICtaBlock } from './types';
import { LinkButton } from 'components/v2/LinkButton';

const CtaBlock: React.FC<ICtaBlock> = ({
  primary: {
    background,
    is_centered: isCentered,
    cta_link: ctaLink,
    cta_label: ctaLabel,
  },
}) => {
  return (
    <CtaBlockContainer background={background}>
      <CtaBlockWrapper isCentered={isCentered}>
        <LinkButton
          fullWidthOnMobile
          color="blue"
          variant="outlined"
          to={ctaLink.url}
        >
          {ctaLabel.text}
        </LinkButton>
      </CtaBlockWrapper>
    </CtaBlockContainer>
  );
};

export default CtaBlock;

import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import SideImageTextBlock from 'features/Blog/BlogPost/SideImageTextBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import FooterBanner from 'features/FooterBanner';
import QuestionTitle from 'features/QuestionsTitle';
import { Spacing } from 'features/Spacing/Spacing';
import TableBlock from 'features/TableBlock';
import Title from 'features/Title';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import { SliceMap } from 'types';
import {
  PrismicBlogImage,
  PrismicImageTabs,
  TextBlock,
} from '@smartproxy-web/features';

export enum QUESTIONS_SLICES {
  Breadcrumbs = 'breadcrumbs',
  BlogImage = 'blog_image',
  TextBlock = 'text_block',
  CtaBlock = 'cta_block',
  CodeBlock = 'code_block',
  TableBlock = 'proxy_table',
  SideImageTextBlock = 'side_image_text_block',
  Spacing = 'spacing',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  ColumnsTextBlock = 'column_text',
  Title = 'title',
  QuestionTitle = 'question_title',
  FooterBanner = 'footer_banner_slice',
  ImageTabs = 'image_tabs',
}

export const questionsSliceMap: SliceMap<QUESTIONS_SLICES> = {
  [QUESTIONS_SLICES.Breadcrumbs]: Breadcrumbs,
  [QUESTIONS_SLICES.BlogImage]: PrismicBlogImage,
  [QUESTIONS_SLICES.TextBlock]: TextBlock,
  [QUESTIONS_SLICES.CtaBlock]: CtaBlock,
  [QUESTIONS_SLICES.CodeBlock]: CodeBlock,
  [QUESTIONS_SLICES.TableBlock]: TableBlock,
  [QUESTIONS_SLICES.SideImageTextBlock]: SideImageTextBlock,
  [QUESTIONS_SLICES.Spacing]: Spacing,
  [QUESTIONS_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [QUESTIONS_SLICES.VideoBlock]: VideoBlock,
  [QUESTIONS_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [QUESTIONS_SLICES.Title]: Title,
  [QUESTIONS_SLICES.QuestionTitle]: QuestionTitle,
  [QUESTIONS_SLICES.FooterBanner]: FooterBanner,
  [QUESTIONS_SLICES.ImageTabs]: PrismicImageTabs,
};

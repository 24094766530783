import styled from '@emotion/styled';
import { HideOnMobile } from 'commonStyles';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import SearchBar from 'components/SearchBar';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const BlogHeroBackgroundWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    padding: `150px 20px ${getThemeGutter(props, TSHIRT_SIZE.XXXL)}`,
    height: 'auto',
    width: '100%',
    [MEDIA_QUERIES.TABLET]: {
      padding: `${getThemeGutter(props, TSHIRT_SIZE.XXXL)} 20px`,
    },
  })
);

export const BlogHero = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: '100%',
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  gridTemplateColumns: '1fr 360px',
  gridTemplateRows: 'auto auto',
  maxWidth: '1024px',
  margin: `${getThemeGutter(props, TSHIRT_SIZE.XXS)} auto`,
  height: 'auto',
  [MEDIA_QUERIES.TABLET]: {
    placeItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
  },
}));

export const BlogHeroText = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  height: 'auto',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.FLEX_START,
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    alignItems: CSS_ALIGN.CENTER,
    order: 1,
  },
}));

export const BlogHeroTitle = styled.div<ITheme & { accentColor: string }>(
  ({ accentColor, ...props }) => ({
    'h1, h2, h3': {
      width: '100%',
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
      color: accentColor || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      textAlign: CSS_ALIGN.START,
      fontWeight: 500,
      [MEDIA_QUERIES.TABLET]: {
        width: '100%',
        textAlign: CSS_ALIGN.CENTER,
      },
      [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
        lineHeight: '48px',
      },
    },
  })
);

export const BlogHeroSubtitle = styled.div<ITheme & { accentColor: string }>(
  ({ accentColor, ...props }) => ({
    p: {
      width: 'auto',
      marginBottom: '27px',
      color: getThemeMainColor(props),
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      fontWeight: 400,
      [MEDIA_QUERIES.TABLET]: {
        width: '100%',
        textAlign: CSS_ALIGN.CENTER,
      },
      a: {
        color: accentColor || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
        ':hover': {
          textDecoration: 'underline',
        },
      },
      ':last-of-type': {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
        [MEDIA_QUERIES.PHONE_PORTRAIT]: {
          marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
        },
      },
    },
  })
);

export const BlogHeroImage = styled.div({
  maxHeight: '460px',
  maxWidth: '360px',
  div: {
    height: '100%',
    width: '100%',
  },
  ...HideOnMobile,
});

export const StyledSearchBar = styled(SearchBar)({
  maxWidth: '1024px',
  margin: '0 auto',
});

export const HideableSearchBar = hideable(StyledSearchBar);

import { BUTTON_THEMES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { useScrollRatio } from 'hooks/useScrollRatio';
import { IconHandSwipe } from 'images';
import React, { useRef } from 'react';
import {
  StyledContainedContainer,
  StyledContainer,
  StyledHandIconWrapper,
  StyledTable,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableFooter,
  StyledTableHeader,
  StyledTableWrapper,
  HideableTitles,
} from './styledComponents';
import { IFeatureComparisonTableProps } from './types';
import { getColumns, getRows } from './utils';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';
import { RichText } from 'components/v2/RichText';

const FeatureComparisonTable: React.FC<IFeatureComparisonTableProps> = ({
  items,
  primary,
}) => {
  const { background, title, subtitle } = primary;

  const columns = getColumns(primary);

  const rows = getRows(columns.length, items);

  const tableElRef = useRef<HTMLDivElement>(null);

  const tableScrollX = useScrollRatio({
    el: tableElRef.current,
    direction: 'x',
  });

  const { tags, type } = usePageContext();
  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'feature-comparison-table',
  });

  return (
    <StyledContainer background={background}>
      <StyledContainedContainer>
        <HideableTitles isVisible={!!title || !!subtitle}>
          <RichText field={title} />
          <RichText field={subtitle} />
        </HideableTitles>
        <StyledTableWrapper background={background} scrollX={tableScrollX}>
          <StyledTable ref={tableElRef}>
            <StyledTableHeader noOfCols={columns.length}>
              {columns.map((column, colIndex) => (
                <div key={column.value.text || colIndex}>
                  <RichText field={column.value} />
                </div>
              ))}
            </StyledTableHeader>
            <StyledTableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableBodyRow key={rowIndex} noOfCols={columns.length}>
                  {row.map(
                    (cell, cellIndex) =>
                      cell && (
                        <div key={cell.text || cellIndex}>
                          <RichText field={cell} />
                        </div>
                      )
                  )}
                </StyledTableBodyRow>
              ))}
            </StyledTableBody>
            <StyledTableFooter noOfCols={columns.length}>
              {columns.map(({ action }, colIndex) => (
                <div key={colIndex}>
                  {action.url && (
                    <Cta
                      isVisible
                      ctaText={action.label}
                      ctaLink={action.url}
                      height={TSHIRT_SIZE.L}
                      width={TSHIRT_SIZE.XL}
                      themeName={BUTTON_THEMES.IMPORTANT}
                      data-tracker={buildTrackingSelector({
                        context: action.label,
                        type: 'cta',
                      })}
                    />
                  )}
                </div>
              ))}
            </StyledTableFooter>
          </StyledTable>
        </StyledTableWrapper>

        <StyledHandIconWrapper>
          {tableScrollX != -1 ? <IconHandSwipe /> : null}
        </StyledHandIconWrapper>
      </StyledContainedContainer>
    </StyledContainer>
  );
};

export default FeatureComparisonTable;

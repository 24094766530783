import styled from '@emotion/styled';
import {
  BackgroundWrapper,
  ColContainer,
  flexColContainerStyles,
} from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';

interface IStyledContainer {
  numberOfColumns: number;
}

export const Container = styled(BackgroundWrapper)<ITheme>((props) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
}));

export const ContentWrapper = styled(ColContainer)<ITheme>({
  maxWidth: '1024px',
  width: '100%',
});

export const TitleWrapper = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  textAlign: TEXT_ALIGN.CENTER,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const StyledContainer = styled.div<IStyledContainer & ITheme>(
  ({ numberOfColumns, ...props }) => ({
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${numberOfColumns}, minmax(auto,1fr))`,
    columnGap: `${
      numberOfColumns <= 3 ? getThemeGutter(props, TSHIRT_SIZE.L) : '16px'
    }`,
    rowGap: '48px',
    [MEDIA_QUERIES.TABLET]: {
      columnGap: `${
        numberOfColumns <= 3 && getThemeGutter(props, TSHIRT_SIZE.S)
      }`,
      gridTemplateColumns: `${
        numberOfColumns <= 3
          ? `repeat(${numberOfColumns}, minmax(auto,1fr))`
          : 'repeat(4, minmax(auto,1fr))'
      }`,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: `${
        numberOfColumns <= 3 ? '1fr' : 'repeat(3, minmax(auto,1fr))'
      }`,
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: `${
        numberOfColumns > 3 && 'repeat(2, minmax(auto,1fr))'
      }`,
    },
  })
);

export const StyledFeaturesWrapper = styled.div<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  alignItems: CSS_ALIGN.CENTER,
  ...flexColContainerStyles,
  div: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    height: '96px',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    ...flexColContainerStyles,
  },
}));

export const DescriptionWrapper = styled.div<ITheme>((props) => ({
  p: {
    fontWeight: 400,
    '&:empty': {
      display: CSS_DISPLAY.NONE,
    },
  },
  'ol, ul': {
    paddingLeft: '15px',
  },
  li: {
    fontWeight: 400,
    padding: 0,
    marginBottom: getThemeGutter(props),
  },
  a: {
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

export const StyledCtaContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  a: {
    margin: 0,
  },
}));

export const StyledCta = styled(Cta)<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  height: '42px',
  minWidth: '180px',
  padding: 0,
  margin: 0,
  ':hover': {
    padding: 0,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    maxWidth: '180px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: 'unset',
  },
}));

export const HideableStyledCtaContainer = hideable(StyledCtaContainer);

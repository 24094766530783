import React from 'react';
import { MENU_CONTENT_VALUES } from '../../constants';
import { transformUrl } from '../../helpers';
import {
  CategoriesItemsContainer,
  CategoryContentContainer,
  CategoryItemContainer,
  CategoryItemDescription,
  CategoryItemTitle,
  CategoryItemTitleContainer,
  CategoryItemWrapper,
  CategoryPricing,
  CategoryTitle,
} from '../styledComponents';
import { ICategoryItems, ITracker } from './types';
import { useHover } from '@smartproxy-web/shared/hooks';
import { Badge, IconMegaMenuArrow } from '@smartproxy-web/ui';
import { IMenuCatgoryItemChildren } from '../types';
import { getPeriod } from './utils';
import Link from 'components/GatsbyLink';

export const CategoriesItems: React.FC<ICategoryItems> = ({
  itemTitle,
  itemLink,
  itemChildren,
  buildTrackingId,
  handleCategoryItemClick,
}) => {
  return (
    <CategoriesItemsContainer>
      {!!itemLink?.url ? (
        <Link
          to={itemLink.url}
          data-tracker={buildTrackingId({
            context: itemTitle,
            section: 'header-dropdown',
            type: 'link',
          })}
          onClick={() => handleCategoryItemClick(transformUrl(item.url))}
        >
          <CategoryTitle>
            {itemTitle} <IconMegaMenuArrow />
          </CategoryTitle>
        </Link>
      ) : (
        <CategoryTitle>{itemTitle}</CategoryTitle>
      )}
      <CategoryContentContainer>
        {itemChildren?.map((item, index) => (
          <CategoryItem
            key={index}
            item={item}
            buildTrackingId={buildTrackingId}
            handleCategoryItemClick={handleCategoryItemClick}
          />
        ))}
      </CategoryContentContainer>
    </CategoriesItemsContainer>
  );
};

type Props = {
  item: IMenuCatgoryItemChildren;
  buildTrackingId: ITracker['buildTrackingId'];
  handleCategoryItemClick: ICategoryItems['handleCategoryItemClick'];
};

function CategoryItem({
  item,
  buildTrackingId,
  handleCategoryItemClick,
}: Props) {
  const { hoverRef, isHovered } = useHover();

  return (
    <CategoryItemWrapper ref={hoverRef}>
      <CategoryItemContainer
        hasPrice={!!item.price}
        to={item.url}
        data-tracker={buildTrackingId({
          context: transformUrl(item.url),
          section: 'header-dropdown',
          type: 'link',
        })}
        onClick={() => handleCategoryItemClick(transformUrl(item.url))}
      >
        <div>
          <CategoryItemTitleContainer
            color={item.badgeColor.toLowerCase()}
            hasPrice={!!item.price}
          >
            <CategoryItemTitle>{item.label}</CategoryItemTitle>
            {!!item.badgeLabel && (
              <Badge
                variant="outlined"
                color={item.badgeColor.toLowerCase()}
                forcedEvent={isHovered ? 'hover' : undefined}
              >
                {item.badgeLabel}
              </Badge>
            )}
          </CategoryItemTitleContainer>
          {!!item.categoryDescription.text && (
            <CategoryItemDescription>
              {item.categoryDescription.text}
            </CategoryItemDescription>
          )}
        </div>
        {!!item.price && (
          <CategoryPricing>
            <span>{MENU_CONTENT_VALUES.PRICE_FROM}</span>
            <div>
              ${item.price}
              <span>{getPeriod(`/${item.pricePer}`)}</span>
            </div>
          </CategoryPricing>
        )}
      </CategoryItemContainer>
    </CategoryItemWrapper>
  );
}

import { PRISMIC_VALUES, TEXT_LENGTH_VALUES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import { shortenText } from 'features/utils';
import Link from 'components/GatsbyLink';
import * as React from 'react';
import { ITextImageColumns } from '../types';
import {
  TextImageColumnsContainer,
  TextImageColumnsItems,
  TextImageColumnsTitle,
  TextImageColumnsWrapper,
  TextImageColumnBlock,
  TextImageColumnTitle,
  TextImageColumnImageAndTitleContainer,
  TextImageColumnDescription,
  TextImageColumnLinkContainer,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';

const TextImageColumn: React.FC<ITextImageColumns> = ({
  primary: { column_background: background, column_list_title: columnsTitle },
  items,
}) => (
  <TextImageColumnsWrapper background={background}>
    <TextImageColumnsContainer>
      <TextImageColumnsTitle>
        <RichText field={columnsTitle} />
      </TextImageColumnsTitle>
      <TextImageColumnsItems>
        {items.map(
          (
            {
              column_link: link,
              column_description: description,
              column_image: image,
              column_title: title,
              link_text: linkText,
            },
            index
          ) => (
            <TextImageColumnBlock key={index}>
              <TextImageColumnImageAndTitleContainer>
                <GatsbyImg image={image} />
                <TextImageColumnTitle>
                  <RichText field={title} />
                </TextImageColumnTitle>
              </TextImageColumnImageAndTitleContainer>
              <TextImageColumnDescription>
                {shortenText(
                  description.text,
                  TEXT_LENGTH_VALUES.HOMEPAGE_PROXIES
                )}
              </TextImageColumnDescription>
              <TextImageColumnLinkContainer>
                <Link to={link.url}>
                  {linkText || PRISMIC_VALUES.LEARN_MORE}
                </Link>
              </TextImageColumnLinkContainer>
            </TextImageColumnBlock>
          )
        )}
      </TextImageColumnsItems>
    </TextImageColumnsContainer>
  </TextImageColumnsWrapper>
);

export default TextImageColumn;

import React from 'react';
import { OptionButton, StyledContainer } from '../styledComponents';
import { IOptionProps } from '../types';

const Option: React.FC<IOptionProps> = ({ label, onClick }) => (
  <StyledContainer>
    <OptionButton type="button" onClick={onClick}>
      {label}
    </OptionButton>
  </StyledContainer>
);

export default Option;

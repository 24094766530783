import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  BackgroundWrapper,
  LogosContainer,
  PageLogos,
} from './styledComponents';
import { IRecognisedByBlock } from './types';

const RecognisedByBlock: React.FC<IRecognisedByBlock> = ({
  primary: { title_for_logos: titleForLogos, background },
  items,
}) => (
  <BackgroundWrapper background={background}>
    <LogosContainer>
      <RichText field={titleForLogos} />
      <PageLogos>
        {items.map(({ icon }, index) => (
          <GatsbyImg image={icon} key={index} />
        ))}
      </PageLogos>
    </LogosContainer>
  </BackgroundWrapper>
);

export default RecognisedByBlock;

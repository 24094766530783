import { SECTION_NAMES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import { generateSectionsId, slugify, updateColorInfo } from 'features/utils';
import * as React from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  CtaHeroUSP,
  HeroFeature,
  HeroHomePage,
  HeroHomePageImageBlock,
  HeroHomePageTextBlock,
  HeroTitleWrapper,
  StyledHomePageHeroContainer,
  StyledHomePageHeroWrapper,
  TrustpilotContainer,
} from './styledComponents';
import { IHeroUSP } from './types';
import { RichText } from 'components/v2/RichText';

const HomepageHeroUSP: React.FC<IHeroUSP> = ({
  primary: {
    cta_label: ctaLabel,
    cta_link1: ctaLink,
    hero_image: heroImage,
    text,
    title1: title,
    trustpilot,
    trustpilot_score: trustpilotScore,
    checkmark,
    cta_and_title_color: color,
  },
  items,
  pageContext,
}) => {
  const { tags, type } = pageContext || { tags: [] };
  const buildClickId = (btnType: string) => {
    return `hero-${slugify(tags[0] || type)}-${slugify(btnType)}-cta`;
  };
  const updatedColor = updateColorInfo(color.toLowerCase());

  return (
    <StyledHomePageHeroWrapper
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledHomePageHeroContainer>
        <HeroHomePage>
          <HeroHomePageTextBlock>
            <TrustpilotContainer>
              <GatsbyImg image={trustpilot} />
              <span>{trustpilotScore.text}</span>
            </TrustpilotContainer>
            <HeroTitleWrapper color={updatedColor.color}>
              <RichText field={title} />
            </HeroTitleWrapper>
            <RichText field={text} />
            {items.map((item, index) => (
              <HeroFeature key={index}>
                <GatsbyImg image={checkmark} />
                {item.checkmark_feature.text}
              </HeroFeature>
            ))}
            <CtaHeroUSP
              linkId={buildClickId(ctaLabel.text)}
              linkClassName={buildTrackingSelector({
                page: tags[0] || type,
                section: 'homepageHero',
                context: 'primary',
                type: 'cta',
              })}
              isVisible
              ctaText={ctaLabel.text}
              ctaLink={ctaLink.url}
              themeName={updatedColor.themeName}
              themeType={updatedColor.themeType}
            />
          </HeroHomePageTextBlock>
          <HeroHomePageImageBlock>
            <GatsbyImg image={heroImage} />
          </HeroHomePageImageBlock>
        </HeroHomePage>
      </StyledHomePageHeroContainer>
    </StyledHomePageHeroWrapper>
  );
};

export default HomepageHeroUSP;

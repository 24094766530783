import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import IconListBlock from 'features/IconListBlock';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import { Spacing } from 'features/Spacing/Spacing';
import SideImageTextBlock from 'features/Blog/BlogPost/SideImageTextBlock';
import TableBlock from 'features/TableBlock';
import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import CtaBlock from 'features/CtaBlock';
import { SliceMap } from 'types';
import Breadcrumbs from 'features/Breadcrumbs';
import Hero from 'features/Hero';
import Title from 'features/Title';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import { SocialButtonsBar } from 'features/SocialButtonsBar';
import SalesRepContacts from 'features/SalesRepContacts';
import CaseStudyColumns from 'features/CaseStudyColumns';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import {
  PrismicBlogImage,
  PrismicImageTabs,
  TextBlock,
} from '@smartproxy-web/features';

export enum CASE_POST_SLICES {
  Hero = 'hero_block',
  Breadcrumbs = 'breadcrumbs',
  BlogImage = 'blog_image',
  TextBlock = 'text_block',
  CtaBlock = 'cta_block',
  CodeBlock = 'code_block',
  TableBlock = 'proxy_table',
  SideImageTextBlock = 'side_image_text_block',
  Spacing = 'spacing',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  IconListBlock = 'icon_list_block',
  ColumnsTextBlock = 'column_text',
  Title = 'title',
  ImageTextBlock = 'image_text_block',
  SocialButtonsBar = 'social_buttons_bar',
  SalesRepContacts = 'sales_rep_contacts',
  CaseStudyColumns = 'case_study_columns',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  ImageTabs = 'image_tabs',
}

export const casePostSliceMap: SliceMap<CASE_POST_SLICES> = {
  [CASE_POST_SLICES.Hero]: Hero,
  [CASE_POST_SLICES.Breadcrumbs]: Breadcrumbs,
  [CASE_POST_SLICES.BlogImage]: PrismicBlogImage,
  [CASE_POST_SLICES.TextBlock]: TextBlock,
  [CASE_POST_SLICES.CtaBlock]: CtaBlock,
  [CASE_POST_SLICES.CodeBlock]: CodeBlock,
  [CASE_POST_SLICES.TableBlock]: TableBlock,
  [CASE_POST_SLICES.SideImageTextBlock]: SideImageTextBlock,
  [CASE_POST_SLICES.Spacing]: Spacing,
  [CASE_POST_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [CASE_POST_SLICES.VideoBlock]: VideoBlock,
  [CASE_POST_SLICES.IconListBlock]: IconListBlock,
  [CASE_POST_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [CASE_POST_SLICES.Title]: Title,
  [CASE_POST_SLICES.ImageTextBlock]: ImageTextBlock,
  [CASE_POST_SLICES.SocialButtonsBar]: SocialButtonsBar,
  [CASE_POST_SLICES.SalesRepContacts]: SalesRepContacts,
  [CASE_POST_SLICES.CaseStudyColumns]: CaseStudyColumns,
  [CASE_POST_SLICES.FooterBanner]: FooterBanner,
  [CASE_POST_SLICES.Herov2]: Herov2,
  [CASE_POST_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [CASE_POST_SLICES.HeroLogosv2]: HeroLogosv2,
  [CASE_POST_SLICES.ImageTabs]: PrismicImageTabs,
};

import {
  getThemeGutter,
  getThemeInputWidthShape,
  ITheme,
  IThemeProps,
} from '../theme';
import { BUTTON_THEMES, BUTTON_TYPES } from './Button';
import { TSHIRT_SIZE } from './constants';

export type TGutter = IGutterProps & ITheme;

export interface IGutterProps {
  gutterTop?: TSHIRT_SIZE;
  gutterBot?: TSHIRT_SIZE;
  gutterLeft?: TSHIRT_SIZE;
  gutterRight?: TSHIRT_SIZE;
}
export const getGutter = ({
  gutterTop,
  gutterBot,
  gutterLeft,
  gutterRight,
  ...props
}: TGutter) =>
  `${getThemeGutter(props, gutterTop)} 
    ${getThemeGutter(props, gutterRight || TSHIRT_SIZE.XXS)} 
    ${getThemeGutter(props, gutterBot)} 
    ${getThemeGutter(props, gutterLeft || TSHIRT_SIZE.XXS)}`;

export const getInputMaxWidth = (
  props: { theme: IThemeProps },
  inputWidth?: TSHIRT_SIZE
) => (inputWidth ? getThemeInputWidthShape(props, inputWidth) : '400px');

export const noop = () => {};

export const updateColorInfo = (color: string) => {
  const colorMap = {
    blue: {
      themeName: BUTTON_THEMES.IMPORTANT,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#2C96F7',
    },
    red: {
      themeName: BUTTON_THEMES.CONVERSION,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#F44862',
    },
    green: {
      themeName: BUTTON_THEMES.FOLLOWING,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#00BE7A',
    },
    yellow: {
      themeName: BUTTON_THEMES.UNIMPORTANT,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#F8A300',
    },
    transparent: {
      themeName: BUTTON_THEMES.CONVERSION,
      themeType: BUTTON_TYPES.SECONDARY,
      color: '#FFFF',
    },
  };

  return colorMap[color];
};

export const getUpdatedAccentColor = (accentColor: string) => {
  const updatedColor =
    accentColor !== 'None' && updateColorInfo(accentColor?.toLowerCase());

  return updatedColor;
};

export const getTypeFromPath = (pathname: string) =>
  pathname?.split('/')[1]?.toLocaleLowerCase();

import styled from '@emotion/styled';
import { flexColContainerStyles, HideOnMobile } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const StyledWrapper = styled.section<ITheme & IColor>(
  ({ background, ...props }) => ({
    background: background || themev2.colors.black.S900,
    width: '100%',
    padding: `${getThemeGutter(props, TSHIRT_SIZE.XXXL)} 20px `,
  })
);

export const StyledContainer = styled.div<ITheme>((props) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '1fr 360px',
  alignItems: CSS_ALIGN.CENTER,
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, auto)',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: 'unset',
    margin: '0 auto',
  },
}));

export const StyledTextContainer = styled.div<ITheme & { isReviewOn?: string }>(
  ({ color, isReviewOn, ...props }) => ({
    ...flexColContainerStyles,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    h1: {
      marginTop: isReviewOn
        ? getThemeGutter(props, TSHIRT_SIZE.XL)
        : getThemeGutter(props, TSHIRT_SIZE.XXS),
      marginBottom: getThemeGutter(props),
      color: color || getThemeBrandedColor(props),
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      },
    },
    p: {
      marginBottom: '27px',
      color: getThemeMainColor(props),
      a: {
        color: color || getThemeBrandedColor(props),
        ':hover': {
          textDecoration: 'underline',
        },
      },
      ':last-of-type': {
        marginBottom: 0,
        [MEDIA_QUERIES.PHONE_PORTRAIT]: {
          marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
        },
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      alignItems: CSS_ALIGN.CENTER,
      gridRow: 2,
      textAlign: TEXT_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      h4: {
        marginBottom: '28px',
      },
    },
  })
);

export const Title = styled.div<ITheme & { isReviewOn?: string }>(
  ({ color, isReviewOn, ...props }) => ({
    'h1, h2, h3': {
      marginTop: isReviewOn
        ? getThemeGutter(props, TSHIRT_SIZE.XL)
        : getThemeGutter(props, TSHIRT_SIZE.XXS),
      marginBottom: getThemeGutter(props),
      color: color || getThemeBrandedColor(props),
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      },
    },
  })
);

export const Subtitle = styled.div<ITheme & { isReviewOn: string }>(
  ({ color, ...props }) => ({
    p: {
      marginBottom: '27px',
      color: getThemeMainColor(props),
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
      fontWeight: 400,
      ':last-of-type': {
        marginBottom: 0,
        [MEDIA_QUERIES.PHONE_PORTRAIT]: {
          marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
        },
      },
      a: {
        color: color || getThemeBrandedColor(props),
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      'h2, h3, h4': {
        marginBottom: '28px',
      },
    },
  })
);

export const CtaContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  display: CSS_DISPLAY.FLEX,
  a: {
    margin: 0,
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '87%',
    minWidth: '460px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: '100%',
    minWidth: 'unset',
    margin: 0,
  },
}));

export const CtaPricing = styled(Cta)<ITheme>((props) => ({
  width: '220px',
  height: '52px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  margin: 0,
  [MEDIA_QUERIES.TABLET]: {
    width: '220px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '220px',
    maxWidth: '220px',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '100%',
  },
}));

export const CtaStart = styled(CtaPricing)<ITheme>((props) => ({
  marginRight: getThemeGutter(props),
}));

export const ImageContainer = styled.div({
  margin: 'auto',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    margin: 0,
  },
  div: {
    maxWidth: 'auto',
    width: '100%',
    height: '100%',
    img: {
      height: '460px',
      width: '360px',
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        width: '100%',
      },
    },
    div: {
      maxWidth: 'unset !important',
    },
  },
  ...HideOnMobile,
});

export const HideableImageContainer = hideable(ImageContainer);
export const StyledReviews = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  p: {
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H5),
    fontWeight: 500,
    [MEDIA_QUERIES.TABLET_PORTRAIT]: {
      margin: '0 !important',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const StyledImageWrapper = styled.div<ITheme>((props) => ({
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginRight: 0,
    marginBottom: getThemeGutter(props),
  },
}));

export const HeroHomePageLogosContainer = styled.div<ITheme>((props) => ({
  gridRow: 'span 1 / span 1',
  gridColumn: 'span 2 / span 2',
  height: 'auto',
  clear: 'left',
  [MEDIA_QUERIES.TABLET]: {
    gridRowStart: 3,
    gridRowEnd: 4,
    gridColumnStart: 1,
    gridColumnEnd: 2,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageLogosTitle = styled.div<ITheme>((props) => ({
  lineHeight: '20px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontWeight: 400,
  color: getThemeMainColor(props),
  [MEDIA_QUERIES.TABLET]: {
    textAlign: CSS_ALIGN.CENTER,
  },
}));

export const HeroHomePageLogos = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  alignItems: CSS_ALIGN.CENTER,
  div: {
    marginTop: '20px',
    verticalAlign: 'middle',
    display: CSS_DISPLAY.INLINE_BLOCK,
    img: {
      display: CSS_DISPLAY.INLINE_BLOCK,
      height: 'auto',
      width: 'auto',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_AROUND,
    div: {
      padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
      img: {
        width: 'auto',
      },
    },
  },
}));

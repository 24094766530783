import BadgesBlock from 'features/BadgesBlock';
import { BiggerPlansBanner, PrismicImageTabs } from '@smartproxy-web/features';
import Breadcrumbs from 'features/Breadcrumbs';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import Title from 'features/Title';
import { SliceMap } from 'types';
import HomepagePricingCards from 'features/HomePage/PricingCards';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { PrismicTextGrid } from '@smartproxy-web/features';

export enum WHY_SMARTPROXY_SLICES {
  BadgesBlock = 'badges_block',
  BiggerPlansBanner = 'bigger_plans',
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  PaymentMethod = 'payment_method',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  Title = 'title',
  HomepagePricingCards = 'homepage_pricing_cards',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  AutomatedPricingCards = 'automated_pricing_cards',
  ImageTabs = 'image_tabs',
}

export const whySmartproxySliceMap: SliceMap<WHY_SMARTPROXY_SLICES> = {
  [WHY_SMARTPROXY_SLICES.BadgesBlock]: BadgesBlock,
  [WHY_SMARTPROXY_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [WHY_SMARTPROXY_SLICES.Breadcrumbs]: Breadcrumbs,
  [WHY_SMARTPROXY_SLICES.Hero]: Hero,
  [WHY_SMARTPROXY_SLICES.ImageTextBlock]: ImageTextBlock,
  [WHY_SMARTPROXY_SLICES.PaymentMethod]: PaymentMethod,
  [WHY_SMARTPROXY_SLICES.Spacing]: Spacing,
  [WHY_SMARTPROXY_SLICES.TextGrid]: PrismicTextGrid,
  [WHY_SMARTPROXY_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [WHY_SMARTPROXY_SLICES.VideoBlock]: VideoBlock,
  [WHY_SMARTPROXY_SLICES.Title]: Title,
  [WHY_SMARTPROXY_SLICES.HomepagePricingCards]: HomepagePricingCards,
  [WHY_SMARTPROXY_SLICES.SocialProofBlock]: SocialProofBlock,
  [WHY_SMARTPROXY_SLICES.FeaturedIn]: FeaturedIn,
  [WHY_SMARTPROXY_SLICES.FooterBanner]: FooterBanner,
  [WHY_SMARTPROXY_SLICES.Herov2]: Herov2,
  [WHY_SMARTPROXY_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [WHY_SMARTPROXY_SLICES.HeroLogosv2]: HeroLogosv2,
  [WHY_SMARTPROXY_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [WHY_SMARTPROXY_SLICES.ImageTabs]: PrismicImageTabs,
};

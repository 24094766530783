import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice, PrismicTextField, TGatsbyImage } from 'types';
import { PageTemplateQuery } from 'types.generated';
import {
  homePageSliceMap,
  HOME_PAGE_SLICES,
} from 'pageUtils/homePage/constants';
import { Wrapper } from 'commonStyles';
import { IWithBlogProps } from 'pageUtils/blog/types';

interface IWithPrismicHomePage {
  prismicHomePage: {
    data: {
      title: PrismicTextField;
      description: PrismicTextField;
      cta_text: PrismicTextField;
      cta_link: string;
      homepage_image: TGatsbyImage;
      body: DefaultSlice[];
    };
  };
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

export interface Props {
  data: IWithPrismicHomePage;
}

const HomePage = ({ data }: Props & PageTemplateProps & IWithBlogProps) => {
  const { allPrismicBlog, prismicHomePage } = data;
  const templates = getTemplates<HOME_PAGE_SLICES, DefaultSlice>(
    prismicHomePage.data.body,
    homePageSliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          {...slice}
          title={prismicHomePage.data.title}
          description={prismicHomePage.data.description}
          ctaText={prismicHomePage.data.cta_text}
          ctaLink={prismicHomePage.data.cta_link}
          homepageImage={prismicHomePage.data.homepage_image}
          articles={allPrismicBlog.nodes}
        />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(HomePage);

export const query = graphql`
  query HomePageQuery {
    prismicHomePage {
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        title {
          richText
          text
        }
        description {
          richText
          text
        }
        cta_text {
          richText
          text
        }
        cta_link {
          url
        }
        homepage_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        body {
          ... on PrismicHomePageDataBodyTrustpilotReviews {
            id
            items {
              review {
                richText
                text
              }
              star_rating {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              user_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              username {
                richText
                text
              }
            }
            primary {
              background
              link_to {
                url
              }
              trustpilot_logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyHeroLogos {
            id
            slice_type
            items {
              logos {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
            primary {
              background
              title_for_logos {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
            }
          }
          ... on PrismicHomePageDataBodyPromoBanner {
            id
            slice_type
            items {
              banner_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              content
              cta_text
              cta_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBodyLocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                text
                richText
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              subtitle {
                richText
                text
              }
              available_cities {
                richText
                text
              }
              background
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicHomePageDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyProductCardsBlock {
            id
            items {
              badge {
                richText
                text
              }
              link {
                url
              }
              product_description {
                richText
                text
              }
              product_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              product_title {
                richText
                text
              }
            }
            slice_type
            primary {
              background
              block_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyUseCaseBlock {
            id
            items {
              group_name
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
            primary {
              number_of_columns
              background_color
              column_title_1 {
                richText
                text
              }
              column_title_2 {
                richText
                text
              }
              column_title_3 {
                richText
                text
              }
              main_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyImageTextColumnsWithCta {
            id
            items {
              cta_label {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              title_field {
                richText
                text
              }
              subtitle_and_text_alignment
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyHomepagePricingCards {
            id
            items {
              benefit_1 {
                text
                richText
              }
              benefit_2 {
                richText
                text
              }
              benefit_3 {
                richText
                text
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_name: card_title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link_field {
                url
              }
              card_price: price {
                richText
                text
              }
            }
            primary {
              column_number
              background
              title_field {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyTextImageColumnList {
            id
            slice_type
            primary {
              column_background
              column_list_title {
                richText
                text
              }
            }
            items {
              link_text
              column_description {
                richText
                text
              }
              column_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              column_link {
                url
              }
              column_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyRelatedArticles {
            id
            slice_type
            primary {
              background_color
              title {
                richText
                text
              }
            }
            items {
              article {
                document {
                  ...BlogItemFragment
                }
              }
            }
          }
          ... on PrismicHomePageDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
            }
          }
          ... on PrismicHomePageDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyImageTextBlock {
            slice_type
            id
            primary {
              text_below_title {
                richText
                text
              }
              image_zoom_modal
              is_image_right
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
              sub_title {
                richText
                text
              }
              review_description
              review_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_bottom_text {
                richText
                text
              }
              columns_number
            }
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyCtaBlock {
            id
            primary {
              background
              is_centered
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyHomepageHeroUspsFeatures {
            id
            slice_type
            primary {
              is_background_dark
            }
            items {
              feature_description {
                richText
                text
              }
              feature_logo {
                gatsbyImageData
                alt
              }
              feature_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyHomepageHeroUsps {
            id
            items {
              checkmark_feature {
                richText
                text
              }
            }
            slice_type
            primary {
              checkmark {
                gatsbyImageData
                url
              }
              cta_and_title_color
              cta_label {
                richText
                text
              }
              cta_link1 {
                url
              }
              hero_image {
                gatsbyImageData
                alt
              }
              text {
                text
                richText
              }
              title1 {
                richText
                text
              }
              trustpilot {
                gatsbyImageData
                alt
              }
              trustpilot_score {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyHomepageHeroUspsLogos {
            id
            slice_type
            items {
              logo {
                gatsbyImageData
                alt
              }
            }
            primary {
              is_background_dark
              title_for_hero_logos {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyExpandablePricingOverview {
            id
            slice_type
            items {
              badge_label
              badge_color
              checklist {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product {
                richText
                text
              }
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              product_starting_price
              time_unit
              price_prefix
            }
            primary {
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              checkmark {
                gatsbyImageData
                alt
              }
              background
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              background
              columns
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label_primary {
                text
                richText
              }
              cta_link_primary {
                url
              }
              main_description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_label_secondary {
                text
                richText
              }
              cta_link_secondary {
                url
              }
              main_title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicHomePageDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              features_description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              features_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              logos_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicHomePageDataBodyBlogPostAutomated {
            id
            slice_type
            primary {
              background
              title1 {
                html
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              columns
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyNewProductCards {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_badge_color
              card_badge_label {
                richText
                text
              }
              card_cta_label {
                richText
                text
              }
              card_cta_link {
                url
              }
              card_item_description {
                richText
                text
              }
              card_download_cta_link {
                url
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_item_title {
                richText
                text
              }
            }
            primary {
              background
              content_alignment
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomePageDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicHomePageDataBodyQuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(
                        formatString: "MMM DD, yyyy"
                        locale: "zh-CN"
                      )
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
        }
      }
    }
    allPrismicBlog(sort: { fields: data___release_date, order: DESC }) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMMM DD, yyyy", locale: "zh-CN")
        data {
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMMM DD, yyyy", locale: "zh-CN")
          body {
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

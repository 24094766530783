import Spinner from 'components/Spinner';
import React, { useEffect, useRef, useState } from 'react';
import { PrismicTextField } from 'types';
import hideable from 'utils/hideable';
import {
  LoaderWrapper,
  StyledWrapper,
  TextComponent,
  VideoBlockTitle,
  VideoBlockWrapper,
  VideoComponent,
  VideoTextContainer,
} from './styledComponents';
import { enhanceWithLazyLoading } from 'helpers/lazyLoadHelper';
import { RichText } from 'components/v2/RichText';

interface IVideoBlock {
  primary: {
    background: string;
    video_block_title?: PrismicTextField;
    embed_code: string;
    video_possition: string;
    video_text_field: PrismicTextField;
  };
}

const VideoBlock: React.FC<IVideoBlock> = ({
  primary: {
    background,
    video_possition: position,
    video_text_field: text,
    embed_code: embedCode,
    video_block_title: title,
  },
}) => {
  const [gridTemplateColumns, setgridTemplateColumns] = useState('1fr 30%');
  const [shouldLoad, setShouldLoad] = useState(false);
  const videoContainerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (position === 'center') {
      setgridTemplateColumns('auto');
    }
    if (position === 'right') {
      setgridTemplateColumns('30% 1fr');
    }
  });

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const callback: IntersectionObserverCallback = (entries) => {
        if (!entries[0].isIntersecting) return;
        setShouldLoad(true);
      };

      const options: IntersectionObserverInit = {
        rootMargin: '360px',
      };

      const observer = new IntersectionObserver(callback, options);

      if (videoContainerEl?.current) {
        observer.observe(videoContainerEl.current);
      }

      return observer.disconnect;
    }

    return setShouldLoad(true);
  }, []);

  const HideableTitle = hideable(VideoBlockTitle);

  return (
    <VideoBlockWrapper ref={videoContainerEl} background={background}>
      <StyledWrapper>
        <HideableTitle text={title} isVisible={title}>
          <RichText field={title} />
        </HideableTitle>
        <VideoTextContainer
          position={position}
          gridTemplateColumns={gridTemplateColumns}
        >
          <VideoComponent
            position={position}
            dangerouslySetInnerHTML={
              shouldLoad
                ? {
                    __html: enhanceWithLazyLoading(embedCode),
                  }
                : undefined
            }
          />
          <LoaderWrapper>
            <Spinner color="black" size={32} />
            <p>Loading video...</p>
          </LoaderWrapper>
          <TextComponent>
            <RichText field={text} />
          </TextComponent>
        </VideoTextContainer>
      </StyledWrapper>
    </VideoBlockWrapper>
  );
};

export default VideoBlock;

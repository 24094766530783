import { RichText } from 'components/v2/RichText';
import React from 'react';
import Item from './Item';
import {
  Container,
  DescriptionContainer,
  HideableTitles,
  ItemsContainer,
  Wrapper,
} from './styledComponents';
import { IVisionMission } from './types';

const VisionMission: React.FC<IVisionMission> = ({
  primary: {
    background,
    title,
    subtitle,
    description,
    is_background_dark: isBackgroundDark,
  },
  items,
}) => (
  <Wrapper background={background} isBackgroundDark={isBackgroundDark}>
    <Container>
      <HideableTitles isVisible={!!title?.text || !!subtitle?.text}>
        <RichText field={title} />
        <RichText field={subtitle} />
      </HideableTitles>
      <ItemsContainer>
        {items.map(({ description, title, icon }) => (
          <Item
            key={title.text}
            icon={icon}
            title={title}
            description={description}
          />
        ))}
      </ItemsContainer>
      <DescriptionContainer>
        <RichText field={description} />
      </DescriptionContainer>
    </Container>
  </Wrapper>
);

export default VisionMission;

import styled from '@emotion/styled';
import { DOM_STATE, TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import { getInputBorderColor } from 'components/Input/styledComponents';
import { getGutter, getInputMaxWidth, IGutterProps } from 'components/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeBackgroundColor,
  getThemeInputBorderShape,
  getThemeInputFontSizeShape,
  getThemeInputHeightShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';
import { TStyledSelect } from './types';

export const HeaderContainer = styled.div<ITheme>((props) => ({
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  height: getThemeInputHeightShape(props, TSHIRT_SIZE.M),
  border: `1px solid ${getInputBorderColor(props)}`,
  alignItems: CSS_ALIGN.STRETCH,
  display: CSS_DISPLAY.FLEX,
}));

export const StyledInput = styled.input<{ isIcon: boolean } & TStyledSelect>(
  ({ isIcon, isSearchable, ...props }) => ({
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    padding: isIcon ? '5px 0 5px 5px' : '5px 0 5px 10px',
    cursor: isSearchable ? 'text' : 'pointer',
    alignItems: CSS_ALIGN.CENTER,
    display: CSS_DISPLAY.FLEX,
    border: 'none',
    width: '100%',
    flexGrow: 1,
    '::placeholder': {
      color: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
    },
  })
);

const DropdownIndicator = styled.div({
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_JUSTIFY_CONTENT.CENTER,
  display: CSS_DISPLAY.FLEX,
  cursor: 'pointer',
  width: '30px',
  flexShrink: 0,
});

export const ArrowDown = styled.div<ITheme>((props) => ({
  borderTop: `3px solid ${getThemeBackgroundColor(props, COLOR_SHADE.DEEP)}`,
  borderRight: '3px solid transparent',
  borderLeft: '3px solid transparent',
  height: 0,
  width: 0,
}));

const IconContainer = styled.div<IGutterProps & ITheme>((props) => ({
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_JUSTIFY_CONTENT.CENTER,
  display: CSS_DISPLAY.FLEX,
  margin: getGutter(props),
  width: '30px',
  flexShrink: 0,
  [DOM_STATE.FOCUS]: {
    outline: 'none',
  },
}));

export const HideableIconContainer = hideable(IconContainer);

export const StyledList = styled.div<ITheme>((props) => ({
  border: `1px solid ${getInputBorderColor(props)}`,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  background: getThemeMainColor(props),
  maxWidth: getInputMaxWidth(props),
  position: CSS_POSITION.ABSOLUTE,
  transform: 'translate(-50%, 0)',
  maxHeight: '200px',
  overflow: 'auto',
  marginTop: '2px',
  width: '100%',
  left: '50%',
  zIndex: 1,
  [MEDIA_QUERIES.TABLET]: {
    maxWidth: 'unset',
  },
}));

export const StyledContainer = styled.div<ITheme>((props) => ({
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  display: CSS_DISPLAY.FLEX,
  ':hover': {
    backgroundColor: getThemeNeutralColor(props, COLOR_SHADE.PRIMARY),
  },
}));

export const OptionButton = styled.button({
  textAlign: TEXT_ALIGN.LEFT,
  background: 'none',
  padding: '5px 7px',
  margin: '2px 0px',
  border: 'none',
  width: '100%',
  [DOM_STATE.FOCUS]: {
    outline: 'none',
  },
});

export const SelectContainer = styled.div<IGutterProps & ITheme>((props) => ({
  position: CSS_POSITION.RELATIVE,
  padding: getGutter(props),
  [MEDIA_QUERIES.SMALL_MOBILE]: {
    width: '100%',
  },
}));

const EmptyOption = styled.div<ITheme>((props) => ({
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.L),
  color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  display: CSS_DISPLAY.FLEX,
  margin: '5px 0',
}));

export const HideableEmptyOption = hideable(EmptyOption);
export const HideableDropdownIndicator = hideable(DropdownIndicator);

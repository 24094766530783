import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  PrismicCarouselBlock,
  GettingStarted,
  FaqBlock,
  PrismicImageTabs,
  PromoBanner,
} from '@smartproxy-web/features';
import { BlogParent } from '@smartproxy-web/features';
import Breadcrumbs from 'features/Breadcrumbs';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import TrustpilotReviews from 'features/TruspilotReviews';
import { SliceMap } from 'types';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import Title from 'features/Title';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import CtaBlock from 'features/CtaBlock';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { ProductCardsComponent } from 'features/v2/ProductCards';
import { ComparisonTable, PrismicTextGrid } from '@smartproxy-web/features';
import Author from '@smartproxy-web/features/src/components/Blog/Author';
import { QuoteComponent, TextImageDynamic } from '@smartproxy-web/features';

export enum APPS_SLICES {
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  ImageTextColumn = 'image_text_column',
  Spacing = 'spacing',
  BiggerPlansBanner = 'bigger_plans',
  PaymentMethod = 'payment_method',
  TextGrid = 'text_grid',
  BadgesBlock = 'badges_block',
  FaqBlock = 'faq_block',
  TrustpilotReviews = 'trustpilot_reviews',
  LocationFlags = 'location_flags',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  Title = 'title',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  CtaBlock = 'cta_block',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  TextImageDynamic = 'dynamic_text_and_image',
  ProductCardsComponent = 'new_product_cards',
  AutomatedPricingCards = 'automated_pricing_cards',
  ComparisonTable = 'comparison_table',
  AuthorV2 = 'author_v2',
  PrismicCarouselBlock = 'carousel_block',
  GettingStarted = 'getting_started',
  BlogParent = 'blog_parent_block',
  QuoteComponent = 'quote_component',
  ImageTabs = 'image_tabs',
  PromoBanner = 'promo_banner',
}

export const appsSliceMap: SliceMap<APPS_SLICES> = {
  [APPS_SLICES.Breadcrumbs]: Breadcrumbs,
  [APPS_SLICES.Hero]: Hero,
  [APPS_SLICES.ImageTextBlock]: ImageTextBlock,
  [APPS_SLICES.ImageTextColumn]: ImageTextColumn,
  [APPS_SLICES.Spacing]: Spacing,
  [APPS_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [APPS_SLICES.PaymentMethod]: PaymentMethod,
  [APPS_SLICES.TextGrid]: PrismicTextGrid,
  [APPS_SLICES.BadgesBlock]: BadgesBlock,
  [APPS_SLICES.FaqBlock]: FaqBlock,
  [APPS_SLICES.TrustpilotReviews]: TrustpilotReviews,
  [APPS_SLICES.LocationFlags]: LocationFlags,
  [APPS_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
  [APPS_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [APPS_SLICES.VideoBlock]: VideoBlock,
  [APPS_SLICES.Title]: Title,
  [APPS_SLICES.SocialProofBlock]: SocialProofBlock,
  [APPS_SLICES.FeaturedIn]: FeaturedIn,
  [APPS_SLICES.FooterBanner]: FooterBanner,
  [APPS_SLICES.CtaBlock]: CtaBlock,
  [APPS_SLICES.Herov2]: Herov2,
  [APPS_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [APPS_SLICES.HeroLogosv2]: HeroLogosv2,
  [APPS_SLICES.TextImageDynamic]: TextImageDynamic,
  [APPS_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [APPS_SLICES.ProductCardsComponent]: ProductCardsComponent,
  [APPS_SLICES.ComparisonTable]: ComparisonTable,
  [APPS_SLICES.AuthorV2]: Author,
  [APPS_SLICES.PrismicCarouselBlock]: PrismicCarouselBlock,
  [APPS_SLICES.GettingStarted]: GettingStarted,
  [APPS_SLICES.BlogParent]: BlogParent,
  [APPS_SLICES.QuoteComponent]: QuoteComponent,
  [APPS_SLICES.ImageTabs]: PrismicImageTabs,
  [APPS_SLICES.PromoBanner]: PromoBanner,
};

import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import GlobalStyleWrapper from './src/components/Layout/GlobalStylesWrapper';
import PageWrapper from './src/components/Layout/PageWrapper';
import Head from './src/components/Head';
import { repositoryConfigs } from './src/prismicPreviews';
import PageContextProvider from './src/contexts/PageContext';
import { Script } from 'gatsby';
import {
  BuildContextProvider,
  LinkConfigProvider,
} from '@smartproxy-web/features';
import { getCurrentSiteDomain, siteLang } from 'constants/build';
import { initPosthog } from '@smartproxy-web/analytics';
import { PH_PROJECT_NAME } from 'constants/common';

// Adds a shared React Context for Prismic preview sessions.
export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <BuildContextProvider
      value={{
        site: getCurrentSiteDomain(),
        lang: siteLang,
      }}
    >
      <LinkConfigProvider config={undefined}>
        <GlobalStyleWrapper>{element}</GlobalStyleWrapper>
      </LinkConfigProvider>
    </BuildContextProvider>
  </PrismicPreviewProvider>
);

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  const seoData = props.data && props.data[Object.keys(props?.data)[0]]?.data;
  const path = props.location.pathname;

  initPosthog(PH_PROJECT_NAME);

  return (
    <>
      <Head {...seoData} path={path} />

      <PageContextProvider
        pageContext={{
          type: props.pageContext.type || '',
          tags: props.pageContext.tags || [],
        }}
      >
        <PageWrapper {...props}>{element}</PageWrapper>
        <Script strategy="idle">
          {`
          window.__lc = window.__lc || {};
          window.__lc.license = 12092754;
          ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `}
        </Script>
        <Script
          src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          strategy="post-hydrate"
        />
      </PageContextProvider>
    </>
  );
};

import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { useState } from 'react';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'utils/browserStorage';
import { isBrowser } from 'utils/build';
import { DIALOG_CLOSED_ITEM, DIALOG_SLICES } from './constants';
import DialogContainer from './DialogContainer';
import { query } from './query';
import { IPrismicDialog, TDialog } from './types';
import { mapItemsToPaths } from './utils';

const Dialog: React.FC<TDialog> = ({ pathname }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data } = useMergePrismicPreviewData(query());
  const {
    body,
    is_visible: isVisible,
    ...rest
  }: IPrismicDialog = data.prismicDialog.data;
  const pageListSlice = body?.filter(
    (slice) => slice.slice_type === DIALOG_SLICES.PageList
  );

  const paths = pageListSlice && pageListSlice[0].items.map(mapItemsToPaths);

  const isPathnameInPaths =
    isBrowser && paths ? paths.includes(pathname) : true;

  const isDialogClosed = isBrowser
    ? !!getSessionStorageItem(DIALOG_CLOSED_ITEM)
    : true;

  const isDialogVisible =
    isVisible && !isDialogClosed && isDialogOpen && isPathnameInPaths;

  const handleClose = () => {
    setIsDialogOpen(false);
    setSessionStorageItem(DIALOG_CLOSED_ITEM, 'TRUE');
  };

  return (
    <DialogContainer
      onClose={handleClose}
      isVisible={isDialogVisible}
      setIsDialogOpen={setIsDialogOpen}
      {...rest}
    />
  );
};

export default Dialog;

import {
  HeroHomePageLogos,
  HeroHomePageLogosContainer,
  HeroHomePageLogosTitle,
} from './styledComponents';
import React from 'react';
import { ILogos } from './types';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

export const Logos: React.FC<ILogos> = ({ title, items }) => (
  <HeroHomePageLogosContainer>
    <HeroHomePageLogosTitle>
      <RichText field={title} />
    </HeroHomePageLogosTitle>
    <HeroHomePageLogos>
      {items.map((item, index) => (
        <GatsbyImg image={item.logo} key={index} loading="eager" />
      ))}
    </HeroHomePageLogos>
  </HeroHomePageLogosContainer>
);

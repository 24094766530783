import useIntersection from 'hooks/useIntersection';
import React, { useEffect, useRef, useState } from 'react';
import DotsContainer from './DotsContainer';
import { StyledBackground, TestimonialsContainer } from './styledComponents';
import Testimonial from './Testimonial';
import { ITestimonials } from './types';

const Testimonials: React.FC<ITestimonials> = ({
  primary: {
    background,
    is_text_light: isTextLight,
    for_id_wrapper: forIdWrapper,
  },
  items,
}) => {
  const [visibleTestimonial, setVisibleTestimonial] = useState<number>(1);
  const [isAutoTestimonialsOn, setAutoTestimonialsOn] = useState<boolean>(true);
  const testimonialsRef = useRef<HTMLDivElement>(null);

  const setForwardTestimonial = () =>
    visibleTestimonial < items.length
      ? setVisibleTestimonial((prevState) => prevState + 1)
      : setVisibleTestimonial(1);

  const onRightArrowClick = () => {
    setAutoTestimonialsOn(false);
    setForwardTestimonial();
  };
  const onLeftArrowClick = () => {
    setAutoTestimonialsOn(false);
    visibleTestimonial > 1
      ? setVisibleTestimonial((prevState) => prevState - 1)
      : setVisibleTestimonial(items.length);
  };

  const intersectionObserverEntry = useIntersection(testimonialsRef.current, {
    rootMargin: '-50%',
    fireOnce: true,
  });

  useEffect(() => {
    const automatedTestimonialChange = setTimeout(setForwardTestimonial, 5000);
    intersectionObserverEntry?.isIntersecting && isAutoTestimonialsOn
      ? automatedTestimonialChange
      : clearTimeout(automatedTestimonialChange);
    return () => {
      clearTimeout(automatedTestimonialChange);
    };
  }, [isAutoTestimonialsOn, visibleTestimonial, intersectionObserverEntry]);

  return (
    <StyledBackground background={background} ref={testimonialsRef}>
      <TestimonialsContainer text={forIdWrapper}>
        {items.map(({ image, role, name, description }, index) => (
          <Testimonial
            key={name.text}
            image={image}
            role={role}
            name={name}
            description={description}
            isTextLight={isTextLight}
            isVisible={index + 1 === visibleTestimonial}
            onRightArrowClick={onRightArrowClick}
            onLeftArrowClick={onLeftArrowClick}
          />
        ))}
        <DotsContainer
          items={items}
          isActive={visibleTestimonial}
          setVisibleTestimonial={setVisibleTestimonial}
          stopAutoPlay={() => setAutoTestimonialsOn(false)}
        />
      </TestimonialsContainer>
    </StyledBackground>
  );
};
export default Testimonials;

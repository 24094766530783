import Link from 'components/GatsbyLink';
import { initBuildTrackingSelector } from 'utils/page';
import {
  LinksContainer,
  StyledTitle,
  StyledLinkItem,
  StyledQRCodeWrapper,
  StyledLinkTrigger,
  StyledLinkItemLabel,
} from '../styledComponents';
import { FooterLink } from '../constants';
import * as Popover from '@radix-ui/react-popover';
import GatsbyImg from 'components/GatsbyImg';
import { TGatsbyImage } from 'types';

type FooterLinkWithQR = FooterLink & { qrCode?: TGatsbyImage };

interface ActionItemProps {
  direction?: 'row' | 'column';
  sectionName: string;
  title: string;
  links: FooterLinkWithQR[];
  isDealsOrLpPage?: boolean;
}

const ActionItem = ({
  direction,
  sectionName,
  title,
  links,
  isDealsOrLpPage,
}: ActionItemProps) => {
  const trackingSelector = initBuildTrackingSelector({
    section: `footer-${sectionName}`,
  });

  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
      <LinksContainer
        direction={direction}
        isDealsOrLpPage={isDealsOrLpPage}
        itemsNumber={Object.entries(links).length}
      >
        {links.map(({ icon: Icon, ...link }) => (
          <StyledLinkItem key={link.key}>
            <Popover.Root>
              <Popover.Trigger asChild>
                {link.url ? (
                  <Link
                    to={link.url}
                    data-tracker={trackingSelector({
                      context: link.key,
                      type: 'link',
                    })}
                  >
                    {!!Icon && <Icon />}
                    {!!link.label && (
                      <StyledLinkItemLabel>{link.label}</StyledLinkItemLabel>
                    )}
                  </Link>
                ) : (
                  <StyledLinkTrigger
                    hasQrCode={!!link.qrCode}
                    data-tracker={trackingSelector({
                      context: link.key,
                      type: 'trigger',
                    })}
                  >
                    {!!Icon && <Icon />}
                    {!!link.label && (
                      <StyledLinkItemLabel>{link.label}</StyledLinkItemLabel>
                    )}
                  </StyledLinkTrigger>
                )}
              </Popover.Trigger>
              <Popover.Portal>
                {!!link.qrCode && (
                  <Popover.Content side="right" sideOffset={4}>
                    <Popover.Arrow fill="white" />
                    <StyledQRCodeWrapper>
                      <GatsbyImg image={link.qrCode} />
                    </StyledQRCodeWrapper>
                  </Popover.Content>
                )}
              </Popover.Portal>
            </Popover.Root>
          </StyledLinkItem>
        ))}
      </LinksContainer>
    </div>
  );
};

export default ActionItem;

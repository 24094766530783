import { Box } from 'components/v2/Box';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import { ColumnsTextContainer, ColumnsTextWrapper } from './styledComponents';
import { IColumnsTextBlock } from './types';

const ColumnsTextBlock: React.FC<IColumnsTextBlock> = ({
  primary: { columns_number: columns },
  items,
}) => (
  <ColumnsTextContainer>
    <ColumnsTextWrapper columns={columns}>
      {items.map(({ text }, idx) => (
        <Box key={idx}>
          <RichText field={text} />
        </Box>
      ))}
    </ColumnsTextWrapper>
  </ColumnsTextContainer>
);

export default ColumnsTextBlock;

import { PRISMIC_VALUES } from 'constants/common';
import React, { useRef, useEffect } from 'react';
import { setGeneratedId } from 'templates/Blog/utils';
import { PrismicTextField } from 'types';

export interface IGeneratedIdWrapper {
  text: PrismicTextField;
}
const GeneratedIdWrapper: React.FC<IGeneratedIdWrapper> = ({
  children,
  text,
  ...props
}) => {
  const idRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    idRef.current && setGeneratedId(text, PRISMIC_VALUES.HEADING_2, idRef);
  }, []);

  return (
    <div {...props} ref={idRef}>
      {children}
    </div>
  );
};

export default GeneratedIdWrapper;

import Spinner from 'components/Spinner';
import { enhanceWithLazyLoading } from 'helpers/lazyLoadHelper';
import useIntersection from 'hooks/useIntersection';
import React, { useRef } from 'react';
import { PrismicTextField } from 'types';
import {
  HideableTextComponent,
  LoaderWrapper,
  VideoComponent,
  VideoTextContainer,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';
import { Slice, SliceHeader } from 'components/v2/Slice';
import { Link } from 'prismic-reactjs';
import { LinkButton } from 'components/v2/LinkButton';
import { Box } from 'components/v2/Box';
import { generateSectionsId } from 'features/utils';
import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';
import { transformUrl } from '../Header/helpers';

interface IVideoBlock {
  primary: {
    background?: string;
    embed_code?: string;
    video_block_title?: PrismicTextField;
    video_possition?: 'right' | 'center' | 'left';
    video_text_field?: PrismicTextField;
    cta_title?: PrismicTextField;
    cta_link?: Link;
    cta_position?: 'top' | 'bottom';
  };
}

const VideoBlock: React.FC<IVideoBlock> = ({
  primary: {
    background,
    video_possition: position = 'center',
    video_text_field: text,
    embed_code: embedCode,
    video_block_title: title,
    cta_title: ctaLabel,
    cta_link: ctaLink,
    cta_position: ctaPosition = 'top',
  },
}) => {
  const videoContainerEl = useRef<HTMLDivElement>(null);
  const { tags, type } = usePageContext();

  const intersectionObserverEntry = useIntersection(videoContainerEl.current, {
    fireOnce: true,
  });

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.VIDEO_BLOCK,
    prefix: type,
  });

  const Cta =
    !!ctaLabel?.text && !!ctaLink?.url ? (
      <LinkButton
        to={ctaLink?.url}
        fullWidthOnMobile
        minWidth="200px"
        variant="outlined"
        color="blue"
        data-tracker={trackingSelector({
          context: 'cta-' + transformUrl(ctaLink?.url || ''),
        })}
      >
        {ctaLabel.text}
      </LinkButton>
    ) : null;

  return (
    <Slice
      ref={videoContainerEl}
      backgroundColor={background}
      id={generateSectionsId('video-slice' || '', tags, type)}
    >
      <SliceHeader
        title={title}
        description={position === 'center' ? text : undefined}
        bottomItems={
          ctaPosition === 'top' &&
          Cta && <Box sx={{ width: '100%' }}>{Cta}</Box>
        }
      />
      {embedCode && (
        <VideoTextContainer
          position={position}
          gridTemplateColumns={
            position === 'center'
              ? 'auto'
              : position === 'right'
              ? '30% 1fr'
              : '1fr 30%'
          }
        >
          <VideoComponent
            position={position}
            dangerouslySetInnerHTML={
              intersectionObserverEntry
                ? {
                    __html: enhanceWithLazyLoading(embedCode),
                  }
                : undefined
            }
          />
          {!intersectionObserverEntry && (
            <LoaderWrapper>
              <Spinner color="black" size={32} />
              <p>Loading video...</p>
            </LoaderWrapper>
          )}
          <HideableTextComponent
            isVisible={text?.text && position !== 'center'}
          >
            <RichText field={text} />
          </HideableTextComponent>
        </VideoTextContainer>
      )}
      {ctaPosition === 'bottom' && (
        <Box
          sx={{
            paddingTop: { base: '32px', md: '60px', lg: '80px' },
            display: { base: 'block', md: 'flex' },
            justifyContent: 'center',
          }}
        >
          {Cta}
        </Box>
      )}
    </Slice>
  );
};

export default VideoBlock;

import { Wrapper } from 'commonStyles';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';

import { DefaultSlice, PrismicTextField } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { questionsSliceMap, QUESTIONS_SLICES } from './constants';

interface IWithPrismicQuestions {
  prismicQuestions: {
    data: {
      body: DefaultSlice[];
      question_title: PrismicTextField;
    };
  };
}
export interface Props {
  data: IWithPrismicQuestions;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const Questions = ({ data }: Props & PageTemplateProps) => {
  const { prismicQuestions } = data;
  const templates = getTemplates<QUESTIONS_SLICES, DefaultSlice>(
    prismicQuestions.data.body,
    questionsSliceMap
  );
  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          {...slice}
          title={prismicQuestions.data.question_title}
        />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(Questions);

export const query = graphql`
  query QuestionsQuery($id: String!) {
    prismicQuestions(id: { eq: $id }) {
      _previewable
      ...QuestionsFragment
    }
  }
`;

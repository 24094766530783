import GatsbyImg from 'components/GatsbyImg';
import { Wrapper } from 'features/BiggerPlansBanner/styledComponents';
import React from 'react';
import { IBadgeBlock } from './types';
import {
  BadgeItem,
  BadgesBlockContainer,
  BadgesList,
  TextContainer,
  TitleContainer,
} from './styledComponents';
import { usePageContext } from 'contexts/PageContext';
import { getUpdatedAccentColor } from 'components/utils';
import { generateSectionsId } from 'features/utils';
import { SECTION_NAMES } from 'constants/common';
import { RichText } from 'components/v2/RichText';

const BadgesBlock: React.FC<IBadgeBlock> = ({
  primary: {
    title,
    background,
    text_field: textField,
    is_text_centered: isTextCentered,
    is_image_on_left: isImageOnLeft,
    is_text_white: isTextWhite,
    accent_color: accentColor,
  },
  items,
}) => {
  const { tags, type } = usePageContext();
  const titleColor = getUpdatedAccentColor(accentColor);

  return (
    <Wrapper
      background={background}
      data-section-id={generateSectionsId(SECTION_NAMES.BADGES, tags, type)}
    >
      <BadgesBlockContainer isImageOnLeft={isImageOnLeft}>
        <BadgesList items={items.length}>
          {items.map(({ icon }, idx) => (
            <BadgeItem key={idx}>
              <GatsbyImg image={icon} />
            </BadgeItem>
          ))}
        </BadgesList>
        <TextContainer isCentered={isTextCentered} isTextWhite={isTextWhite}>
          <TitleContainer color={titleColor?.color}>
            <RichText field={title} />
          </TitleContainer>
          <RichText field={textField} />
        </TextContainer>
      </BadgesBlockContainer>
    </Wrapper>
  );
};

export default BadgesBlock;

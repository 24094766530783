import { SIGNIN_URL } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import { ImageContainer, StyledLink, Subtitle } from './styledComponents';
import { IItem } from './types';
import { RichText } from 'components/v2/RichText';

const ColumnItem: React.FC<IItem> = ({ icon, subtitle, link_to: linkTo }) => (
  <StyledLink to={linkTo.url || SIGNIN_URL}>
    <ImageContainer>
      <GatsbyImg image={icon} />
    </ImageContainer>
    <Subtitle>
      <RichText field={subtitle} />
    </Subtitle>
  </StyledLink>
);

export default ColumnItem;

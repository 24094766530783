import {
  CONTACT_SALES_URL_HASH,
  OLD_DOMAIN,
  PAGE_DOMAIN,
} from 'constants/common';

export const changeCtaDomain = (url: string) => {
  if (!!url && url.includes(OLD_DOMAIN)) {
    return url.replace(OLD_DOMAIN, PAGE_DOMAIN);
  }
  return url;
};

export const changeDomain = () => {
  const linkInText = document.querySelectorAll('a');
  const urlArray = Array.from(linkInText);
  urlArray.forEach((item) => {
    //does not apply to contact sales
    !item.href.includes(CONTACT_SALES_URL_HASH) &&
      (item.href = changeCtaDomain(item.href));
  });
};

import { RichText } from 'components/v2/RichText';
import React from 'react';
import { PrismicTextField } from 'types';
import {
  BackgroundContainer,
  CountriesListItem,
  CountriesListWrapepr,
  CountriesList,
  CountriesListContainer,
  Title,
} from './styledComponents';

interface IProxyListCountries {
  primary: {
    background: string;
    main_title: PrismicTextField;
    title_position: boolean;
  };
  items: IItem[];
}

interface IItem {
  column_title: PrismicTextField;
  first_column: PrismicTextField;
  second_column: PrismicTextField;
  third_column: PrismicTextField;
}

const ProxyListCountries: React.FC<IProxyListCountries> = ({
  primary: { background, main_title: title, title_position: position },
  items,
}) => (
  <BackgroundContainer background={background}>
    <CountriesListWrapepr>
      <Title position={position}>
        <RichText field={title} />
      </Title>
      {items.map(
        (
          {
            column_title: title,
            first_column: first,
            second_column: second,
            third_column: third,
          },
          index
        ) => (
          <CountriesListItem key={index}>
            <RichText field={title} />
            <CountriesListContainer>
              <CountriesList>
                <RichText field={first} />
              </CountriesList>
              <CountriesList>
                <RichText field={second} />
              </CountriesList>
              <CountriesList>
                <RichText field={third} />
              </CountriesList>
            </CountriesListContainer>
          </CountriesListItem>
        )
      )}
    </CountriesListWrapepr>
  </BackgroundContainer>
);

export default ProxyListCountries;

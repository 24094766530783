export const shouldScriptLoad = () => {
  const script = document.createElement('script');
  script.src = 'https://smartproxy.com/activehosted/f/embed.php?id=5';
  script.id = 'leads-script';
  script.async = true;
  document.body.appendChild(script);
};

export const removeScript = () =>
  document.getElementById('leads-script')?.remove();

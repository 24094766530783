import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  PrismicCarouselBlock,
  GettingStarted,
  TabbedCodeBlock,
  FaqBlock,
  PrismicImageTabs,
  PrismicBlogImage,
  PromoBanner,
} from '@smartproxy-web/features';
import { BlogParent } from '@smartproxy-web/features';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import ColumnLinks from 'features/ColumnsLinks';
import CtaBlock from 'features/CtaBlock';
import Hero from 'features/Hero';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import TableBlock from 'features/TableBlock';
import Title from 'features/Title';
import TrustpilotReviews from 'features/TruspilotReviews';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import TableOfContent from 'features/TableOfContent';
import RelatedArticles from 'features/RelatedArticles';
import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import SideImageTextBlock from 'features/Blog/BlogPost/SideImageTextBlock';
import IconListBlock from 'features/IconListBlock';
import { SliceMap } from 'types';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import ProductCards from 'features/ProductCards';
import TargetsList from 'features/TargetsList';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { ProductCardsComponent } from 'features/v2/ProductCards';
import {
  ComparisonTable,
  PrismicTextGrid,
  TextImageDynamic,
} from '@smartproxy-web/features';
import { QuoteComponent, TextBlock } from '@smartproxy-web/features';

export enum SCRAPING_SLICES {
  BadgesBlock = 'badges_block',
  BiggerPlansBanner = 'bigger_plans',
  Breadcrumbs = 'breadcrumbs',
  FaqBlock = 'faq_block',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  ImageTextColumn = 'image_text_column',
  LocationFlags = 'location_flags',
  PaymentMethod = 'payment_method',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  TrustpilotReviews = 'trustpilot_reviews',
  TextBlock = 'text_block',
  TableBlock = 'proxy_table',
  CtaBlock = 'cta_block',
  ColumnsTextBlock = 'column_text',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  ColumnLinks = 'column_links',
  Title = 'title',
  BlogImage = 'blog_image',
  TableOfContent = 'table_of_content',
  RelatedArticles = 'related_articles',
  CodeBlock = 'code_block',
  SideImageTextBlock = 'side_image_text_block',
  IconListBlock = 'icon_list_block',
  ImageTabs = 'image_tabs',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  TextImageDynamic = 'dynamic_text_and_image',
  ProductCard = 'product_card',
  TargetsList = 'targets_list',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  ProductCardsComponent = 'new_product_cards',
  AutomatedPricingCards = 'automated_pricing_cards',
  ComparisonTable = 'comparison_table',
  PrismicCarouselBlock = 'carousel_block',
  GettingStarted = 'getting_started',
  BlogParent = 'blog_parent_block',
  QuoteComponent = 'quote_component',
  TabbedCodeBlock = 'tabbed_code_block',
  PromoBanner = 'promo_banner',
}

export const scrapingSliceMap: SliceMap<SCRAPING_SLICES> = {
  [SCRAPING_SLICES.BadgesBlock]: BadgesBlock,
  [SCRAPING_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [SCRAPING_SLICES.Breadcrumbs]: Breadcrumbs,
  [SCRAPING_SLICES.FaqBlock]: FaqBlock,
  [SCRAPING_SLICES.Hero]: Hero,
  [SCRAPING_SLICES.ImageTextBlock]: ImageTextBlock,
  [SCRAPING_SLICES.ImageTextColumn]: ImageTextColumn,
  [SCRAPING_SLICES.LocationFlags]: LocationFlags,
  [SCRAPING_SLICES.PaymentMethod]: PaymentMethod,
  [SCRAPING_SLICES.Spacing]: Spacing,
  [SCRAPING_SLICES.TextGrid]: PrismicTextGrid,
  [SCRAPING_SLICES.TrustpilotReviews]: TrustpilotReviews,
  [SCRAPING_SLICES.TextBlock]: TextBlock,
  [SCRAPING_SLICES.TableBlock]: TableBlock,
  [SCRAPING_SLICES.CtaBlock]: CtaBlock,
  [SCRAPING_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [SCRAPING_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [SCRAPING_SLICES.VideoBlock]: VideoBlock,
  [SCRAPING_SLICES.ColumnLinks]: ColumnLinks,
  [SCRAPING_SLICES.Title]: Title,
  [SCRAPING_SLICES.BlogImage]: PrismicBlogImage,
  [SCRAPING_SLICES.TableOfContent]: TableOfContent,
  [SCRAPING_SLICES.RelatedArticles]: RelatedArticles,
  [SCRAPING_SLICES.CodeBlock]: CodeBlock,
  [SCRAPING_SLICES.SideImageTextBlock]: SideImageTextBlock,
  [SCRAPING_SLICES.IconListBlock]: IconListBlock,
  [SCRAPING_SLICES.ImageTabs]: PrismicImageTabs,
  [SCRAPING_SLICES.SocialProofBlock]: SocialProofBlock,
  [SCRAPING_SLICES.FeaturedIn]: FeaturedIn,
  [SCRAPING_SLICES.FooterBanner]: FooterBanner,
  [SCRAPING_SLICES.TextImageDynamic]: TextImageDynamic,
  [SCRAPING_SLICES.ProductCard]: ProductCards,
  [SCRAPING_SLICES.TargetsList]: TargetsList,
  [SCRAPING_SLICES.Herov2]: Herov2,
  [SCRAPING_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [SCRAPING_SLICES.HeroLogosv2]: HeroLogosv2,
  [SCRAPING_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [SCRAPING_SLICES.ProductCardsComponent]: ProductCardsComponent,
  [SCRAPING_SLICES.ComparisonTable]: ComparisonTable,
  [SCRAPING_SLICES.PrismicCarouselBlock]: PrismicCarouselBlock,
  [SCRAPING_SLICES.GettingStarted]: GettingStarted,
  [SCRAPING_SLICES.BlogParent]: BlogParent,
  [SCRAPING_SLICES.QuoteComponent]: QuoteComponent,
  [SCRAPING_SLICES.TabbedCodeBlock]: TabbedCodeBlock,
  [SCRAPING_SLICES.PromoBanner]: PromoBanner,
};

import React from 'react';
import { TSHIRT_SIZE } from 'components/constants';
import { TSelectInput } from './types';
import { SelectWrapper, StyledSelect } from './styledComponents';

const SelectInput: React.FC<TSelectInput> = ({
  name,
  label,
  options,
  isSearchable,
  context: { setFieldValue, values, isSubmitting },
  selectAttrs,
  ...rest
}) => {
  const handleChange = (value: string) => {
    setFieldValue(name, value, true);
  };

  return (
    <SelectWrapper>
      <StyledSelect
        selectAttrs={selectAttrs}
        isSearchable={isSearchable}
        disabled={isSubmitting}
        label={label}
        value={values[name]}
        options={options}
        onChange={handleChange}
        gutterTop={TSHIRT_SIZE.XXS}
        gutterBot={TSHIRT_SIZE.XXS}
        {...rest}
      />
    </SelectWrapper>
  );
};

export default SelectInput;

import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import hideable from 'utils/hideable';

export interface IVideo {
  gridTemplateColumns?: string;
  position: string;
}

export const VideoTextContainer = styled.div<IVideo>(
  ({ gridTemplateColumns }) => ({
    width: '100%',
    height: '100%',
    display: 'grid',
    gridAutoColumns: '1fr',
    gap: '36px',
    alignItems: 'start',
    gridTemplateColumns,
    gridTemplateRows: 'auto',
    position: 'relative',
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: '1fr',
      margin: '0 auto',
    },
  })
);

export const TextComponent = styled.div({
  'li+li': {
    marginTop: themev2.spacings.XS,
  },
  'a, em': {
    fontFamily: themev2.fonts.family.additional,
  },
});

export const HideableTextComponent = hideable(TextComponent);

export const VideoComponent = styled.div<IVideo>(({ position }) => ({
  width: '100%',
  aspectRatio: '16/9',
  order: position === 'right' ? 1 : -1,
  transition: 'colors 0.5s',
  position: 'relative',

  [MEDIA_QUERIES.TABLET]: {
    order: position === 'right' ? -1 : -1,
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    borderRadius: '12px',
  },
}));

export const LoaderWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  backgroundColor: themev2.colors.neutral.N10,
  top: 0,
  left: 0,
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  p: {
    marginTop: '12px',
  },
});

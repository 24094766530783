import React from 'react';
import { StyledPagedNav, StyledPagedNavInner } from './styledComponents';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NAV_PAGE_TRANSITION_DURATION } from '../constants';
import { NavItemPage } from './NavItemPage';
import { PagedNavProps } from './types';

export const PagedNav: React.FC<PagedNavProps> = ({
  navStack,
  handleItemClick,
  handleBackClick,
  buildTrackingId,
  closeDrawer,
}) => (
  <StyledPagedNav
    style={{ ['--current-page-index' as string]: navStack.length - 1 }}
  >
    <TransitionGroup component={StyledPagedNavInner}>
      {navStack.map((navItem, index) => (
        <CSSTransition
          key={navItem.key}
          in={!!navItem.key}
          mountOnEnter
          unmountOnExit
          timeout={NAV_PAGE_TRANSITION_DURATION}
        >
          <NavItemPage
            isCurrentPage={index === navStack.length - 1}
            navItem={navItem}
            parentNavItem={navStack[index - 1]}
            handleBackClick={handleBackClick}
            handleItemClick={handleItemClick}
            buildTrackingId={buildTrackingId}
            closeDrawer={closeDrawer}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </StyledPagedNav>
);

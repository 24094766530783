import { STICKY_BAR_SLICES } from 'constants/common';
import { onScroll } from 'hooks/useScroll';
import React, { useLayoutEffect, useState } from 'react';
import { DefaultSlice } from 'types';
import { stickyContactsBarQuery } from './query';
import StickyBarItem from './StickyBarItem';
import { HideableContactsBarContainer } from './styledComponents';

const StickyContactsBar: React.FC = () => {
  const { body } = stickyContactsBarQuery().prismicStickyContactsBar.data;
  const { items } = body.filter(
    (slice: DefaultSlice) => slice.slice_type === STICKY_BAR_SLICES.StickyBar
  )[0];
  const [isBarVisible, setIsBarVisible] = useState<boolean>(false);
  const [openedItem, setOpenedItem] = useState<number | null>(null);
  const [barOpacity, setBarOpacity] = useState(0);
  const pageScrollPosition = 400;

  const toggleAdditionalTab = (idx: number | null) => {
    setOpenedItem(openedItem !== idx ? idx : null);
  };

  const closeAdditionalTab = () => {
    setOpenedItem(null);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', () =>
      onScroll(setIsBarVisible, setBarOpacity, pageScrollPosition)
    );
    return () =>
      window.removeEventListener('scroll', () =>
        onScroll(setIsBarVisible, setBarOpacity, pageScrollPosition)
      );
  }, []);

  return (
    <HideableContactsBarContainer
      itemsNumb={items.length}
      isVisible={isBarVisible}
      opacity={barOpacity}
      scrolled={isBarVisible}
    >
      {items.map((item, index) => (
        <StickyBarItem
          key={index}
          {...item}
          index={index}
          isOpen={openedItem === index}
          onToggle={() => toggleAdditionalTab(index)}
          onMouseLeave={closeAdditionalTab}
          elementsNumb={items.length}
        />
      ))}
    </HideableContactsBarContainer>
  );
};

export default React.memo(StickyContactsBar);

import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import {
  StyledHeroDescriptionWrapper,
  HeroFeature,
  StyledHeroMainContentWrapper,
  HeroTitleWrapper,
  StyledHeroFeaturesWrapper,
  StyledHeroWrapperInner,
  StyledHeroWrapper,
} from './styledComponents';
import { HeroProps } from './types';
import { IconDonev2 } from 'images';
import { usePageContext } from 'contexts/PageContext';
import { getHeroAccentColor } from './helpers';
import { HeroCtas } from './HeroCtas';
import { HeroImage } from './HeroImage';
import { initBuildTrackingSelector } from 'utils/page';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';
import { HeroBadges } from '@smartproxy-web/features';

export const Hero: React.FC<HeroProps> = ({
  primary: {
    hero_image: heroImage,
    cta_label_primary: ctaLabel,
    cta_link_primary: ctaLink,
    cta_label_secondary: secondaryCtaLabel,
    cta_link_secondary: secondaryCtaLink,
    main_title: title,
    main_description: description,
    accent_color: accentColorName,
    video_embed: videoEmbedCode,
    is_money_back_visible: isMoneyBackVisible,
  },
  items,
}) => {
  const { tags, type } = usePageContext();

  const createBadgeTrackingID = initBuildTrackingSelector({
    section: 'hero-badge',
    type: 'link',
  });
  const buildCtaTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero',
    type: 'cta',
  });

  const heroCtaTrackingSelector = buildCtaTrackingSelector({
    context: 'primary',
  });
  const heroSecondaryCtaTrackingSelector = buildCtaTrackingSelector({
    context: 'secondary',
  });

  const hasValidCta = !!ctaLink.url || !!secondaryCtaLink.url;

  const isOnHomepage = type === 'home_page';
  const isMoneyBackSelected =
    isMoneyBackVisible !== null ? isMoneyBackVisible : true;

  return (
    <StyledHeroWrapper
      data-section-name="hero"
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledHeroWrapperInner>
        <StyledHeroMainContentWrapper>
          <HeroBadges
            trackingSelectors={{
              g2: createBadgeTrackingID({ context: 'g2' }),
              proxyway: createBadgeTrackingID({ context: 'proxyway' }),
              trustpilot: createBadgeTrackingID({ context: 'trustpilot' }),
            }}
          />
          <HeroTitleWrapper>
            <RichText field={title} />
          </HeroTitleWrapper>
          <StyledHeroDescriptionWrapper>
            <RichText field={description} />
          </StyledHeroDescriptionWrapper>
          <StyledHeroFeaturesWrapper order={isOnHomepage ? 0 : 1}>
            {items.map((item, index) => (
              <HeroFeature key={index}>
                <IconDonev2 color={getHeroAccentColor(accentColorName)} />
                <span>{item.feature_label.text}</span>
              </HeroFeature>
            ))}
          </StyledHeroFeaturesWrapper>
          {hasValidCta && (
            <HeroCtas
              order={isOnHomepage ? 1 : 0}
              primaryCtaTrackingSelector={heroCtaTrackingSelector}
              primaryCtaLabel={ctaLabel.text}
              primaryCtaLink={ctaLink.url}
              secondaryCtaTrackingSelector={heroSecondaryCtaTrackingSelector}
              secondaryCtaLabel={secondaryCtaLabel.text}
              secondaryCtaLink={secondaryCtaLink.url}
            />
          )}
          <MoneyBackTag
            isOnHomepage={isOnHomepage}
            isVisible={isMoneyBackSelected && !!ctaLink.url}
          />
        </StyledHeroMainContentWrapper>
        <HeroImage image={heroImage} embedCode={videoEmbedCode} />
      </StyledHeroWrapperInner>
    </StyledHeroWrapper>
  );
};

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import GatsbyImg from 'components/GatsbyImg';
import Link from 'components/GatsbyLink';
import { StyledCta } from 'features/HomePage/PricingCards/styledComponents';
import React from 'react';
import { IconDropdownv2 } from 'images';
import {
  HideableBadge,
  StyledCheckmarkListItemWrapper,
  StyledCheckmarkListWrapper,
  StyledImageTitleContainer,
  StyledImageWrapper,
  StyledPriceWrapper,
  StyledProductAccordionBody,
  StyledProductAccordionTrigger,
  StyledProductDescription,
  StyledProductDropdownIconWrapper,
  StyledProductItem,
  StyledTitleContainer,
  StyledTitleDetailsContainer,
} from './styledComponents';
import { IExpandablePricingCardsListItem } from './types';
import { RichText } from 'components/v2/RichText';
import { getPeriod } from 'features/v2/Header/Menu/CategoriesContainer/utils';

const ExpandablePricingCardsListItem: React.FC<
  IExpandablePricingCardsListItem
> = ({
  product: {
    checklist,
    product,
    product_description: productDescription,
    learn_more_link: learnMoreLink,
    learn_more_text: learnMoreText,
    product_cta_link: ctaLink,
    product_cta_text: ctaText,
    product_image: productImage,
    product_starting_price: startingPrice,
    price_prefix: pricePrefix,
    badge_label: badgeLabel,
    badge_color: badgeColor = 'red',
  },
  checkmark,
  buildTrackingSelector,
}) => {
  const normalizedBadgeColor = badgeColor && badgeColor.toLowerCase();
  return (
    <StyledProductItem>
      <input type="checkbox" id={product.text} />
      <StyledProductAccordionTrigger
        className={`accordion-trigger ${buildTrackingSelector({
          context: `${product.text}-trigger`,
          type: 'button',
        })}`}
        htmlFor={product.text}
      >
        <StyledImageWrapper>
          <GatsbyImg image={productImage} />
        </StyledImageWrapper>
        <StyledImageTitleContainer>
          <StyledTitleDetailsContainer>
            <StyledTitleContainer>
              <RichText field={product} />
            </StyledTitleContainer>
            <HideableBadge
              color={normalizedBadgeColor}
              isVisible={!!badgeLabel}
            >
              {badgeLabel}
            </HideableBadge>
          </StyledTitleDetailsContainer>
          {!!startingPrice && (
            <StyledPriceWrapper>
              入门价: <span className="product-price">${startingPrice}</span>
              {getPeriod(`/${pricePrefix}`)}
            </StyledPriceWrapper>
          )}
        </StyledImageTitleContainer>
        <StyledProductDropdownIconWrapper className="dropdown-icon">
          <IconDropdownv2 />
        </StyledProductDropdownIconWrapper>
      </StyledProductAccordionTrigger>
      <StyledProductAccordionBody className="accordion-body">
        <div>
          {!!checklist.text && (
            <StyledCheckmarkListWrapper>
              {checklist.richText.map((checklistText) => (
                <StyledCheckmarkListItemWrapper key={checklistText.text}>
                  <GatsbyImg image={checkmark} />
                  <p>{checklistText.text}</p>
                </StyledCheckmarkListItemWrapper>
              ))}
            </StyledCheckmarkListWrapper>
          )}
          {!!productDescription.text && (
            <StyledProductDescription>
              <RichText field={productDescription} />
              {learnMoreText && learnMoreLink.url && (
                <Link
                  data-tracker={`learn-more-link ${buildTrackingSelector({
                    context: `${product.text}-learn-more`,
                    type: 'link',
                  })}`}
                  to={learnMoreLink.url}
                >
                  {learnMoreText}
                </Link>
              )}
            </StyledProductDescription>
          )}
          <StyledCta
            isVisible={ctaText && ctaLink.url}
            ctaText={ctaText}
            ctaLink={ctaLink.url}
            themeName={BUTTON_THEMES.DEFAULT}
            themeType={BUTTON_TYPES.SECONDARY}
            data-tracker={buildTrackingSelector({
              context: product.text,
            })}
          />
        </div>
      </StyledProductAccordionBody>
    </StyledProductItem>
  );
};
export default ExpandablePricingCardsListItem;

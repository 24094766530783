import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import useIntersection from 'hooks/useIntersection';
import { IconArrowLeft, IconArrowRight } from 'images';
import React, { useEffect, useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  LeftArrowContainerDesktop,
  MobileTabsContainer,
  RightArrowContainerDesktop,
} from './styledComponents';
import { ArrowsProps } from './types';
import { onLeftArrowClick, onRightArrowClick } from './utils';

const ArrowsContainer: React.FC<ArrowsProps> = ({
  itemsLength,
  carouselContainer,
  carouselItem,
  tabName,
  sliceName,
}) => {
  const { type, tags } = usePageContext();
  const [isRightArrowActive, setRightArrowActive] = useState(true);
  const [isLeftArrowActive, setLeftArrowActive] = useState(false);
  const lastChildIntersectionObserver = useIntersection(
    carouselContainer.current?.lastChild,
    {
      rootMargin: '100%',
    }
  );
  const firstChildIntersectionObserver = useIntersection(
    carouselContainer.current?.firstChild,
    {
      rootMargin: '100%',
    }
  );

  useEffect(() => {
    setRightArrowActive(!lastChildIntersectionObserver?.isIntersecting);
    setLeftArrowActive(!firstChildIntersectionObserver?.isIntersecting);
  }, [
    lastChildIntersectionObserver?.isIntersecting,
    firstChildIntersectionObserver?.isIntersecting,
  ]);

  return (
    <>
      <LeftArrowContainerDesktop
        isActive={isLeftArrowActive}
        itemsLength={itemsLength}
        onClick={() => onLeftArrowClick(carouselContainer, carouselItem)}
        data-tracker={buildTrackingSelector({
          page: tags[0] || type,
          section: !!sliceName ? sliceName : 'social-proof',
          context: 'arrow-left',
        })}
      >
        <IconArrowLeft />
      </LeftArrowContainerDesktop>
      <RightArrowContainerDesktop
        isActive={isRightArrowActive}
        itemsLength={itemsLength}
        onClick={() => onRightArrowClick(carouselContainer, carouselItem)}
        data-tracker={buildTrackingSelector({
          page: tags[0] || type,
          section: !!sliceName ? sliceName : 'social-proof',
          context: 'arrow-right',
        })}
      >
        <IconArrowRight />
      </RightArrowContainerDesktop>
      {!!tabName && (
        <MobileTabsContainer>
          <RichText field={tabName} />
        </MobileTabsContainer>
      )}
    </>
  );
};

export default ArrowsContainer;

import { useEffect, useRef, useState } from 'react';

interface UseIntersectionOptions extends IntersectionObserverInit {
  callback?: (entries: IntersectionObserverEntry[]) => void;
  /** If true, observer is disconnected once the element has intersected */
  fireOnce?: boolean;
}

const useIntersection = (
  el: HTMLElement | null,
  options: UseIntersectionOptions
): IntersectionObserverEntry | null => {
  const observerRef = useRef<IntersectionObserver>();
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState<IntersectionObserverEntry | null>(null);

  useEffect(() => {
    if (el && 'IntersectionObserver' in window) {
      const handler = (entries: IntersectionObserverEntry[]) => {
        setIntersectionObserverEntry(entries[0]);
        if (options.callback) {
          options.callback(entries);
        }
      };

      observerRef.current = new IntersectionObserver(handler, options);
      observerRef.current.observe(el);

      return () => {
        setIntersectionObserverEntry(null);
        observerRef.current?.disconnect();
      };
    }
    return () => {};
  }, [
    el,
    options.threshold,
    options.root,
    options.rootMargin,
    options.callback,
    options.fireOnce,
  ]);

  useEffect(() => {
    if (
      intersectionObserverEntry?.isIntersecting &&
      observerRef.current &&
      options.fireOnce
    ) {
      observerRef.current.disconnect();
    }
  }, [
    intersectionObserverEntry?.isIntersecting,
    observerRef.current,
    options.fireOnce,
  ]);

  return intersectionObserverEntry;
};

export default useIntersection;

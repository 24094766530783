import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import GeneratedIdWrapper from 'features/Blog/BlogPost/GeneratedIdWrapper';
import Link from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const Wrapper = styled(GeneratedIdWrapper)<ITheme>((props) => ({
  width: '100%',
  padding: '0 20px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

export const IconListBlockContainer = styled.div({
  margin: '0 auto',
  width: '100%',
  maxWidth: '1024px',
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
});

export const TextFieldContainer = styled.div<ITheme & { isCentered }>(
  ({ isCentered, ...props }) => ({
    width: '100%',
    textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    'h1, h2, h3': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      fontWeight: 500,
    },
    p: {
      marginBottom: '27px',
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
      ':last-child': {
        marginBottom: 0,
      },
      '&:empty': {
        display: CSS_DISPLAY.NONE,
      },
    },
    a: {
      ':hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export const IconList = styled.ul<ITheme & { columnNumber: number }>(
  ({ columnNumber, ...props }) => ({
    width: '100%',
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${columnNumber || 6}, 1fr)`,
    listStyle: 'none',
    padding: 0,
    columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: `repeat(${columnNumber >= 4 ? 4 : 3}, 1fr)`,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  })
);

export const IconListItem = styled.li({
  padding: 0,
  height: '108px',
  [MEDIA_QUERIES.TABLET]: {
    height: '84px',
  },
});

export const StyledLink = styled(Link)<ITheme>((props) => ({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  textAlign: TEXT_ALIGN.CENTER,
  p: {
    color: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
    fontWeight: 700,
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    },
  },
  a: {
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

export const IconContainer = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  div: {
    width: '48px',
    height: '48px',
  },
}));

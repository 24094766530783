import { usePageContext } from 'contexts/PageContext';
import React, { useEffect, useRef } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { StyledMenuWrapperMobile } from '../styledComponents';
import { BottomNavMenuList } from './BottomNavMenuList';
import { isCompactHeader } from './helpers';
import { LeftNavMenuList } from './LeftNavMenuList';
import { PagedNav } from './PagedNav';
import { RightNavMenuList } from './RightNavMenuList';
import { StyledHeaderNav, StyledHeaderNavInner } from './styledComponents';
import { INavItem, NavProps } from './types';
import { useNavStack } from './useNavStack';
import { useHeaderScreenSize } from '@smartproxy-web/shared/hooks';

const Nav: React.FC<NavProps> = ({
  isOpen,
  categories,
  languages,
  links,
  location,
  toggleOpen,
}) => {
  const { navStack, ...navStackActions } = useNavStack(categories);
  const { isDesktop, notMountedScreen } = useHeaderScreenSize();
  const navContentsElRef = useRef<HTMLDivElement>(null);

  const { type, tags } = usePageContext();
  const buildTrackingId = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'header-nav',
  });
  const buildNavItemTrackingId = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'header-nav-item',
  });

  const closeDrawer = () => {
    toggleOpen(false);
    navStackActions.reset();
  };

  const handleNavItemClick = (category: INavItem) => {
    if (!isCompactHeader() || !category.children) return;
    navStackActions.push(category);
  };

  const handleBackClick = () => navStackActions.pop();

  useEffect(() => {
    if (!isOpen) navStackActions.reset();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      closeDrawer();
    }
  }, [location.pathname]);

  return (
    !notMountedScreen && (
      <StyledHeaderNav
        id="header-nav-menu"
        data-state={isOpen ? 'open' : 'closed'}
        onClick={closeDrawer}
      >
        <StyledHeaderNavInner
          ref={navContentsElRef}
          onClick={(e) => e.stopPropagation()}
        >
          {isDesktop && (
            <LeftNavMenuList
              categories={categories}
              handleItemClick={() => {}}
              buildTrackingId={buildNavItemTrackingId}
            />
          )}
          {!isDesktop && (
            <PagedNav
              navStack={navStack}
              handleBackClick={handleBackClick}
              handleItemClick={handleNavItemClick}
              buildTrackingId={buildNavItemTrackingId}
              closeDrawer={closeDrawer}
            />
          )}
          <RightNavMenuList
            links={links}
            languages={languages}
            buildTrackingId={buildTrackingId}
          />
          <BottomNavMenuList links={links} buildTrackingId={buildTrackingId} />
        </StyledHeaderNavInner>
        <StyledMenuWrapperMobile
          data-tracker={buildTrackingId({
            context: 'out-of-menu',
            section: 'header-dropdown-close',
            type: 'field',
          })}
        />
      </StyledHeaderNav>
    )
  );
};

export default Nav;

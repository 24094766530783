import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { BackgroundWrapper } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { GeneratedIdWrapper } from 'features/GeneratedIdWrapper';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeBrandedColor,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  MAIN_COLOR,
} from 'theme';

const FadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

export const StyledBackground = styled(BackgroundWrapper)((props) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: `${getThemeInputPaddingShape(
    props,
    TSHIRT_SIZE.XL
  )} ${getThemeGutter(props, TSHIRT_SIZE.XXXL)} 0`,
  [MEDIA_QUERIES.TABLET]: {
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px 0`,
  },
}));

export const TestimonialsContainer = styled(GeneratedIdWrapper)({
  maxWidth: '1024px',
  position: CSS_POSITION.RELATIVE,
  height: '450px',
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  [MEDIA_QUERIES.TABLET]: {
    height: 'auto',
    textAlign: TEXT_ALIGN.CENTER,
  },
});

export const TestimonialWrapper = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '1fr 360px',
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  animation: `${FadeIn} .3s ease-in-out forwards`,
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 450px',
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateRows: '1fr 350px',
  },
}));

export const Description = styled.div<{ isTextLight: boolean }>(
  ({ isTextLight, ...props }) => ({
    'p, strong, em, a': {
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      color: isTextLight
        ? getThemeMainColor(props, MAIN_COLOR.WHITE)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
    },
    [MEDIA_QUERIES.TABLET]: {
      marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
    },
  })
);

export const Name = styled.div((props) => ({
  'p, strong, em': {
    color: getThemeBrandedColor(props),
    marginBottom: '6px',
  },
}));

export const Role = styled.div((props) => ({
  'p, strong, em': {
    color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
    marginBottom: 0,
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  },
}));

export const Container = styled.div({
  display: CSS_DISPLAY.FLEX,
  position: CSS_POSITION.ABSOLUTE,
  bottom: '20px',
  height: '9px',
  [MEDIA_QUERIES.TABLET]: {
    top: '-24px',
    width: '100%',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
});

export const ImageContainer = styled.div({
  position: CSS_POSITION.ABSOLUTE,
  right: 0,
  bottom: 0,
  maxWidth: '360px',
  maxHeight: '450px',
  [MEDIA_QUERIES.TABLET]: {
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    img: {
      maxHeight: '350px',
    },
  },
});

export const StyledDot = styled.span<{ isActive: boolean }>(
  ({ isActive, ...props }) => ({
    cursor: 'pointer',
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    background: isActive
      ? getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)
      : getThemeMainColor(props, MAIN_COLOR.WHITE),
    marginRight: '4px',
    opacity: isActive ? 1 : 0.2,
    display: CSS_DISPLAY.BLOCK,
    ':hover': {
      opacity: 1,
      background: isActive
        ? getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)
        : '#ddd',
    },
  })
);

export const LeftArrow = styled.span({
  position: CSS_POSITION.ABSOLUTE,
  width: '60px',
  height: '40px',
  top: '50%',
  left: '-10%',
  cursor: 'pointer',
  textAlign: TEXT_ALIGN.CENTER,
  svg: {
    opacity: 0.3,
    ':hover': {
      opacity: 1,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    left: '5%',
    top: '-35px',
  },
});

export const RightArrow = styled.span({
  position: CSS_POSITION.ABSOLUTE,
  width: '60px',
  height: '40px',
  textAlign: TEXT_ALIGN.CENTER,
  top: '50%',
  right: '-10%',
  cursor: 'pointer',
  svg: {
    opacity: 0.3,
    ':hover': {
      opacity: 1,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    right: '5%',
    top: '-35px',
  },
});

import { BottomBanner } from './styledComponents';
import Link from 'components/GatsbyLink';

const FooterBottom = () => {
  const currentYear = new Date().getFullYear();
  return (
    <BottomBanner>
      <p>© 2018-{currentYear} Smartdaili 版权所有</p>
      <Link to="https://beian.miit.gov.cn/#/Integrated/index">
        津ICP备2022004334号-1
      </Link>
    </BottomBanner>
  );
};

export default FooterBottom;

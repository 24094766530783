import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import Features from './Features';
import {
  CtaContainer,
  CtaPricing,
  CtaStart,
  ImageContainer,
  StyledContainer,
  StyledTextContainer,
  StyledWrapper,
  Subtitle,
  Title,
} from './styledComponents';
import { THeroSlice } from './types';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';
import { HeroBadges } from '@smartproxy-web/features';

const Hero: React.FC<THeroSlice> = ({
  primary: {
    title,
    sub_title: subtitle,
    link_label_start: linkLabelStart,
    link_to_start: linkToStart,
    link_label_pricing: linkLabelPricing,
    link_to_pricing: linkToPricing,
    image,
    review_trustpilot: reviewTrustpilot,
    review_stars: reviewStars,
    review_text: reviewText,
    accent_color: accentColor,
    button_color: buttonColor,
    is_money_back_visible: isMoneyBackVisible,
  },
  items,
}) => {
  const isReviewOn = reviewStars.url || reviewTrustpilot.url || reviewText.text;

  const { tags, type } = usePageContext();
  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero',
  });
  const createBadgeTrackingID = initBuildTrackingSelector({
    section: 'hero-badge',
    type: 'link',
  });

  const isMoneyBackSelected =
    isMoneyBackVisible !== null ? isMoneyBackVisible : true;

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledTextContainer color={accentColor} isReviewOn={isReviewOn}>
          <HeroBadges
            trackingSelectors={{
              g2: createBadgeTrackingID({ context: 'g2' }),
              proxyway: createBadgeTrackingID({ context: 'proxyway' }),
              trustpilot: createBadgeTrackingID({ context: 'trustpilot' }),
            }}
          />
          <Title isReviewOn={isReviewOn} color={accentColor}>
            <RichText field={title} />
          </Title>
          <Subtitle>
            <RichText field={subtitle} />
          </Subtitle>
          <CtaContainer>
            <CtaStart
              isVisible={linkLabelStart.text && linkToStart.url}
              ctaText={linkLabelStart.text}
              ctaLink={linkToStart.url}
              themeName={BUTTON_THEMES[buttonColor]}
              themeType={BUTTON_TYPES.PRIMARY}
              data-tracker={buildTrackingSelector({
                context: 'primary',
                type: 'cta',
              })}
            />
            <CtaPricing
              isVisible={linkLabelPricing.text && linkToPricing.url}
              ctaText={linkLabelPricing.text}
              ctaLink={linkToPricing.url}
              themeName={BUTTON_THEMES.CONVERSION}
              themeType={BUTTON_TYPES.SECONDARY}
              data-tracker={buildTrackingSelector({
                context: 'secondary',
                type: 'cta',
              })}
            />
          </CtaContainer>
          <MoneyBackTag isVisible={isMoneyBackSelected && !!linkToStart.url} />
        </StyledTextContainer>
        <ImageContainer>
          <GatsbyImg image={image} loading="eager" />
        </ImageContainer>
      </StyledContainer>
      <Features isVisible={!!items.length} features={items} />
    </StyledWrapper>
  );
};

export default Hero;

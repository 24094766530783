import { navigate, useLocation } from '@reach/router';

export const useHash = () => {
  const { hash } = useLocation();

  const clearHash = () => {
    navigate(location.pathname + location.search);
  };

  return { hash, clearHash };
};

import { PRISMIC_VALUES, SECTION_NAMES } from 'constants/common';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import {
  RelatedArticlesContainer,
  StyledArticleThumbnails,
  StyledBackgroundWrapper,
} from 'features/ArticleThumbnails/styledComponents';
import { IPostThumbnail } from 'features/ArticleThumbnails/types';
import { TemplatePaths } from 'paths';
import * as React from 'react';
import { CtaContainer } from './styledComponents';
import { generateSectionsId } from 'features/utils';

const Blog: React.FC<IPostThumbnail> = ({
  primary: { title, background_color: background },
  items,
  pageContext: { tags, type },
}) => (
  <StyledBackgroundWrapper
    background={background}
    data-section-id={generateSectionsId(
      SECTION_NAMES.RELATED_ARTICLES,
      tags,
      type
    )}
  >
    <RelatedArticlesContainer>
      <StyledArticleThumbnails title={title} thumbnails={items} />
      <CtaContainer>
        <Cta
          ctaLink={TemplatePaths.blog}
          ctaText={PRISMIC_VALUES.VISIT_BLOG}
          isVisible
          themeType={BUTTON_TYPES.SECONDARY}
          themeName={BUTTON_THEMES.FOLLOWING}
          gutterBot={TSHIRT_SIZE.XXS}
          gutterTop={TSHIRT_SIZE.L}
        />
      </CtaContainer>
    </RelatedArticlesContainer>
  </StyledBackgroundWrapper>
);

export default Blog;

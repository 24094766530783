import { TEXT_LENGTH_VALUES } from 'constants/common';
import React from 'react';
import { ArticlesList } from './ArticlesList';
import {
  AllArticlesBackgroundWrapper,
  AllArticlesContainer,
} from './styledComponents';
import { IAllArticles } from './types';

export const AllArticles: React.FC<IAllArticles> = ({
  primary: { background },
  articles,
}) => {
  return (
    <AllArticlesBackgroundWrapper background={background}>
      <AllArticlesContainer>
        <ArticlesList
          articles={articles}
          descriptionLength={TEXT_LENGTH_VALUES.ARTICLES_DESCRIPTION}
          isHomepage={false}
        />
      </AllArticlesContainer>
    </AllArticlesBackgroundWrapper>
  );
};

import { Wrapper } from 'commonStyles';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import {
  proxyAffiliateProgramliceMap,
  PROXY_AFFILIATE_PROGRAM_SLICES,
} from 'pageUtils/proxy-affiliate-program/constants';

interface IWithPrismicProxyAffiliateProgram {
  prismicProxyAffiliateProgram: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicProxyAffiliateProgram;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const ProxyAffiliateProgram = ({ data }: Props & PageTemplateProps) => {
  const { prismicProxyAffiliateProgram } = data;
  const templates = getTemplates<PROXY_AFFILIATE_PROGRAM_SLICES, DefaultSlice>(
    prismicProxyAffiliateProgram?.data?.body,
    proxyAffiliateProgramliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query ProxyAffiliateProgramQuery {
    prismicProxyAffiliateProgram {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicProxyAffiliateProgramDataBodyBreadcrumbs {
            id
            slice_label
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicProxyAffiliateProgramDataBodyImageTextBlock {
            id
            slice_label
            slice_type
            primary {
              text_below_title {
                richText
                text
              }
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_zoom_modal
              is_image_right
              review_bottom_text {
                richText
                text
              }
              review_description
              review_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              is_dark_theme
              is_header_centered
              background
              columns
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyImageTextColumnsWithCta {
            id
            items {
              cta_label {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              title_field {
                richText
                text
              }
              subtitle_and_text_alignment
            }
            slice_type
          }
          ... on PrismicProxyAffiliateProgramDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicProxyAffiliateProgramDataBodyTextImageColumnList {
            id
            slice_type
            primary {
              column_background
              column_list_title {
                richText
                text
              }
            }
            items {
              link_text
              column_description {
                richText
                text
              }
              column_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              column_link {
                url
              }
              column_title {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicProxyAffiliateProgramDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label_primary {
                text
                richText
              }
              cta_link_primary {
                url
              }
              main_description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_label_secondary {
                text
                richText
              }
              cta_link_secondary {
                url
              }
              main_title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              features_description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              features_title {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              logos_title {
                richText
                text
              }
            }
          }
          ... on PrismicProxyAffiliateProgramDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(ProxyAffiliateProgram);

import { PrismicImageTabs } from '@smartproxy-web/features';
import Hero from 'features/Blog';
import Breadcrumbs from 'features/Breadcrumbs';
import ColumnLinks from 'features/ColumnsLinks';
import FaqQuestions from 'features/FaqQuestions';
import FaqText from 'features/FaqText';
import FooterBanner from 'features/FooterBanner';
import { Spacing } from 'features/Spacing/Spacing';
import { SliceMap } from 'types';

export enum FAQ_SLICES {
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  FaqQuestions = 'faq_questions',
  Spacing = 'spacing',
  ColumnLinks = 'column_links',
  FaqText = 'faq_text',
  FooterBanner = 'footer_banner_slice',
  ImageTabs = 'image_tabs',
}

export const faqSliceMap: SliceMap<FAQ_SLICES> = {
  [FAQ_SLICES.Breadcrumbs]: Breadcrumbs,
  [FAQ_SLICES.Hero]: Hero,
  [FAQ_SLICES.FaqQuestions]: FaqQuestions,
  [FAQ_SLICES.Spacing]: Spacing,
  [FAQ_SLICES.ColumnLinks]: ColumnLinks,
  [FAQ_SLICES.FaqText]: FaqText,
  [FAQ_SLICES.FooterBanner]: FooterBanner,
  [FAQ_SLICES.ImageTabs]: PrismicImageTabs,
};

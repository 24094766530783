import Hero from 'features/Hero';
import { Spacing } from 'features/Spacing/Spacing';
import { SliceMap } from 'types';
import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  PrismicBlogImage,
  PrismicImageTabs,
} from '@smartproxy-web/features';
import ImageTextColumn from 'features/ImageTextColumn';
import PaymentMethod from 'features/PaymentMethod';
import Title from 'features/Title';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import Breadcrumbs from 'features/Breadcrumbs';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { PrismicTextGrid } from '@smartproxy-web/features';

export enum HOW_IT_WORKS_SLICES {
  BadgesBlock = 'badges_block',
  Hero = 'hero_block',
  Spacing = 'spacing',
  Title = 'title',
  BiggerPlansBanner = 'bigger_plans',
  ImageTextColumn = 'image_text_column',
  PaymentMethod = 'payment_method',
  TextGrid = 'text_grid',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  BlogImage = 'blog_image',
  Breadcrumbs = 'breadcrumbs',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  AutomatedPricingCards = 'automated_pricing_cards',
  ImageTabs = 'image_tabs',
}

export const howItWorksMap: SliceMap<HOW_IT_WORKS_SLICES> = {
  [HOW_IT_WORKS_SLICES.Hero]: Hero,
  [HOW_IT_WORKS_SLICES.Spacing]: Spacing,
  [HOW_IT_WORKS_SLICES.BadgesBlock]: BadgesBlock,
  [HOW_IT_WORKS_SLICES.Title]: Title,
  [HOW_IT_WORKS_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [HOW_IT_WORKS_SLICES.BlogImage]: PrismicBlogImage,
  [HOW_IT_WORKS_SLICES.ImageTextColumn]: ImageTextColumn,
  [HOW_IT_WORKS_SLICES.PaymentMethod]: PaymentMethod,
  [HOW_IT_WORKS_SLICES.TextGrid]: PrismicTextGrid,
  [HOW_IT_WORKS_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [HOW_IT_WORKS_SLICES.VideoBlock]: VideoBlock,
  [HOW_IT_WORKS_SLICES.Breadcrumbs]: Breadcrumbs,
  [HOW_IT_WORKS_SLICES.FooterBanner]: FooterBanner,
  [HOW_IT_WORKS_SLICES.Herov2]: Herov2,
  [HOW_IT_WORKS_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [HOW_IT_WORKS_SLICES.HeroLogosv2]: HeroLogosv2,
  [HOW_IT_WORKS_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [HOW_IT_WORKS_SLICES.ImageTabs]: PrismicImageTabs,
};

import BadgesBlock from 'features/BadgesBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import Hero from 'features/Hero';
import HeroAboutUs from 'features/HeroAboutUs';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import RecognisedByBlock from 'features/RecognisedByLogos';
import { Spacing } from 'features/Spacing/Spacing';
import Testimonials from 'features/Testimonials';
import TextCtaBlock from 'features/TextCtaBlock';
import Title from 'features/Title';
import VisionMission from 'features/VisionMission';
import { SliceMap } from 'types';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { PrismicImageTabs } from '@smartproxy-web/features';
import { SocialProofBlock, TextBlock } from '@smartproxy-web/features';

export enum ABOUT_SLICES {
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  VisionMission = 'vision_and_mission',
  BadgesBlock = 'badges_block',
  TextBlock = 'text_block',
  Title = 'title',
  Spacing = 'spacing',
  CtaBlock = 'cta_block',
  Testimonials = 'testimonials',
  RecognisedByBlock = 'recognised_by_block',
  HeroAboutUs = 'hero_about_us',
  TextCtaBlock = 'text_and_cta',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  ImageTabs = 'image_tabs',
}

export const aboutSliceMap: SliceMap<ABOUT_SLICES> = {
  [ABOUT_SLICES.Breadcrumbs]: Breadcrumbs,
  [ABOUT_SLICES.Hero]: Hero,
  [ABOUT_SLICES.ImageTextBlock]: ImageTextBlock,
  [ABOUT_SLICES.VisionMission]: VisionMission,
  [ABOUT_SLICES.BadgesBlock]: BadgesBlock,
  [ABOUT_SLICES.TextBlock]: TextBlock,
  [ABOUT_SLICES.Title]: Title,
  [ABOUT_SLICES.Spacing]: Spacing,
  [ABOUT_SLICES.CtaBlock]: CtaBlock,
  [ABOUT_SLICES.Testimonials]: Testimonials,
  [ABOUT_SLICES.RecognisedByBlock]: RecognisedByBlock,
  [ABOUT_SLICES.HeroAboutUs]: HeroAboutUs,
  [ABOUT_SLICES.TextCtaBlock]: TextCtaBlock,
  [ABOUT_SLICES.SocialProofBlock]: SocialProofBlock,
  [ABOUT_SLICES.FeaturedIn]: FeaturedIn,
  [ABOUT_SLICES.FooterBanner]: FooterBanner,
  [ABOUT_SLICES.Herov2]: Herov2,
  [ABOUT_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [ABOUT_SLICES.HeroLogosv2]: HeroLogosv2,
  [ABOUT_SLICES.ImageTabs]: PrismicImageTabs,
};

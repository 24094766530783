import React from 'react';
import {
  StyledHeaderLogo,
  StyledHeaderLogoLink,
  StyledHeaderLogoWrapper,
} from '../styledComponents';
import { LogoProps } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const HeaderLogo: React.FC<LogoProps> = ({
  logo,
  logoTrackerId,
  isClickable,
}) => (
  <>
    {isClickable ? (
      <StyledHeaderLogoLink to="/" data-tracker={logoTrackerId}>
        <StyledHeaderLogoWrapper>
          <GatsbyImg image={logo} loading="eager" />
        </StyledHeaderLogoWrapper>
      </StyledHeaderLogoLink>
    ) : (
      <StyledHeaderLogo>
        <StyledHeaderLogoWrapper>
          <GatsbyImg image={logo} loading="eager" />
        </StyledHeaderLogoWrapper>
      </StyledHeaderLogo>
    )}
  </>
);

export default HeaderLogo;

import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Link from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const Wrapper = styled.section<ITheme & { background: string }>(
  ({ background, ...props }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const MainTitle = styled.div<ITheme>((props) => ({
  'h1, h2, h3': {
    textAlign: TEXT_ALIGN.CENTER,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    color: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      lineHeight: '48px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    },
  },
}));

export const UseCasesContainer = styled.ul<ITheme & { numbOfColumns: number }>(
  ({ numbOfColumns }) => ({
    width: '100%',
    maxWidth: '1024px',
    padding: 0,
    listStyle: 'none',
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${numbOfColumns || 2}, 1fr)`,
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyItems: CSS_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const ListItem = styled.li<ITheme>((props) => ({
  padding: 0,
  'h2, h3, h4': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
  },
}));

export const StyledLink = styled(Link)<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const Subtitle = styled.div<ITheme>((props) => ({
  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    marginTop: '4px',
    marginBottom: '2px',
    fontWeight: 600,
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    'a, strong, em': {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

export const ImageContainer = styled.div({
  marginRight: '15px',
  [MEDIA_QUERIES.TABLET]: {
    marginRight: 0,
  },
});

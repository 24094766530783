import React from 'react';
import Link from 'components/GatsbyLink';
import { LocationFlagsItem } from './styledComponents';
import { IFlagsItem } from './types';
import FlagContent from './FlagContent';
import hideable from 'utils/hideable';

const HideableLink = hideable(Link);

const FlagsItem: React.FC<IFlagsItem> = ({ url, icon, text }) => (
  <LocationFlagsItem>
    <HideableLink isVisible={url} to={url}>
      <FlagContent isVisible icon={icon} text={text} />
    </HideableLink>
    <FlagContent isVisible={!url} icon={icon} text={text} />
  </LocationFlagsItem>
);

export default FlagsItem;

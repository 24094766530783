import { graphql, PageProps } from 'gatsby';
import { BlogPageSliceMap, BLOG_PAGE_SLICES } from 'pageUtils/blog/constants';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { IWithBlogProps } from 'pageUtils/blog/types';
import { PageTemplateQuery } from 'types.generated';
import { Wrapper } from 'commonStyles';

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const BlogPage = ({ data }: IWithBlogProps & PageTemplateProps) => {
  const { prismicBlogPage, allPrismicBlog } = data;
  const templates = getTemplates<BLOG_PAGE_SLICES, DefaultSlice>(
    prismicBlogPage.data.body,
    BlogPageSliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} articles={allPrismicBlog.nodes} />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(BlogPage);

export const query = graphql`
  query BlogPageQuery {
    prismicBlogPage {
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicBlogPageDataBodyBreadcrumbs {
            id
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
            primary {
              text_color
            }
            slice_type
          }
          ... on PrismicBlogPageDataBodyHeroBlock {
            id
            slice_type
            primary {
              background
              accent_color
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicBlogPageDataBodyKhArticles {
            id
            slice_type
          }
          ... on PrismicBlogPageDataBodyAllArticles {
            id
            slice_type
            primary {
              background
            }
          }
          ... on PrismicBlogPageDataBodyContentTags {
            id
            slice_type
            items {
              topic {
                tags
                document {
                  ... on PrismicTopic {
                    id
                    data {
                      topic_label
                    }
                  }
                }
              }
            }
            primary {
              title {
                richText
                text
              }
            }
          }
          ... on PrismicBlogPageDataBodyKnowledgeHubHero {
            id
            primary {
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              primary_cta_label {
                text
                richText
              }
              primary_cta_link {
                url
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicBlogPageDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicBlogPageDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___release_date, order: DESC }
      filter: { tags: { nin: "test" } }
    ) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMMM DD, yyyy", locale: "zh-CN")
        url
        data {
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMM DD, yyyy", locale: "zh-CN")
          time_spent_reading
          body {
            ... on PrismicBlogDataBodyBlogAuthor {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
              }
            }
            ... on PrismicBlogDataBodyAuthorV2 {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
              }
            }
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ... on PrismicTopic {
                      id
                      data {
                        topic_label
                      }
                      tags
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

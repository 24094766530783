import GatsbyImg from 'components/GatsbyImg';
import { Badge } from 'components/v2/Badge';
import { Box } from 'components/v2/Box';
import { LinkButton } from 'components/v2/LinkButton';
import { RichText } from 'components/v2/RichText';
import { IconArrowRightBluev2, IconDownloadv2 } from 'images';
import React from 'react';
import {
  StyledCardContainer,
  StyledCardHeader,
  StyledCtaContainer,
  StyledCtaDownloadContainer,
  StyledCtaWrapper,
} from './styledComponents';
import { ProductCardItemProps } from './types';

export const ProductCard: React.FC<ProductCardItemProps> = ({
  card_item_description: description,
  card_item_title: title,
  card_badge_color: badgeColor,
  card_badge_label: badgeLabel,
  card_cta_label: ctaLabel,
  card_cta_link: ctaLink,
  card_download_cta_link: downloadCtaLink,
  card_icon: icon,
  trackerIdCta,
  trackerIdCtaDownload,
}) => (
  <StyledCardContainer>
    <StyledCardHeader>
      {!!badgeLabel?.text && (
        <Badge as="span" variant="filled" color={badgeColor}>
          {badgeLabel.text}
        </Badge>
      )}
      <GatsbyImg image={icon} />
      <Box sx={{ marginTop: '16px' }}>
        <RichText field={title} />
      </Box>
    </StyledCardHeader>
    <Box
      sx={{
        padding: '24px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ paddingBottom: '36px' }}>
        <RichText field={description} />
      </Box>
      <StyledCtaWrapper>
        {!!ctaLabel?.text && !!ctaLink?.url && (
          <StyledCtaContainer>
            <LinkButton
              to={ctaLink.url}
              variant="text"
              color="blue"
              data-tracker={trackerIdCta}
            >
              {ctaLabel.text}
              <IconArrowRightBluev2 />
            </LinkButton>
          </StyledCtaContainer>
        )}
        {!!downloadCtaLink?.url && (
          <StyledCtaDownloadContainer>
            <LinkButton
              to={downloadCtaLink.url}
              variant="text"
              data-tracker={trackerIdCtaDownload}
            >
              <IconDownloadv2 />
            </LinkButton>
          </StyledCtaDownloadContainer>
        )}
      </StyledCtaWrapper>
    </Box>
  </StyledCardContainer>
);

import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  BackgroundContainer,
  ContentContainer,
  IconsContainer,
} from './styledComponents';
import { IFeaturedIn } from './types';

const FeaturedIn: React.FC<IFeaturedIn> = ({
  primary: { background, title },
  items,
}) => (
  <BackgroundContainer background={background}>
    <ContentContainer>
      <RichText field={title} />
      <IconsContainer>
        {items.map(({ icon }) => (
          <GatsbyImg image={icon} key={icon.alt} />
        ))}
      </IconsContainer>
    </ContentContainer>
  </BackgroundContainer>
);

export default FeaturedIn;

import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  ITheme,
  getThemeGutter,
  CSS_JUSTIFY_CONTENT,
  getThemeInputPaddingShape,
  getThemeFontSize,
  getThemeInputWidthShape,
  getThemeAccentColor,
  ACCENT_COLOR,
} from 'theme';

export const CtaContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  a: {
    button: {
      minWidth: '180px',
      padding: `${getThemeInputPaddingShape(
        props,
        TSHIRT_SIZE.S
      )} 15px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)}`,
      color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
      borderColor: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
      span: {
        fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
        lineHeight: '20px',
      },
    },
    ':hover': {
      button: {
        padding: `9px 14px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
        borderWidth: '2px',
      },
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      margin: getThemeGutter(props, TSHIRT_SIZE.XXS),
      width: 'auto',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    },
  },
}));

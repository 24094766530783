import { SIGNUP_URL } from 'constants/common';
import styled from '@emotion/styled';
import Button, {
  BUTTON_TYPES,
  BUTTON_THEMES,
  TButton,
} from 'components/Button';
import { TEXT_ALIGN } from 'components/constants';
import Link from 'components/GatsbyLink';
import React from 'react';
import hideable from 'utils/hideable';
import { ICta } from '../types';
import { clearPlanCookies } from 'helpers/cookiesHelper';
import { MEDIA_QUERIES } from 'globalStyles';
import { TARGET } from './constants';
import { changeCtaDomain } from 'helpers/updateLink';

const StyledExternal = styled.a({
  [MEDIA_QUERIES.TABLET]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '50%',
    margin: '20px 0 5px 0',
    button: {
      width: '100%',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
});
const HideableExternal = hideable(StyledExternal);
const StyledInternal = hideable(StyledExternal.withComponent(Link));

const Cta: React.FC<ICta & TButton> = ({
  ctaText,
  ctaLink,
  onClick,
  isExternal,
  themeName = BUTTON_THEMES.CONVERSION,
  themeType = BUTTON_TYPES.PRIMARY,
  target = TARGET.BLANK,
  icon,
  dataTracker,
  ...props
}) => (
  <>
    <HideableExternal
      target={target}
      href={changeCtaDomain(ctaLink!) || SIGNUP_URL}
      isVisible={isExternal}
      data-tracker={dataTracker}
    >
      <Button
        {...props}
        themeName={themeName}
        themeType={themeType}
        onClick={onClick || clearPlanCookies}
      >
        <>
          {ctaText}
          {icon && icon}
        </>
      </Button>
    </HideableExternal>
    <StyledInternal
      to={changeCtaDomain(ctaLink!) || SIGNUP_URL}
      isVisible={!isExternal}
      data-tracker={dataTracker}
    >
      <Button
        {...props}
        themeName={themeName}
        themeType={themeType}
        onClick={onClick || clearPlanCookies}
      >
        <>
          {ctaText}
          {icon && icon}
        </>
      </Button>
    </StyledInternal>
  </>
);

export default hideable(Cta);

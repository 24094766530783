import Link from 'components/GatsbyLink';
import { IconChevronDownv2, IconTranslationWhite } from 'images';
import { forwardRef } from 'react';
import { MENU_CONTENT_VALUES } from '../../constants';
import { transformUrl } from '../../helpers';
import {
  CategoryPricingNav,
  StyledNavMenuItemTrigger,
  StyledNavMenuItemTriggerLabel,
} from './styledComponents';
import { NavMenuItemProps } from './types';
import { useHover } from '@smartproxy-web/shared/hooks';
import { Badge } from '@smartproxy-web/ui';
import { Box } from 'components/v2/Box';
import { getPeriod } from '../../Menu/CategoriesContainer/utils';

export const NavMenuItem = forwardRef<HTMLButtonElement, NavMenuItemProps>(
  (
    {
      navItem,
      handleClick,
      tabIndex,
      buildTrackingId,
      disabled = false,
      opened,
      hasPricing,
      closeDrawer,
    },
    ref
  ) => {
    const { hoverRef, isHovered } = useHover();

    if (navItem.url) {
      const trackingId = buildTrackingId({
        context: transformUrl(navItem.url),
        type: 'link',
      });

      return (
        <div ref={hoverRef}>
          <Link
            to={!disabled ? navItem.url : undefined}
            data-disabled={disabled}
            tabIndex={!disabled ? tabIndex : -1}
            data-tracker={trackingId}
            onClick={
              !disabled
                ? () => {
                    closeDrawer();
                  }
                : undefined
            }
          >
            <StyledNavMenuItemTrigger ref={ref} as="div">
              <StyledNavMenuItemTriggerLabel>
                {navItem.renderLabel ? navItem.renderLabel() : navItem.label}
                {!!navItem.badgeLabel && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Badge
                      variant="outlined"
                      color={navItem.badgeColor.toLowerCase()}
                      forcedEvent={isHovered ? 'hover' : undefined}
                    >
                      {navItem.badgeLabel}
                    </Badge>
                  </Box>
                )}
              </StyledNavMenuItemTriggerLabel>
              {!!navItem.children && <IconChevronDownv2 />}
            </StyledNavMenuItemTrigger>
            {hasPricing && (
              <CategoryPricingNav>
                <span>{MENU_CONTENT_VALUES.PRICE_FROM}</span>${navItem.price}
                <span>{getPeriod(`/${navItem.pricePer}`)}</span>{' '}
              </CategoryPricingNav>
            )}
          </Link>
        </div>
      );
    }

    const trackingId = buildTrackingId({
      context: navItem.label,
      type: 'button',
    });

    return (
      <div ref={hoverRef}>
        <StyledNavMenuItemTrigger
          ref={ref}
          tabIndex={!disabled ? tabIndex : -1}
          onClick={
            !disabled
              ? () => {
                  handleClick(navItem);
                }
              : undefined
          }
          data-disabled={disabled}
          data-tracker={trackingId}
          opened={opened}
        >
          <StyledNavMenuItemTriggerLabel>
            {navItem.label === 'Languages' && <IconTranslationWhite />}
            {navItem.label}
            {!!navItem.badgeLabel && (
              <Badge
                variant="outlined"
                forcedEvent={isHovered ? 'hover' : undefined}
              >
                {navItem.badgeLabel}
              </Badge>
            )}
          </StyledNavMenuItemTriggerLabel>
          {!!navItem.children && <IconChevronDownv2 />}
        </StyledNavMenuItemTrigger>
      </div>
    );
  }
);

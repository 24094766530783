/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic.
 *
 * @see https://github.com/angeloashmore/gatsby-source-prismic/tree/alpha/packages/gatsby-plugin-prismic-previews
 */

import { linkResolver } from 'prismicConfig';
import {
  componentResolverFromMap,
  PrismicUnpublishedRepositoryConfigs,
} from 'gatsby-plugin-prismic-previews';
import About from 'pages/about';
import HomePage from 'pages/index';
import SmartdailiQuickStartGuide from 'pages/smartdaili-quick-start-guide';
import CasePost from 'templates/CaseStudies';
import Configuration from 'templates/Configuration';
import Faq from 'templates/Faq';
import Legal from 'templates/Legal';
import Lp from 'templates/Lp';
import Questions from 'templates/Questions';
import NotFoundPage from './pages/404';
import AppsPage from './templates/Apps';
import Blog from './templates/Blog';
import DealsPage from './templates/Deals';
import IntegrationsPage from './templates/Integrations';
import ScrapingPage from 'templates/Scraping';
import ProxyPage from './templates/Proxies';
import BlogPage from './pages/blog';
import Targets from 'templates/Targets';
import PartnersGuide from './templates/PartnersGuide';
import UseCases from './templates/UseCases';
import WhySmartproxy from 'pages/why-smartproxy';
import HowItWorks from 'pages/how-it-works';
import ProxyAffiliateProgram from 'pages/proxy-affiliate-program';
import KHLanding from 'templates/Devs';
import SmartdailiQuickStartGuidePages from 'templates/smartdaili-quick-start-guide';

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `withPrismicPreview` and
 * `withPrismicUnpublishedPreview` higher order components.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 * @see https://github.com/angeloashmore/gatsby-source-prismic/tree/alpha/packages/gatsby-plugin-prismic-previews#content-pages-and-templates
 */
export const repositoryConfigs: PrismicUnpublishedRepositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      404: NotFoundPage,
      about: About,
      apps: AppsPage,
      blog_page: BlogPage,
      blog: Blog,
      case_post: CasePost,
      configuration: Configuration,
      deals: DealsPage,
      faq: Faq,
      home_page: HomePage,
      integrations: IntegrationsPage,
      legal: Legal,
      lp: Lp,
      proxies: ProxyPage,
      questions: Questions,
      smartdaili_quick_start_guide: SmartdailiQuickStartGuide,
      scraping: ScrapingPage,
      targtes: Targets,
      partners_guide: PartnersGuide,
      use_cases: UseCases,
      why_smartproxy: WhySmartproxy,
      how_it_works: HowItWorks,
      proxy_affiliate_program: ProxyAffiliateProgram,
      knowledge_hub_landing: KHLanding,
      smartdaili_quick_start_guide_pages: SmartdailiQuickStartGuidePages,
    }),
  },
];

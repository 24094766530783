interface IFontSizes {
  large: string;
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  p_large: string;
  p_medium: string;
  p_small: string;
  p_xsmall: string;
}

interface IFontFamily {
  main: string;
  mono: string;
  additional: string;
}

export interface ThemeFonts {
  size: IFontSizes;
  lineHeight: IFontSizes;
  family: IFontFamily;
}

export const fonts: ThemeFonts = {
  family: {
    main: 'Poppins, sans-serif',
    additional: 'DM Sans',
    mono: 'SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
    additional: 'DM Sans',
  },
  size: {
    large: '96px',
    h1: '36px',
    h2: '32px',
    h3: '24px',
    h4: '20px',
    h5: '18px',
    p_large: '18px',
    p_medium: '16px',
    p_small: '14px',
    p_xsmall: '12px',
  },
  lineHeight: {
    large: '144px',
    h1: '54px',
    h2: '48px',
    h3: '36px',
    h4: '26px',
    h5: '23px',
    p_large: '27px',
    p_medium: '24px',
    p_small: '21px',
    p_xsmall: '18px',
  },
};

import { PRISMIC_VALUES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import Link from 'components/GatsbyLink';
import * as React from 'react';
import { IProductCard } from '../types';
import {
  ProductBadge,
  ProductCard,
  ProductCardContainer,
  ProductCards,
  ProductCardTitle,
  ProductCardWrapper,
  ProductDescription,
  ProductImageWrapper,
  ProductLink,
  ProductTextContainer,
  ProductTitle,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';

const ProductCardBlock: React.FC<IProductCard> = ({
  primary: { block_title: title, background },
  items,
}) => (
  <ProductCardWrapper background={background}>
    <ProductCardContainer>
      <ProductCardTitle>
        <RichText field={title} />
      </ProductCardTitle>
      <ProductCards>
        {items.map(
          (
            {
              product_title: title,
              product_logo: logo,
              badge,
              product_description: description,
              link,
            },
            index
          ) => (
            <ProductCard key={index}>
              <ProductImageWrapper>
                <GatsbyImg image={logo} />
              </ProductImageWrapper>
              <ProductTextContainer>
                <ProductBadge>{badge.text}</ProductBadge>
                <ProductTitle>
                  <RichText field={title} />
                </ProductTitle>
                <ProductDescription>
                  <RichText field={description} />
                </ProductDescription>
                <ProductLink>
                  <Link to={link.url}>{PRISMIC_VALUES.LEARN_MORE}</Link>
                </ProductLink>
              </ProductTextContainer>
            </ProductCard>
          )
        )}
      </ProductCards>
    </ProductCardContainer>
  </ProductCardWrapper>
);

export default ProductCardBlock;

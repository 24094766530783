import { TSetGeneratedId } from 'features/TextBlock/types';
import { generateTitleId } from 'features/utils';

export const setGeneratedId: TSetGeneratedId = (
  { richText },
  titleType,
  ref
) => {
  richText?.find((item) =>
    item.type === titleType
      ? ref.current?.setAttribute('id', generateTitleId(item.text))
      : null
  );
};

import { DEALS, LP } from 'constants/common';
import { mapItemsToPaths } from 'features/Dialog/utils';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { useState } from 'react';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'utils/browserStorage';
import { isBrowser } from 'utils/build';
import BannerDesktopContainer from './BannerDesktopContainer';
import BannerMobileContainer from './BannerMobileContainer';
import { BANNER_TOP_CLOSED, BANNER_TOP_SLICES } from './constants';
import { query } from './query';
import { IPrismicBannerTop, TBannerTop } from './types';
import { isTypeExcludedFromPath } from './utils';

const BannerTop: React.FC<TBannerTop> = () => {
  const [isBannerTopOpen, setIsBannerTopOpen] = useState(true);
  const { data } = useMergePrismicPreviewData(query());

  if (!data.prismicBannerTop?.data) return null;

  const {
    body,
    show_in_all_pages: showInAllPages,
    is_hidden_from_paths: isHiddenFromPaths,
    ...rest
  }: IPrismicBannerTop = data.prismicBannerTop?.data;

  const pageListSlice = body?.filter(
    (slice) => slice.slice_type === BANNER_TOP_SLICES.PageList
  );

  const paths = pageListSlice && pageListSlice[0]?.items.map(mapItemsToPaths);
  const isPathnameInPaths =
    isBrowser && paths ? paths.includes(window.location.pathname) : false;

  const isBannerTopClosed = isBrowser
    ? !!getSessionStorageItem(BANNER_TOP_CLOSED)
    : true;

  const isDealLp =
    isBrowser &&
    isHiddenFromPaths &&
    isTypeExcludedFromPath(location.pathname, [DEALS, LP]);
  const isBannerShown = !isBannerTopClosed && isBannerTopOpen;

  const isBannerTopVisible =
    (isBannerShown && isPathnameInPaths) ||
    (isBannerShown && showInAllPages && !isDealLp);

  const handleClose = () => {
    setIsBannerTopOpen(false);
    setSessionStorageItem(BANNER_TOP_CLOSED, JSON.stringify(true));
  };

  return (
    <>
      <BannerDesktopContainer
        onClose={handleClose}
        isVisible={isBannerTopVisible}
        setIsDialogOpen={setIsBannerTopOpen}
        {...rest}
      />
      <BannerMobileContainer
        onClose={handleClose}
        isVisible={isBannerTopVisible}
        setIsDialogOpen={setIsBannerTopOpen}
        {...rest}
      />
    </>
  );
};

export default BannerTop;

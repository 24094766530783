import { IconClosev2 } from 'images';
import React from 'react';
import {
  CloseIconContainer,
  StyledMenuContainer,
  StyledMenuWrapper,
} from '../styledComponents';
import { MenuContent } from './MenuContent';
import { VisibilityContainer } from './styledComponents';
import { IMenuContent } from './types';

export const MenuContainer: React.FC<IMenuContent> = ({
  selected,
  category,
  handleClose,
  buildTrackingId,
}) => (
  <>
    <StyledMenuContainer selected={selected}>
      <VisibilityContainer selected={selected}>
        <CloseIconContainer>
          <IconClosev2
            data-tracker={buildTrackingId({
              context: 'icon',
              section: 'header-dropdown-close',
              type: 'button',
            })}
            onClick={handleClose}
          />
        </CloseIconContainer>
        <MenuContent
          category={category}
          handleClose={handleClose}
          handleCategoryItemClick={handleClose}
          buildTrackingId={buildTrackingId}
        />
      </VisibilityContainer>
    </StyledMenuContainer>
    <StyledMenuWrapper
      data-tracker={buildTrackingId({
        context: 'out-of-menu',
        section: 'header-dropdown-close',
        type: 'field',
      })}
      onClick={handleClose}
      selected={selected}
    />
  </>
);

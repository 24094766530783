import { IconMoneyBackv2 } from 'images';
import {
  CardTitle,
  CrossedOutPrice,
  CtaContainer,
  TimePeriod,
  MoneyBack,
  NewPrice,
  PricingCardContainer,
  StyledCta,
  DiscountContainer,
  PriceWrapper,
  TotalPriceWrapper,
  CrossedOutPrimaryPrice,
  HideableCardFlag,
} from './styled';
import { Badge } from 'components/v2/Badge';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { appendPlanIdToURL } from 'features/utils';
import { TGatsbyImage, theme } from '@smartproxy-web/ui';

export type PricingCardProps = {
  title: string;
  discount: string;
  discountLabel: string;
  price: string;
  priceOld: string;
  pricePer: string; // IP, GB
  priceTotal: string;
  priceTotalPrefixText: string;
  priceTotalPostfixText: string;
  priceTotalOld: string;
  buttonText: string;
  moneyBackText: string;
  redirectUrl: string;
  planId: string;
  isPopular: boolean;
  prefixText: string;
  icon: TGatsbyImage;
};

// TODO refactor in nextjs
export default function PricingCard({
  title,
  discount,
  discountLabel,
  price,
  priceOld,
  pricePer,
  priceTotal,
  priceTotalPrefixText,
  priceTotalPostfixText,
  priceTotalOld,
  buttonText,
  moneyBackText,
  redirectUrl,
  planId,
  isPopular,
  prefixText,
  icon,
}: PricingCardProps) {
  const redirectUrlWithPlanId = redirectUrl?.endsWith('#contact-sales')
    ? redirectUrl
    : !planId
    ? redirectUrl
    : appendPlanIdToURL(
        'https://dashboard.smartdaili-china.com/register',
        planId
      );
  return (
    <PricingCardContainer
      borderColorC={isPopular ? theme.colors.black.S900 : undefined}
      extraTopSpacing={isPopular}
    >
      <HideableCardFlag isVisible={isPopular}>{prefixText}</HideableCardFlag>

      <CardTitle>{title}</CardTitle>

      <DiscountContainer visible={!!discount}>
        <Badge
          color="green"
          style={{
            padding: '2px 8px',
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 500,
            letterSpacing: '1px',
            fontFamily: theme.fonts.family.additional,
          }}
        >
          {discountLabel} {discount}
        </Badge>
      </DiscountContainer>

      <PriceWrapper>
        {priceOld && (
          <CrossedOutPrimaryPrice>{priceOld}</CrossedOutPrimaryPrice>
        )}

        <NewPrice>
          {price}
          <TimePeriod>/ {pricePer}</TimePeriod>
        </NewPrice>
      </PriceWrapper>

      <TotalPriceWrapper>
        {priceTotalPrefixText}
        <CrossedOutPrice>{priceTotalOld}</CrossedOutPrice>
        {priceTotal} {priceTotalPostfixText}
      </TotalPriceWrapper>

      <CtaContainer>
        <StyledCta
          isButtonBlue={true}
          ctaText={buttonText}
          ctaLink={redirectUrlWithPlanId}
          isVisible={true}
          themeName={BUTTON_THEMES.DEFAULT}
          themeType={BUTTON_TYPES.PRIMARY}
          icon={icon}
        />
      </CtaContainer>
      <MoneyBack>
        <IconMoneyBackv2 /> <p>{moneyBackText}</p>
      </MoneyBack>
    </PricingCardContainer>
  );
}

import { graphql, useStaticQuery } from 'gatsby';

export const inquiryFormQuery = () =>
  useStaticQuery(
    graphql`
      query InquiryFormQuery {
        prismicInquiryForm {
          data {
            company_email_label
            company_name_label
            cta_text
            full_name_label
            phone_number_label
            social_media_contact_label
            use_case_description
            use_case_label
            contact_channel_max_characters_error
            invalid_email_error
            must_be_number_error
            phone_max_characters_error
            required_field_error
            use_case_max_characters_error
            bottom_text {
              richText
              text
            }
            body {
              ... on PrismicInquiryFormDataBodyFeatures {
                id
                items {
                  feature_item
                }
                primary {
                  title_text
                  image {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
                slice_type
              }
              ... on PrismicInquiryFormDataBodySuccessModal {
                id
                slice_type
                primary {
                  cta_text
                  description {
                    richText
                    text
                  }
                  icon {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  image_1 {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  image_2 {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  logo {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  title {
                    richText
                    text
                  }
                }
              }
              ... on PrismicInquiryFormDataBodyErrorModal {
                id
                slice_type
                primary {
                  cta_text
                  description {
                    richText
                    text
                  }
                  icon {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  image_1 {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  image_2 {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  logo {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  title {
                    richText
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  );

import React from 'react';
import styled from '@emotion/styled';
import {
  getThemeErrorColor,
  getThemeFontLineHeight,
  getThemeInputFontSizeShape,
  ITheme,
} from 'theme';
import { TEXT_TYPE, TSHIRT_SIZE } from './constants';
import hideable from 'utils/hideable';

export const ErrorText = styled.div<ITheme>((props) => ({
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
  color: getThemeErrorColor(props),
  whiteSpace: 'nowrap',
  height: 0,
}));

interface IInputError {
  error?: string | React.ReactNode;
}

const InputError: React.FC<IInputError> = ({ error }) => (
  <ErrorText>{error}</ErrorText>
);

export default hideable(InputError);

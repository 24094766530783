import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  ITheme,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColumns } from './types';

export const Wrapper = styled.section<ITheme & { background: string }>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: `${getThemeGutter(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const ImageTextColumnContainer = styled.div<ITheme>((props) => ({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  height: 'fit-content',
  'h1, h2, h3': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.M),
    },
  },
}));

export const Columns = styled.ul<ITheme & IColumns>(
  ({ colNumber, columnCount, textAlign, ...props }) => ({
    padding: 0,
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${colNumber}, 1fr)`,
    gap: getThemeGutter(props, TSHIRT_SIZE.L),
    columnGap: columnCount
      ? getThemeGutter(props, TSHIRT_SIZE.S)
      : getThemeGutter(props, TSHIRT_SIZE.L),
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    maxWidth: '1024px',
    listStyle: 'none',
    li: {
      padding: columnCount
        ? `20px 20px ${getThemeGutter(props, TSHIRT_SIZE.S)}`
        : getThemeGutter(props, TSHIRT_SIZE.XXS),
      div: {
        img: {
          maxHeight: columnCount ? '96px' : '160px',
          marginBottom: columnCount
            ? '4px'
            : getThemeGutter(props, TSHIRT_SIZE.XXS),
        },
      },
      a: {
        button: {
          minWidth: columnCount ? 'auto' : '160px',
          padding: columnCount
            ? `${getThemeInputPaddingShape(
                props,
                TSHIRT_SIZE.S
              )} 15px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)}`
            : getThemeInputPaddingShape(props, TSHIRT_SIZE.M),
          marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
          ':hover': {
            padding: columnCount
              ? `9px 14px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`
              : getThemeInputPaddingShape(props, TSHIRT_SIZE.S),
          },
        },
      },
      'p, h2, h3, h4': {
        textAlign: textAlign,
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      columnGap: '20px',
      rowGap: columnCount ? '18px' : getThemeGutter(props, TSHIRT_SIZE.L),
      gridTemplateColumns: columnCount ? '1fr 1fr ' : 'repeat(3, 1fr)',
      gridTemplateRows: columnCount ? '1fr 1fr' : '1fr',
      li: {
        a: {
          button: {
            marginTop: columnCount
              ? getThemeGutter(props, TSHIRT_SIZE.M)
              : getThemeGutter(props, TSHIRT_SIZE.XS),
          },
        },
      },
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: columnCount ? '1fr 1fr ' : '1fr',
      rowGap: columnCount
        ? getThemeGutter(props, TSHIRT_SIZE.L)
        : getThemeGutter(props, TSHIRT_SIZE.XXXL),
      li: {
        padding: columnCount
          ? '40px 20px'
          : getThemeGutter(props, TSHIRT_SIZE.XXS),
      },
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: '1fr',
      li: {
        div: {
          img: {
            marginBottom: columnCount
              ? '28px'
              : getThemeGutter(props, TSHIRT_SIZE.XXS),
          },
        },
        a: {
          maxWidth: columnCount ? 'none' : '100%',
          button: {
            marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
            width: columnCount ? '105px' : '100%',
          },
        },
      },
    },
  })
);

export const ColumnsItem = styled.li({
  paddingBottom: 0,
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  img: {
    maxHeight: '160px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      margin: 0,
      width: 'auto',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      width: '100%',
    },
  },
});

export const Subtitle = styled.div<ITheme>((props) => ({
  width: '100%',
  'h2, h3, h4': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    marginTop: '20px',
    fontSize: themev2.fonts.size.h4,
    fontWeight: 500,
  },
}));
export const ImageTitleContainer = styled.div({
  ...flexColContainerStyles,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
});

export const ImageTitleTextContainer = styled.div({
  ...flexColContainerStyles,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
});

export const Text = styled.div<ITheme>({
  p: {
    minHeight: '65px',
  },
  a: {
    ':hover': {
      textDecoration: 'underline',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    p: {
      height: '126px',
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    p: {
      height: 'auto',
    },
  },
});

export const StyledCta = styled(Cta)({
  marginBottom: 0,
  minWidth: '160px',
  height: '43px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    maxWidth: '160px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '100%',
  },
});

import { SliceMap } from 'types';
import NotFoundHero from 'features/404';

export enum NOT_FOUND_PAGE_SLICES {
  NotFoundHero = '404_hero',
}

export const NotFoundPageSliceMap: SliceMap<NOT_FOUND_PAGE_SLICES> = {
  [NOT_FOUND_PAGE_SLICES.NotFoundHero]: NotFoundHero,
};

import GatsbyImg from 'components/GatsbyImg';
import Link from 'components/GatsbyLink';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import {
  BottomText,
  BottomTextContainer,
  ItemsContainer,
  Card,
  StyledDescription,
  StyledTitle,
  StyledUserName,
  ImageContainer,
} from './styledComponents';
import { ClientReviewsProps } from './types';
import { RichText } from 'components/v2/RichText';

const ClientReviews: React.FC<ClientReviewsProps> = ({
  bottom_logo: bottomLogo,
  bottom_text: bottomText,
  repeatable_zone: repeatableZone,
  tab_name: tabName,
}) => {
  const { type, tags } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: `social-proof-${tabName.text}-review`,
    prefix: type,
  });

  return (
    <>
      <ItemsContainer>
        {repeatableZone.map(
          (
            {
              stars_review: stars,
              title,
              description,
              user_name: userName,
              block_url: blockUrl,
            },
            index
          ) => (
            <Card
              isClickable={!!blockUrl?.url}
              key={title.text}
              data-tracker={trackingSelector({
                context: `${index + 1}`,
              })}
            >
              <Link to={blockUrl?.url}>
                <ImageContainer>
                  <GatsbyImg image={stars} />
                </ImageContainer>
                <StyledTitle>
                  <RichText field={title} />
                </StyledTitle>
                <StyledDescription>
                  <RichText field={description} />
                </StyledDescription>
                <StyledUserName>
                  <RichText field={userName} />
                </StyledUserName>
              </Link>
            </Card>
          )
        )}
      </ItemsContainer>
      <BottomTextContainer>
        <GatsbyImg image={bottomLogo} />
        <BottomText
          data-tracker={trackingSelector({
            context: 'link',
          })}
        >
          <RichText field={bottomText} />
        </BottomText>
      </BottomTextContainer>
    </>
  );
};

export default hideable(ClientReviews);

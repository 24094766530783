import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'features/v2/Header/constants';

export const BANNER_TOP_CLOSED = 'bannerTopClosed';
export const DESKTOP_OFFSET_START = HEADER_HEIGHT_DESKTOP;
export const DESKTOP_SCROLL_POSITION = 102;
export const DEFAULT_DESKTOP_OFFSET = -250;
export const DEFAULT_MOBILE_OFFSET = -300;
export const MOBILE_OFFSET_START = HEADER_HEIGHT_MOBILE;
export const MOBILE_SCROLL_POSITION = 80;

export enum BANNER_TOP_SLICES {
  PageList = 'page_list',
}

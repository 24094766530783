import { useRef, useState, useEffect } from 'react';
import {
  SocialProofTab,
  SocialProofSelectedTabBackground,
  SocialProofToggleButtonContainer,
} from './styledComponents';

const SocialProofToggle = ({
  tabNames,
  selectedTabIndex,
  setSelectedTabIndex,
  trackingSelector,
}) => {
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const [activeTabElement, setActiveTabElement] =
    useState<HTMLDivElement | null>(null);
  const [elementWidth, setElementWidth] = useState<number>();
  const [elementHeight, setElementHeight] = useState<number>();

  useEffect(() => {
    let cumulativeWidth = 0;
    let cumulativeHeight = 0;

    if (!tabsContainerRef.current) {
      setElementWidth(cumulativeWidth);
      setElementHeight(cumulativeHeight);
    }

    const tabs = tabsContainerRef.current!.children;
    for (let i = 0; i < selectedTabIndex; i++) {
      cumulativeWidth += tabs[i].clientWidth;
      cumulativeHeight += tabs[i].clientHeight + 26;
    }

    setElementWidth(cumulativeWidth);
    setElementHeight(cumulativeHeight);
  }, [selectedTabIndex]);

  return (
    <SocialProofToggleButtonContainer ref={tabsContainerRef}>
      {tabNames.map((tab, index) => (
        <SocialProofTab
          data-tracker={trackingSelector({ context: tab.text })}
          key={tab.text}
          isActive={selectedTabIndex === index}
          onClick={() => setSelectedTabIndex(index)}
          ref={(el) => selectedTabIndex === index && setActiveTabElement(el)}
        >
          {tab.text}
        </SocialProofTab>
      ))}
      <SocialProofSelectedTabBackground
        tabsNumber={tabNames.length}
        sx={{
          minWidth: `${activeTabElement?.clientWidth}px !important`,
          transform: {
            base: `translateY(${elementHeight}px)`,
            sm: `translateX(${elementWidth}px)`,
          },
        }}
      />
    </SocialProofToggleButtonContainer>
  );
};

export default SocialProofToggle;

import { PRISMIC_VALUES } from 'constants/common';

export const getPeriod = (billingName: string) => {
  if (billingName === PRISMIC_VALUES.MONTH)
    return PRISMIC_VALUES.PERIOD_PER_MONTH;
  if (billingName === PRISMIC_VALUES.PERIOD_PER_GB)
    return PRISMIC_VALUES.PERIOD_PER_GB;
  if (billingName === PRISMIC_VALUES.NOTHING)
    return PRISMIC_VALUES.PERIOD_EMPTY;
  if (billingName === PRISMIC_VALUES.PER_K_REQ)
    return PRISMIC_VALUES.K_REQ_VALUE;
  if (billingName === PRISMIC_VALUES.PERIOD_PER_IP)
    return PRISMIC_VALUES.PERIOD_PER_IP;
  return PRISMIC_VALUES.PERIOD_PER_MONTH;
};

import {
  IFeatureComparisonTableProps,
  IFeatureComparisonTableRow,
} from './types';

export const getColumns = (
  primaryData: IFeatureComparisonTableProps['primary']
) => {
  const columns = [
    {
      value: primaryData.column_header_1,
      action: {
        label: primaryData.column_cta_text_1,
        url: primaryData.column_cta_link_1.url,
      },
    },
    {
      value: primaryData.column_header_2,
      action: {
        label: primaryData.column_cta_text_2,
        url: primaryData.column_cta_link_2.url,
      },
    },
    {
      value: primaryData.column_header_3,
      action: {
        label: primaryData.column_cta_text_3,
        url: primaryData.column_cta_link_3.url,
      },
    },
    {
      value: primaryData.column_header_4,
      action: {
        label: primaryData.column_cta_text_4,
        url: primaryData.column_cta_link_4.url,
      },
    },
  ];

  return columns.filter((column, index) => {
    if (index === 0) return true;
    return column.value.richText.length > 0;
  });
};

export const getRows = (
  maxColumns: number,
  items: IFeatureComparisonTableRow[]
) => {
  return items.map((item) =>
    [item.cell_1, item.cell_2, item.cell_3, item.cell_4].filter(
      (_, index) => index < maxColumns
    )
  );
};

import React from 'react';
import { Container, StyledDot } from './styledComponents';
import { IDotsContainer } from './types';

const DotsContainer: React.FC<IDotsContainer> = ({
  items,
  isActive,
  setVisibleTestimonial,
  stopAutoPlay,
}) => {
  const setActiveTestimonial = (index) => {
    setVisibleTestimonial(index + 1);
    stopAutoPlay();
  };

  return (
    <Container>
      {items.map((_, index) => (
        <StyledDot
          key={`dot-${index}`}
          isActive={isActive === index + 1}
          onClick={() => setActiveTestimonial(index)}
        />
      ))}
    </Container>
  );
};

export default DotsContainer;

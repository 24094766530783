import styled from '@emotion/styled';
import { PaymentMethods } from 'features/AutomatedPricingCards/components/PaymentMethods';
import PricingCard from './PricingCard';
import Switch from 'components/v2/Switch';
import { useState, useMemo } from 'react';
import { DynamicToggle } from 'components/v2/Toggle';
import { MEDIA_QUERIES } from 'globalStyles';
import { Heading } from 'components/v2/Heading';
import { Text } from 'components/v2/Text';
import { StyledBaseSection } from 'components/Section';
import Features from './Features';
import ExtraPricingCard from './ExtraPricingCard';
import {
  IconArrowRightv2,
  IconCommentUser,
  IconContactSalesv2,
  IconDonev2,
  IconHandHoldingHeart,
} from 'images';
import {
  ispPricingsGlobal,
  tabsIspGlobal,
  ispPricingsChina,
  tabsIspChina,
  featuresIspGlobal,
  featuresIspChina,
} from './ispData';
import {
  dcPricingsGlobal,
  tabsDcGlobal,
  dcPricingsChina,
  tabsDcChina,
  featuresDcGlobal,
  featuresDcChina,
} from './dcData';
import { theme } from '@smartproxy-web/ui';
import { PAYMENT_METHODS } from 'features/AutomatedPricingCards/constants';
import { Product } from 'constants/products';

const textDcGlobal = {
  title: 'Speed and stability at an excellent price',
  description:
    'Get 99.99% uptime and <0.3s average speed with our shared and dedicated datacenter proxies.',
} as const;

const textDcChina = {
  title: '灵活的数据中心代理计划',
  description: '选择适合您的计划。使用不可阻止且稳定的代理取得成功。',
} as const;

const textIspGlobal = {
  title: 'Flexible ISP proxy plans',
  description:
    'Pick a plan that suits you. Get ahead with unblockable and stable proxies.',
} as const;

const textIspChina = {
  title: '灵活的静态住宅（ISP）代理套餐',
  description: '选择适合您的计划。使用不可阻止且稳定的代理取得成功。',
} as const;

const textGlobal = {
  subscriptionText: 'Get subscription',
  contactSalesText: 'Contact sales',
  contactSalesTitleText: 'Need more?',
  contactSalesDescriptionText:
    'Chat with us and we’ll find the best solution for you',
  moneyBackText: '14-DAY MONEY-BACK',
  discountLabel: 'SAVE',
  featuresTitleText: 'With each plan you access',
  mostPopularText: 'MOST POPULAR',
  enterpriseText: 'Enterprise',
  sequentialText: 'Sequential',
  nonSequentialText: 'Non-sequential',
  payGbText: 'Pay / GB',
  sharedPayIpText: 'Pay / IP',
  dedicatedPayIpText: 'Dedicated',
  priceTotalPrefixText: 'Total:',
  priceTotalPostfixText: '+ VAT billed monthly',
  paymentMethodsTitleText: PAYMENT_METHODS.SSL_SECURE_PAYMENT,
  paymentMethodsDescriptionText: PAYMENT_METHODS.SSL_PROTECTED_INFORMATION,
  customPlanButtonText: 'Contact sales',
  customPlanTitleText: 'Custom plan',
  customPlanDescriptionText: 'Contact sales for a custom plan',
  enterpriseExtraText: 'Dedicated Account Manager included',
} as const;

const textChina = {
  subscriptionText: '订购',
  contactSalesText: '联系销售人员',
  contactSalesTitleText: '需要更多讯息?',
  contactSalesDescriptionText: '与我们聊天，我们将为您找到最佳解决方案',
  moneyBackText: '14 天退款选项',
  discountLabel: '节省',
  featuresTitleText: '对于每个计划，您都可以访问',
  mostPopularText: '最热门',
  enterpriseText: '企业',
  sequentialText: '顺序的',
  nonSequentialText: '非顺序',
  payGbText: '共享流量',
  sharedPayIpText: '共享 IP',
  dedicatedPayIpText: '独享 IP ',
  priceTotalPrefixText: '总计:',
  priceTotalPostfixText: '+ 增值税 / 月付',
  paymentMethodsTitleText: 'SSL安全支付',
  paymentMethodsDescriptionText: '您的信息受256位SSL加密保护',
  customPlanButtonText: '联系销售人员',
  customPlanTitleText: '自定义计划',
  customPlanDescriptionText: '联系销售人员以获取自定义计划',
  enterpriseExtraText: '包含专属客户经理',
} as const;

type PlanTypes = 'standard' | 'enterprise';
type PlanSequantial = 'sequential' | 'non-sequential';
type PlansDc = 'payGb' | 'sharedPayIp' | 'dedicatedPayIp';
type PlansIsp = 'staticProxies' | 'dedicatedProxies' | 'payPerIp';
type Plans = PlansDc | PlansIsp;

type NewPricingCardsProps = {
  primary: {
    is_dc: boolean;
    is_global: boolean;
  };
};

// TODO update and improve when moved to storyblok to be dynamic
export default function NewPricingCards({
  primary: { is_dc = true, is_global = true },
}: NewPricingCardsProps) {
  const [planType, setPlanType] = useState<PlanTypes>('standard');
  const [planSequential, setPlanSequential] =
    useState<PlanSequantial>('sequential');
  const [selectedTab, setSelectedTab] = useState<Plans>(() =>
    is_dc ? 'sharedPayIp' : 'dedicatedProxies'
  );

  const {
    tabsDc,
    tabsIsp,
    dcPricings,
    ispPricings,
    featuresDc,
    featuresIsp,
    text,
    redirectUrl,
  } = useMemo(() => {
    return is_global
      ? {
          tabsDc: tabsDcGlobal,
          tabsIsp: tabsIspGlobal,
          dcPricings: dcPricingsGlobal,
          ispPricings: ispPricingsGlobal,
          featuresDc: featuresDcGlobal,
          featuresIsp: featuresIspGlobal,
          text: { ...textGlobal, ...(is_dc ? textDcGlobal : textIspGlobal) },
          redirectUrl: 'https://dashboard.smartproxy.com/register',
        }
      : {
          tabsDc: tabsDcChina,
          tabsIsp: tabsIspChina,
          dcPricings: dcPricingsChina,
          ispPricings: ispPricingsChina,
          featuresDc: featuresDcChina,
          featuresIsp: featuresIspChina,
          text: { ...textChina, ...(is_dc ? textDcChina : textIspChina) },
          redirectUrl: is_dc
            ? 'https://dashboard.smartdaili-china.com/datacenter/pricing'
            : 'https://dashboard.smartdaili-china.com/isp/pricing',
        };
  }, [is_global]);

  const tabs = is_dc ? tabsDc : tabsIsp;
  const selectedPricings = is_dc ? dcPricings : ispPricings;
  const pricings =
    selectedTab === 'dedicatedPayIp'
      ? selectedPricings[selectedTab][`${planSequential}-${planType}`]
      : selectedPricings[selectedTab][planType];
  const filteredPricings = pricings.filter(
    (pricing) => !Boolean(pricing.contactSales) && !Boolean(pricing.customPlan)
  );
  const selectedFeatures = is_dc ? featuresDc : featuresIsp;
  const features = selectedFeatures[selectedTab];

  function hasContactSalesCard() {
    return pricings.some((pricing) => !!pricing.contactSales);
  }

  function hasCustomPlanCard() {
    return pricings.some((pricing) => !!pricing.customPlan);
  }

  function handleEnterpriseToggle() {
    setPlanType(planType === 'standard' ? 'enterprise' : 'standard');
  }

  function handleSequentialToggle() {
    setPlanSequential(
      planSequential === 'sequential' ? 'non-sequential' : 'sequential'
    );
  }

  function handleTabChange(key: Plans) {
    setSelectedTab(key);
    if (!(planType in selectedPricings[key])) {
      setPlanType('standard');
    }
  }

  function checkProductType() {
    const planTypeMap: { [key in Plans]: Product } = {
      payGb: 'datacenter',
      sharedPayIp: 'datacenter_ip',
      dedicatedPayIp: 'dedicated_datacenter',
      staticProxies: 'isp',
      dedicatedProxies: 'dedicated_isp',
    };

    return planTypeMap[selectedTab];
  }

  const isEnterprise = planType === 'enterprise';

  const hasSwitch = Object.keys(selectedPricings[selectedTab]).length === 2;

  return (
    <StyledBaseSection>
      <StyledWrapper>
        <StyledHeadingWrapper>
          <Heading level={2}>{text.title}</Heading>
        </StyledHeadingWrapper>

        <StyledTextWrapper>
          <Text>{text.description}</Text>
        </StyledTextWrapper>

        <StyledTabsWrapper>
          <DynamicToggle
            tabs={tabs}
            // TODO generics in nextjs
            onSelect={(key) => handleTabChange(key as Plans)}
          />
        </StyledTabsWrapper>

        <StyledTogglesWrapper>
          {hasSwitch && (
            <Switch
              isOn={planType === 'enterprise'}
              onToggle={handleEnterpriseToggle}
              label={text.enterpriseText}
            />
          )}
          {selectedTab === 'dedicatedPayIp' && (
            <Switch
              isOn={planSequential === 'non-sequential'}
              onToggle={handleSequentialToggle}
              label={text.nonSequentialText}
            />
          )}
          {planType === 'enterprise' && (
            <StyledExtraEnterpriseText>
              <Text>
                <IconHandHoldingHeart /> {text.enterpriseExtraText}
              </Text>
            </StyledExtraEnterpriseText>
          )}
        </StyledTogglesWrapper>

        <StyledCardsWrapper>
          {filteredPricings.map((pricing, index) => (
            <PricingCard
              key={index}
              {...pricing}
              buttonText={
                isEnterprise ? text.contactSalesText : text.subscriptionText
              }
              discountLabel={text.discountLabel}
              moneyBackText={text.moneyBackText}
              priceTotalPrefixText={text.priceTotalPrefixText}
              priceTotalPostfixText={text.priceTotalPostfixText}
              redirectUrl={isEnterprise ? '#contact-sales' : redirectUrl}
              icon={
                isEnterprise ? <IconContactSalesv2 /> : <IconArrowRightv2 />
              }
              prefixText={text.mostPopularText}
            />
          ))}
          {hasContactSalesCard() && (
            <ExtraPricingCard
              icon={<IconCommentUser />}
              buttonText={text.contactSalesText}
              buttonLink="#contact-sales"
              title={text.contactSalesTitleText}
              description={text.contactSalesDescriptionText}
              moneyBackText={text.moneyBackText}
            />
          )}
          {hasCustomPlanCard() && (
            <ExtraPricingCard
              icon={<IconCommentUser />}
              buttonText={text.customPlanButtonText}
              buttonLink="#contact-sales"
              title={text.customPlanTitleText}
              description={text.customPlanDescriptionText}
              moneyBackText={text.moneyBackText}
            />
          )}
          <Features
            title={text.featuresTitleText}
            checkmark={
              <IconDonev2 style={{ color: theme.colors.blue.default }} />
            }
            features={features}
            inRow={pricings.length % 3 === 0}
          />
        </StyledCardsWrapper>

        <PaymentMethodsSpacing />
        <PaymentMethods
          product={checkProductType()}
          title={text.paymentMethodsTitleText}
          description={text.paymentMethodsDescriptionText}
        />
      </StyledWrapper>
    </StyledBaseSection>
  );
}

const StyledWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledTabsWrapper = styled.div({
  marginBottom: '1.5rem',
});

const StyledTogglesWrapper = styled.div({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '5rem',
  flexWrap: 'wrap',
});

const StyledCardsWrapper = styled.div({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(3, 300px)',
  rowGap: '2.25rem',
  columnGap: '1.5rem',
  justifyContent: 'center',
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: 'repeat(2, 300px)',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
  },
});

const StyledTextWrapper = styled.div({
  marginBottom: '2.25rem',
});

const StyledHeadingWrapper = styled.div({
  marginBottom: '1.5rem',
});

const StyledExtraEnterpriseText = styled.div({
  marginTop: '0.5rem',
  flexBasis: '100%',
  display: 'center',
  justifyContent: 'center',
});

const PaymentMethodsSpacing = styled.div({
  marginTop: '3rem',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: 0,
  },
});

import styled from '@emotion/styled';
import {
  BackgroundWrapper,
  ColContainer,
  flexRowContainerStyles,
} from 'commonStyles';
import Button from 'components/Button';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_FLEX_WRAP,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';

export const BlockContainer = styled(BackgroundWrapper)<ITheme>((props) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
}));

export const TitleWrapper = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  textAlign: TEXT_ALIGN.CENTER,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const ContentContainer = styled(ColContainer)<ITheme>({
  width: '100%',
  maxWidth: '1024px',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    h4: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
});

const ButtonWrapper = styled.div<ITheme>((props) => ({
  ...flexRowContainerStyles,
  paddingTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  flexWrap: CSS_FLEX_WRAP.WRAP,
}));

export const AllTagsWrapper = styled(BackgroundWrapper)<ITheme>((props) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: `0 20px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)}`,
}));

export const StyledButton = styled(Button)({
  marginBottom: '8px',
  padding: '7px 12px',
  fontWeight: 400,
  [MEDIA_QUERIES.TABLET]: {
    maxWidth: 'unset',
    width: 'unset',
  },
  ':hover': {
    padding: '6px 11px',
  },
});

export const StyledAllButton = styled(Button)({
  marginBottom: '8px',
  padding: '7px 12px',
  fontWeight: 400,
  [MEDIA_QUERIES.TABLET]: {
    maxWidth: 'unset',
    width: 'unset',
  },
  ':hover': {
    padding: '7px 12px',
  },
});

export const AllTagsLabel = styled.span<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  lineHeight: '26px',
  fontWeight: 600,
}));

export const HideableButtonWrapper = hideable(ButtonWrapper);

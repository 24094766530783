import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeBackgroundColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const Wrapper = styled.section<ITheme & { background: string }>(
  ({ background, ...props }) => ({
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    width: '100%',
    background,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: `${getThemeGutter(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const HomepageCardsContainer = styled.div<ITheme>((props) => ({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  'h1, h2, h3': {
    textAlign: TEXT_ALIGN.CENTER,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      lineHeight: '48px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    },
  },
}));

export const CardsList = styled.ul<ITheme & { columnNumber: number }>(
  ({ columnNumber, ...props }) => ({
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    maxWidth: '1024px',
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${columnNumber <= 4 ? columnNumber : 4}, 1fr)`,
    listStyle: 'none',
    rowGap: '40px',
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XS),
    padding: 0,
    [MEDIA_QUERIES.TABLET]: {
      maxWidth: '80%',
      gap: getThemeGutter(props, TSHIRT_SIZE.S),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      maxWidth: 'unset',
      justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
      gridTemplateColumns: '70%',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const CardItem = styled.li<ITheme>((props) => ({
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  borderRadius: '12px',
  boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  a: {
    width: '100%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: `${getThemeGutter(props, TSHIRT_SIZE.S)} 20px`,
  },
}));

export const StyledCta = styled(Cta)((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  marginBottom: 0,
  height: '43px',
  marginTop: '15px',
}));

export const StyledPriceContainer = styled.div<ITheme>((props) => ({
  color: getThemeBackgroundColor(props, COLOR_SHADE.DEEP),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  small: {
    display: CSS_DISPLAY.BLOCK,
    marginBottom: '6px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  },
}));

export const StyledPrice = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  height: '21px',
  'p, strong': {
    width: 'auto',
    fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  },
  strong: {
    fontWeight: 600,
  },
  span: {
    margin: '6px 0 0 2px',
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 600,
  },
}));

export const ImageContainer = styled.div<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const CardTitle = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props),
  height: '52px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  strong: {
    fontWeight: 500,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    height: 'auto',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  },
}));

export const BenefitsList = styled.ul({
  padding: 0,
  minHeight: '78px',
});

export const Benefit = styled.li<ITheme>((props) => ({
  marginBottom: '6px',
  padding: 0,
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '16px 1fr',
  columnGap: '8px',
  color: getThemeBackgroundColor(props, COLOR_SHADE.DEEP),
  svg: {
    paddingTop: '3px',
    width: '16px',
  },
  'p, strong, a': {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  },
}));

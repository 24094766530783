import {
  BiggerPlansBanner,
  FaqBlock,
  PrismicImageTabs,
} from '@smartproxy-web/features';
import Breadcrumbs from 'features/Breadcrumbs';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import { Spacing } from 'features/Spacing/Spacing';
import TextImageColumn from 'features/HomePage/TextImageColumns/TextImageColumn';
import { SliceMap } from 'types';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { PrismicTextGrid } from '@smartproxy-web/features';

export enum PROXY_AFFILIATE_PROGRAM_SLICES {
  BiggerPlansBanner = 'bigger_plans',
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  Text_image_column = 'text_image_column_list',
  FooterBanner = 'footer_banner_slice',
  FaqBlock = 'faq_block',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  ImageTabs = 'image_tabs',
}

export const proxyAffiliateProgramliceMap: SliceMap<PROXY_AFFILIATE_PROGRAM_SLICES> =
  {
    [PROXY_AFFILIATE_PROGRAM_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
    [PROXY_AFFILIATE_PROGRAM_SLICES.Breadcrumbs]: Breadcrumbs,
    [PROXY_AFFILIATE_PROGRAM_SLICES.Hero]: Hero,
    [PROXY_AFFILIATE_PROGRAM_SLICES.ImageTextBlock]: ImageTextBlock,
    [PROXY_AFFILIATE_PROGRAM_SLICES.TextGrid]: PrismicTextGrid,
    [PROXY_AFFILIATE_PROGRAM_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
    [PROXY_AFFILIATE_PROGRAM_SLICES.Spacing]: Spacing,
    [PROXY_AFFILIATE_PROGRAM_SLICES.Text_image_column]: TextImageColumn,
    [PROXY_AFFILIATE_PROGRAM_SLICES.FooterBanner]: FooterBanner,
    [PROXY_AFFILIATE_PROGRAM_SLICES.FaqBlock]: FaqBlock,
    [PROXY_AFFILIATE_PROGRAM_SLICES.Herov2]: Herov2,
    [PROXY_AFFILIATE_PROGRAM_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
    [PROXY_AFFILIATE_PROGRAM_SLICES.HeroLogosv2]: HeroLogosv2,
    [PROXY_AFFILIATE_PROGRAM_SLICES.ImageTabs]: PrismicImageTabs,
  };

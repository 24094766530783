import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { GeneratedIdWrapper } from 'features/GeneratedIdWrapper';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  getThemeNeutralColors,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';

export interface IVideo {
  gridTemplateColumns?: string;
  position: string;
}
export const VideoBlockWrapper = styled.div<IColor>(({ background }) => ({
  background,
  padding: '0 20px',
  width: '100%',
  height: '100%',
  paddingBottom: '60px',
}));

export const StyledWrapper = styled.div<IColor>({
  margin: '0 auto',
  maxWidth: '1024px',
  width: '100%',
});

export const VideoTextContainer = styled.div<IVideo & ITheme>(
  ({ gridTemplateColumns, ...props }) => ({
    width: '100%',
    height: '100%',
    display: CSS_DISPLAY.GRID,
    gridAutoColumns: '1fr',
    gap: '48px',
    alignItems: CSS_ALIGN.START,
    gridTemplateColumns,
    gridTemplateRows: 'auto',
    position: CSS_POSITION.RELATIVE,
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
      margin: '0 auto',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      margin: getThemeGutter(props, TSHIRT_SIZE.XXS),
    },
  })
);

export const VideoBlockTitle = styled(GeneratedIdWrapper)<ITheme>((props) => ({
  width: '100%',
  maxWidth: '1024px',
  margin: '0 auto',
  'h1, h2, h3': {
    marginBottom: getThemeGutter(props),
    color: getThemeNeutralColors(props).deep,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: CSS_ALIGN.CENTER,
  },
}));

export const TextComponent = styled.div<ITheme>((props) => ({
  width: '100%',
  height: '100%',
  p: {
    marginBottom: '27px',
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontWeight: 400,
    '&:empty': {
      display: CSS_DISPLAY.NONE,
    },
    ':last-of-type': {
      marginBottom: 0,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: '15px',
    },
  },
  'ol, ul': {
    paddingLeft: '15px',
  },
  'a, strong, em': {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  },
  a: {
    ':hover': {
      textDecoration: 'underline',
    },
  },
  li: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontWeight: 400,
    padding: 0,
    marginBottom: getThemeGutter(props),
  },
}));

export const VideoComponent = styled.div<IVideo>(({ position }) => ({
  width: '100%',
  aspectRatio: '16/9',
  order: position === 'right' ? 1 : -1,
  transition: 'colors 0.5s',
  position: CSS_POSITION.RELATIVE,
  [MEDIA_QUERIES.TABLET]: {
    order: position === 'right' ? -1 : -1,
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
}));

export const LoaderWrapper = styled.div((props) => ({
  width: '100%',
  height: '100%',
  display: CSS_DISPLAY.FLEX,
  zIndex: -1,
  top: 0,
  left: 0,
  position: CSS_POSITION.ABSOLUTE,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,

  p: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
}));

import BadgesBlock from 'features/BadgesBlock';
import Breadcrumbs from 'features/Breadcrumbs';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import Hero from 'features/Hero';
import ProductCards from 'features/ProductCards';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import Title from 'features/IpHeroBlock/Title';
import { SocialProofBlock } from '@smartproxy-web/features';
import { Spacing } from 'features/Spacing/Spacing';
import TableBlock from 'features/TableBlock';
import TableOfContent from 'features/TableOfContent';
import CodeBlock from 'features/Blog/BlogPost/CodeBlock';
import {
  BiggerPlansBanner,
  FaqBlock,
  PrismicBlogImage,
  PrismicImageTabs,
  TextImageDynamic,
} from '@smartproxy-web/features';
import CtaBlock from 'features/CtaBlock';
import { FeatureComparisonTable } from 'features/FeatureComparisonTable';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import PaymentMethod from 'features/PaymentMethod';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import { SliceMap } from 'types';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { PrismicTextGrid, TextBlock } from '@smartproxy-web/features';

export enum PARTNERS_GUIDE_SLICES {
  BadgesBlock = 'badges_block',
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  Spacing = 'spacing',
  ProductCard = 'product_card',
  Title = 'title',
  TableBlock = 'proxy_table',
  TableOfContent = 'table_of_content',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  CodeBlock = 'code_block',
  BiggerPlansBanner = 'bigger_plans',
  FaqBlock = 'faq_block',
  ImageTextColumn = 'image_text_column',
  LocationFlags = 'location_flags',
  PaymentMethod = 'payment_method',
  TextGrid = 'text_grid',
  TextBlock = 'text_block',
  CtaBlock = 'cta_block',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  BlogImage = 'blog_image',
  TextImageDynamic = 'dynamic_text_and_image',
  FeatureComparisonTable = 'feature_comparison_table',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  AutomatedPricingCards = 'automated_pricing_cards',
  ImageTabs = 'image_tabs',
}

export const partnersGuideSliceMap: SliceMap<PARTNERS_GUIDE_SLICES> = {
  [PARTNERS_GUIDE_SLICES.Breadcrumbs]: Breadcrumbs,
  [PARTNERS_GUIDE_SLICES.Hero]: Hero,
  [PARTNERS_GUIDE_SLICES.ImageTextBlock]: ImageTextBlock,
  [PARTNERS_GUIDE_SLICES.Spacing]: Spacing,
  [PARTNERS_GUIDE_SLICES.BadgesBlock]: BadgesBlock,
  [PARTNERS_GUIDE_SLICES.ProductCard]: ProductCards,
  [PARTNERS_GUIDE_SLICES.TableBlock]: TableBlock,
  [PARTNERS_GUIDE_SLICES.TableOfContent]: TableOfContent,
  [PARTNERS_GUIDE_SLICES.Title]: Title,
  [PARTNERS_GUIDE_SLICES.SocialProofBlock]: SocialProofBlock,
  [PARTNERS_GUIDE_SLICES.FeaturedIn]: FeaturedIn,
  [PARTNERS_GUIDE_SLICES.FooterBanner]: FooterBanner,
  [PARTNERS_GUIDE_SLICES.CodeBlock]: CodeBlock,
  [PARTNERS_GUIDE_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [PARTNERS_GUIDE_SLICES.FaqBlock]: FaqBlock,
  [PARTNERS_GUIDE_SLICES.ImageTextColumn]: ImageTextColumn,
  [PARTNERS_GUIDE_SLICES.LocationFlags]: LocationFlags,
  [PARTNERS_GUIDE_SLICES.PaymentMethod]: PaymentMethod,
  [PARTNERS_GUIDE_SLICES.TextGrid]: PrismicTextGrid,
  [PARTNERS_GUIDE_SLICES.TextBlock]: TextBlock,
  [PARTNERS_GUIDE_SLICES.CtaBlock]: CtaBlock,
  [PARTNERS_GUIDE_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [PARTNERS_GUIDE_SLICES.VideoBlock]: VideoBlock,
  [PARTNERS_GUIDE_SLICES.BlogImage]: PrismicBlogImage,
  [PARTNERS_GUIDE_SLICES.TextImageDynamic]: TextImageDynamic,
  [PARTNERS_GUIDE_SLICES.FeatureComparisonTable]: FeatureComparisonTable,
  [PARTNERS_GUIDE_SLICES.Herov2]: Herov2,
  [PARTNERS_GUIDE_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [PARTNERS_GUIDE_SLICES.HeroLogosv2]: HeroLogosv2,
  [PARTNERS_GUIDE_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [PARTNERS_GUIDE_SLICES.ImageTabs]: PrismicImageTabs,
};

import React from 'react';
import FlagsItem from './FlagsItem';
import {
  Content,
  LocationFlagsContainer,
  LocationFlagsList,
  StyledSlice,
} from './styledComponents';
import { ILocationFlags } from './types';
import { SliceHeader } from 'components/v2/Slice';
import { theme } from 'theme-v2/theme';
import CtaComponent from './CtaComponent';

const LocationFlags: React.FC<ILocationFlags> = ({
  primary: {
    title,
    subtitle,
    cta_label: ctaLabel,
    cta_link: ctaLink,
    available_cities: availableCities,
    column_number: columnNumber,
    background_color: backgroundColor,
    flags_alignment: flagsAlignment,
  },
  items,
}) => {
  const getBackgroundColor = (color) =>
    color && color.toLowerCase() === 'gray'
      ? theme.colors.neutral.N02
      : theme.colors.white.default;
  const checkFlagsAlignment = flagsAlignment && flagsAlignment.toLowerCase();
  return (
    <StyledSlice backgroundColor={getBackgroundColor(backgroundColor)}>
      <Content flagsAlignment={checkFlagsAlignment}>
        {title?.text && <SliceHeader title={title} description={subtitle} />}
        <LocationFlagsContainer flagsAlignment={checkFlagsAlignment}>
          <LocationFlagsList
            columnNumber={columnNumber || 6}
            flagsAlignment={checkFlagsAlignment}
          >
            {items.map(({ flag_icon: flagIcon, link, text }, idx) => (
              <FlagsItem key={idx} icon={flagIcon} url={link.url} text={text} />
            ))}
          </LocationFlagsList>
        </LocationFlagsContainer>
        <CtaComponent
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          availableCities={availableCities}
          flagsAlignment={checkFlagsAlignment}
          isVisible={ctaLink.url || availableCities}
        />
      </Content>
    </StyledSlice>
  );
};
export default LocationFlags;

import ArticleThumbnail from 'components/ArticleThumbnail';
import React from 'react';
import {
  StyledArticleThumbnailsTitleWrapper,
  ThumbnailWrapper,
} from './styledComponents';
import { TArticleThumbnails } from './types';
import { RichText } from 'components/v2/RichText';
import { transformBlogPostData } from '@smartproxy-web/features';
import { getArticleUrl } from 'features/AllArticles/utils';

const ArticleThumbnails: React.FC<TArticleThumbnails> = ({
  title,
  thumbnails,
}) => {
  return (
    <>
      <StyledArticleThumbnailsTitleWrapper>
        <RichText field={title} />
      </StyledArticleThumbnailsTitleWrapper>
      <ThumbnailWrapper>
        {thumbnails.map(({ article: { document } }) =>
          !!document ? (
            <ArticleThumbnail
              {...transformBlogPostData(document)}
              key={document.id}
              url={getArticleUrl(document.type, document.tags)}
            />
          ) : null
        )}
      </ThumbnailWrapper>
    </>
  );
};

export default ArticleThumbnails;

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { RichText } from 'components/v2/RichText';
import { getUpdatedAccentColor } from 'features/utils';
import React from 'react';
import RecognisedBy from './RecognisedBy';
import {
  BackgroundWrapper,
  CtaContainer,
  Feature,
  FeaturesContainer,
  HeroContent,
  StyledCta,
  Subtitle,
  TrustpilotContainer,
} from './styledComponents';
import { IHero } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

export const NewLandingHero: React.FC<IHero> = ({
  primary: {
    background,
    title,
    subtitle,
    cta_link: ctaLink,
    cta_text: ctaText,
    button_color: buttonColor,
    image,
    checkmark,
    trustpilot,
    trustpilot_score: trustpilotScore,
    recognised_by: recognisedBy,
  },
  items,
}) => {
  const ctaColor = getUpdatedAccentColor(buttonColor);
  const isIconsAdded = !!recognisedBy?.document;
  const recognisedByData = isIconsAdded && recognisedBy.document.data;
  return (
    <BackgroundWrapper background={background} backgroundImage={image.url}>
      <HeroContent>
        <TrustpilotContainer>
          <GatsbyImg image={trustpilot} loading="eager" />
          <RichText field={trustpilotScore} />
        </TrustpilotContainer>
        <RichText field={title} />
        <Subtitle>
          <RichText field={subtitle} />
        </Subtitle>
        <FeaturesContainer>
          {items.map(({ feature }) => (
            <Feature key={feature.text}>
              <GatsbyImg image={checkmark} loading="eager" />
              <RichText field={feature} />
            </Feature>
          ))}
        </FeaturesContainer>
        <CtaContainer>
          <StyledCta
            ctaText={ctaText}
            ctaLink={ctaLink.url}
            isVisible={ctaText && ctaLink.url}
            themeName={ctaColor?.themeName || BUTTON_THEMES.DEFAULT}
            themeType={ctaColor?.themeType || BUTTON_TYPES.PRIMARY}
          />
        </CtaContainer>
      </HeroContent>
      <RecognisedBy data={recognisedByData} isVisible={isIconsAdded} />
    </BackgroundWrapper>
  );
};

import GatsbyImg from 'components/GatsbyImg';
import { Triangle } from 'images';
import React, { useState } from 'react';
import BarcodeInfo from './BarcodeInfo';
import {
  AdditionalTab,
  HideableTabs,
  IconContainer,
  BarcodeInfoContainer,
} from './styledComponents';
import { IItem } from './types';
import TabItem from './Tab';
import { MAX_PERCENTAGE } from 'constants/common';
import Link from 'components/GatsbyLink';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';

const StickyBarItem: React.FC<IItem> = ({
  barcode1,
  barcode2,
  tab1,
  tab2,
  icon,
  barcode1_code: code1,
  barcode2_code: code2,
  link_field: link,
  additional_small_text: smallText,
  index,
  elementsNumb,
  isOpen = false,
  onToggle,
  onMouseLeave,
}) => {
  const { type, tags } = usePageContext();

  const tabs = [tab1, tab2];
  const barcodes = [
    { barcode: barcode1, code: code1 },
    { barcode: barcode2, code: code2 },
  ];
  const [selectedTab, setSelectTab] = useState<number>(0);
  const [selectedBarcode, setSelectBarcode] = useState<number>(0);

  const selectSmallTab = (tab) => {
    setSelectTab(tab);
    setSelectBarcode(tab);
  };
  const getTopPosition = index * (MAX_PERCENTAGE / elementsNumb);

  const buildIconTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'sticky-contacts-bar',
    context: icon.alt,
  });

  return link.url ? (
    <Link
      to={link.url}
      target={link.target}
      data-tracker={buildIconTrackingSelector({ type: 'link' })}
    >
      <IconContainer>
        <GatsbyImg image={icon} />
      </IconContainer>
    </Link>
  ) : (
    <div>
      <IconContainer
        onClick={onToggle}
        data-tracker={buildIconTrackingSelector()}
      >
        <GatsbyImg image={icon} />
      </IconContainer>
      <AdditionalTab topPosition={getTopPosition} isOpen={isOpen}>
        <Triangle />
        <HideableTabs isVisible={tab1.text || tab2.text}>
          {tabs.map((tab, index) => (
            <TabItem
              key={tab.text}
              isVisible={tab.text}
              text={tab}
              onSelect={() => selectSmallTab(index)}
              selected={selectedTab === index}
            />
          ))}
        </HideableTabs>
        {barcodes.map(({ code, barcode }, index) => (
          <BarcodeInfoContainer
            isVisible={selectedBarcode === index}
            key={code.text}
          >
            <BarcodeInfo
              onMouseLeave={onMouseLeave}
              barcode={barcode}
              smallText={smallText}
              barCode1={code}
            />
          </BarcodeInfoContainer>
        ))}
      </AdditionalTab>
    </div>
  );
};

export default StickyBarItem;

import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const TextColumnsContainer = styled.div<ITheme>({
  width: '100%',
  padding: '0 20px',
});

export const TextColumnWrapper = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
}));

export const Title = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  h3: {
    fontSize: '22px',
  },
  h4: {
    fontSize: '20px',
  },
}));

export const StyledListContainer = styled.ul<ITheme>((props) => ({
  listStyle: 'none',
  padding: 0,
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    columnGap: getThemeGutter(props),
  },
}));

export const ListItem = styled.li<ITheme>((props) => ({
  padding: 0,
  width: '100%',
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
}));

export const SmallTitle = styled.div<ITheme>((props) => ({
  'h2, h3, h4': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    lineHeight: '26px',
    wordBreak: 'break-word',
  },
}));

export const Content = styled.div<ITheme>((props) => ({
  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    wordBreak: 'break-word',
  },
}));

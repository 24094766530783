import styled from '@emotion/styled';
import { mediaQueries } from '@smartproxy-web/ui';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import {
  CSS_ALIGN,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
} from 'theme';

export const StyledCtaBlock = styled(Cta)((props) => ({
  alignSelf: CSS_ALIGN.FLEX_START,
  margin: 0,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  minWidth: '180px',
  minHeight: '42px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  textAlign: TEXT_ALIGN.CENTER,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)} 15px`,
  lineHeight: '20px',
  ':hover': {
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)} 15px`,
  },
}));

export const CtaBlockContainer = styled.div<{
  background: string;
}>(({ background }) => ({
  width: '100%',
  background,
  padding: '16px 20px',
  [mediaQueries.sm]: {
    padding: '24px 40px',
  },
}));

export const CtaBlockWrapper = styled.div<{
  isCentered: boolean;
}>(({ isCentered }) => ({
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  textAlign: isCentered ? 'center' : 'left',
  [mediaQueries.md]: {
    width: 'auto',
    maxWidth: 'fit-content',
  },
  [mediaQueries.sm]: {
    width: 'auto',
    maxWidth: '1024px',
  },
}));

import Breadcrumbs from 'features/Breadcrumbs';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import { Spacing } from 'features/Spacing/Spacing';
import { SliceMap } from 'types';
import BadgesBlock from 'features/BadgesBlock';
import ProductCardBlock from 'features/HomePage/ProductCard/ProductCardBlock';
import TableBlock from 'features/TableBlock';
import TableOfContent from 'features/TableOfContent';
import Title from 'features/IpHeroBlock/Title';
import FooterBanner from 'features/FooterBanner';
import SocialProofBlock from 'features/SocialProof';
import FeaturedIn from 'features/FeaturedIn';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { TabbedCodeBlock, PrismicImageTabs } from '@smartproxy-web/features';

export enum SMARTDAILI_QUICK_START_GUIDE_SLICES {
  BadgesBlock = 'badges_block',
  Breadcrumbs = 'breadcrumbs',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  Spacing = 'spacing',
  ProductCardsBlock = 'product_cards_block',
  Title = 'title',
  TableBlock = 'proxy_table',
  TableOfContent = 'table_of_content',
  FooterBanner = 'footer_banner_slice',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  TabbedCodeBlock = 'tabbed_code_block',
  ImageTabs = 'image_tabs',
}

export const smartdailiQuickStartGuideMap: SliceMap<SMARTDAILI_QUICK_START_GUIDE_SLICES> =
  {
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.Breadcrumbs]: Breadcrumbs,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.Hero]: Hero,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.ImageTextBlock]: ImageTextBlock,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.Spacing]: Spacing,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.BadgesBlock]: BadgesBlock,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.ProductCardsBlock]: ProductCardBlock,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.TableBlock]: TableBlock,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.TableOfContent]: TableOfContent,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.Title]: Title,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.FooterBanner]: FooterBanner,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.SocialProofBlock]: SocialProofBlock,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.FeaturedIn]: FeaturedIn,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.Herov2]: Herov2,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.HeroLogosv2]: HeroLogosv2,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.TabbedCodeBlock]: TabbedCodeBlock,
    [SMARTDAILI_QUICK_START_GUIDE_SLICES.ImageTabs]: PrismicImageTabs,
  };

import { TSHIRT_SIZE } from 'components/constants';
import {
  HideableLabelContainer,
  Label,
} from 'components/Input/styledComponents';
import InputError from 'components/InputError';
import React, { ChangeEvent } from 'react';
import {
  Container,
  HideableDescription,
  StyledTextarea,
} from './styledComponents';
import { ITextarea } from './types';

const Textarea: React.FC<ITextarea> = ({
  onChange,
  error,
  label,
  description,
  placeholder,
  value,
  height = TSHIRT_SIZE.L,
  ...rest
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    onChange && onChange(event.target.value);
  };

  return (
    <Container>
      <HideableLabelContainer isVisible={!!label}>
        <Label>{label}</Label>
      </HideableLabelContainer>
      <StyledTextarea
        hasError={!!error}
        height={height}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
      <InputError error={error} isVisible={error} />
      <HideableDescription isVisible={description}>
        {description}
      </HideableDescription>
    </Container>
  );
};

export default Textarea;

import styled from '@emotion/styled';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeGutter,
  getThemeInputWidthShape,
  ITheme,
} from 'theme';
import { IGutterProps } from 'components/utils';
import ArticleThumbnails from '.';
import { IColor } from 'types';
import { MEDIA_QUERIES } from 'globalStyles';
import { flexColContainerStyles } from 'commonStyles';
import { themev2 } from 'theme-v2';
import { TSHIRT_SIZE } from 'components/constants';

const PHONE_PORTRAIT_SMALL = '@media (max-width: 400px)'; //breakpoint needed for mobile so author name, release date, reading time would fall to second line nicely

export const StyledBackgroundWrapper = styled.div<IColor & ITheme>(
  ({ background }) => ({
    background,
    width: '100%',
    padding: `${themev2.spacings.sectionSpacing} 20px`,
  })
);

export const RelatedArticlesContainer = styled.div<ITheme>(() => ({
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
}));

export const StyledArticleThumbnailsTitleWrapper = styled.div({
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  marginBottom: themev2.spacings.L,
  [MEDIA_QUERIES.TABLET]: {
    textAlign: 'left',
  },
});
export const StyledArticleThumbnails = styled(ArticleThumbnails)({
  ...flexColContainerStyles,
});

export const ThumbnailWrapper = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  gridAutoColumns: '1fr',
  gridTemplateColumns: `1fr 1fr`,
  gridTemplateRows: 'auto',
  gridGap: '36px',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXS),
  [MEDIA_QUERIES.TABLET]: {
    height: '100%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: `1fr`,
    gridTemplateRows: 'auto auto',
  },
}));

export const ArticleContainer = styled.div<IGutterProps & ITheme>((props) => ({
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.FLEX_START,
  gridAutoColumns: '1fr',
  columnGap: '24px',
  rowGap: '16px',
  gridTemplateColumns: '30% 1fr',
  gridTemplateRows: 'auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '20% 1fr',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
    height: 'auto',
    rowGap: '16px',
  },
  [PHONE_PORTRAIT_SMALL]: {
    marginBottom: '36px',
  },
}));

export const BlogArticleImageWrapper = styled.div({
  div: {
    maxHeight: '200px',
    img: {
      borderRadius: '12px',
      objectFit: 'cover !important',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      maxHeight: '168px',
    },
  },
});

export const ArticleImageWrapper = styled.div({
  display: CSS_DISPLAY.INLINE_BLOCK,
  height: '100%',
  width: 'auto',
  verticalAlign: 'middle',
  div: {
    img: {
      display: CSS_DISPLAY.INLINE_BLOCK,
      aspectRatio: '1/1',
      borderRadius: '6px',
      overflow: 'hidden',
      objectFit: 'cover',
    },
  },
});

export const ArticleTitleShorter = styled.h3({
  fontSize: themev2.fonts.size.h4,
  lineHeight: themev2.fonts.lineHeight.h4,
  fontWeight: '700',
  fontFamily: themev2.fonts.family.additional,
  marginTop: '16px',
  marginBottom: '8px',
  color: themev2.colors.neutral.N99,
  WebkitLineClamp: '2',
});

export const ArticleDescription = styled.p<IGutterProps & ITheme>((props) => ({
  color: themev2.colors.neutral.N80,
  fontSize: themev2.fonts.size.p_medium,
  fontFamily: themev2.fonts.family.additional,
  lineHeight: themev2.fonts.lineHeight.p_small,
  marginBottom: '16px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: 'vertical',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
}));

export const StyledArticleInfo = styled.div({
  color: themev2.colors.neutral.N50,
  height: '36px',
  fontSize: themev2.fonts.size.p_small,
  svg: {
    height: '18px',
    color: themev2.colors.neutral.N50,
    verticalAlign: 'middle',
  },
});

export const StyledPostTags = styled.div({
  marginTop: '22px',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
});

export const StyledAuthorWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

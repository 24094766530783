import styled from '@emotion/styled';
import Link from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import {
  COMPACT_HEADER_MEDIA_QUERY,
  HEADER_CONTENTS_MAX_WIDTH,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
  MENU_MAX_HEIGHT,
} from './constants';

export const StyledHeader = styled.header({
  position: 'fixed',
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 40px',
  height: HEADER_HEIGHT_DESKTOP,
  background: themev2.colors.gray.S900,
  zIndex: 10,

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: '0 20px',
    height: HEADER_HEIGHT_MOBILE,
  },
});

export const StyledHeaderContents = styled.div({
  height: '100%',
  width: '100%',
  maxWidth: HEADER_CONTENTS_MAX_WIDTH,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 36,
  margin: '0 auto',
});

export const StyledHeaderLogoLink = styled(Link)({
  lineHeight: 1,

  [COMPACT_HEADER_MEDIA_QUERY]: {
    div: {
      width: '140px',
      div: {
        height: '100%',
        width: '100%',
      },
    },
  },
});

export const StyledHeaderLogoWrapper = styled.div({
  width: 163,
  '> * , > * > *': {
    height: '100%',
    width: '100%',
  },
});

export const StyledHeaderNavToggle = styled.div({
  display: 'none',
  [COMPACT_HEADER_MEDIA_QUERY]: {
    display: 'block',
    button: {
      paddingRight: 0,
    },
    svg: {
      path: {
        fill: themev2.colors.gray.S300,
      },
    },
  },
});

export const StyledMenuContainer = styled.div<{ selected: boolean }>(
  ({ selected }) => ({
    flexDirection: 'column',
    background: themev2.colors.black.transparent,
    backdropFilter: 'blur(10px)',
    width: '100%',
    maxHeight: selected ? MENU_MAX_HEIGHT : 0,
    transition: 'maxHeight 0.3s ease',
    transitionDelay: '0.15s',
    overflow: 'hidden',
    isolation: 'isolate',
    zIndex: 2,
    position: 'fixed',
    right: 0,
    top: HEADER_HEIGHT_DESKTOP,
  })
);

export const StyledMenuWrapper = styled.div<{ selected?: boolean }>(
  ({ selected }) => ({
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: HEADER_HEIGHT_DESKTOP,
    width: '100%',
    '-webkit-backdrop-filter': 'blur(4px)',
    backdropFilter: 'blur(4px)',
    display: selected ? 'flex' : 'none',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      top: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(4px)',
    },
  })
);

export const StyledMenuWrapperMobile = styled.div<{ selected?: boolean }>(
  ({ selected }) => ({
    position: 'fixed',
    right: 0,
    top: HEADER_HEIGHT_DESKTOP,
    width: '100%',
    height: selected ? '100%' : 0,
    backdropFilter: 'blur(4px)',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      top: 0,
      width: '100%',
      height: '100%',
      '-webkit-backdrop-filter': 'blur(4px)',
      backdropFilter: 'blur(4px)',
    },
  })
);

export const CloseIconContainer = styled.div({
  position: 'absolute',
  right: 40,
  cursor: 'pointer',
  svg: {
    path: {
      fill: themev2.colors.gray.S300,
    },
  },
});

export const StyledHeaderLogo = styled.div({
  lineHeight: 1,
  [COMPACT_HEADER_MEDIA_QUERY]: {
    div: {
      width: '140px',
      div: {
        height: '100%',
        width: '100%',
      },
    },
  },
});

import { TSHIRT_SIZE } from 'components/constants';
import SelectInput from 'components/Form/SelectInput';
import TextareaInput from 'components/Form/TextareaInput';
import TextInput from 'components/Form/TextInput';
import {
  chatServicesOptions,
  FIELD_NAMES,
  initialValues,
  phoneCodeOptions,
} from 'features/InquiryForm/constants';
import {
  IFormState,
  IInquiryFormAuthMessages,
} from 'features/InquiryForm/types';
import { getValidationSchema } from 'features/InquiryForm/utils';
import { Formik } from 'formik';
import React from 'react';
import Button from './Button';
import { FORM_FIELD_LABELS } from './constants';
import {
  StyledForm,
  StyledRowContainer,
  StyledTextInput,
} from './styledComponents';
import { IForm } from './types';

const Form: React.FC<IForm & IInquiryFormAuthMessages> = ({
  onSubmit,
  isLoading,
  buttonColor,
  ...rest
}) => {
  const validationSchema = getValidationSchema(rest);

  return (
    <Formik<IFormState>
      initialValues={{
        ...initialValues,
        countryCode: phoneCodeOptions[0].value,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(context) => (
        <StyledForm id="hero-contact-sales-form">
          <StyledRowContainer>
            <TextInput
              name={FIELD_NAMES.fullName}
              label={FORM_FIELD_LABELS.FULL_NAME}
              context={context}
            />
            <TextInput
              name={FIELD_NAMES.companyName}
              label={FORM_FIELD_LABELS.COMPANY_NAME}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              gutterBot={TSHIRT_SIZE.XXS}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <TextInput
              name={FIELD_NAMES.companyEmail}
              label={FORM_FIELD_LABELS.COMPANY_EMAIL}
              context={context}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <SelectInput
              name={FIELD_NAMES.contactChannel}
              label={FORM_FIELD_LABELS.SOCIAL_MEDIA}
              options={chatServicesOptions}
              context={context}
            />
            <StyledTextInput
              name={FIELD_NAMES.contactChannelId}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <SelectInput
              isSearchable
              name={FIELD_NAMES.countryCode}
              label={FORM_FIELD_LABELS.PHONE_NUMBER}
              options={phoneCodeOptions}
              context={context}
            />
            <StyledTextInput
              name={FIELD_NAMES.companyPhone}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
            />
          </StyledRowContainer>
          <TextareaInput
            name={FIELD_NAMES.useCase}
            label={FORM_FIELD_LABELS.USE_CASE}
            context={context}
          />
          <Button
            isLoading={isLoading}
            isDisabled={!(context.isValid && context.dirty) || isLoading}
            buttonColor={buttonColor}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;

import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  getThemeGutter,
  getThemeInputWidthShape,
  ITheme,
  CSS_FLEX_DIRECTION,
  CSS_ALIGN,
  CSS_JUSTIFY_CONTENT,
  getThemeNeutralColors,
  getThemeMainColor,
  MAIN_COLOR,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeInputPaddingShape,
} from 'theme';
import { IColor } from 'types';

export const BackgroundContainer = styled.section<ITheme & IColor>(
  ({ background, ...props }) => ({
    ...flexColContainerStyles,
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    width: '100%',
    background,
  })
);

export const CountriesListWrapepr = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  maxWidth: '1024px',
  margin: '0 auto',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  '& div:last-of-type': {
    div: {
      marginBottom: 0,
    },
  },
}));

export const Title = styled.div<ITheme & { position: boolean }>(
  ({ position, ...props }) => ({
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    'h1,h2,h3,h4,h5,h6': {
      color: getThemeNeutralColors(props).deep,
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      textAlign: position ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    },
  })
);

export const CountriesListItem = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  'h2,h3,h4,h5,h6': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    fontSize: '22px',
    fontWeight: 500,
    textAlign: TEXT_ALIGN.LEFT,
    a: {
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

export const CountriesListContainer = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
  },
}));

export const CountriesList = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  p: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
    lineHeight: '36px',
    a: {
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

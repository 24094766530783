import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { DOM_STATE, TSHIRT_SIZE } from 'components/constants';
import Link from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import { IconCloseBlack, IconSearch } from 'images';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeBackgroundColor,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputFontSizeShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';

export const StyledInput = styled.input<ITheme>((props) => ({
  width: '100%',
  borderWidth: '1px 0 1px 1px',
  border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
  padding: `5px ${getThemeGutter(props, TSHIRT_SIZE.S)} `,
  display: CSS_DISPLAY.FLEX,
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.XL),
  borderRight: '1px',
  borderRadius: `${getThemeInputBorderShape(
    props,
    BORDER_PROPS.RADIUS
  )} 0 0 ${getThemeInputBorderShape(props, BORDER_PROPS.RADIUS)}`,
  color: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  '::placeholder': {
    color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
  },
}));

export const IconContainer = styled.div<ITheme>((props) => ({
  cursor: 'pointer',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_JUSTIFY_CONTENT.CENTER,
  padding: `5px ${getThemeGutter(props, TSHIRT_SIZE.S)} 5px 0`,
  backgroundColor: getThemeMainColor(props, MAIN_COLOR.WHITE),
  border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
  borderWidth: '1px 1px 1px 0',
  borderRadius: `0 ${getThemeInputBorderShape(
    props,
    BORDER_PROPS.RADIUS
  )} ${getThemeInputBorderShape(props, BORDER_PROPS.RADIUS)} 0`,
  [DOM_STATE.FOCUS]: {
    outline: 'none',
  },
}));

export const InputContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  height: '62px',
  alignItems: CSS_ALIGN.STRETCH,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  backgroundColor: getThemeBackgroundColor(props, COLOR_SHADE.MAIN),
  [MEDIA_QUERIES.TABLET]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const SearchBarContainer = styled.div({
  position: CSS_POSITION.RELATIVE,
  [MEDIA_QUERIES.SMALL_MOBILE]: {
    width: '100%',
  },
});

const List = styled.div<ITheme>((props) => ({
  position: CSS_POSITION.ABSOLUTE,
  width: '100%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  zIndex: 1,
  maxHeight: '200px',
  overflow: 'auto',
  top: '50px',
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
  borderWidth: '0 1px 1px 1px',
  borderRadius: `0 0 ${getThemeInputBorderShape(
    props,
    BORDER_PROPS.RADIUS
  )} ${getThemeInputBorderShape(props, BORDER_PROPS.RADIUS)}`,
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  ...flexColContainerStyles,
}));

export const EmptyOption = hideable(
  styled.div<ITheme>((props) => ({
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
    color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
    fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.L),
  }))
);

export const EmptyText = styled.span<ITheme>((props) => ({
  marginLeft: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const Option = styled(Link)<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.XL),
  ':hover': {
    textDecoration: 'underline',
  },
}));

export const HideableList = hideable(List);
export const SearchIcon = hideable(IconSearch);
export const Closeicon = hideable(IconCloseBlack);

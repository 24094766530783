import styled from '@emotion/styled';
import { BackgroundWrapper } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import hideable from 'utils/hideable';

export const StyledBackgroundWrapper = styled(BackgroundWrapper)((props) => ({
  margin: 0,
  maxWidth: 'unset',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  'h1, h2, h3': {
    marginBottom: themev2.spacings.sectionSpacing,
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: themev2.spacings.L,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: '32px',
    },
  },
}));

export const TitleWrapper = styled.div((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  textAlign: TEXT_ALIGN.CENTER,
}));

export const CardsContainer = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: getThemeGutter(props),
  rowGap: '16px',
  maxWidth: '1024px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
  },
}));

export const Card = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateRows: '32px auto',
  rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  boxShadow: `0 0 ${getThemeGutter(props, TSHIRT_SIZE.S)} 0 rgba(0,0,0,.1)`,
  borderRadius: '12px',
  ul: {
    paddingLeft: getThemeGutter(props),
  },
  li: {
    padding: 0,
  },
  'p, strong, em ': {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  },
}));
export const CardDescription = styled.div((props) => ({
  'p, strong, em': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    marginBottom: 0,
    lineHeight: '20px',
  },
  ul: {
    li: {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
      marginBottom: 0,
      lineHeight: '20px',
    },
  },
}));

export const CardTitle = styled.div<{ isImage: boolean }>(
  ({ isImage, ...props }) => ({
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: isImage ? '32px auto' : 'auto',
    alignItems: CSS_ALIGN.CENTER,
    'h4, h5, h6': {
      fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
      textAlign: TEXT_ALIGN.LEFT,
      marginLeft: isImage ? getThemeGutter(props) : 0,
      fontWeight: 500,
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
    },
  })
);

export const CtaContainer = styled.div({
  fontSize: themev2.fonts.size.p_small,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      width: 'unset',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
    a: {
      width: '100%',
    },
  },
});
export const StyledCta = styled(Cta)((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  height: '42px',
  padding: `0 15px`,
  margin: 0,
  ':hover': {
    padding: `0 15px`,
  },
}));

export const HideableCardDescription = hideable(CardDescription);

import * as React from 'react';
import { IBlogPosts } from './types';
import {
  StyledBackgroundWrapper,
  RelatedArticlesContainer,
  ThumbnailWrapper,
} from '../../ArticleThumbnails/styledComponents';
import {
  PRISMIC_VALUES,
  SECTION_NAMES,
  TEXT_LENGTH_VALUES,
} from 'constants/common';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { TemplatePaths } from 'paths';
import { ArticlesList } from 'features/AllArticles/ArticlesList';
import { CtaContainer } from '../Blog/styledComponents';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';
import { generateSectionsId } from 'features/utils';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';

const HomepageBlogPosts: React.FC<IBlogPosts> = ({
  primary: { title1: title, background },
  articles,
}) => {
  const firstFourArticles = articles.slice(0, 4);
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.RELATED_ARTICLES,
    prefix: type,
  });

  return (
    <StyledBackgroundWrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.RELATED_ARTICLES,
        tags,
        type
      )}
    >
      <RelatedArticlesContainer>
        <Box sx={{ textAlign: 'center', marginBottom: '60px' }}>
          <RichText field={title} />
        </Box>
        <ThumbnailWrapper>
          <ArticlesList
            articles={firstFourArticles}
            descriptionLength={TEXT_LENGTH_VALUES.HOMEPAGE_BLOG}
            isHomepage={true}
          />
        </ThumbnailWrapper>
        <CtaContainer>
          <Cta
            ctaLink={TemplatePaths.blog}
            ctaText={PRISMIC_VALUES.VISIT_BLOG}
            isVisible
            themeType={BUTTON_TYPES.SECONDARY}
            themeName={BUTTON_THEMES.FOLLOWING}
            gutterBot={TSHIRT_SIZE.XXS}
            gutterTop={TSHIRT_SIZE.L}
            data-tracker={trackingSelector({
              context: 'cta',
            })}
          />
        </CtaContainer>
      </RelatedArticlesContainer>
    </StyledBackgroundWrapper>
  );
};

export default HomepageBlogPosts;

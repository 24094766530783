import React from 'react';
import { BottomNavMenuListItem } from './BottomNavMenuListItem';
import { StyledBottomNavMenuList } from './styledComponents';
import { BottomNavMenuListProps } from './types';
import { transformUrl } from '../../helpers';

export const BottomNavMenuList: React.FC<BottomNavMenuListProps> = ({
  links,
  buildTrackingId,
}) => {
  const reversedLinks = [...links].reverse();
  return (
    <StyledBottomNavMenuList>
      {reversedLinks.map((link, index) => (
        <BottomNavMenuListItem
          index={index}
          link={link}
          key={link.label}
          trackingId={buildTrackingId({
            context: transformUrl(link.url!),
            type: 'link',
          })}
        />
      ))}
    </StyledBottomNavMenuList>
  );
};

import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { IBackground } from 'features/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledHeroLogosWrapper = styled.div<ITheme & IBackground>(
  ({ isBackgroundDark, ...props }) => ({
    background: isBackgroundDark
      ? themev2.colors.black.S900
      : getThemeMainColor(props),
    overflow: 'hidden',
    padding: `60px 20px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)}`,
    height: 'auto',
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    p: {
      color: isBackgroundDark
        ? getThemeMainColor(props)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
    },
    [String(StyledHeroLogosContainer)]: {
      opacity: isBackgroundDark ? 0.3 : 1,
    },
    [MEDIA_QUERIES.TABLET]: {
      paddingTop: '38px',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      paddingBottom: '60px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      paddingTop: '86px',
    },
  })
);

export const StyledHeroLogosContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  gridRowStart: 'span 1',
  gridRowEnd: 'span 1',
  gridColumnStart: 'span 2',
  gridColumnEnd: 'span 2',
  clear: 'left',
  p: {
    margin: 0,
    lineHeight: '20px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 400,
    fontFamily: getThemeFontFamily(props),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageLogos = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  alignItems: CSS_ALIGN.CENTER,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_AROUND,
  },
}));

export const LogoContainer = styled.div<ITheme>({
  display: CSS_DISPLAY.INLINE,
  div: {
    width: 'auto',
    height: '100%',
  },
  [MEDIA_QUERIES.TABLET]: {
    margin: '0 20px 20px',
  },
});

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Link from 'components/GatsbyLink';
import Spinner from 'components/Spinner';
import React from 'react';
import { FORM_FIELD_LABELS } from './constants';
import { BottomText, StyledButton } from './styledComponents';
import { IButton } from './types';

const Button: React.FC<IButton> = ({ isDisabled, isLoading, buttonColor }) => (
  <>
    <BottomText>
      Smartproxy将处理您的数据以管理询价并提供服务详情。更多信息请访问我们的
      <Link to="/legal"> 隐私政策。</Link>
    </BottomText>
    <StyledButton
      fontSize={TSHIRT_SIZE.L}
      gutterTop={TSHIRT_SIZE.XXS}
      gutterBot={TSHIRT_SIZE.XXS}
      themeName={BUTTON_THEMES[buttonColor] || BUTTON_THEMES.CONVERSION}
      themeType={BUTTON_TYPES.PRIMARY}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {!isLoading ? FORM_FIELD_LABELS.BUTTON_TEXT : <Spinner />}
    </StyledButton>
  </>
);

export default Button;

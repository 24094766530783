import { RichText } from 'components/v2/RichText';
import React from 'react';
import hideable from 'utils/hideable';
import { Tab } from './styledComponents';
import { ITabItem } from './types';

const TabItem: React.FC<ITabItem> = ({ text, selected, onSelect }) => (
  <Tab selected={selected} onClick={onSelect}>
    <RichText field={text} />
  </Tab>
);

export default hideable(TabItem);

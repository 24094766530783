import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { BaseSection } from 'components/Section';
import { richTextElements, richTextParagraphElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const Wrapper = styled(BaseSection)<IColor>(
  ({ background, ...props }) => ({
    background,
    div: {
      'h2, h3, h4': {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      },
    },
  })
);

export const ProductCardsWrapper = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  rowGap: '16px',
  marginTop: '32px',
  [MEDIA_QUERIES.TABLET]: {
    columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: ' 1fr',
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const ProductCardContainer = styled.div<
  IColor & {
    recommended: boolean;
  }
>(({ recommended, color, ...props }) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  position: CSS_POSITION.RELATIVE,
  alignItems: CSS_ALIGN.FLEX_START,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  width: '100%',
  padding: '24px',
  background: getThemeMainColor(props),
  border: recommended ? `2px solid ${color}` : 'none',
  boxShadow: '0 0 16px 0 rgba(0,0,0,.1)',
  borderRadius: '12px',
  a: {
    width: 'auto',
    minWidth: '180px',
    height: '42px',
    paddingTop: '11px',
    button: {
      marginTop: '-15px',
      padding: 0,
      width: 'auto',
      minWidth: '180px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      textAlign: TEXT_ALIGN.CENTER,
      fontFamily: getThemeFontFamily(props),
      fontWeight: 500,
      ':hover': {
        padding: 0,
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      width: '100%',
      button: {
        width: '100%',
      },
    },
  },
}));

export const ProductCardHeader = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const ProductCardIconContainer = styled.div((props) => ({
  width: '56px',
  height: '56px',
  minHeight: '56px',
  minWidth: '56px',
  marginRight: getThemeGutter(props),
  div: {
    verticalAlign: 'middle',
    width: 'auto',
    height: 'auto',
  },
}));

export const ProductCardInfoContainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  [richTextElements]: {
    marginBottom: '6px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    lineHeight: '26px',
    fontWeight: 600,
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
}));

export const ProductCardFeaturesContainer = styled.div<{ color: string }>(
  ({ color, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: '100%',
    div: {
      display: CSS_DISPLAY.FLEX,
      marginBottom: getThemeGutter(props),
      p: {
        marginBottom: 0,
        marginLeft: '10px',
        fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
        lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
        fontWeight: 500,
        color: getThemeMainColor(props, MAIN_COLOR.BLACK),
        [richTextParagraphElements]: {
          fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
          lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
          fontWeight: 500,
        },
      },
      svg: {
        maxWidth: '14px',
        maxHeight: '17px',
        path: {
          stroke: color,
        },
      },
    },
  })
);

export const ProductCardInfoPrice = styled.div<IColor>(
  ({ color, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    color: color,
    lineHeight: '14px',
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    span: {
      marginLeft: '6px',
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
  })
);

export const ProductCardDescription = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  margin: `${getThemeGutter(props)} 0 ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
  [richTextElements]: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    margin: `0 auto ${getThemeGutter(props)}`,
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    ':last-child': {
      margin: 0,
    },
    strong: {
      fontWeight: 600,
    },
  },
  'ul, ol': {
    paddingLeft: '15px',
    li: {
      padding: 0,
      fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
      margin: `0 auto ${getThemeGutter(props)}`,
      ':last-of-type': {
        margin: 0,
      },
    },
  },
}));

export const RecommendedContainer = styled.div<IColor>(
  ({ color, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    position: CSS_POSITION.ABSOLUTE,
    height: '26px',
    minWidth: '100px',
    padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
    borderRadius: '6px',
    background: color,
    cursor: 'pointer',
    transform: 'translate(-50%, 0%)',
    top: -13,
    left: '50%',
    fontFamily: getThemeFontFamily(props),
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontWeight: 500,
    svg: {
      marginLeft: getThemeGutter(props),
    },
  })
);

export const ProductCardTooltip = styled.div((props) => ({
  display: CSS_DISPLAY.BLOCK,
  position: CSS_POSITION.ABSOLUTE,
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -150%)',
  width: 'max-content',
  maxWidth: '100%',
  background: '#415365',
  borderRadius: '6px',
  margin: 0,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  padding: `6px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
  lineHeight: '20px',
  color: getThemeMainColor(props),
  textAlign: TEXT_ALIGN.CENTER,
  overflowWrap: 'break-word',
}));

const TitlesContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  [richTextElements]: {
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
  'h1, h2, h3:first-of-type': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  h3: {
    fontWeight: 500,
    fontSize: themev2.fonts.size.h4,
  },
  p: {
    textAlign: 'center',
  },
}));
export const CrossedOutPrice = hideable(
  styled.span({
    textDecoration: 'line-through',
  })
);
export const HideableTitles = hideable(TitlesContainer);

import { RichText } from 'components/v2/RichText';
import React from 'react';
import { IHomepagePricingCards } from '../types';
import Card from './CardItem';
import { CardsList, HomepageCardsContainer, Wrapper } from './styledComponents';

const HomepagePricingCards: React.FC<IHomepagePricingCards> = ({
  primary: { background, title_field: title, column_number: columnNumber },
  items,
}) => (
  <Wrapper background={background}>
    <HomepageCardsContainer>
      <RichText field={title} />
      <CardsList columnNumber={columnNumber || 4}>
        {items.map((item, idx) => (
          <Card key={idx} {...item} />
        ))}
      </CardsList>
    </HomepageCardsContainer>
  </Wrapper>
);

export default HomepagePricingCards;

import { IconHandSwipe } from 'images';
import React from 'react';
import { PrismicTextField } from 'types';
import { TableBlockColumn } from './Column';
import {
  StyledWrapper,
  TableContainer,
  TabledWrapper,
} from './styledComponents';
import { getTableHeader, groupTableData } from './utils';

export interface IProxyCell {
  cell_background: string;
  cell_value: PrismicTextField;
  column_name: PrismicTextField;
  highlight_color: string;
}
export interface ITable {
  primary: {
    background: string;
    header_background: string;
  };
  items: IProxyCell[];
  isTablet: boolean;
}

const TableBlock: React.FC<ITable> = ({
  primary: { background, header_background: headerBackground },
  items,
}) => {
  const tableData = groupTableData(items);
  const tableHeader = getTableHeader(items);
  const noOfRows = tableData[Object.keys(tableData)[0]].length + 1;

  return (
    <TabledWrapper background={background}>
      <StyledWrapper>
        <TableContainer noOfCols={tableHeader.length} noOfRows={noOfRows}>
          {tableHeader.map((item, index) => (
            <TableBlockColumn
              headerBackground={headerBackground}
              header={item}
              data={tableData[item]}
              key={index}
              isLast={tableHeader.length - 1 === index}
            />
          ))}
        </TableContainer>
      </StyledWrapper>
      <IconHandSwipe />
    </TabledWrapper>
  );
};
export default TableBlock;

import {
  IconFacebookSocial,
  IconRedditSocial,
  IconTwitterSocial,
  IconLinkedinSocial,
} from 'images';
import React from 'react';
import SocialButton from './SocialButton';
import Link from 'components/GatsbyLink';
import { ISocialButtonsBarProps } from './types';
import {
  StyledContainer,
  StyledTopicsLink,
  StyledTopicsText,
  HideableTextContainer,
  ButtonContainer,
  Wrapper,
  HideableAdditionalText,
} from './styledComponents';
import { getComma, getTopicLabel } from 'features/ContentTags/utils';
import { PRISMIC_VALUES, SOCIAL_BUTTON_TYPE } from 'constants/common';
import { RichText } from 'components/v2/RichText';

export const SocialButtonsBar: React.FC<ISocialButtonsBarProps> = ({
  primary: {
    is_fb_visible: isFbVisible,
    is_twitter_visible: isTwitterVisible,
    is_reddit_visible: isRedditVisible,
    background,
    is_icons_centered: isCentered,
    is_linkedin_visible: isLinkedinVisible,
    text,
  },
  items,
}) => {
  const isTopicsVisible = !!items?.length;

  return (
    <Wrapper background={background}>
      <StyledContainer>
        <HideableTextContainer isVisible={isTopicsVisible}>
          <StyledTopicsText>{PRISMIC_VALUES.TOPICS}</StyledTopicsText>
          {items?.map(({ topic: { document } }, index) => (
            <>
              {' '}
              <Link key={index} to={`/${document?.tags?.join('/')}`}>
                <StyledTopicsLink>{getTopicLabel(document)}</StyledTopicsLink>
              </Link>{' '}
              {getComma(index, items.length)}
            </>
          ))}
        </HideableTextContainer>
        <ButtonContainer isCentered={isCentered}>
          <HideableAdditionalText isVisible={!!text}>
            <RichText field={text} />
          </HideableAdditionalText>
          <SocialButton
            isVisible={isTwitterVisible}
            type={SOCIAL_BUTTON_TYPE.TWITTER}
          >
            <IconTwitterSocial />
          </SocialButton>
          <SocialButton
            isVisible={isFbVisible}
            type={SOCIAL_BUTTON_TYPE.FACEBOOK}
          >
            <IconFacebookSocial />
          </SocialButton>
          <SocialButton
            isVisible={isRedditVisible}
            type={SOCIAL_BUTTON_TYPE.REDDIT}
          >
            <IconRedditSocial />
          </SocialButton>
          <SocialButton
            isVisible={isLinkedinVisible}
            type={SOCIAL_BUTTON_TYPE.LINKEDIN}
          >
            <IconLinkedinSocial />
          </SocialButton>
        </ButtonContainer>
      </StyledContainer>
    </Wrapper>
  );
};

import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import Actions from './components/Actions';
import NavItem from './components/NavItem';
import { FOOTER_SLICES, IFooter } from './constants';
import FooterBottom from './FooterBottom';
import { footerQuery } from './query';
import {
  StyledFooter,
  StyledFooterContainer,
  StyledNavItemWrapper,
  FooterDisclaimer,
  FooterDisclaimerInner,
} from './styledComponents';
import { extractPrismicFooterQRCodes } from './helpers';

export const Footer: React.FC<IFooter> = ({ isDealsOrLpPage }) => {
  const { tags, type } = usePageContext();
  const { prismicFooterV2 } = footerQuery();
  const navItems = prismicFooterV2.data.body.filter(
    (slice) => slice.slice_type === FOOTER_SLICES.NAV
  );

  const qrCodes = extractPrismicFooterQRCodes(prismicFooterV2.data.body);

  return (
    <StyledFooter
      data-section-id={generateSectionsId(SECTION_NAMES.FOOTER, tags, type)}
    >
      <StyledFooterContainer isDealsOrLpPage={isDealsOrLpPage}>
        <Actions qrCodes={qrCodes} isDealsOrLpPage={isDealsOrLpPage} />
        {!isDealsOrLpPage && (
          <StyledNavItemWrapper>
            {navItems.map(({ primary: { category }, items }) => (
              <NavItem category={category} items={items} key={category.text} />
            ))}
          </StyledNavItemWrapper>
        )}
      </StyledFooterContainer>
      {!isDealsOrLpPage && (
        <FooterDisclaimer>
          <FooterDisclaimerInner>
            <p>
              Smartdaili全球代理仅提供IP服务用于正规商业用途,
              用户使用Smartdaili从事的任何行为均不代表Smartdaili的意志和观点,
              与Smartdaili的立场无关。严禁用户使用Smartdaili全球代理从事任何违法犯罪行为例如获取用户信息或者非法内容。产生的相关责任用户自负,
              对此Smartdaili不承担任何法律责任。
            </p>
          </FooterDisclaimerInner>
        </FooterDisclaimer>
      )}
      <FooterBottom />
    </StyledFooter>
  );
};

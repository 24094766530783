import Link from 'components/GatsbyLink';
import { RichText } from 'components/v2/RichText';
import { initBuildTrackingSelector } from 'utils/page';
import { List, StyledNavItemCategoryWrapper } from '../styledComponents';

const NavItem = ({ category, items }) => {
  const trackingSelector = initBuildTrackingSelector({
    section: `footer-${category.text}`,
  });

  return (
    <div>
      <StyledNavItemCategoryWrapper>
        <RichText field={category} />
      </StyledNavItemCategoryWrapper>
      <List>
        {items.map(({ link_label: linkLabel, link }) => (
          <li key={linkLabel.text}>
            <Link
              to={link.url}
              data-tracker={trackingSelector({ context: linkLabel.text })}
            >
              {linkLabel.text}
            </Link>
          </li>
        ))}
      </List>
    </div>
  );
};

export default NavItem;

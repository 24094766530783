import React, { useEffect, useState } from 'react';
import { CONTACT_SALES_URL_HASH } from 'constants/common';
import { useScrollBlock } from 'hooks/useScrollBlock';
import { useHash } from 'hooks/useHash';
import {
  AnimationWrapper,
  Overlay,
} from 'features/InquiryForm/styledComponents';
import { inquiryFormQuery } from './query';
import { getSlice, mapFormDataToPostData } from 'features/InquiryForm/utils';
import {
  IFormState,
  IInquiryFormConfirmation,
  TForkmikHelpers,
} from 'features/InquiryForm/types';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import { apiEndpoints } from 'apiEndpoints';
import { INQUIRY_FORM_SLICES } from 'features/InquiryForm/constants';
import InquiryFormContent from 'features/InquiryForm/components/InquiryFormContent';
import InquiryFormFeatures from 'features/InquiryForm/components/InquiryFormFeatures';
import InquiryFormWrapper from 'features/InquiryForm/components/InquiryFormWrapper';
import InquiryFormConfirmation from 'features/InquiryForm/components/InquiryFormConfirmation';

export enum InquiryPage {
  FORM = 'form_page',
  SUCCESS = 'success_page',
  ERROR = 'error_page',
}

const InquiryForm: React.FC = () => {
  const { ErrorModal, Features, SuccessModal } = INQUIRY_FORM_SLICES;
  const { isLoading, isError, isSuccess, request, reset } = useApi(
    HTTP_METHOD.POST,
    apiEndpoints.inquiryFormSubmit
  );
  const { clearHash, hash } = useHash();

  const { body, ...rest } = inquiryFormQuery().prismicInquiryForm.data;
  const successSlice = getSlice<IInquiryFormConfirmation>(body, SuccessModal);
  const errorSlice = getSlice<IInquiryFormConfirmation>(body, ErrorModal);
  const featuresSlice = getSlice(body, Features);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(hash === CONTACT_SALES_URL_HASH);
  }, [hash]);
  const isFormVisible = !isError && !isSuccess;

  const handleClose = () => {
    reset();
    clearHash();
  };

  const handleSubmit = (values: IFormState, actions: TForkmikHelpers) => {
    request(mapFormDataToPostData(values), () => {
      actions.resetForm();
    });

    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer?.push({
      event: 'ContactSalesForm_submitted',
    });
  };

  useScrollBlock(isModalVisible);

  return (
    <Overlay isVisible={isModalVisible}>
      <AnimationWrapper>
        <InquiryFormContent isVisible={isFormVisible} onClose={handleClose}>
          <InquiryFormFeatures {...featuresSlice} isVisible={isFormVisible} />
          <InquiryFormWrapper
            {...rest}
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        </InquiryFormContent>
        <InquiryFormContent isVisible={isSuccess} onClose={handleClose}>
          <InquiryFormConfirmation {...successSlice} onClick={handleClose} />
        </InquiryFormContent>
        <InquiryFormContent isVisible={isError} onClose={handleClose}>
          <InquiryFormConfirmation {...errorSlice} onClick={reset} />
        </InquiryFormContent>
      </AnimationWrapper>
    </Overlay>
  );
};

export default InquiryForm;

import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Link from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  ACCENT_COLOR,
  CSS_ALIGN,
  CSS_DISPLAY,
  getThemeAccentColor,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const StyledToc = styled.section<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  padding: `${getThemeGutter(props, TSHIRT_SIZE.M)} 20px 0`,
  width: '100%',
  ...flexColContainerStyles,
  p: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '1024px',
    fontWeight: 600,
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXXL),
  },
}));

export const StyledTocUl = styled.ul<ITheme & { rows: number }>(
  ({ rows, ...props }) => ({
    width: '100%',
    margin: '0 auto',
    maxWidth: '1024px',
    paddingLeft: '0',
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: `repeat(${Math.ceil(rows / 2)}, auto)`,
    columnGap: '60px',
    gridAutoFlow: 'column',
    paddingBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    [MEDIA_QUERIES.TABLET]: {
      columnGap: '24px',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: 'auto',
      gridAutoFlow: 'row',
    },
  })
);

export const TocListItem = styled.li<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '8px auto',
  columnGap: '12px',
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: '10px',
  padding: '0',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
}));

export const StyledLink = styled(Link)<ITheme>((props) => ({
  color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

import { LinkButton } from 'components/v2/LinkButton';
import React from 'react';
import { PrismicInputField, PrismicTextField } from 'types';
import { ITrackingSelectorProps } from 'utils/page';
import { StyledCtasWrapper } from '../styledComponents';

interface CtasProps {
  primaryCtaLink?: PrismicInputField;
  primaryCtaLabel?: PrismicTextField;
  secondaryCtaLink?: PrismicInputField;
  secondaryCtaLabel?: PrismicTextField;
  buildCtaTrackingSelector: (props?: ITrackingSelectorProps) => string;
}

export const Ctas: React.FC<CtasProps> = ({
  primaryCtaLink,
  primaryCtaLabel,
  secondaryCtaLink,
  secondaryCtaLabel,
  buildCtaTrackingSelector,
}) => (
  <StyledCtasWrapper>
    <LinkButton
      to={primaryCtaLink?.url}
      variant="filled"
      color="green"
      data-tracker={buildCtaTrackingSelector({
        context: 'primary',
      })}
    >
      {primaryCtaLabel?.text}
    </LinkButton>
    {!!secondaryCtaLabel?.text && !!secondaryCtaLink?.url && (
      <LinkButton
        to={secondaryCtaLink.url}
        variant="outlined"
        color="white"
        data-tracker={buildCtaTrackingSelector({
          context: 'secondary',
        })}
      >
        {secondaryCtaLabel.text}
      </LinkButton>
    )}
  </StyledCtasWrapper>
);

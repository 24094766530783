import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  PrismicCarouselBlock,
  GettingStarted,
  FaqBlock,
  PrismicImageTabs,
  PrismicBlogImage,
  PromoBanner,
  TextBlock,
} from '@smartproxy-web/features';
import { BlogParent } from '@smartproxy-web/features';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import ImageTextColumn from 'features/ImageTextColumn';
import LocationFlags from 'features/LocationFlags';
import LpHero from 'features/LpHero';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import TrustpilotReviews from 'features/TruspilotReviews';
import VideoBlock from 'features/v2/VideoSlice';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import { SliceMap } from 'types';
import TableBlock from 'features/TableBlock';
import ColumnsTextBlock from 'features/Blog/BlogPost/ColumnsTextBlock';
import ColumnLinks from 'features/ColumnsLinks';
import Title from 'features/Title';
import HeroInquiryForm from 'features/HeroInquiryForm';
import HomepagePricingCards from 'features/HomePage/PricingCards';
import UseCasesBlock from 'features/UseCasesBlock';
import RecognisedByBlock from 'features/RecognisedByLogos';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import ExpandablePricingCards from 'features/ExpandablePricingCards';
import ProductCards from 'features/ProductCards';
import SideImageTextBlock from 'features/Blog/BlogPost/SideImageTextBlock';
import IconListBlock from 'features/IconListBlock';
import ProxyListCountries from 'features/ProxyListCountries';
import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import { FeatureComparisonTable } from 'features/FeatureComparisonTable';
import ProductCardBlock from 'features/HomePage/ProductCard/ProductCardBlock';
import TargetsList from 'features/TargetsList';
import TextImageColumn from 'features/HomePage/TextImageColumns/TextImageColumn';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { HeroLpPPC } from 'features/v2/HeroLpPPC';
import { HeaderLpDeals } from 'features/v2/HeaderLpDeals';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { ProductCardsComponent } from 'features/v2/ProductCards';
import { QuoteComponent } from '@smartproxy-web/features';
import {
  ComparisonTable,
  PrismicTextGrid,
  TabbedCodeBlock,
  TextImageDynamic,
} from '@smartproxy-web/features';

export enum LP_SLICES {
  BadgesBlock = 'badges_block',
  Breadcrumbs = 'breadcrumbs',
  FaqBlock = 'faq_block',
  LocationFlags = 'location_flags',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  CtaBlock = 'cta_block',
  BiggerPlansBanner = 'bigger_plans',
  Hero = 'hero_block',
  ImageTextBlock = 'image_text_block',
  ImageTextColumn = 'image_text_column',
  PaymentMethod = 'payment_method',
  TrustpilotReviews = 'trustpilot_reviews',
  LpHero = 'lp_hero',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  TableBlock = 'proxy_table',
  TextBlock = 'text_block',
  ColumnsTextBlock = 'column_text',
  ColumnLinks = 'column_links',
  Title = 'title',
  BlogImage = 'blog_image',
  HeroWithContactSales = 'hero_with_contact_sales_form',
  HomepagePricingCards = 'homepage_pricing_cards',
  UseCasesBlock = 'use_case_block',
  RecognisedByBlock = 'recognised_by_block',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  ExpandableProductsPricingOverview = 'expandable_pricing_overview',
  ProductCard = 'product_card',
  SideImageTextBlock = 'side_image_text_block',
  IconListBlock = 'icon_list_block',
  ProxyListCountries = 'proxy_list_countries',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  TextImageDynamic = 'dynamic_text_and_image',
  FeatureComparisonTable = 'feature_comparison_table',
  ProductCardsBlock = 'product_cards_block',
  TargetsList = 'targets_list',
  Text_image_column = 'text_image_column_list',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  HeroLpPPC = 'ppc_lp_hero',
  HeaderLpDeals = 'lp_deals_header',
  AutomatedPricingCards = 'automated_pricing_cards',
  ProductCardsComponent = 'new_product_cards',
  ComparisonTable = 'comparison_table',
  PrismicCarouselBlock = 'carousel_block',
  GettingStarted = 'getting_started',
  BlogParent = 'blog_parent_block',
  QuoteComponent = 'quote_component',
  TabbedCodeBlock = 'tabbed_code_block',
  ImageTabs = 'image_tabs',
  PromoBanner = 'promo_banner',
}

export const lpSliceMap: SliceMap<LP_SLICES> = {
  [LP_SLICES.BadgesBlock]: BadgesBlock,
  [LP_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [LP_SLICES.Hero]: Hero,
  [LP_SLICES.ImageTextBlock]: ImageTextBlock,
  [LP_SLICES.ImageTextColumn]: ImageTextColumn,
  [LP_SLICES.PaymentMethod]: PaymentMethod,
  [LP_SLICES.TrustpilotReviews]: TrustpilotReviews,
  [LP_SLICES.Breadcrumbs]: Breadcrumbs,
  [LP_SLICES.CtaBlock]: CtaBlock,
  [LP_SLICES.FaqBlock]: FaqBlock,
  [LP_SLICES.LocationFlags]: LocationFlags,
  [LP_SLICES.Spacing]: Spacing,
  [LP_SLICES.TextGrid]: PrismicTextGrid,
  [LP_SLICES.LpHero]: LpHero,
  [LP_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [LP_SLICES.VideoBlock]: VideoBlock,
  [LP_SLICES.TableBlock]: TableBlock,
  [LP_SLICES.TextBlock]: TextBlock,
  [LP_SLICES.ColumnsTextBlock]: ColumnsTextBlock,
  [LP_SLICES.ColumnLinks]: ColumnLinks,
  [LP_SLICES.Title]: Title,
  [LP_SLICES.BlogImage]: PrismicBlogImage,
  [LP_SLICES.HeroWithContactSales]: HeroInquiryForm,
  [LP_SLICES.HomepagePricingCards]: HomepagePricingCards,
  [LP_SLICES.UseCasesBlock]: UseCasesBlock,
  [LP_SLICES.RecognisedByBlock]: RecognisedByBlock,
  [LP_SLICES.SocialProofBlock]: SocialProofBlock,
  [LP_SLICES.FeaturedIn]: FeaturedIn,
  [LP_SLICES.FooterBanner]: FooterBanner,
  [LP_SLICES.ExpandableProductsPricingOverview]: ExpandablePricingCards,
  [LP_SLICES.ProductCard]: ProductCards,
  [LP_SLICES.SideImageTextBlock]: SideImageTextBlock,
  [LP_SLICES.IconListBlock]: IconListBlock,
  [LP_SLICES.ProxyListCountries]: ProxyListCountries,
  [LP_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
  [LP_SLICES.TextImageDynamic]: TextImageDynamic,
  [LP_SLICES.FeatureComparisonTable]: FeatureComparisonTable,
  [LP_SLICES.ProductCardsBlock]: ProductCardBlock,
  [LP_SLICES.TargetsList]: TargetsList,
  [LP_SLICES.Text_image_column]: TextImageColumn,
  [LP_SLICES.Herov2]: Herov2,
  [LP_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [LP_SLICES.HeroLogosv2]: HeroLogosv2,
  [LP_SLICES.HeroLpPPC]: HeroLpPPC,
  [LP_SLICES.HeaderLpDeals]: HeaderLpDeals,
  [LP_SLICES.ProductCardsComponent]: ProductCardsComponent,
  [LP_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [LP_SLICES.ComparisonTable]: ComparisonTable,
  [LP_SLICES.PrismicCarouselBlock]: PrismicCarouselBlock,
  [LP_SLICES.GettingStarted]: GettingStarted,
  [LP_SLICES.BlogParent]: BlogParent,
  [LP_SLICES.QuoteComponent]: QuoteComponent,
  [LP_SLICES.TabbedCodeBlock]: TabbedCodeBlock,
  [LP_SLICES.ImageTabs]: PrismicImageTabs,
  [LP_SLICES.PromoBanner]: PromoBanner,
};

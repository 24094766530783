import {
  BUTTON_OPACITY_START_VALUE,
  PAGE_SCROLL_POSITION,
} from 'constants/common';

export const onScroll = (
  isVisible: (item) => void,
  opacity: (item) => void,
  scrollPosition: number = PAGE_SCROLL_POSITION
) => {
  const itemFade = window.pageYOffset > scrollPosition;
  isVisible(itemFade);
  const position =
    (window.pageYOffset - scrollPosition) / scrollPosition +
    BUTTON_OPACITY_START_VALUE;
  const itemOpacity = position < 1 ? position : 1;
  opacity(itemOpacity);
};

import GatsbyImg from 'components/GatsbyImg';
import { generateSectionsId, slugify } from 'features/utils';
import { useScrollBlock } from 'hooks/useScrollBlock';
import { IconVideoModal, IconZoomWhite } from 'images';
import React, { useState } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import ImageModal from './ImageModal';
import { Content, IconWrapper, ImageWrapper } from './styledComponents';
import TextBlock from './TextBlock';
import { IImageTextBlock } from './types';
import { Slice, SliceHeader } from 'components/v2/Slice';
import styled from '@emotion/styled';
import { transformUrl } from '../Header/helpers';
import { usePageContext } from 'contexts/PageContext';

const StyledSlice = styled(Slice)({
  '& + &': {
    paddingTop: 0,
  },
});

const ImageTextBlock: React.FC<IImageTextBlock> = ({
  primary: {
    background,
    description,
    image,
    title,
    sub_title: subTitle,
    cta_link: ctaLink,
    cta_text: ctaText,
    is_image_right: isImageRight,
    image_zoom_modal: imageZoomModal,
    review_image: reviewImage,
    review_bottom_text: reviewBottomText,
    review_description: reviewDescription,
    is_background_dark: isBackgroundDark,
    video_embed: video,
    is_split_equally: isSplitEqually,
    is_icon_visible: isIconVisible,
    text_below_title: textBelowTitle,
  },
  items = [],
}) => {
  const { tags, type } = usePageContext();
  const [isModalVisible, setModalVisibility] = useState(false);
  useScrollBlock(isModalVisible);

  const sectionName =
    slugify(title?.text || '') || slugify(subTitle?.text || '');
  const sectionId = generateSectionsId(sectionName, tags, type);

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'text-image-slice',
    prefix: type,
  });

  const showModal = () => {
    setModalVisibility((isModalVisible) => !isModalVisible);
  };

  return (
    <StyledSlice
      backgroundColor={background}
      isDark={isBackgroundDark}
      id={sectionId}
    >
      {title?.text && (
        <SliceHeader title={title} description={textBelowTitle} />
      )}
      <Content isOnTheRight={isImageRight} isSplitEqually={isSplitEqually}>
        <ImageWrapper
          isOnTheRight={isImageRight}
          onClick={imageZoomModal || !!video ? showModal : () => null}
          isZoomIconVisible={imageZoomModal || !!video}
          isSplitEqually={isSplitEqually}
        >
          {!!video && (
            <IconWrapper>
              <IconVideoModal />
            </IconWrapper>
          )}
          {imageZoomModal && !video && (
            <IconWrapper>{<IconZoomWhite />}</IconWrapper>
          )}
          {image && <GatsbyImg image={image} />}
        </ImageWrapper>
        <TextBlock
          isDark={isBackgroundDark}
          description={description}
          subTitle={subTitle}
          ctaLink={ctaLink}
          ctaText={ctaText}
          items={items}
          reviewBottomText={reviewBottomText}
          reviewImage={reviewImage}
          reviewDescription={reviewDescription}
          isIconVisible={isIconVisible}
          selector={trackingSelector({
            context: transformUrl(ctaLink?.url || ''),
            type: 'cta',
          })}
        />
      </Content>
      <ImageModal
        image={image}
        isVisible={isModalVisible}
        onClose={showModal}
        video={video}
        closeBtnTrackingId={trackingSelector({
          section: title?.text || subTitle?.text,
          context: 'close-video',
        })}
      />
    </StyledSlice>
  );
};

export default ImageTextBlock;

import { RichText } from 'components/v2/RichText';
import React from 'react';
import hideable from 'utils/hideable';
import {
  BackgroundContainer,
  LinksListItem,
  LinksList,
} from './styledComponents';
import { IColumnLinks } from './types';

const HideableRichText = hideable(RichText);

const ColumnLinks: React.FC<IColumnLinks> = ({
  primary: { background, number_of_columns: columns },
  items,
}) => (
  <BackgroundContainer background={background}>
    <LinksList columns={columns}>
      {items.map(({ title, text }, idx) => (
        <LinksListItem key={idx} isTitleVisible={!!title.text}>
          <HideableRichText field={title} isVisible={title.text} />
          <RichText field={text} />
        </LinksListItem>
      ))}
    </LinksList>
  </BackgroundContainer>
);

export default ColumnLinks;

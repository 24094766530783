import { RichText } from 'components/v2/RichText';
import { GOOGLE_SCHEMA, SCHEMA_LINKS } from 'constants/common';
import React from 'react';
import FaqListItem from './FaqListItem';
import { BackgroundWrapper, FaqList } from './styledComponents';
import { IFaqText } from './types';

const FaqText: React.FC<IFaqText> = ({
  primary: { background, main_title: title },
  items,
}) => (
  <BackgroundWrapper background={background}>
    <RichText field={title} />
    <FaqList
      className={GOOGLE_SCHEMA.schemaMarkup}
      itemScope
      itemType={SCHEMA_LINKS.FAQ}
    >
      {items.map(({ subtitle, text }, idx) => (
        <FaqListItem subtitle={subtitle} text={text} key={idx} />
      ))}
    </FaqList>
  </BackgroundWrapper>
);

export default FaqText;

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TEXT_TYPE } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  ColumnsItem,
  ImageTitleContainer,
  ImageTitleTextContainer,
  StyledCta,
  Subtitle,
  Text,
} from './styledComponents';
import { IItem } from './types';

const Item: React.FC<IItem> = ({
  icon,
  subtitle,
  text,
  cta_label: ctaLabel,
  link,
}) => (
  <ColumnsItem>
    <ImageTitleTextContainer>
      <ImageTitleContainer>
        <GatsbyImg image={icon} />
        <Subtitle>
          <RichText field={subtitle} />
        </Subtitle>
      </ImageTitleContainer>
      <Text>
        <RichText field={text} />
      </Text>
    </ImageTitleTextContainer>
    <StyledCta
      isVisible={ctaLabel.text && link.url}
      ctaText={ctaLabel.text}
      ctaLink={link.url}
      themeName={BUTTON_THEMES.IMPORTANT}
      themeType={BUTTON_TYPES.SECONDARY}
      fontSize={TEXT_TYPE.P1}
    />
  </ColumnsItem>
);

export default Item;

import Hero from 'features/Blog';
import { SliceMap } from 'types';
import Breadcrumbs from 'features/Breadcrumbs';
import { AllArticles } from 'features/AllArticles';
import ContentTags from 'features/ContentTags';
import FooterBanner from 'features/FooterBanner';
import {
  KHArticles,
  KnowledgeHubHero,
  PrismicImageTabs,
} from '@smartproxy-web/features';

export enum BLOG_PAGE_SLICES {
  AllArticles = 'all_articles',
  Breadcrumbs = 'breadcrumbs',
  ContentTags = 'content_tags',
  Hero = 'hero_block',
  FooterBanner = 'footer_banner_slice',
  KHArticles = 'kh_articles',
  KnowledgeHubHero = 'knowledge_hub_hero',
  ImageTabs = 'image_tabs',
}

export const BlogPageSliceMap: SliceMap<BLOG_PAGE_SLICES> = {
  [BLOG_PAGE_SLICES.AllArticles]: AllArticles,
  [BLOG_PAGE_SLICES.Breadcrumbs]: Breadcrumbs,
  [BLOG_PAGE_SLICES.ContentTags]: ContentTags,
  [BLOG_PAGE_SLICES.Hero]: Hero,
  [BLOG_PAGE_SLICES.FooterBanner]: FooterBanner,
  [BLOG_PAGE_SLICES.KHArticles]: KHArticles,
  [BLOG_PAGE_SLICES.KnowledgeHubHero]: KnowledgeHubHero,
  [BLOG_PAGE_SLICES.ImageTabs]: PrismicImageTabs,
};

import {
  getThemeGutter,
  ITheme,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeFontFamily,
  FONT_FAMILY,
  getThemeFontSize,
  getThemeFontLineHeight,
  CSS_ALIGN,
  CSS_JUSTIFY_CONTENT,
  getThemeNeutralColors,
  getThemeMainColor,
  MAIN_COLOR,
  getThemeAccentColor,
  ACCENT_COLOR,
} from 'theme';
import { IColor } from 'types';
import { MEDIA_QUERIES } from 'globalStyles';
import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE, TEXT_ALIGN } from 'components/constants';

export const ProductCardWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: `${getThemeGutter(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const ProductCardContainer = styled.div(() => ({
  display: CSS_DISPLAY.FLEX,
  maxWidth: '1024px',
  margin: '0 auto',
  width: '100%',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  [MEDIA_QUERIES.TABLET]: {
    flexWrap: CSS_FLEX_WRAP.NOWRAP,
  },
}));

export const ProductCardTitle = styled.div<ITheme>((props) => ({
  'h1, h2, h3': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    textAlign: CSS_ALIGN.CENTER,
  },
}));

export const ProductCards = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  maxWidth: '1024px',
  gridAutoColumns: '1fr',
  gap: getThemeGutter(props, TSHIRT_SIZE.XL),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  gridTemplateColumns: '1fr 1fr',
  [MEDIA_QUERIES.TABLET]: {
    rowGap: '30px',
    marginBottom: '30px',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    rowGap: getThemeGutter(props, TSHIRT_SIZE.XL),
  },
}));

export const ProductCard = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  padding: '20px 20px 25px',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  borderRadius: '12px',
  backgroundColor: getThemeMainColor(props),
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 10%)',
  [MEDIA_QUERIES.TABLET]: {
    width: '80%',
    marginBottom: '30px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: '40px 20px',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  },
}));

export const ProductImageWrapper = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  height: 'auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  maxHeight: '120px',
  maxWidth: '96px',
  div: {
    img: {
      maxHeight: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      maxWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      height: 'auto',
      width: 'auto',
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const ProductTextContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  paddingLeft: '20px',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    paddingLeft: getThemeGutter(props, TSHIRT_SIZE.XXS),
    alignItems: CSS_ALIGN.CENTER,
  },
}));

export const ProductTitle = styled.div<ITheme>((props) => ({
  'h2, h3, h4': {
    display: CSS_DISPLAY.BLOCK,
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    height: 'auto',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      textAlign: CSS_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      whiteSpace: 'normal',
    },
  },
}));

export const ProductDescription = styled.div<ITheme>((props) => ({
  height: '63px',
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  p: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    textAlign: TEXT_ALIGN.LEFT,
    height: 'auto',
    '&:empty': {
      display: CSS_DISPLAY.NONE,
    },
    ':last-child': {
      marginBottom: 0,
    },
  },
  'ol, ul': {
    paddingLeft: '15px',
  },
  li: {
    fontWeight: 400,
    padding: 0,
    'a, strong, em': {
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    '-webkit-line-clamp': 4,
    height: 'auto',
  },
}));

export const ProductBadge = styled.div<ITheme>((props) => ({
  width: 'fit-content',
  alignSelf: CSS_ALIGN.FLEX_END,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
  borderRadius: '10px',
  backgroundColor: getThemeNeutralColors(props).light,
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontWeight: 500,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    alignSelf: CSS_ALIGN.CENTER,
  },
}));

export const ProductLink = styled.div<ITheme>((props) => ({
  marginTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.S),
  height: 'auto',
  color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
  a: {
    width: '100%',
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

import ImageTextColumnsCta from 'features/HomePage/ImageTextColumnCta';
import HomepagePricingCards from 'features/HomePage/PricingCards';
// import Hero from 'features/HomePage/Hero';
import ProductCardBlock from 'features/HomePage/ProductCard/ProductCardBlock';
import TextImageColumn from 'features/HomePage/TextImageColumns/TextImageColumn';
import LocationFlags from 'features/LocationFlags';
import TrustpilotReviews from 'features/TruspilotReviews';
import { SliceMap } from 'types';
import Blog from 'features/HomePage/Blog/Blog';
import UseCasesBlock from 'features/UseCasesBlock';
import TableBlock from 'features/TableBlock';
import { Spacing } from 'features/Spacing/Spacing';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import CtaBlock from 'features/CtaBlock';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import HeroFeaturesUSP from 'features/HomePage/HeroFeaturesUSP';
import HeroLogosUSP from 'features/HomePage/HeroLogosUSP';
import HomepageHeroUSP from 'features/HomePage/HomepageHeroUSP';
import FooterBanner from 'features/FooterBanner';
import ExpandablePricingCards from 'features/ExpandablePricingCards';
import Title from 'features/Title';
import { Hero } from 'features/v2/Hero';
import { HeroFeatures } from 'features/v2/HeroFeatures';
import { HeroLogos } from 'features/v2/HeroLogos';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import HomepageBlogPosts from 'features/HomePage/HomepageBlogPosts';
import {
  PrismicBlogImage,
  PrismicImageTabs,
  QuoteComponent,
  PromoBanner,
} from '@smartproxy-web/features';
import {
  ComparisonTable,
  PrismicCarouselBlock,
  PrismicTextGrid,
  GettingStarted,
} from '@smartproxy-web/features';
import { BlogParent } from '@smartproxy-web/features';

export enum HOME_PAGE_SLICES {
  // temporary solution for removing old Hero component
  // Hero = 'hero_logos',
  LocationFlags = 'location_flags',
  Product_cards_block = 'product_cards_block',
  TrustpilotReview = 'trustpilot_reviews',
  HomepagePricingCards = 'homepage_pricing_cards',
  Text_image_column = 'text_image_column_list',
  ImageTextColumnsCta = 'image_text_columns_with_cta',
  Blog = 'related_articles',
  UseCasesBlock = 'use_case_block',
  TableBlock = 'proxy_table',
  Spacing = 'spacing',
  ImageTextBlock = 'image_text_block',
  CtaBlock = 'cta_block',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  HomepageHeroUSPs = 'homepage_hero_usps',
  HomepageHeroFeaturesUSPs = 'homepage_hero_usps_features',
  HomepageHeroLogosUSPs = 'homepage_hero_usps_logos',
  ExpandableProductsPricingOverview = 'expandable_pricing_overview',
  Title = 'title',
  TextGrid = 'text_grid',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  BlogImage = 'blog_image',
  SimpleCardsSlice = 'simple_cards_slice',
  blog_post_automated = 'blog_post_automated',
  ComparisonTable = 'comparison_table',
  PrismicCarouselBlock = 'carousel_block',
  GettingStarted = 'getting_started',
  BlogParent = 'blog_parent_block',
  QuoteComponent = 'quote_component',
  ImageTabs = 'image_tabs',
  PromoBanner = 'promo_banner',
}

export const homePageSliceMap: SliceMap<HOME_PAGE_SLICES> = {
  // temporary solution for removing old Hero component
  // [HOME_PAGE_SLICES.Hero]: Hero,
  [HOME_PAGE_SLICES.LocationFlags]: LocationFlags,
  [HOME_PAGE_SLICES.Product_cards_block]: ProductCardBlock,
  [HOME_PAGE_SLICES.TrustpilotReview]: TrustpilotReviews,
  [HOME_PAGE_SLICES.HomepagePricingCards]: HomepagePricingCards,
  [HOME_PAGE_SLICES.Text_image_column]: TextImageColumn,
  [HOME_PAGE_SLICES.ImageTextColumnsCta]: ImageTextColumnsCta,
  [HOME_PAGE_SLICES.Blog]: Blog,
  [HOME_PAGE_SLICES.UseCasesBlock]: UseCasesBlock,
  [HOME_PAGE_SLICES.TableBlock]: TableBlock,
  [HOME_PAGE_SLICES.Spacing]: Spacing,
  [HOME_PAGE_SLICES.ImageTextBlock]: ImageTextBlock,
  [HOME_PAGE_SLICES.CtaBlock]: CtaBlock,
  [HOME_PAGE_SLICES.SocialProofBlock]: SocialProofBlock,
  [HOME_PAGE_SLICES.FeaturedIn]: FeaturedIn,
  [HOME_PAGE_SLICES.HomepageHeroUSPs]: HomepageHeroUSP,
  [HOME_PAGE_SLICES.HomepageHeroFeaturesUSPs]: HeroFeaturesUSP,
  [HOME_PAGE_SLICES.HomepageHeroLogosUSPs]: HeroLogosUSP,
  [HOME_PAGE_SLICES.FooterBanner]: FooterBanner,
  [HOME_PAGE_SLICES.ExpandableProductsPricingOverview]: ExpandablePricingCards,
  [HOME_PAGE_SLICES.Title]: Title,
  [HOME_PAGE_SLICES.TextGrid]: PrismicTextGrid,
  [HOME_PAGE_SLICES.Herov2]: Hero,
  [HOME_PAGE_SLICES.HeroFeaturesv2]: HeroFeatures,
  [HOME_PAGE_SLICES.HeroLogosv2]: HeroLogos,
  [HOME_PAGE_SLICES.BlogImage]: PrismicBlogImage,
  [HOME_PAGE_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [HOME_PAGE_SLICES.blog_post_automated]: HomepageBlogPosts,
  [HOME_PAGE_SLICES.ComparisonTable]: ComparisonTable,
  [HOME_PAGE_SLICES.PrismicCarouselBlock]: PrismicCarouselBlock,
  [HOME_PAGE_SLICES.GettingStarted]: GettingStarted,
  [HOME_PAGE_SLICES.BlogParent]: BlogParent,
  [HOME_PAGE_SLICES.QuoteComponent]: QuoteComponent,
  [HOME_PAGE_SLICES.ImageTabs]: PrismicImageTabs,
  [HOME_PAGE_SLICES.PromoBanner]: PromoBanner,
};

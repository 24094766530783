import { Wrapper } from 'commonStyles';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import {
  smartdailiQuickStartGuideMap,
  SMARTDAILI_QUICK_START_GUIDE_SLICES,
} from './constants';

import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';

interface IWithPrismicSmartdailiQuickStartGuidePages {
  prismicSmartdailiQuickStartGuidePages: {
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicSmartdailiQuickStartGuidePages;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const SmartdailiQuickStartGuidePages = ({
  data,
}: Props & PageTemplateProps) => {
  const { prismicSmartdailiQuickStartGuidePages } = data;
  const templates = getTemplates<
    SMARTDAILI_QUICK_START_GUIDE_SLICES,
    DefaultSlice
  >(
    prismicSmartdailiQuickStartGuidePages?.data?.body,
    smartdailiQuickStartGuideMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(SmartdailiQuickStartGuidePages);

export const query = graphql`
  query SmartdailiQuickStartGuidePages($id: String!) {
    prismicSmartdailiQuickStartGuidePages(id: { eq: $id }) {
      _previewable
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyBreadcrumbs {
            id
            slice_label
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyImageTextBlock {
            id
            slice_label
            slice_type
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              cta_color
              is_split_equally
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_zoom_modal
              is_image_right
              review_bottom_text {
                richText
                text
              }
              review_description
              review_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyTableOfContent {
            id
            slice_type
            primary {
              table_title {
                richText
                text
              }
            }
            items {
              link_label {
                richText
                text
              }
              link_to {
                url
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyBadgesBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              is_text_centered
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyProductCardsBlock {
            id
            items {
              badge {
                richText
                text
              }
              link {
                url
              }
              product_description {
                richText
                text
              }
              product_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              product_title {
                richText
                text
              }
            }
            slice_type
            primary {
              background
              block_title {
                richText
                text
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label_primary {
                text
                richText
              }
              cta_link_primary {
                url
              }
              main_description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_label_secondary {
                text
                richText
              }
              cta_link_secondary {
                url
              }
              main_title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyTabbedCodeBlock {
            id
            items {
              code {
                richText
                text
              }
              language
            }
            slice_type
            primary {
              cta_label_1
              cta_label_2
              cta_link_1 {
                url
              }
              cta_link_2 {
                url
              }
              show_copy_button
              subtitle {
                richText
                text
              }
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
            }
            slice_type
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              features_description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              features_title {
                richText
                text
              }
            }
          }
          ... on PrismicSmartdailiQuickStartGuidePagesDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              logos_title {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import {
  CategoriesAdditionalItems,
  CategoriesItems,
} from './CategoriesContainer';
import { ICategoriesContent } from './CategoriesContainer/types';
import { CategoriesItemsWrapper } from './styledComponents';

export const MenuContent: React.FC<ICategoriesContent> = ({
  category,
  handleClose,
  handleCategoryItemClick,
  buildTrackingId,
}) => (
  <CategoriesItemsWrapper>
    {category.children.map((item, index) => (
      <CategoriesItems
        key={index}
        handleCategoryItemClick={handleCategoryItemClick}
        itemTitle={item.label}
        itemLink={item?.titleUrl}
        itemChildren={item.children}
        buildTrackingId={buildTrackingId}
      />
    ))}
    <CategoriesAdditionalItems
      category={category}
      handleClose={handleClose}
      buildTrackingId={buildTrackingId}
    />
  </CategoriesItemsWrapper>
);

import { TGatsbyImage, Slice } from 'types';
import { FOOTER_ACTIONS_SLICE_NAME, FooterLink } from './constants';
import { normalizeString } from '@smartproxy-web/shared/utils';

interface QRCode {
  image: TGatsbyImage;
  name: string;
}

export type QRCodeMap = Record<string, QRCode['image']>;

export const extractPrismicFooterQRCodes = (
  prismicFooterDataBody: Slice<unknown, QRCode[]>[]
) => {
  const slice = prismicFooterDataBody.find(
    (slice) => slice.slice_type === FOOTER_ACTIONS_SLICE_NAME
  );

  if (!slice) return {};

  const output: QRCodeMap = {};

  for (const item of slice.items) {
    if (item.name && item.image) {
      output[normalizeString(item.name)] = item.image;
    }
  }

  return output;
};

export const enrichFooterLinksWithQRCode = (
  links: FooterLink[],
  qrCodes: QRCodeMap
) => {
  return links.map((link) => {
    const qrCode = qrCodes[link.key];

    if (!qrCode) return link;

    return {
      ...link,
      qrCode,
    };
  });
};

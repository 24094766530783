import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { StyledCta } from 'features/HomePage/PricingCards/styledComponents';
import { shortenText, updateColorInfo } from 'features/utils';
import { IconCheckmarkHome, IconInfoCircle } from 'images';
import React, { useState } from 'react';
import {
  CrossedOutPrice,
  ProductCardContainer,
  ProductCardDescription,
  ProductCardFeaturesContainer,
  ProductCardHeader,
  ProductCardIconContainer,
  ProductCardInfoContainer,
  ProductCardInfoPrice,
  ProductCardTooltip,
  RecommendedContainer,
} from './styledComponents';
import { IProductCard } from './types';

const ProductCard: React.FC<IProductCard> = ({
  accentColor,
  cta_label: ctaLabel,
  cta_link: ctaLink,
  feature_one: featureOne,
  feature_three: featureTwo,
  feature_two: featureThree,
  icon,
  price_time_period: priceTimePeriod,
  product_description: description,
  product_title: title,
  recommended,
  tooltip,
  tooltip_text: tooltipText,
  product_price: productPrice,
  old_product_price: oldProductPrice,
  trackerCta,
  trackerPopup,
}) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const updatedAccentColor = updateColorInfo(accentColor.toLowerCase());
  const featuresArray = [featureOne, featureTwo, featureThree];

  return (
    <ProductCardContainer
      recommended={recommended}
      color={updatedAccentColor.color}
    >
      {popupIsVisible && (
        <ProductCardTooltip>
          {shortenText(tooltipText.text, 100)}
        </ProductCardTooltip>
      )}
      {recommended && (
        <RecommendedContainer color={updatedAccentColor.color}>
          Recommended
          {tooltip && (
            <IconInfoCircle
              onMouseOver={() => setPopupIsVisible(true)}
              onMouseOut={() => setPopupIsVisible(false)}
              data-tracker={trackerPopup}
            />
          )}
        </RecommendedContainer>
      )}
      <ProductCardHeader>
        <ProductCardIconContainer>
          <GatsbyImg image={icon} />
        </ProductCardIconContainer>
        <ProductCardInfoContainer>
          <RichText field={title} />
          <ProductCardInfoPrice color={updatedAccentColor.color}>
            价格从
            <CrossedOutPrice isVisible={!!oldProductPrice}>
              ${oldProductPrice}
            </CrossedOutPrice>
            <span>${productPrice}</span>
            {priceTimePeriod.text}
          </ProductCardInfoPrice>
        </ProductCardInfoContainer>
      </ProductCardHeader>
      <ProductCardFeaturesContainer color={updatedAccentColor.color}>
        {featuresArray.map((item) => (
          <div key={item?.text}>
            {!!item?.text && <IconCheckmarkHome />}
            <RichText field={item} />
          </div>
        ))}
      </ProductCardFeaturesContainer>
      <ProductCardDescription>
        <RichText field={description} />
      </ProductCardDescription>
      <StyledCta
        isVisible={ctaLabel.text && ctaLink.url}
        ctaText={ctaLabel.text}
        ctaLink={ctaLink.url}
        themeName={updatedAccentColor.themeName}
        themeType={updatedAccentColor.themeType}
        data-tracker={trackerCta}
      />
    </ProductCardContainer>
  );
};

export default ProductCard;

import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';
import { HEADER_CONTENTS_MAX_WIDTH } from '../Header/constants';

export const StyledHeaderContents = styled.div<{ isLogoCentered: boolean }>(
  ({ isLogoCentered }) => ({
    height: '100%',
    width: '100%',
    maxWidth: HEADER_CONTENTS_MAX_WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: isLogoCentered ? 'center' : 'flex-start',
    gap: 36,
    margin: '0 auto',
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      justifyContent: 'flex-start',
    },
  })
);

export const StyledHeaderLogoWrapper = styled.div({
  svg: {
    maxWidth: '140px',
    width: 'auto',
    height: '100%',
  },
});

import React from 'react';
import {
  CtaComponentContainer,
  HideableAvailableCitiesContainer,
  HideableCtaContainer,
} from './styledComponents';
import { LinkButton } from 'components/v2/LinkButton';
import { RichText } from 'components/v2/RichText';
import hideable from 'utils/hideable';
import { ICtaComponent } from './types';

const CtaComponent: React.FC<ICtaComponent> = ({
  ctaLabel,
  ctaLink,
  availableCities,
  flagsAlignment,
}) => (
  <CtaComponentContainer flagsAlignment={flagsAlignment}>
    <HideableCtaContainer isVisible={ctaLabel.text && ctaLink.url}>
      <LinkButton fullWidth color="blue" variant="outlined" to={ctaLink.url}>
        {ctaLabel.text}
      </LinkButton>
    </HideableCtaContainer>
    <HideableAvailableCitiesContainer isVisible={availableCities.text}>
      <RichText field={availableCities} />
    </HideableAvailableCitiesContainer>
  </CtaComponentContainer>
);

export default hideable(CtaComponent);

import { RichText } from 'components/v2/RichText';
import React from 'react';
import hideable from 'utils/hideable';
import {
  BarcodeContainer,
  BottomText,
  HideableBottomTextContainer,
  SmallText,
} from './styledComponents';
import { IBarcodeInfo } from './types';

const HideableRichText = hideable(RichText);

const BarcodeInfo: React.FC<IBarcodeInfo> = ({
  barcode,
  smallText,
  barCode1,
  barCode2,
  onMouseLeave,
}) => {
  const isBottomTextVisible =
    smallText?.text || barCode1?.text || barCode2?.text;

  return (
    <BarcodeContainer onMouseLeave={onMouseLeave}>
      <img
        src={barcode.url?.replace(
          'china-web.cdn.prismic.io',
          'china-web.cdn.prismic.io'
        )}
      />
      <HideableBottomTextContainer isVisible={isBottomTextVisible}>
        <SmallText>
          <RichText field={smallText} />
        </SmallText>
        <BottomText>
          <HideableRichText
            isVisible={barCode1 || barCode2}
            field={barCode1 || barCode2}
          />
        </BottomText>
      </HideableBottomTextContainer>
    </BarcodeContainer>
  );
};

export default BarcodeInfo;

import { TARGET } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import Link from 'components/GatsbyLink';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  Comment,
  ImageContainer,
  Review,
  ReviewsContainer,
  ReviewsList,
  StarRatingContainer,
  StyledLinkContainer,
  Username,
  Wrapper,
} from './styledComponents';
import { ITruspilotReviews } from './types';

const TrustpilotReviews: React.FC<ITruspilotReviews> = ({
  primary: { background, link_to: linkTo, trustpilot_logo: trustpilotLogo },
  items,
}) => (
  <Wrapper background={background}>
    <ReviewsContainer>
      <StyledLinkContainer>
        <Link to={linkTo.url} target={TARGET.BLANK}>
          <GatsbyImg image={trustpilotLogo} />
        </Link>
      </StyledLinkContainer>
      <ReviewsList>
        {items.map(
          (
            {
              username,
              review,
              user_image: userImage,
              star_rating: starRating,
            },
            idx
          ) => (
            <Review key={idx}>
              <ImageContainer>
                <GatsbyImg image={userImage} />
              </ImageContainer>
              <Username>
                <RichText field={username} />
              </Username>
              <StarRatingContainer>
                <GatsbyImg image={starRating} />
              </StarRatingContainer>
              <Comment>
                <RichText field={review} />
              </Comment>
            </Review>
          )
        )}
      </ReviewsList>
    </ReviewsContainer>
  </Wrapper>
);

export default TrustpilotReviews;

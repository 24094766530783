import { graphql, useStaticQuery } from 'gatsby';

export const stickyContactsBarQuery = () =>
  useStaticQuery(
    graphql`
      query StickyContactsBarQuery {
        prismicStickyContactsBar {
          data {
            body {
              ... on PrismicStickyContactsBarDataBodyStickyBar {
                id
                slice_type
                items {
                  additional_small_text {
                    text
                    richText
                  }
                  barcode1 {
                    url
                    gatsbyImageData(placeholder: BLURRED)
                    alt
                  }
                  barcode2 {
                    url
                    alt
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  icon {
                    alt
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  link_field {
                    url
                    target
                  }
                  tab1 {
                    richText
                    text
                  }
                  tab2 {
                    richText
                    text
                  }
                  barcode1_code {
                    richText
                    text
                  }
                  barcode2_code {
                    richText
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  );

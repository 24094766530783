import GatsbyImg from 'components/GatsbyImg';
import { IconDownload } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import { RepeatableZone } from './RepeatableZone';
import {
  HideableReview,
  ReviewBottomText,
  ReviewImage,
  ReviewText,
  TextBlockContent,
} from './styledComponents';
import { IItem } from './types';
import { RichText } from 'components/v2/RichText';
import { theme } from 'theme-v2/theme';
import { PrismicTextField, TGatsbyImage, PrismicInputField } from 'types';
import { LinkButton } from 'components/v2/LinkButton';
import { Box } from 'components/v2/Box';

const HideableRichText = hideable(RichText);

interface Props {
  selector: string;
  description?: PrismicTextField;
  subTitle?: PrismicTextField;
  reviewDescription?: string;
  reviewImage?: TGatsbyImage;
  reviewBottomText?: PrismicTextField;
  ctaLink?: PrismicInputField;
  ctaText?: string;
  isIconVisible?: boolean;
  items?: IItem[];
  isDark?: boolean;
}

const TextBlock: React.FC<Props> = ({
  description,
  subTitle,
  reviewDescription,
  reviewImage,
  reviewBottomText,
  ctaLink,
  ctaText,
  selector,
  items = [],
  isIconVisible,
  isDark,
}) => {
  return (
    <TextBlockContent>
      <HideableRichText isVisible={subTitle?.text} field={subTitle} />
      <HideableRichText field={description} isVisible={description?.text} />
      <HideableReview isVisible={!!reviewDescription?.length}>
        {reviewImage && (
          <ReviewImage>
            <GatsbyImg image={reviewImage} />
          </ReviewImage>
        )}
        <div>
          <ReviewText>{reviewDescription}</ReviewText>
          <ReviewBottomText>
            <RichText field={reviewBottomText} />
          </ReviewBottomText>
        </div>
      </HideableReview>
      {items.length > 1 && <RepeatableZone isDark={isDark} items={items} />}
      {ctaLink?.url && (
        <Box sx={{ marginTop: { base: '32px', sm: '36px' } }}>
          <LinkButton
            fullWidthOnMobile
            to={ctaLink?.url}
            data-tracker={selector}
            rightIcon={
              isIconVisible && <IconDownload fill={theme.colors.primary.P99} />
            }
            minWidth="200px"
            variant="outlined"
            color="blue"
          >
            {ctaText}
          </LinkButton>
        </Box>
      )}
    </TextBlockContent>
  );
};

export default TextBlock;

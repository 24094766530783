import React from 'react';
import { LinkButton } from 'components/v2/LinkButton';
import { BottomNavMenuListItemProps } from './types';

export const BottomNavMenuListItem: React.FC<BottomNavMenuListItemProps> = ({
  index,
  link,
  trackingId,
}) => {
  const variant = index === 0 ? 'filled' : 'outlined';
  const color = index == 0 ? 'blue' : 'white';

  return (
    <li key={link.key}>
      <LinkButton
        to={link.url?.endsWith('#contact-sales') ? '#contact-sales' : link.url}
        variant={variant}
        color={color}
        data-tracker={trackingId}
        fullWidth
      >
        {link.label}
      </LinkButton>
    </li>
  );
};

import React from 'react';
import hideable from 'utils/hideable';
import { CloseIcon, Content, IconWrapper } from '../styledComponents';
import { IInquiryFormContent } from '../types';

const InquiryFormContent: React.FC<IInquiryFormContent> = ({
  onClose,
  children,
}) => (
  <Content>
    {children}
    <IconWrapper onClick={onClose}>
      <CloseIcon />
    </IconWrapper>
  </Content>
);

export default hideable(InquiryFormContent);

import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeBackgroundColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const Wrapper = styled.section<ITheme & { background: string }>(
  ({ background, ...props }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    width: '100%',
    background,
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const ReviewsContainer = styled.div({
  width: '100%',
  maxWidth: '1024px',
});

export const ReviewsList = styled.ul<ITheme>((props) => ({
  listStyle: 'none',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: '16px',
  columnGap: '16px',
  padding: 0,
  [MEDIA_QUERIES.TABLET]: {
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '80%',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledLinkContainer = styled.div<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

export const Review = styled.li<ITheme>((props) => ({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  borderRadius: '12px',
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  boxShadow: '0 0 24px rgba(0,0,0,.1)',
}));

export const Username = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  fontWeight: 500,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  lineHeight: '20px',
  color: getThemeBackgroundColor(props, COLOR_SHADE.DEEP),
}));

export const Comment = styled.div<ITheme>((props) => ({
  width: '100%',
  p: {
    fontWeight: 400,
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    'a, strong, em': {
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
      fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    },
    a: {
      ':hover': {
        textDecoration: 'underline',
      },
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: '15px',
    },
  },
}));

export const ImageContainer = styled.div<ITheme>((props) => ({
  width: '60px',
  height: '60px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  borderRadius: '50%',
  img: {
    border: '1px solid #fff',
    '-webkit-border-radius': '50%',
  },
}));

export const StarRatingContainer = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  height: '20px',
  maxWidth: '127px',
  div: {
    maxHeight: '20px',
  },
}));

import styled from '@emotion/styled';
import { TEXT_TYPE } from 'components/constants';
import { getThemeFontSize } from 'theme';
import { IBaseSectionProps } from './types';
import { mediaQueries } from '@smartproxy-web/ui';
import { themev2 } from 'theme-v2';

export const StyledBaseSection = styled.section<
  Pick<IBaseSectionProps, 'shouldContentBleed'>
>(({ shouldContentBleed = false, ...props }) => ({
  padding: `${themev2.spacings.sectionSpacing} 20px`,
  width: '100%',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  overflow: 'hidden',

  [mediaQueries.md]: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },

  '& > div': {
    margin: '0 auto',
    ...(!shouldContentBleed && { maxWidth: '1024px' }),
  },
}));

import React, { createContext, useContext } from 'react';
import { getPageContextFromPathname } from 'utils/page';
import { useLocation } from '@reach/router';

interface IPageContext {
  tags: string[];
  type: string;
}

const contextValue: IPageContext = {
  tags: [],
  type: '',
};

export const PageContext = createContext(contextValue);

export const usePageContext = () => useContext(PageContext);

const PageContextProvider: React.FC<
  React.PropsWithChildren<{ pageContext: IPageContext }>
> = ({ children, pageContext }) => {
  const { pathname } = useLocation();

  const { tags: tagsFromUrl, type: typeFromUrl } =
    getPageContextFromPathname(pathname);

  return (
    <PageContext.Provider
      value={{
        tags: pageContext.tags || tagsFromUrl,
        type: pageContext.type.length > 0 ? pageContext.type : typeFromUrl,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContextProvider;

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import {
  CtaBlockWrapper,
  StyledCtaBlock,
} from 'features/CtaBlock/styledComponents';
import { slugify } from 'features/utils';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  HideableTitlesContainer,
  SubtitleContainer,
  TextCtaWrapper,
  TextWrapper,
} from './styledComponents';
import { ITextCtaBlock } from './types';
import { usePageContext } from 'contexts/PageContext';
import { getUpdatedAccentColor } from 'components/utils';
import { RichText } from 'components/v2/RichText';

const TextCtaBlock: React.FC<ITextCtaBlock> = ({
  primary: {
    accent_color: accentColor,
    background,
    cta_label: ctaLabel,
    cta_link: ctaLink,
    is_background_dark: isBackgroundDark,
    is_text_centered: isTextCentered,
    subtitle,
    text,
    title,
  },
}) => {
  const { tags, type } = usePageContext();
  const buildClickId = (buttonText: string) => {
    const context = tags[1] ? `-${tags[1]}` : '';
    return `cta-block-${slugify(tags[0] || type)}${context}-${slugify(
      buttonText
    )}-cta`;
  };
  const updatedAccentColor = getUpdatedAccentColor(accentColor);

  return (
    <TextCtaWrapper background={background} isBackgroundDark={isBackgroundDark}>
      <HideableTitlesContainer
        isVisible={!!title || !!subtitle}
        accentColor={updatedAccentColor.color}
      >
        <RichText field={title} />
        <SubtitleContainer isCentered={isTextCentered}>
          <RichText field={subtitle} />
        </SubtitleContainer>
      </HideableTitlesContainer>
      <TextWrapper isCentered={isTextCentered}>
        <RichText field={text} />
      </TextWrapper>
      <CtaBlockWrapper isCentered={isTextCentered}>
        <StyledCtaBlock
          isVisible={ctaLabel.text && ctaLink.url}
          linkId={buildClickId(ctaLabel?.text)}
          ctaText={ctaLabel.text}
          ctaLink={ctaLink.url}
          themeName={
            !updatedAccentColor
              ? BUTTON_THEMES.CONVERSION
              : updatedAccentColor.themeName
          }
          themeType={
            !updatedAccentColor
              ? BUTTON_TYPES.PRIMARY
              : updatedAccentColor.themeType
          }
          ctaClassName={buildTrackingSelector({
            page: tags[0] || type,
            context: ctaLabel.text,
            type: 'cta',
          })}
        />
      </CtaBlockWrapper>
    </TextCtaWrapper>
  );
};

export default TextCtaBlock;

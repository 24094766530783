import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  ContentContainer,
  CtaContainer,
  SaleRepInfo,
  SalesAdditionalInfo,
  SalesRepName,
  StyledBackgroundWrapper,
  StyledCta,
} from './styledComponents';
import { ISalesRepContacts } from './types';

const SalesRepContacts: React.FC<ISalesRepContacts> = ({
  primary: {
    background,
    title,
    rep_name: name,
    additional_info: info,
    cta_label: ctaLabel,
    cta_url: ctaUrl,
    image,
  },
}) => (
  <StyledBackgroundWrapper background={background}>
    <ContentContainer>
      <GatsbyImg image={image} />
      <SaleRepInfo>
        <RichText field={title} />
        <SalesRepName>
          <RichText field={name} />
        </SalesRepName>
        <SalesAdditionalInfo>
          <RichText field={info} />
        </SalesAdditionalInfo>
        <CtaContainer>
          <StyledCta
            ctaText={ctaLabel.text}
            ctaLink={ctaUrl.url}
            isVisible={ctaUrl.url}
            themeName={BUTTON_THEMES.IMPORTANT}
            themeType={BUTTON_TYPES.SECONDARY}
          />
        </CtaContainer>
      </SaleRepInfo>
    </ContentContainer>
  </StyledBackgroundWrapper>
);
export default SalesRepContacts;

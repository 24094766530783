import { SECTION_NAMES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import { generateSectionsId } from 'features/utils';
import * as React from 'react';
import {
  HeroHomePageLogos,
  LogoContainer,
  StyledHeroLogosContainer,
  StyledHeroLogosWrapper,
} from './styledComponents';
import { ILogosUSP } from './types';
import { RichText } from 'components/v2/RichText';

const HeroLogosUSP: React.FC<ILogosUSP> = ({
  items,
  primary: {
    title_for_hero_logos: logosTitle,
    is_background_dark: isBackgroundDark,
  },
  pageContext,
}) => {
  const { tags, type } = pageContext || { tags: [] };
  return (
    <StyledHeroLogosWrapper
      isBackgroundDark={isBackgroundDark}
      data-section-id={generateSectionsId(SECTION_NAMES.HERO_LOGOS, tags, type)}
    >
      <StyledHeroLogosContainer>
        <RichText field={logosTitle} />
        <HeroHomePageLogos>
          {items.map((item, index) => (
            <LogoContainer key={index}>
              <GatsbyImg image={item.logo} />
            </LogoContainer>
          ))}
        </HeroHomePageLogos>
      </StyledHeroLogosContainer>
    </StyledHeroLogosWrapper>
  );
};

export default HeroLogosUSP;

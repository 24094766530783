import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import {
  ConfirmationDescription,
  ConfirmationTitle,
  Container,
  CtaText,
  LogoWrapper,
  ModalContainer,
  StatusIconWrapper,
  StyledButton,
} from '../styledComponents';
import { IInquiryFormConfirmation } from '../types';

const InquiryFormConfirmation: React.FC<IInquiryFormConfirmation> = ({
  primary: { cta_text: ctaText, description, image_1, image_2, logo, title },
  onClick,
}) => (
  <ModalContainer>
    <LogoWrapper>
      <GatsbyImg image={logo} />
    </LogoWrapper>
    <Container>
      <StatusIconWrapper>
        {!!image_1 && <GatsbyImg image={image_1} />}
        {!!image_2 && <GatsbyImg image={image_2} />}
      </StatusIconWrapper>
      <ConfirmationTitle>
        <RichText field={title} />
      </ConfirmationTitle>
      <ConfirmationDescription>
        <RichText field={description} />
      </ConfirmationDescription>
      <StyledButton
        height={TSHIRT_SIZE.L}
        fontSize={TSHIRT_SIZE.L}
        themeName={BUTTON_THEMES.FOLLOWING}
        themeType={BUTTON_TYPES.PRIMARY}
        onClick={onClick}
      >
        <CtaText>{ctaText}</CtaText>
      </StyledButton>
    </Container>
  </ModalContainer>
);

export default InquiryFormConfirmation;

import React from 'react';
import { AllArticlesItem } from './AllArticlesItem';
import { TArticlesList } from './types';
import { getArticleUrl } from './utils';

export const ArticlesList: React.FC<TArticlesList> = ({
  articles,
  descriptionLength,
  isHomepage,
}) => (
  <>
    {articles.map(({ data, id, type, tags }) => (
      <AllArticlesItem
        key={id}
        url={getArticleUrl(type, tags)}
        {...data}
        descriptionLength={descriptionLength!}
        isHomepage={isHomepage!}
      />
    ))}
  </>
);

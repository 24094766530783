import React, { useState } from 'react';
import { MenuContainer } from '../../Menu';
import { NavMenuItem } from '../NavMenuItem';
import { INavItem } from '../types';
import { StyledLeftNavMenuList } from './styledComponents';
import { LeftNavMenuListProps } from './types';

export const LeftNavMenuList: React.FC<LeftNavMenuListProps> = ({
  categories,
  handleItemClick,
  buildTrackingId,
}) => {
  const handleNavMenuItemClick = (navItem: INavItem) => {
    handleItemClick(navItem);
  };
  const [openedCategory, setOpenedCategory] = useState<number | null>(null);

  return (
    <StyledLeftNavMenuList>
      {categories.map((category, index) => (
        <div key={category.key}>
          <li
            className={category.className}
            onClick={() =>
              setOpenedCategory(openedCategory !== index ? index : null)
            }
          >
            <NavMenuItem
              navItem={category}
              handleClick={(navItem) => handleNavMenuItemClick(navItem)}
              buildTrackingId={buildTrackingId}
              opened={openedCategory === index}
            />
          </li>
          {openedCategory === index && (
            <MenuContainer
              openedCategory={openedCategory}
              selected={openedCategory === index}
              handleClose={() => setOpenedCategory(null)}
              category={category}
              buildTrackingId={buildTrackingId}
            />
          )}
        </div>
      ))}
    </StyledLeftNavMenuList>
  );
};

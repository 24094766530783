import { IColor } from 'types';
import styled from '@emotion/styled';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  ITheme,
} from 'theme';
import { MEDIA_QUERIES } from 'globalStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { themev2 } from 'theme-v2';

export const TextImageColumnsWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const TextImageColumnsContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  maxWidth: '1024px',
  margin: `${getThemeGutter(props, TSHIRT_SIZE.XXS)} auto`,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
}));

export const TextImageColumnsTitle = styled.div<ITheme>((props) => ({
  'h1, h2, h3': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXXL),
    fontFamily: getThemeFontFamily(props),
    textAlign: CSS_ALIGN.CENTER,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      lineHeight: '48px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      lineHeight: '36px',
    },
  },
}));

export const TextImageColumnsItems = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.STRETCH,
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  gridTemplateColumns: 'repeat(3, 1fr)',
  [MEDIA_QUERIES.TABLET]: {
    columnGap: '20px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.XXXL),
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, auto)',
  },
}));

export const TextImageColumnBlock = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  },
}));

export const TextImageColumnImageAndTitleContainer = styled.div<ITheme>(
  (props) => ({
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
    objectFit: 'cover',
    div: {
      img: {
        margin: '0 auto 40px',
        verticalAlign: 'middle',
        maxHeight: '160px',
        maxWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
        height: 'auto',
        width: 'auto',
      },
    },
  })
);

export const TextImageColumnTitle = styled.div<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.LEFT,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  'h2, h3, h4': {
    fontSize: themev2.fonts.size.h4,
    height: 'auto',
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    fontWeight: 500,
  },
  [MEDIA_QUERIES.TABLET]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    'h2, h3, h4': {
      whiteSpace: 'normal',
    },
  },
}));

export const TextImageColumnDescription = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  textAlign: TEXT_ALIGN.LEFT,
}));

export const TextImageColumnLinkContainer = styled.div<ITheme>((props) => ({
  alignSelf: CSS_ALIGN.FLEX_START,
  marginTop: '6px',
  height: '30px',
  a: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: '20px',
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

import GatsbyImg from 'components/GatsbyImg';
import { Box } from 'components/v2/Box';
import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import { HideableTitles } from 'features/ProductCards/styledComponents';
import ArrowsContainer from 'features/SocialProof/Arrows';
import React, { useRef } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { Wrapper } from '../ProductCards/styledComponents';
import {
  ListItemContainer,
  ListItemsContainer,
  ListItemsWrapper,
} from './styledComponents';
import { ITargetsList } from './types';
import Link from 'components/GatsbyLink';

const TargetsList: React.FC<ITargetsList> = ({
  primary: { background, title, subtitle },
  items,
}) => {
  const carouselContainer = useRef<HTMLDivElement>(null);
  const carouselItemRef = useRef<HTMLDivElement>(null);

  const { tags, type } = usePageContext();
  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'target-list-item',
  });

  return (
    <Wrapper background={background} data-tracker={buildTrackingSelector()}>
      <HideableTitles isVisible={!!title || !!subtitle}>
        <RichText field={title} />
        {!!subtitle && (
          <Box sx={{ marginBottom: '2rem' }}>
            <RichText field={subtitle} />
          </Box>
        )}
      </HideableTitles>
      <ListItemsWrapper>
        {items.length >= 5 && (
          <ArrowsContainer
            itemsLength={items.length}
            carouselContainer={carouselContainer}
            carouselItem={carouselItemRef}
            sliceName="targets-list"
          />
        )}
        <ListItemsContainer ref={carouselContainer}>
          {items.map(
            ({
              card_item_text: text,
              card_item_image: image,
              card_item_link: link,
            }) => (
              <ListItemContainer
                key={text.text}
                ref={carouselItemRef}
                data-tracker={buildTrackingSelector({
                  context: text.text,
                  type: 'cta',
                })}
              >
                <Link to={link.url}>
                  <GatsbyImg image={image} />
                  <RichText field={text} />
                </Link>
              </ListItemContainer>
            )
          )}
        </ListItemsContainer>
      </ListItemsWrapper>
    </Wrapper>
  );
};

export default TargetsList;

import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import {
  ConfirmationDescription,
  ConfirmationTitle,
  Container,
  CtaText,
  StatusIconWrapper,
  StyledButton,
} from 'features/InquiryForm/styledComponents';
import { IInquiryFormConfirmation } from 'features/InquiryForm/types';
import React from 'react';
import { LogoWrapper, ModalContainer } from './styledComponents';

const FormConfirmation: React.FC<IInquiryFormConfirmation> = ({
  primary: { cta_text: ctaText, description, icon, logo, title },
  onClick,
}) => (
  <ModalContainer>
    <LogoWrapper>
      <GatsbyImg image={logo} />
    </LogoWrapper>
    <Container>
      <StatusIconWrapper>
        <GatsbyImg image={icon} />
      </StatusIconWrapper>
      <ConfirmationTitle>
        <RichText field={title} />
      </ConfirmationTitle>
      <ConfirmationDescription>
        <RichText field={description} />
      </ConfirmationDescription>
      <StyledButton
        height={TSHIRT_SIZE.L}
        fontSize={TSHIRT_SIZE.L}
        themeName={BUTTON_THEMES.FOLLOWING}
        themeType={BUTTON_TYPES.PRIMARY}
        onClick={onClick}
      >
        <CtaText>{ctaText}</CtaText>
      </StyledButton>
    </Container>
  </ModalContainer>
);

export default FormConfirmation;

import React from 'react';
import { IBreadcrumbs } from './types';
import Link from 'components/GatsbyLink';
import { BreadcrumbsContainer, ListItem, Wrapper } from './styledComponents';
import { getBreadcrumbSchema } from './utils';
import { Helmet } from 'react-helmet';
import { SITE_URL } from 'constants/common';
import { RichText } from 'components/v2/RichText';

const Breadcrumbs: React.FC<IBreadcrumbs> = ({
  primary: { text_color: textColor },
  items,
}) => {
  const itemsLength = items.length;
  const schema = getBreadcrumbSchema(items);
  const schemaType = 'application/ld+json';

  return (
    <>
      <Helmet>
        <script type={schemaType}>{JSON.stringify(schema)}</script>
      </Helmet>
      <Wrapper>
        <BreadcrumbsContainer>
          {items.map(({ link, link_label: linkLabel }, idx) =>
            itemsLength === idx + 1 ? (
              <ListItem textColor={textColor} key={idx}>
                <RichText field={linkLabel} />
              </ListItem>
            ) : (
              <ListItem textColor={textColor} key={idx}>
                <Link to={link.url || SITE_URL}>{linkLabel.text}</Link>
                <span> &#62;</span>
              </ListItem>
            )
          )}
        </BreadcrumbsContainer>
      </Wrapper>
    </>
  );
};

export default Breadcrumbs;

import { Wrapper } from 'commonStyles';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';

import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { legalSliceMap, LEGAL_SLICES } from './constants';

interface IWithPrismicLegal {
  prismicLegal: {
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicLegal;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const LegalPage = ({ data }: Props & PageTemplateProps) => {
  const { prismicLegal } = data;
  const templates = getTemplates<LEGAL_SLICES, DefaultSlice>(
    prismicLegal.data.body,
    legalSliceMap
  );
  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(LegalPage);

export const query = graphql`
  query LegalQuery($id: String!) {
    prismicLegal(id: { eq: $id }) {
      _previewable
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicLegalDataBodyHeroBlock {
            id
            primary {
              is_money_back_visible
              sub_title {
                text
                richText
              }
              title {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                text
                richText
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
            items {
              checkmark {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
          }
          ... on PrismicLegalDataBodyTextColumn {
            id
            items {
              content {
                richText
                text
              }
              small_title {
                richText
                text
              }
            }
            primary {
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicLegalDataBodyTextBlock {
            id
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicLegalDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicLegalDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicLegalDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label_primary {
                text
                richText
              }
              cta_link_primary {
                url
              }
              main_description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_label_secondary {
                text
                richText
              }
              cta_link_secondary {
                url
              }
              main_title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicLegalDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              features_description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              features_title {
                richText
                text
              }
            }
          }
          ... on PrismicLegalDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              logos_title {
                richText
                text
              }
            }
          }
          ... on PrismicLegalDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
        }
      }
    }
  }
`;

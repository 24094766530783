import * as Yup from 'yup';
import {
  INQUIRY_FORM_SLICES,
  FREE_DOMAIN_EMAIL_REGEX,
  PHONE_REGEX,
  PHONE_NUMBER_MAX_CHARS,
  USE_CASE_MAX_CHARS,
  CONTACT_CHANNEL_MAX_CHARS,
  initialValues,
} from './constants';
import {
  IAuthMessages,
  IFormState,
  IInquiryFormAuthMessages,
  PostData,
  SliceBody,
} from './types';

export const mapFormDataToPostData = (data: IFormState): PostData => ({
  ...data,
  contactChannel: data.contactChannelId ? data.contactChannel : '',
  companyPhone: data.companyPhone ? data.countryCode + data.companyPhone : '',
  source: initialValues.source,
});

export const getSlice = <T>(
  body: SliceBody<T>[],
  sliceType: INQUIRY_FORM_SLICES
) => body.filter((slice) => slice.slice_type === sliceType)[0];

export const handleSubmit = (
  values: IFormState,
  actions: { resetForm: () => void },
  request: (body: PostData, onFinally: () => void) => Promise<void>
) => {
  const { resetForm } = actions;
  request(mapFormDataToPostData(values), resetForm);
};

const getAuthMessages = (data: IInquiryFormAuthMessages): IAuthMessages => ({
  requiredField: data.required_field_error,
  invalidEmail: data.invalid_email_error,
  mustBeNumber: data.must_be_number_error,
  phoneMaxCharacters: data.phone_max_characters_error,
  useCaseMaxCharacters: data.use_case_max_characters_error,
  contactChannelMaxCharacters: data.contact_channel_max_characters_error,
});

export const getValidationSchema = (
  prismicAuthMessages: IInquiryFormAuthMessages
) => {
  const authMessages = getAuthMessages(prismicAuthMessages);

  return Yup.object().shape({
    fullName: Yup.string().required(authMessages.requiredField),
    companyName: Yup.string().required(authMessages.requiredField),
    companyEmail: Yup.string()
      .required(authMessages.requiredField)
      .email(authMessages.invalidEmail)
      .matches(FREE_DOMAIN_EMAIL_REGEX, authMessages.invalidEmail),
    companyPhone: Yup.string()
      .required(authMessages.requiredField)
      .max(PHONE_NUMBER_MAX_CHARS, authMessages.phoneMaxCharacters)
      .matches(PHONE_REGEX, authMessages.mustBeNumber),
    useCase: Yup.string()
      .required(authMessages.requiredField)
      .max(USE_CASE_MAX_CHARS, authMessages.useCaseMaxCharacters),
    contactChannelId: Yup.string()
      .required(authMessages.requiredField)
      .max(CONTACT_CHANNEL_MAX_CHARS, authMessages.contactChannelMaxCharacters),
  });
};

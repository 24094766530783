export const PAGE_DOMAIN = 'smartdaili-china.com';
export const CN_PAGE_DOMAIN = 'smartdaili.cn';
export const OLD_DOMAIN = 'smartdaili-china.com';
export const STAGING_SUBDOMAIN = 'stage';
export const DASHBOARD_SUBDOMAIN = 'dashboard';
export const PAGE_URL = `https://${PAGE_DOMAIN}`;
export const SIGNIN_URL = `https://dashboard.smartdaili-china.com/login`;
export const SIGNUP_URL = `https://dashboard.smartdaili-china.com/register`;
export const SITE_URL = `https://dashboard.smartproxy.com.com/`;
export const CONTACT_SALES_URL_HASH = '#contact-sales';
export const IP_CHECKER_URL = `https://ip.smartproxy.com.com/json`;
export const DEDICATED_ISP_WAITLIST = '#dedicated-isp-waitlist';
export const CATEGORY_TEXT_LINE_HEIGHT = 1.3;
export const SPACING_DEFAULT_HEIGHT = 80;
export const ISP_WAITLIST = '#shared-isp-ip';

export const PH_PROJECT_NAME = 'web';

export const SCREEN_OPTIONS = {
  tabletLimit: 991,
  phoneLandscapeLimit: 767,
  phonePortraitLimit: 479,
  smallMobileLimit: 239,
  shouldListenOnResize: true,
};

export const TEXT_LENGTH_VALUES = {
  DESCRIPTION: 140,
  ARTICLES_DESCRIPTION: 280,
  HOMEPAGE_PROXIES: 250,
  TOPIC_ARTICLES_DESCRIPTION: 460,
  CASE_STUDIES: 250,
  HOMEPAGE_BLOG: 90,
};

export const FOOTER_LINKS = {
  MAIL: 'mailto:china-sales@smartproxy.com',
  CHAT: 'https://direct.lc.chat/12092754',
  TWITTER: 'https://twitter.com/smartproxy_com',
  FACEBOOK: 'https://www.facebook.com/smartproxycom/',
  YOUTUBE: 'https://www.youtube.com/smartproxy',
  REDDIT: 'https://www.reddit.com/r/Smartproxy/',
  GITHUB: 'https://github.com/Smartproxy',
  LINKEDIN: 'https://www.linkedin.com/company/smartproxy/',
  DISCORD: 'https://discord.gg/gvJhWJPaB4',
  SKYPE: 'https://join.skype.com/invite/Jis06GuU1aXW',
  TELEGRAM: 'https://t.me/smartproxy_com',
  EN_LANG: 'https://smartproxy.com/',
  CH_LANG: PAGE_URL,
};

export const DEALS = 'deals';
export const LP = 'lp';

export const NAMES = {
  BASIC_NAME: 'Basic',
  LITE_NAME: 'Lite',
  MICRO_NAME: 'Micro',
  REGULAR_NAME: 'Regular',
  STANDARD_NAME: 'Standard',
  STARTER_NAME: 'Starter',
  ADVANCED_NAME: 'Advanced',
  PREMIUM_NAME: 'Premium',
  PROFESSIONAL_NAME: 'Professional',
  ENTERPRISE_NAME: 'Enterprise',
  SOLID_NAME: 'Solid',
  FREE_TRIAL_NAME: '3 Day Trial',
  NIMBLE_NAME: 'Nimble',
  FOXY_NAME: 'Foxy',
  CLEVER_NAME: 'Clever',
  SHARP_NAME: 'Sharp',
  TINY_NAME: 'Tiny',
  MINI_NAME: 'Mini',
  GENERAL_NAME: 'General',
  MAIN_NAME: 'Main',
  PAYG_NAME: 'Pay As You Go',
  TEENY_NAME: 'Teeny',
  PLUS_NAME: 'Plus',
  DELUX_NAME: 'Delux',
  PRO_NAME: 'Pro',
  GURU_NAME: 'Guru',
  NANO_NAME: 'Nano',
};
export const TYPES = {
  RESIDENTIAL_TYPE: 'residential_proxies',
  DATACENTER_TYPE: 'shared_proxies',
  RTC_TYPE: 'rtc_proxies',
  DEDICATED_DC_TYPE: 'dedicated_dc_proxies',
  ECOMMERCE_TYPE: 'rtc_ecommerce_proxies',
  UNIVERSAL_TYPE: 'rtc_universal_proxies',
  NO_CODE_TYPE: 'rtc_no_code_proxies',
};

export const COOKIE_VALUES = {
  PLAN_ID: 'plan_id',
  PLAN_NAME: 'plan_name',
  PLAN_TYPE: 'plan_type',
  TRAFFIC_LIMIT: 'traffic_limit',
};

export const USE_CASES_BLOCK = {
  MARKET: 'Market Research',
  MEDIA: 'Social Media',
  RETAIL: 'Retail',
};

export const CONTACT_US = '/contact-us';

//Values needed to avoid hero overlap and too low opacity
export const PAGE_SCROLL_POSITION = 500;
export const BUTTON_OPACITY_START_VALUE = 0.5;
export const MAX_PERCENTAGE = 100;

export enum SOCIAL_BUTTON_TYPE {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  REDDIT = 'REDDIT',
  LINKEDIN = 'LINKEDIN',
}

export enum SHARE_LINKS {
  FACEBOOK = 'https://www.facebook.com/sharer/sharer.php?u=',
  TWITTER = 'http://www.twitter.com/share?url=',
  REDDIT = 'https://reddit.com/submit?url=',
  LINKEDIN = 'https://www.linkedin.com/share?',
}

export enum TABS {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum GOOGLE_SCHEMA {
  schemaMarkup = 'schema-markup',
  entity = 'mainEntity',
  answers = 'acceptedAnswer',
  name = 'name',
  text = 'text',
}

export enum SCHEMA_LINKS {
  FAQ = 'http://schema.org/FAQPage',
  QUESTION = 'http://schema.org/Question',
  ANSWER = 'http://schema.org/Answer',
}

export enum COOKIE_SLICES {
  Cookie = 'cookie_content',
}

export enum STICKY_BAR_SLICES {
  StickyBar = 'sticky_bar',
}

export enum SECTION_NAMES {
  FOOTER = 'footer',
  HERO = 'hero',
  PRICING_CARDS = 'pricing-cards',
  PRODUCT_CARDS = 'product-cards',
  LOCATION_FLAGS = 'location-flags',
  USE_CASES = 'use-cases',
  TRUSTPILOT_REVIEWS = 'trustpilot-reviews',
  RELATED_ARTICLES = 'related-articles',
  TEXT_IMAGE_COLUMNS = 'text-image-columns',
  TEXT_IMAGE_CTA = 'text-image-cta',
  IMAGE_TEXT_COLUMN = 'image-text-column',
  TEXT_GRID = 'text-grid',
  PRICING_SLIDER = 'pricing-slider',
  BADGES = 'badges',
  FAQ = 'faq',
  PAYMENT_METHOD = 'payment-method',
  HERO_FEATURES = 'hero-3-usp-columns-homepage',
  HERO_LOGOS = 'hero-recognized-by-homepage',
  VIDEO_BLOCK = 'video-block',
  SIMPLE_CARDS_SLICE = 'simple-cards-slice',
  COMPARISON_TABLE = 'comparison-table',
}

export const PRISMIC_VALUES = {
  ALL: 'All',
  DOTS: '...',
  LEARN_MORE: '了解更多',
  READ_MORE: '阅读更多',
  SEARCH_PLACEHOLDER: 'Search...',
  TIME_READ_ARTICLES: '分钟阅读',
  TIME_READ: '分钟阅读',
  TOPICS: 'Topics:',
  VISIT_BLOG: '查看博客',
  EMPTY_LIST_TEXT: 'No options',
  PERIOD_PER_MONTH: '/月',
  PERIOD_PER_GB: '/GB',
  PER_K_REQ: '/1k req',
  K_REQ_VALUE: '/1k req',
  PERIOD_HOMEPAGE: '/月起',
  PERIOD_PER_IP: '/IP',
  PERIOD_EMPTY: '',
  LABEL_NEW: '新',
  FROM: '价格从',
  PRODUCT_CATEGORY: 'Products',
  SIGNUP_BUTTON_TEXT: '立即注册',
  SIGNIN_BUTTON_TEXT: '登录',
  CONTACTSALES_BUTTON_TEXT: '申请试用',
  TOTAL: '共计: ',
  HEADING_2: 'heading2',
  LANGUAGE: '语言',
  FOLLOW_US: '关注我们',
  LINKS: '友情链接',
  CONNECT_WITH_US: '联系我们',
  MONTH: 'month',
  GIGABYTE: 'gigabyte',
  NOTHING: '/nothing',
  FOLLOW: '关注我们',
  GET_IN_TOUCH: '联系我们',
};

export const RESIDENTIAL_PRICING_VALUES = {
  PRICING_CARDS_TITLE: '最快的住宅代理 – 现在价格更低',
  PRICING_CARDS_FEATURE_ONE: '14 天退款选项',
  PRICING_CARDS_FEATURE_TWO: 'HTTP(s) + SOCKS5',
  PRICING_CARDS_FEATURE_THREE: '代理起价为 $4/GB',
  PRICING_SLIDER_TITLE: '企业版套餐',
  NEED_BIGGER_PLANS_TITLE: '需要这些或更大的套餐吗？',
  NEED_BIGGER_PLANS_DESCRIPTION: '联系我们，我们将为您找到最佳解决方案。',
  PRICING_DISCOUNT: '-10%',
  BILLED_MONTHLY: '增值税 / 月付',
  BILLED_YEARLY: 'Billed yearly',
  LABEL: '仅限一定时间',
  VALIDATION_TEXT: '在2023 年 5 月 31 日前购买，可为首次或重复购买节省一大笔钱',
};

export const MONEY_BACK_TEXT = '14 天退款选项';

export const richTextElements = 'h1, h2, h3, h4, h5, h6, p, a, strong, em';
export const richTextHeadingElements = 'h1, h2, h3, h4, h5, h6';
export const richTextParagraphElements = 'p, a, strong, em';

import React, { useState } from 'react';
import { Slice, SliceHeader } from 'components/v2/Slice';
import { usePageContext } from 'contexts/PageContext';
import { generateSectionsId } from 'features/utils';
import { initBuildTrackingSelector } from 'utils/page';
import Awards from './Awards';
import { TABS } from './constants';
import IndustryExperts from './IndustryExperts';
import ClientReviews from './TrustpilotReviews';
import SocialProofToggle from './SocialProofToggle';
import { SocialProofProps } from './types';

const SocialProofBlock: React.FC<SocialProofProps> = ({
  primary: { title, subtitle, background },
  items,
}) => {
  const { type, tags } = usePageContext();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const tabNames = items.map(
    ({
      tab_document: {
        document: {
          data: { tab_name: tabName },
        },
      },
    }) => tabName
  );

  const tabContent = items.map(({ tab_document: { document: data } }) => data);

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'social-proof',
    prefix: type,
  });

  const renderTabContent = ({ data }, index) => {
    const slicedData = data.repeatable_zone.slice(0, 4);
    return (
      <div key={data.tab_name.text}>
        <ClientReviews
          {...data}
          repeatable_zone={slicedData}
          isVisible={data.tab === TABS.CLIENTS && selectedTabIndex === index}
        />
        <IndustryExperts
          repeatable_zone={slicedData}
          isVisible={data.tab === TABS.EXPERTS && selectedTabIndex === index}
        />
        <Awards
          repeatable_zone={slicedData}
          isVisible={data.tab === TABS.AWARDS && selectedTabIndex === index}
        />
        <ClientReviews
          {...data}
          repeatable_zone={slicedData}
          isVisible={data.tab === TABS.G2 && selectedTabIndex === index}
        />
      </div>
    );
  };

  return (
    <Slice
      backgroundColor={background}
      id={generateSectionsId('Social-proof', tags, type)}
    >
      <SliceHeader
        title={title}
        description={subtitle}
        align={'center'}
        bottomItems={
          <SocialProofToggle
            tabNames={tabNames}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            trackingSelector={trackingSelector}
          />
        }
      />
      {tabContent.map((data, index) => renderTabContent(data, index))}
    </Slice>
  );
};

export default SocialProofBlock;

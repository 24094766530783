import GatsbyImg from 'components/GatsbyImg';
import Link from 'components/GatsbyLink';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import {
  ItemsContainer,
  Card,
  AwardsImage,
  StyledDescription,
  StyledTitle,
} from './styledComponents';
import { IndustryExpertsProps } from './types';
import { RichText } from 'components/v2/RichText';

const IndustryExperts: React.FC<IndustryExpertsProps> = ({
  repeatable_zone: repeatableZone,
}) => {
  const { type, tags } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'social-proof-industry-experts',
    prefix: type,
  });

  return (
    <ItemsContainer>
      {repeatableZone.map(
        ({ icon, title, description, block_url: blockUrl }, index) => (
          <Card
            isClickable={!!blockUrl?.url}
            key={title.text}
            data-tracker={trackingSelector({
              context: `${index + 1}`,
            })}
          >
            <Link to={blockUrl?.url}>
              <AwardsImage>
                <GatsbyImg image={icon} />
              </AwardsImage>
              <StyledTitle>
                <RichText field={title} />
              </StyledTitle>
              <StyledDescription>
                <RichText field={description} />
              </StyledDescription>
            </Link>
          </Card>
        )
      )}
    </ItemsContainer>
  );
};

export default hideable(IndustryExperts);

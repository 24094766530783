import styled from '@emotion/styled';
import { COMPACT_HEADER_MEDIA_QUERY } from 'features/v2/Header/constants';
import { theme } from 'theme-v2/theme';

export const Wrapper = styled.div({
  width: '100%',
  background: 'transparent',
  position: 'absolute',
});

export const BreadcrumbsContainer = styled.ul({
  margin: '0 auto',
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: theme.spacings.S,
  paddingLeft: '0',
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  listStyle: 'none',
  maxWidth: '1515px',
});

export const ListItem = styled.li<{ textColor: string }>(({ textColor }) => ({
  padding: 0,
  paddingLeft: '5px',
  a: {
    fontWeight: 300,
    color: textColor ? theme.colors.black.default : theme.colors.white.default,

    ':hover': {
      textDecoration: 'underline',
    },
  },
  span: {
    color: textColor ? theme.colors.black.default : theme.colors.white.default,
  },
  p: {
    fontWeight: 300,
    fontSize: theme.fonts.size.p_xsmall,
    color: theme.colors.gray.default,
  },
  ':first-of-type': {
    paddingLeft: '40px',
  },
  [COMPACT_HEADER_MEDIA_QUERY]: {
    ':first-of-type': {
      paddingLeft: '20px',
    },
  },
}));

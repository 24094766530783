import BadgesBlock from 'features/BadgesBlock';
import {
  BiggerPlansBanner,
  FaqBlock,
  PrismicBlogImage,
  PrismicImageTabs,
} from '@smartproxy-web/features';
import Breadcrumbs from 'features/Breadcrumbs';
import CtaBlock from 'features/CtaBlock';
import Hero from 'features/Hero';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import PaymentMethod from 'features/PaymentMethod';
import { Spacing } from 'features/Spacing/Spacing';
import { SliceMap } from 'types';
import VideoBlock from 'features/VideoBlock';
import { SocialProofBlock } from '@smartproxy-web/features';
import FeaturedIn from 'features/FeaturedIn';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import SimpleCardsSlice from 'features/v2/SimpleCardsSlice/SimpleCardsSlice';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { PrismicTextGrid, TextBlock } from '@smartproxy-web/features';

export enum CONFIGURATION_SLICES {
  Hero = 'hero_block',
  Breadcrumbs = 'breadcrumbs',
  ImageTextBlock = 'image_text_block',
  TextGrid = 'text_grid',
  BiggerPlansBanner = 'bigger_plans',
  PaymentMethod = 'payment_method',
  BadgesBlock = 'badges_block',
  TextBlock = 'text_block',
  BlogImage = 'blog_image',
  CtaBlock = 'cta_block',
  Spacing = 'spacing',
  VideoBlock = 'video_block',
  SimpleCardsSlice = 'simple_cards_slice',
  FaqBlock = 'faq_block',
  SocialProofBlock = 'social_proof_block',
  FeaturedIn = 'featured_in',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  AutomatedPricingCards = 'automated_pricing_cards',
  ImageTabs = 'image_tabs',
}

export const configurationSliceMap: SliceMap<CONFIGURATION_SLICES> = {
  [CONFIGURATION_SLICES.Hero]: Hero,
  [CONFIGURATION_SLICES.Breadcrumbs]: Breadcrumbs,
  [CONFIGURATION_SLICES.ImageTextBlock]: ImageTextBlock,
  [CONFIGURATION_SLICES.TextGrid]: PrismicTextGrid,
  [CONFIGURATION_SLICES.BiggerPlansBanner]: BiggerPlansBanner,
  [CONFIGURATION_SLICES.PaymentMethod]: PaymentMethod,
  [CONFIGURATION_SLICES.BadgesBlock]: BadgesBlock,
  [CONFIGURATION_SLICES.TextBlock]: TextBlock,
  [CONFIGURATION_SLICES.BlogImage]: PrismicBlogImage,
  [CONFIGURATION_SLICES.CtaBlock]: CtaBlock,
  [CONFIGURATION_SLICES.Spacing]: Spacing,
  [CONFIGURATION_SLICES.SimpleCardsSlice]: SimpleCardsSlice,
  [CONFIGURATION_SLICES.VideoBlock]: VideoBlock,
  [CONFIGURATION_SLICES.FaqBlock]: FaqBlock,
  [CONFIGURATION_SLICES.SocialProofBlock]: SocialProofBlock,
  [CONFIGURATION_SLICES.FeaturedIn]: FeaturedIn,
  [CONFIGURATION_SLICES.FooterBanner]: FooterBanner,
  [CONFIGURATION_SLICES.Herov2]: Herov2,
  [CONFIGURATION_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [CONFIGURATION_SLICES.HeroLogosv2]: HeroLogosv2,
  [CONFIGURATION_SLICES.AutomatedPricingCards]: AutomatedPricingCards,
  [CONFIGURATION_SLICES.ImageTabs]: PrismicImageTabs,
};

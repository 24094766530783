import React from 'react';
import GatsbyImg from 'components/GatsbyImg';
import { TCard } from '../types';
import {
  CardItem,
  StyledCta,
  StyledPriceContainer,
  StyledPrice,
  ImageContainer,
  CardTitle,
} from './styledComponents';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import Benefits from './Benefits';
import { PRISMIC_VALUES } from 'constants/common';
import { RichText } from 'components/v2/RichText';

const Card: React.FC<TCard> = ({
  card_icon: icon,
  card_price: price,
  card_name: cardTitle,
  benefit_1: benefit1,
  benefit_2: benefit2,
  benefit_3: benefit3,
  cta_label: ctaLabel,
  cta_link_field: ctaLink,
}) => (
  <CardItem>
    <StyledPriceContainer>
      <small>{PRISMIC_VALUES.FROM}</small>
      <StyledPrice>
        <RichText field={price} />
      </StyledPrice>
    </StyledPriceContainer>
    <ImageContainer>
      <GatsbyImg image={icon} />
    </ImageContainer>
    <CardTitle>
      <RichText field={cardTitle} />
    </CardTitle>
    <Benefits benefit1={benefit1} benefit2={benefit2} benefit3={benefit3} />
    <StyledCta
      isVisible={ctaLabel.text && ctaLink.url}
      ctaText={ctaLabel.text}
      ctaLink={ctaLink.url}
      themeName={BUTTON_THEMES.IMPORTANT}
      themeType={BUTTON_TYPES.SECONDARY}
    />
  </CardItem>
);

export default Card;

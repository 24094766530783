import { IconContactSalesv2, IconMoneyBackv2 } from 'images';
import {
  CardTitle,
  CtaContainer,
  MoneyBack,
  PricingCardContainer,
  StyledCta,
  PriceWrapper,
  ExtraDescription,
} from './styled';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';

export type ExtraPricingCardProps = {
  title: string;
  icon: React.ReactNode;
  description: string;
  buttonText: string;
  buttonLink: string;
  moneyBackText: string;
};

export default function ExtraPricingCard({
  title,
  icon,
  description,
  buttonText,
  buttonLink,
  moneyBackText,
}: ExtraPricingCardProps) {
  return (
    <PricingCardContainer>
      <CardTitle>{title}</CardTitle>

      <PriceWrapper style={{ height: 'auto' }}>{icon}</PriceWrapper>

      <ExtraDescription>{description}</ExtraDescription>

      <CtaContainer>
        <StyledCta
          isButtonBlue={false}
          ctaText={buttonText}
          ctaLink={buttonLink}
          isVisible={true}
          themeName={BUTTON_THEMES.DEFAULT}
          themeType={BUTTON_TYPES.SECONDARY}
          icon={<IconContactSalesv2 />}
        />
      </CtaContainer>
      <MoneyBack>
        <IconMoneyBackv2 /> <p>{moneyBackText}</p>
      </MoneyBack>
    </PricingCardContainer>
  );
}

import { graphql, useStaticQuery } from 'gatsby';
import { PrismicTextField, Slice } from 'types';

interface PrismicFooterV2Primary {
  category: PrismicTextField;
}
export interface PrismicFooterV2 {
  prismicFooterV2: {
    data: {
      body: Slice<PrismicFooterV2Primary, Record<string, string>[]>[];
    };
  };
}

export const footerQuery = (): PrismicFooterV2 =>
  useStaticQuery(graphql`
    query FooterQuery {
      prismicFooterV2 {
        data {
          body {
            ... on PrismicFooterV2DataBodyNavItem {
              id
              slice_type
              primary {
                category {
                  richText
                  text
                }
              }
              items {
                link {
                  url
                }
                link_label {
                  richText
                  text
                }
              }
            }
            ... on PrismicFooterV2DataBodyFooterActionList {
              id
              items {
                name
                image {
                  alt
                  url
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              slice_type
            }
          }
        }
      }
    }
  `);

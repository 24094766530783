import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';

export const BackgroundWrapper = styled.section<ITheme & IColor>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    '> h2': {
      marginBottom: themev2.spacings.L,
    },
  })
);

export const Title = styled.div<ITheme>((props) => ({
  'h1,h2,h3,h4,h5,h6': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const Subtitle = styled.div<ITheme>((props) => ({
  'h2,h3,h4,h5,h6': {
    fontSize: '22px',
    fontWeight: 500,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const TextContent = styled.div<ITheme>((props) => ({
  'p, strong, em, a': {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
  },
  p: {
    marginBottom: '27px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  a: {
    ':hover': {
      textDecoration: 'underline',
    },
  },
  'ol, ul': {
    paddingLeft: '20px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
    listStyle: 'initial',
    li: {
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
  },
}));

export const FaqList = styled.ul<ITheme>({
  listStyle: 'none',
  padding: 0,
  width: '100%',
  maxWidth: '1024px',
});

export const FaqItem = styled.li<ITheme>((props) => ({
  padding: 0,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import React from 'react';
import hideable from 'utils/hideable';
import {
  Card,
  AwardsImage,
  StyledTitle,
  ItemsContainer,
  StyledDescription,
} from './styledComponents';
import { AwardsProps } from './types';

const Awards: React.FC<AwardsProps> = ({ repeatable_zone: repeatableZone }) => (
  <ItemsContainer>
    {repeatableZone.map(({ logo, title, description }) => (
      <Card key={title.text} isClickable={false}>
        <AwardsImage>
          <GatsbyImg image={logo} />
        </AwardsImage>
        <StyledTitle>
          <RichText field={title} />
        </StyledTitle>
        <StyledDescription>
          <RichText field={description} />
        </StyledDescription>
      </Card>
    ))}
  </ItemsContainer>
);

export default hideable(Awards);

import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import React, { useLayoutEffect, useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import { OFFSET_START, SCROLL_POSITION } from './constants';
import {
  CloseIcon,
  HideableContainer,
  Content,
  Description,
  ImageWrapper,
  Title,
} from './styledComponents';
import { TDialogContainer } from './types';

const DialogContainer: React.FC<TDialogContainer> = ({
  cta_link: ctaLink,
  cta_text: ctaText,
  description,
  image,
  title,
  isVisible,
  setIsDialogOpen,
  onClose,
}) => {
  const { tags, type } = usePageContext();
  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState(OFFSET_START);

  const onScroll = () => {
    const offsetButton = window.pageYOffset / -OFFSET_START + OFFSET_START;
    const dialogVisible = window.pageYOffset >= SCROLL_POSITION;
    const dialogOpacity =
      (window.pageYOffset - SCROLL_POSITION) / SCROLL_POSITION;
    setIsDialogOpen(dialogVisible);
    setOpacity(dialogOpacity);
    if (offsetButton < 0) {
      setOffset(0);
    } else {
      setOffset(offsetButton);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isVisible]);

  return (
    <HideableContainer
      opacity={opacity}
      offset={Math.round(offset)}
      isVisible={isVisible}
    >
      <CloseIcon
        onClick={onClose}
        data-tracker={buildTrackingSelector({
          page: tags[0] || type,
          section: 'dialog',
          context: 'close',
          type: 'button',
        })}
      />
      <ImageWrapper>
        <GatsbyImg image={image} />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Description>
          <RichText field={description} />
        </Description>
      </Content>
      <Cta
        data-tracker={buildTrackingSelector({
          page: tags[0] || type,
          section: 'dialog',
          type: 'cta',
        })}
        sidePadding="12px"
        fontSize={TSHIRT_SIZE.L}
        ctaText={ctaText}
        ctaLink={ctaLink.url}
        isVisible
      />
    </HideableContainer>
  );
};

export default DialogContainer;

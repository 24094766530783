import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  ArticleAuthorsContainer,
  ArticleAuthorsInfoContainer,
  HideableArticleAuthorName,
  HideableArticleAuthorsImageContainer,
  HideableArticleReleaseDate,
} from './styledComponents';
import { PrismicTextField, TGatsbyImage } from 'types';

interface IArticlesAuthor {
  authorName: PrismicTextField;
  authorImage: TGatsbyImage;
  releaseDate: string;
}

export const ArticleAuthorItem: React.FC<IArticlesAuthor> = ({
  authorImage,
  authorName,
  releaseDate,
}) => (
  <ArticleAuthorsContainer>
    <HideableArticleAuthorsImageContainer isVisible={authorImage}>
      <GatsbyImg image={authorImage} />
    </HideableArticleAuthorsImageContainer>
    <ArticleAuthorsInfoContainer>
      <HideableArticleAuthorName isVisible={authorName?.text}>
        {authorName?.text}
      </HideableArticleAuthorName>
      <HideableArticleReleaseDate isVisible={releaseDate}>
        {releaseDate}
      </HideableArticleReleaseDate>
    </ArticleAuthorsInfoContainer>
  </ArticleAuthorsContainer>
);

import { Wrapper } from 'commonStyles';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { scrapingSliceMap, SCRAPING_SLICES } from './constants';
import { IWithBlogProps } from 'pageUtils/blog/types';

interface IWithPrismicScraping {
  prismicScraping: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicScraping;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const ScrapingPage = ({ data }: Props & PageTemplateProps & IWithBlogProps) => {
  const { prismicScraping, allPrismicBlog } = data;
  const templates = getTemplates<SCRAPING_SLICES, DefaultSlice>(
    prismicScraping.data.body,
    scrapingSliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} articles={allPrismicBlog.nodes} />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query ScrapingQuery($id: String!) {
    prismicScraping(id: { eq: $id }) {
      _previewable
      data {
        meta_title
        meta_description
        robots
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicScrapingDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyTabbedCodeBlock {
            id
            items {
              code {
                richText
                text
              }
              language
            }
            slice_type
            primary {
              cta_label_1
              cta_label_2
              cta_link_1 {
                url
              }
              cta_link_2 {
                url
              }
              show_copy_button
              subtitle {
                richText
                text
              }
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyBadgesBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              is_text_centered
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
          }
          ... on PrismicScrapingDataBodyPaymentMethod {
            id
            slice_type
            primary {
              icons_text {
                richText
                text
              }
              background
              security_description {
                richText
                text
              }
              security_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              security_title {
                richText
                text
              }
            }
            items {
              payment_method_icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicScrapingDataBodyComparisonTable {
            id
            items {
              cell_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              cell_value {
                richText
                text
              }
              column
              cta_color
              cta_text
              cta_url {
                url
              }
            }
            primary {
              is_upper_content_centered
              background
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_text
              cta_link {
                url
              }
              fifth_column_header
              fifth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              fourth_column_header
              second_column_header
              sixth_column_header
              fourth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              second_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              sixth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              subtitle {
                richText
                text
              }
              table_text_alignment
              third_column_header
              third_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicScrapingDataBodyPromoBanner {
            id
            slice_type
            items {
              end_date
              banner_icon {
                url
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              content
              cta_text
              cta_link {
                url
              }
            }
          }
          ... on PrismicScrapingDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyTrustpilotReviews {
            id
            items {
              review {
                richText
                text
              }
              star_rating {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              user_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              username {
                richText
                text
              }
            }
            primary {
              background
              trustpilot_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              link_to {
                url
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
              video_block_title {
                richText
                text
              }
              video_possition
              video_text_field {
                richText
                text
              }
              cta_title {
                text
                richText
              }
              cta_link {
                url
              }
              cta_position
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyImageTextBlock {
            slice_type
            id
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              is_background_dark
              is_split_equally
              image_zoom_modal
              is_image_right
              background
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
              sub_title {
                richText
                text
              }
              review_description
              review_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_bottom_text {
                richText
                text
              }
            }
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
            }
          }
          ... on PrismicScrapingDataBodyImageTextColumn {
            id
            items {
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
            primary {
              background
              numbers_of_columns
              cta_url {
                url
                target
              }
              cta_label {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              background
              columns
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyLocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              subtitle {
                richText
                text
              }
              available_cities {
                richText
                text
              }
              background
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicScrapingDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyColumnLinks {
            id
            items {
              text {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              number_of_columns
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
            }
          }
          ... on PrismicScrapingDataBodyCtaBlock {
            id
            primary {
              background
              is_centered
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyColumnText {
            id
            items {
              text {
                richText
                text
              }
            }
            primary {
              columns_number
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicScrapingDataBodyTableOfContent {
            id
            slice_type
            primary {
              table_title {
                richText
                text
              }
            }
            items {
              link_label {
                richText
                text
              }
              link_to {
                url
              }
            }
          }
          ... on PrismicScrapingDataBodyRelatedArticles {
            id
            slice_type
            primary {
              background_color
              title {
                richText
                text
              }
            }
            items {
              article {
                document {
                  ...BlogItemFragment
                }
              }
            }
          }
          ... on PrismicScrapingDataBodyCodeBlock {
            id
            slice_type
            primary {
              copy_option
              code_field {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodySideImageTextBlock {
            id
            slice_type
            primary {
              side_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              text_field {
                richText
                text
              }
              title_field {
                richText
                text
              }
              is_image_right
            }
          }
          ... on PrismicScrapingDataBodyIconListBlock {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
                target
              }
              text_field {
                richText
                text
              }
            }
            primary {
              is_text_centered
              column_number
              list_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicScrapingDataBodyDynamicTextAndImage {
            id
            primary {
              is_subtitle_centered
              is_image_on_left
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              background
            }
            items {
              cta_link {
                url
              }
              cta_text
              item_description {
                richText
                text
              }
              item_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              item_title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicScrapingDataBodyTargetsList {
            id
            slice_type
            items {
              card_item_image {
                alt
                url
                gatsbyImageData
              }
              card_item_link {
                url
              }
              card_item_text {
                richText
                text
              }
            }
            primary {
              background
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyProductCard {
            id
            slice_type
            items {
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              feature_one {
                richText
                text
              }
              feature_three {
                richText
                text
              }
              feature_two {
                richText
                text
              }
              icon {
                gatsbyImageData
                url
                alt
              }
              product_price
              price_time_period {
                richText
                text
              }
              product_description {
                richText
                text
              }
              product_title {
                richText
                text
              }
              recommended
              tooltip
              tooltip_text {
                richText
                text
              }
            }
            primary {
              accent_color
              background
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label_primary {
                text
                richText
              }
              cta_link_primary {
                url
              }
              main_description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_label_secondary {
                text
                richText
              }
              cta_link_secondary {
                url
              }
              main_title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicScrapingDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              features_description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              features_title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              logos_title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyAutomatedPricingCards {
            id
            items {
              pricing_card {
                type
                document {
                  ...PricingCardFragment
                  ...PricingCardsFeaturesFragment
                }
              }
            }
            slice_type
            primary {
              background_color
              is_limited_time_offer_visible
              enterprise_benefit
              subtitle {
                richText
                text
              }
              switch_enterprise_label
              switch_regular_label
              title {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyNewProductCards {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_badge_color
              card_badge_label {
                richText
                text
              }
              card_cta_label {
                richText
                text
              }
              card_cta_link {
                url
              }
              card_item_description {
                richText
                text
              }
              card_download_cta_link {
                url
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_item_title {
                richText
                text
              }
            }
            primary {
              background
              content_alignment
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicScrapingDataBodyExpandablePricingOverview {
            id
            slice_type
            items {
              badge_color
              badge_label
              checklist {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product {
                richText
                text
              }
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              product_starting_price
              time_unit
              price_prefix
            }
            primary {
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              checkmark {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              background
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicScrapingDataBodyQuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
          ... on PrismicScrapingDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(
                        formatString: "MMM DD, yyyy"
                        locale: "zh-CN"
                      )
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicBlog(sort: { fields: data___release_date, order: DESC }) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMMM DD, yyyy", locale: "zh-CN")
        data {
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMMM DD, yyyy", locale: "zh-CN")
          body {
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(ScrapingPage);
